/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { useGetProfileQuery, useLazyGetProfileQuery } from '../store/services/profleServices'
import { useLocation } from 'react-router-dom'

// Create the context
export const ProfileContext = createContext()

// Provider component
export const ProfileProvider = ({ children }) => {
  // Location
  const location = useLocation()

  const routes = ['/myDocument', '/Home', '/createDocument', '/help', '/myProfile']

  // State to hold the profile data
  const [profile, setProfile] = useState({})

  const [getProfile] = useLazyGetProfileQuery({})

  const { data: profileData = {}, isSuccess } = useGetProfileQuery({})

  useEffect(() => {
    console.log('in context data useEffect', profileData)
    if (profileData && isSuccess) {
      console.log('in context data', profileData)
      setProfile(profileData)
    }
  }, [profileData, isSuccess])

  useEffect(() => {
    // window.addEventListener('focus', handleGetProfile)
    // // Clean up the event listeners when the component unmounts
    // return () => {
    //   window.removeEventListener('focus', handleGetProfile)
    // }

    if (routes.includes(location.pathname)) {
      handleGetProfile()
      console.log('api profile reload please')
      // setRestrictionValues(restrictionData)
      // console.log('restrictionData', restrictionData)
    }
  }, [location])

  const handleGetProfile = () => {
    console.log('Profile get ')
    getProfile()
      .unwrap()
      .then((response) => {
        console.log(response?.message, response, 'fulfil:SOcialLOgin:success:LOGIN')
        setProfile(response)
      })
      .catch((execption) => {
        // toast.error(execption.message)
      })
  }

  // Provide the profile and updateProfile function to the children components
  return (
        <ProfileContext.Provider value={{ profile, setProfile }}>
            {children}
        </ProfileContext.Provider>
  )
}
