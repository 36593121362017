import React from 'react'
import { MenuItem, Select, Typography, FormControl, FormHelperText } from '@mui/material'
import PropTypes from 'prop-types'

const SelectDropdown = ({
  selectData,
  fieldLabel,
  onchange,
  onfocus,
  documentForm,
  sectionIndex
}) => {
  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <FormControl fullWidth error={documentForm?.errors[`${selectData?.id}`]}>
                    <Select
                        fullWidth
                        name={`span${selectData?.id}`}
                        value={
                            !documentForm?.form[`${selectData?.id}`] &&
                            documentForm?.form[`${selectData?.id}`] !== ''
                              ? selectData?.props?.prefilled
                              : documentForm?.form[`${selectData?.id}`]
                        }
                        onOpen={(event) => onfocus(event, selectData)}
                        onChange={(event) => onchange(event, selectData, sectionIndex)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ fontSize: '16px' }}>
                        {JSON.parse(selectData?.props?.options.replace(/'/g, '"')).length > 0 &&
                            JSON.parse(selectData?.props?.options.replace(/'/g, '"')).map(
                              (item) => (
                                    <MenuItem sx={{ fontSize: '16px' }} key={item} value={item}>
                                        {item}
                                    </MenuItem>
                              )
                            )}
                    </Select>
                    <FormHelperText>{documentForm?.errors[`${selectData?.id}`]}</FormHelperText>
                </FormControl>
            </div>
        </div>
  )
}

export default SelectDropdown

SelectDropdown.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  selectData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
