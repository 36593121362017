/* eslint-disable */
import { Grid, MenuItem, Select, styled, Typography } from '@mui/material';
import help from '../../../assets/images/helpimage.svg';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React, { useState } from 'react';
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles';
import { useGetFaqQuesByIdQuery, useGetFaqTopicsQuery } from '../../../store/services/helpServices';
import palette from '../../../theme/palette';
import { HELPFAQTYPE } from '../../../constants/enum';

// Accordian styles MUI
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        // border: `1px solid ${theme.palette.divider}`
    })
);

// AccordionDetails styles MUI
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    background: palette.primary.light
    // borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const Faq = () => {
    const [selectedTopic, setSelectedTopic] = useState(6);
    // State for accordian expand
    const [expanded, setExpanded] = useState(1);

    // Handle accordian expand state
    const handleExpandChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { data: faqQuesArray = [] } = useGetFaqQuesByIdQuery(selectedTopic, {
        skip: !selectedTopic
    });

    // Get all Faq topics according to the user role
    const { data: faqTopicsArray = [] } = useGetFaqTopicsQuery({});

    const handleChange = (event) => {
        setSelectedTopic(event.target.value);
    };

    return (
        <Grid container spacing={5}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                {/* Faq topics accordian */}
                {/* <Select
                    sx={{ fontSize: '16px', marginBottom: '30px', width: '60%' }}
                    value={selectedTopic}
                    labelId="faqTopic"
                    name="faqTopic"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChange}
                    MenuProps={MenuProps}>
                    <MenuItem value="" disabled>
                        <Typography>Select Topic</Typography>
                    </MenuItem>
                    {faqTopicsArray &&
                        faqTopicsArray?.map((item) => (
                            <MenuItem sx={{ fontSize: '16px' }} key={item.id} value={item.id}>
                                {item.topic}
                            </MenuItem>
                        ))}
                </Select> */}
                {faqQuesArray &&
                    faqQuesArray?.map((item, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded}
                            // onChange={handleExpandChange(index)}
                            sx={{ marginBottom: '20px' }}>
                            <AccordionSummary
                                // expandIcon={<img src={downarrow} />}
                                aria-controls="panel1d-content"
                                id="panel1d-header">
                                <Typography
                                    variant="modalLabelSmall"
                                    component="div"
                                    sx={{ fontWeight: 600, whiteSpace: 'pre-line' }}>
                                    {item?.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 2 }}>
                                {item?.type === HELPFAQTYPE?.TEXT?.ID && (
                                    <Typography variant="darkValue" sx={{ whiteSpace: 'pre-line' }}>
                                        {item?.content}
                                    </Typography>
                                )}
                                {item?.type === HELPFAQTYPE?.IMAGE?.ID && (
                                    <span>
                                        <img src={item?.content}></img>
                                    </span>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Grid>
            {/* Help Faq image */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <img src={help} alt="help" />
            </Grid>
        </Grid>
    );
};

export default Faq;
