export const WALLETHEADERBUSINESS = [
  'Transaction ID',
  'Date',
  'By Whom',
  'For What',
  'Amount',
  'Entry Type'
]

export const WALLETHEADERCORPORATE = [
  'Transaction ID',
  'Date',
  'By Whom',
  'Location',
  'For What',
  'Amount',
  'Entry Type'
]

export const WALLETTOBANKHEADERCORPORATE = ['Request ID', 'Date', 'By Whom', 'Amount', 'Status']
export const WALLETTOBANKHEADERBUSINESS = ['Request ID', 'Date', 'Amount', 'Status']
