import React from 'react'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

import deleteicon from '../../../../../assets/actions/actionDelete.svg'
import editicon from '../../../../../assets/actions/actionEdit.svg'

// import emailicon from '../../../../../assets/actions/actionEmail.svg'
import cross from '../../../../../assets/images/cross.svg'
import checked from '../../../../../assets/images/checked.svg'
import PropTypes from 'prop-types'
// import { useSendDocumentForReviewMutation } from '../../../../../store/services/documentServices'
import { DOCUEMENTFILTERSTATUS } from '../../../../../constants/documentConstans'
// import { toast } from 'react-toastify'
import palette from '../../../../../theme/palette'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: palette.text.main,
    color: palette.primary.light,
    fontSize: '14x',
    fontWeight: 400
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: palette.primary.light,
    color: palette.text.dark,
    fontSize: '14px',
    fontWeight: 400
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const PartySignTable = ({
  documentReviewer,
  handleDeleteReviewer,
  documentId,
  documentStatus,
  handleEditReviewer
}) => {
  const tableHeaders = ['Parties', 'Email', 'Phone', 'Address', 'Priority', 'Action', 'Status']

  // Send document for review
  // const [sendDocumentForReview] = useSendDocumentForReviewMutation({})

  /**
     * Handle Resend  Review invite mail
     * @param {array} reviewerData: Selected reviewer data to resend invite mail
     */
  // const handleResendInviteReview = (reviewerData) => {
  //   console.log(reviewerData)
  //   const dataToSend = {
  //     reviewerList: reviewerData,
  //     action: 'resend-mail'
  //     // stampDutyPaidBy: stampDutyInformation?.stampDutyPaid
  //     //   ? stampDutyInformation?.stampDutyPaid
  //     //   : null,
  //     // stampDutyPurchasedBy: stampDutyInformation?.stampDutyPurchased
  //     //   ? stampDutyInformation?.stampDutyPurchased
  //     //   : null
  //   }
  //   sendDocumentForReview({ payload: dataToSend, documentId })
  //     .unwrap()
  //     .then((response) => {
  //       toast.success(response?.message)
  //       // console.log('payment Status', paymentStatus)
  //     })
  //     .catch((execption) => {
  //       console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
  //     })
  // }

  return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeaders?.map((index) => (
                            <StyledTableCell key={index} align="left">
                                {index}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documentReviewer?.map((item, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell scope="row">{item?.partyType?.name}</StyledTableCell>
                            <StyledTableCell align="left">{item?.email}</StyledTableCell>
                            <StyledTableCell align="left">{item?.phone}</StyledTableCell>
                            <StyledTableCell align="left">{item?.address}</StyledTableCell>
                            <StyledTableCell align="left">{item?.priority}</StyledTableCell>

                            <StyledTableCell align="left">
                                <div className="actionIcons">
                                    {/* {documentStatus?.id !== DOCUEMENTFILTERSTATUS?.INDRAFT.ID &&
                                        item?.status?.id !==
                                            DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && ( */}
                                    {/* <img
                                        src={emailicon}
                                        alt="emailicon"
                                        title="Resend mail"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleResendInviteReview([item])}
                                    /> */}
                                    {/* )} */}
                                    {/* {documentStatus?.id === DOCUEMENTFILTERSTATUS?.INDRAFT.ID && ( */}
                                    <img
                                        onClick={() => handleDeleteReviewer(item)}
                                        src={deleteicon}
                                        alt="deleteIcon"
                                        title="Delete reviewer"
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <img
                                        src={editicon}
                                        alt="editicon"
                                        title="Edit mail"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleEditReviewer(item)}
                                    />
                                    {/* )} */}
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className="actionIcons">
                                    {item?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && (
                                        <img
                                            src={checked}
                                            alt="checked"
                                            title="Submitted"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                    {item?.status?.id !==
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && (
                                        <img
                                            src={cross}
                                            alt="deleteIcon"
                                            title="Requested"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
  )
}

export default PartySignTable

PartySignTable.propTypes = {
  documentReviewer: PropTypes.array,
  handleDeleteReviewer: PropTypes.func,
  documentId: PropTypes.string,
  documentStatus: PropTypes.object,
  handleEditReviewer: PropTypes.func
}
