import React from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import {
  ReviewStyledTableCell,
  ReviewStyledTableRow
} from '../../../../StyledComponents/TableStyles/ReviewTableStyles'
import PropTypes from 'prop-types'
import PinchIcon from '@mui/icons-material/Pinch'
import CancelIcon from '@mui/icons-material/Cancel'

const BulkSignatureSelectionReviewTable = ({
  tableData,
  selectedParty,
  handleSelectionActionButton,
  handleRemoveUserSignature,
  signatureAddedList
}) => {
  const tableHeaders = ['Party', 'Action', 'Status']

  return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeaders?.map((index) => (
                            <ReviewStyledTableCell key={index} align="left">
                                {index}
                            </ReviewStyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map((item, index) => (
                        <ReviewStyledTableRow key={index}>
                            <ReviewStyledTableCell scope="row">{item?.name}</ReviewStyledTableCell>
                            <ReviewStyledTableCell align="left">
                                {signatureAddedList.includes(item?.id) ? (
                                    <Tooltip title="Delete Signature" aria-label="Delete Signature">
                                        <IconButton
                                            size="small"
                                            aria-label="Delete Signature"
                                            sx={{ color: '#1dadae' }}
                                            onClick={() => {
                                              handleRemoveUserSignature(item)
                                            }}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Select Signature" aria-label="Select Signature">
                                        <IconButton
                                            size="small"
                                            aria-label="Select Signature"
                                            sx={{
                                              color:
                                                    selectedParty?.id === item?.id
                                                      ? 'red'
                                                      : '#1dadae'
                                            }}
                                            onClick={() => {
                                              handleSelectionActionButton(item)
                                            }}>
                                            <PinchIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ReviewStyledTableCell>

                            <ReviewStyledTableCell align="left">
                                <div className="actionIcons"></div>
                            </ReviewStyledTableCell>
                        </ReviewStyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
  )
}

export default BulkSignatureSelectionReviewTable

BulkSignatureSelectionReviewTable.propTypes = {
  tableData: PropTypes?.array,
  selectedParty: PropTypes?.object,
  handleRemoveUserSignature: PropTypes?.func,
  handleSelectionActionButton: PropTypes.func,
  signatureAddedList: PropTypes.array
}
