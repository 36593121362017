/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../assets/images/cross.svg'
import OtpInput from 'react18-input-otp'
import { useLazyResendOtpQuery } from '../../store/services/userServices'
import { toast } from 'react-toastify'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 3
}

// eslint-disable-next-line react/prop-types
const OtpModal = ({
  openOtpModal,
  handleOtpModalClose,
  code,
  setCode,
  counter,
  setCounter,
  phoneNumberUpdate,
  handleVerifyOtp
}) => {
  // const [verifyOtp] = useLazyVerifyOtpQuery()
  const [resendOtp] = useLazyResendOtpQuery()
  // For otp verification
  const handleOtpChange = (code) => setCode(code)
  const [enableResendOTP, setEnableResendOTP] = useState(false)
  const secondsToDisplay = counter % 60

  // For resend otp
  const twoDigits = (num) => String(num).padStart(2, '0')

  useEffect(() => {
    if (counter === 0) setEnableResendOTP(true)
    else setEnableResendOTP(false)
    const timer =
            counter > 0 &&
            setInterval(() => {
              setCounter(counter - 1)
            }, 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handelVerify = () => {
    if (code.length === 6) {
      handleVerifyOtp()
    } else {
      toast.error('please fill 6 digit otp')
    }
  }

  const resendOTP = () => {
    // const dataToSend = {
    //   phoneNumber
    // }
    resendOtp(phoneNumberUpdate)
      .unwrap()
      .then((response) => {
        toast.success(response.message)
        setCode('')
        setCounter(30)
      })
      .catch((execption) => {
        console.log('error')
        toast.error(execption.message)
      })
  }

  return (
        <Modal
            open={openOtpModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid container justifyContent={'flex-end'}>
                    <img
                        src={cross}
                        alt=""
                        height="21px"
                        width="21px"
                        onClick={handleOtpModalClose}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid>
                <form
                    onSubmit={(event) => {
                      event.preventDefault()
                      handelVerify()
                    }}>
                    <div className="aadharmodal_box">
                        <Typography
                            variant="labelLarge"
                            component="div"
                            sx={{ fontSize: '25px', marginBottom: '20px', fontWeight: 600 }}>
                            Enter OTP
                        </Typography>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ lineHeight: '17px', marginBottom: '20px' }}>
                            Enter OTP sent to your phone number ending with **
                            {phoneNumberUpdate?.toString().slice(8)}
                        </Typography>
                        <OtpInput
                            value={code}
                            onChange={handleOtpChange}
                            numInputs={6}
                            separator={
                                <span
                                    style={{
                                      width: '32px',
                                      marginLeft: '2px',
                                      fontWeight: 'bold'
                                    }}></span>
                            }
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle={{
                              boxSizing: 'border-box',
                              backgroundColor: palette.primary.light,
                              border: `1px solid ${palette.secondary.dark}`,
                              borderRadius: '0px',
                              width: '50.17px',
                              height: '45px',
                              fontSize: '18px',
                              color: palette.text.main,
                              fontWeight: '600',
                              caretColor: palette.primary.dark,
                              marginBottom: '20px'
                            }}
                            focusStyle={{
                              width: '50.17px',
                              height: '45px',
                              border: `1px solid ${palette.secondary.dark}`,
                              outline: 'none',
                              fontWeight: '600'
                            }}
                        />
                        <Grid container sx={{ gap: '20px' }}>
                            <Button variant="primary" sx={{ width: '120px' }} type="submit">
                                Verify
                            </Button>
                            <Button
                                disabled={!enableResendOTP}
                                onClick={resendOTP}
                                variant="cancelButton">
                                Resend code
                                {counter !== 0 && (
                                    <div
                                        style={{
                                          position: 'relative',
                                          left: '5px',
                                          fontWeight: 500
                                        }}>
                                        {` in ${twoDigits(secondsToDisplay)}sec`}
                                    </div>
                                )}
                            </Button>
                        </Grid>
                    </div>
                </form>
            </Box>
        </Modal>
  )
}

export default OtpModal
