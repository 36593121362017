import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '../../../../StyledComponents/AccordianStyles/AccordionStyles'
import { useNavigate, useParams } from 'react-router-dom'
import PreviewScreen from './PreviewScreen/PreviewScreen'
import { useGetDocumentPreviewAndReviewUsersByIdQuery } from '../../../../store/services/signatureService'
import CommonTableReview from '../../../../components/CommonTableReview/CommonTableReview'

const SignatureRequestedPreview = () => {
  const { documentId } = useParams()

  const navigate = useNavigate()

  // Accordian expanded state
  const [expanded, setExpanded] = useState(0)

  // Get document Preview and Review section data
  const {
    data: documentDataResponse = {
      review: [],
      transactions: []
    }
  } = useGetDocumentPreviewAndReviewUsersByIdQuery(documentId, {
    skip: !documentId
  })

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button variant="cancelButton" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={6}>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Accordion expanded={expanded === 0} onChange={handleChange(0)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography variant="darkValue">Details</Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    <CommonTableReview
                                        tableData={documentDataResponse?.transactions}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
                <Grid lg={6} item>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Grid
                            className="details_rightgrid-box"
                            sx={{ maxHeight: 'calc(100vh - 16rem)' }}>
                            <PreviewScreen file={documentDataResponse?.unsignedFile?.fileUri} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
  )
}

export default SignatureRequestedPreview
