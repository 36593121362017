import React, { useState, useEffect } from 'react'
import './Sidebar.css'
import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/headerLogo/digidoc.svg'
import {
  SIDEBAR,
  SIDEBARNO_VISIBLE
  // SIDEBAR_BUSINESS,
  // SIDEBAR_CORPORATE,
  // SIDEBAR_INDIVIDUAL,
  // SUB_ROLE
} from '../../constants/enum'
import { getSession } from '../../util/helper'
import { Grid, Typography } from '@mui/material'
import palette from '../../theme/palette'

export default function Sidebar () {
  const [selectedItem, setSelectedItem] = useState('Home')
  const [visibility] = useState('visible')
  const navigate = useNavigate()
  const location = useLocation()

  const noVisible = [SIDEBARNO_VISIBLE?.SUBSCRIPTION, SIDEBARNO_VISIBLE?.VERIFYSUBSCRIPTION]

  const noVisibleActive = [
    SIDEBARNO_VISIBLE?.SUBSCRIPTION,
    SIDEBARNO_VISIBLE?.VERIFYSUBSCRIPTION,
    SIDEBARNO_VISIBLE?.MYPROFILE
  ]

  const role = JSON.parse(getSession('user')).role
  const subRole = JSON.parse(getSession('user')).subRole

  // const sidebarContent =
  //       role === 'INDIVIDUAL'
  //         ? SIDEBAR_INDIVIDUAL
  //         : role === 'BUSINESS' && (subRole === SUB_ROLE?.OWNER || subRole === SUB_ROLE?.ADMIN)
  //           ? SIDEBAR_BUSINESS
  //           : SIDEBAR_CORPORATE

  const sidebarContent = SIDEBAR.filter(
    (item) => item.roles.includes(role) && item.subRoles.includes(subRole)
  )
  const sidebarActive = [
    {
      title: 'Home',
      route: '/Home'
    },
    {
      title: 'My Document',
      route: '/myDocument'
    },
    {
      title: 'Payment History',
      route: '/invoice'
    },
    {
      title: 'Delivery Status',
      route: '/physicalDocument'
    },
    {
      title: 'Create Document',
      route: '/createDocument'
    },
    {
      title: 'Help',
      route: '/help'
    },
    {
      title: 'Help',
      route: '/help/tickets'
    },
    {
      title: 'Help',
      route: '/addTicket'
    },
    {
      title: 'Help',
      route: '/ticketSummary'
    },
    {
      title: 'Create Document',
      route: '/documentDetails'
    },
    {
      title: 'Create Document',
      route: '/paymentSummary'
    },
    {
      title: 'Create Document',
      route: '/thankYou'
    },
    {
      title: 'Create Document',
      route: '/customDocument'
    },
    {
      title: 'Create Document',
      route: '/commentSection'
    },
    {
      title: 'Create Document',
      route: '/signature'
    },
    {
      title: 'Create Document',
      route: '/customDocument/:documentId'
    },
    {
      title: 'Manage Staff Users',
      route: '/manageStaffUsers'
    },
    {
      title: 'Manage Staff Users',
      route: '/addNewUser'
    },
    {
      title: 'Wallet & Transaction',
      route: '/walletAndTransactions'
    }
  ]

  function handleSidebarClick (sidebarItem) {
    // if (sidebarItem.title === "")
    //   setVisibility((prev) => (prev === "visible" ? "hidden" : "visible"));
    // else
    console.log(sidebarItem)
    setSelectedItem(sidebarItem.title)
    navigate(`${sidebarItem.route}`, {
      state: {
        HeaderTitle: sidebarItem.title
      }
    })
  }

  useEffect(() => {
    const headerText = sidebarActive.find((item) =>
      item.route.includes(location.pathname.split('/')[1])
    )
    console.log('in useEffect called', location.pathname, headerText)

    setSelectedItem(headerText?.title)
  }, [location])

  return (
        <Grid
            sx={{
              backgroundColor: palette.primary.light,
              borderRight: `1px solid ${palette.text.light}`
            }}
            id={noVisible.includes(location.pathname) ? 'SidebarHidden' : 'Sidebar'}
            className={visibility}>
            <Grid mt={3} ml={2} mb={3}>
                <img src={logo} />
            </Grid>
            {sidebarContent.map((sidebarItem, key) => (
                <Typography
                    variant="modalLabelBold"
                    // sx={{ fontWeight: 600 }}
                    id="Sidebaritem"
                    sx={{
                      borderRight:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname)
                              ? `5px solid ${palette.sidebar.main}`
                              : palette.text.main,
                      backgroundColor:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname)
                              ? palette.secondary.light
                              : palette.primary.light,
                      fontWeight:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname) &&
                            700
                    }}
                    key={key}
                    onClick={() => handleSidebarClick(sidebarItem)}>
                    <img id="Sidebaritem_icon" src={sidebarItem.logo} />
                    {visibility === 'visible' ? (
                        <div id="Sidebaritem_title">{sidebarItem.title}</div>
                    ) : (
                      ''
                    )}
                </Typography>
            ))}
        </Grid>
  )
}
