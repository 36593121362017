import { Box, Button, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import palette from '../../theme/palette'
import { RestrictionContext } from '../../contextapi/RestrictionContext'

const ProfileCorporate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selected, setselected] = useState('myProfileCorporate')
  // Restriction context api
  const { restrictionValues } = useContext(RestrictionContext)

  const handleMyProfile = () => {
    navigate('/myProfileCorporate', {
      state: {
        HeaderTitle: 'My Profile'
      }
    })
  }

  const handleChangePassword = () => {
    navigate('/myProfileCorporate/changePassword', {
      state: {
        HeaderTitle: 'Change Password'
      }
    })
  }

  const handleSubscription = () => {
    navigate('/myProfileCorporate/subscription', {
      state: {
        HeaderTitle: 'Subscription'
      }
    })
  }

  // const handleAssociatedBranch = () => {
  //   navigate('/myProfileCorporate/associatedBranch', {
  //     state: {
  //       HeaderTitle: 'Associated Branch'
  //     }
  //   })
  // }

  useEffect(() => {
    if (location?.pathname) {
      const text = location?.pathname.split('/')
      setselected(text[text.length - 1])
    }
  }, [location?.pathname])

  return (
        <Box p={3} mt={2}>
            <Box
                sx={{
                  border: `0.5px solid ${palette.text.light}`,
                  background: palette.secondary.light,
                  borderRadius: '5px'
                }}>
                <Grid
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt="-20px">
                    <Button
                        variant={
                            selected === 'myProfileCorporate' || selected === 'editProfile'
                              ? 'tabSelected'
                              : 'tabUnselected'
                        }
                        sx={{ borderRadius: '5px 0px 0px 5px' }}
                        onClick={handleMyProfile}>
                        My Profile
                    </Button>
                    <Button
                        variant={selected === 'changePassword' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px' }}
                        onClick={handleChangePassword}>
                        Change Password
                    </Button>
                    <Button
                        variant={
                            !restrictionValues?.subscriptionPurchased &&
                            !restrictionValues?.isSubscriptionCanceled
                              ? 'tabDisabled'
                              : selected === 'subscription' || selected === 'editSubscription'
                                ? 'tabSelected'
                                : 'tabUnselected'
                        }
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        disabled={
                            !restrictionValues?.subscriptionPurchased &&
                            !restrictionValues?.isSubscriptionCanceled
                        }
                        onClick={handleSubscription}>
                        Subscription
                    </Button>
                    {/* <Button
                        variant={selected === 'associatedBranch' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        onClick={handleAssociatedBranch}>
                        Associated Branch
                    </Button> */}
                </Grid>
                <Outlet />
            </Box>
        </Box>
  )
}

export default ProfileCorporate
