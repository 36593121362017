import React from 'react'
import parse from 'html-react-parser'
import { useGetTermsAndConditionsQuery } from '../../store/services/userServices'
import { useParams } from 'react-router-dom'
// import { TERMTYPES } from '../../constants/enum'

const TermsAndConditions = () => {
  const { termType } = useParams()
  console.log('Term type', termType)
  // const [content, setContent] = useState('')

  const { data: termsAndConditions = {} } = useGetTermsAndConditionsQuery({})

  // useEffect(() => {
  //   if (termsAndConditions != null) {
  //     if (termType === TERMTYPES.TERMSANDCONDITIONS.value) {
  //       setContent(termsAndConditions?.termsAndConditions)
  //     } else if (termType === TERMTYPES.PRIVACYPOLICY.value) {
  //       setContent(termsAndConditions?.privacyPolicy)
  //     } else {
  //       setContent(termsAndConditions?.disclaimer)
  //     }
  //   }
  // }, [termsAndConditions])

  return (
        <>
            {termsAndConditions[`${termType}`] ? parse(termsAndConditions[`${termType}`]) : ''}
            {/* {parse(documentPreviewConstant)} */}
        </>
  )
}

export default TermsAndConditions
