import React from 'react'
import {
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  FormHelperText
} from '@mui/material'
import PropTypes from 'prop-types'
import { PATTERN } from '../../constants/enum'

const SelectTextField = ({
  fieldLabel,
  selectData,
  textFieldData,
  onchange,
  onfocus,
  documentForm,
  sectionIndex
}) => {
  const selectValue =
        !documentForm?.form[`${selectData?.id}`] && documentForm?.form[`${selectData?.id}`] !== ''
          ? selectData?.props?.prefilled
          : documentForm?.form[`${selectData?.id}`]
  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <FormControl fullWidth error={documentForm?.errors[`${selectData?.id}`]}>
                    <Select
                        name={`span${selectData?.id}`}
                        value={
                            !documentForm?.form[`${selectData?.id}`] &&
                            documentForm?.form[`${selectData?.id}`] !== ''
                              ? selectData?.props?.prefilled
                              : documentForm?.form[`${selectData?.id}`]
                        }
                        onOpen={(event) => onfocus(event, selectData)}
                        onChange={(event) => onchange(event, selectData, sectionIndex)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          fontSize: '16px',
                          // width: '40%',
                          marginRight: '30px'
                        }}>
                        {JSON.parse(selectData?.props?.options.replace(/'/g, '"')).length > 0 &&
                            JSON.parse(selectData?.props?.options.replace(/'/g, '"')).map(
                              (item) => (
                                    <MenuItem sx={{ fontSize: '16px' }} key={item} value={item}>
                                        {item}
                                    </MenuItem>
                              )
                            )}
                    </Select>
                    <FormHelperText>{documentForm?.errors[`${selectData?.id}`]}</FormHelperText>
                </FormControl>

                {selectValue !== 'Cash' && (
                    <TextField
                        error={documentForm?.errors[`${textFieldData?.id}`]}
                        helperText={documentForm?.errors[`${textFieldData?.id}`]}
                        value={
                            !documentForm?.form[`${textFieldData?.id}`] &&
                            documentForm?.form[`${textFieldData?.id}`] !== ''
                              ? textFieldData?.props?.prefilled
                              : documentForm?.form[`${textFieldData?.id}`]
                        }
                        required
                        placeholder={textFieldData?.placeholderName}
                        id={textFieldData?.id}
                        name={`span${textFieldData?.id}`}
                        type={textFieldData?.type}
                        fullWidth
                        onChange={(event) => {
                          if (textFieldData?.type === 'currency') {
                            if (
                              event.target.value === '' ||
                                    PATTERN?.NUMBERONLY.test(
                                      (event?.target?.value).replaceAll(',', '')
                                    )
                            ) {
                              onchange(event, textFieldData, sectionIndex)
                            }
                          } else {
                            onchange(event, textFieldData, sectionIndex)
                          }
                        }}
                        onFocus={(event) => onfocus(event, textFieldData)}
                        InputProps={{
                          startAdornment: (
                                <InputAdornment position="start">
                                    {textFieldData?.prpos?.prefix || ''}
                                </InputAdornment>
                          )
                        }}
                    />
                )}
            </div>
        </div>
  )
}

export default SelectTextField

SelectTextField.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  selectData: PropTypes.object,
  textFieldData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
