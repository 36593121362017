import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../../../../../../theme/palette'
import windows from '../../../../../../../assets/images/windows.png'
import ubuntu from '../../../../../../../assets/images/ubuntu.png'
import chrome from '../../../../../../../assets/images/chrome.png'
import edge from '../../../../../../../assets/images/edge.png'
import mac from '../../../../../../../assets/images/macLogo.png'

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { XORKEESIGN } from '../../../../../../../constants/enum'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '530px',
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const DigitalSignatureGuideModal = ({
  digitalSignGuideModalState,
  handleOkButtonDigitalSignModal,
  handleProccedButtonDigitalSignModal,
  componentType
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Prevent the default behavior and handle the key event
      event.preventDefault()
      handleProccedButtonDigitalSignModal()
    }
  }

  return (
        <Modal
            open={digitalSignGuideModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyDown={handleKeyDown}>
            <Box sx={style}>
                {/* <form
            onSubmit={(event) => {
              console.log('hi im there')
              event.preventDefault()
              handleProccedButtonDigitalSignModal()
            }}> */}
                <Grid p={2}>
                    <Typography
                        variant="labelMedium"
                        component="div"
                        For
                        Windows
                        Downlaod
                        Edge
                        Store
                        mb={3}
                        sx={{ fontSize: '22px', lineHeight: '31px' }}>
                        {digitalSignGuideModalState?.title}
                    </Typography>
                    {/* <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '20px', lineHeight: '31px' }}>
                            Please note that digital Signature will work only in google chrome
                            browser
                        </Typography>
                    </Grid> */}

                    {/* <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '20px', lineHeight: '31px' }}>
                            Step1 -&nbsp;
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://chromewebstore.google.com/detail/signerdigital-digital-sig/glghokcicpikglmflbbelbgeafpijkkf">
                                <span style={{ color: 'blue', cursor: 'pointer' }}>Click here</span>
                            </a>
                            to download Signer Digital extension for Google Chrome browser.&nbsp;
                        </Typography>
                    </Grid> */}

                    <Grid mb={3}>
                        <Grid>
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                Step1 - Download Xorkee Sign India G2C Extension
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                            p={'0 20px'}
                            mt={3}>
                            <Grid item xs={4} lg={2} sm={2} md={2}>
                                <img src={chrome} alt="window" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={6} md={6}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Chrome
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={XORKEESIGN?.EXTENSIONLINK?.CHROME}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid>

                            <Grid item xs={4} lg={2} sm={4} md={4}>
                                <img src={edge} alt="window" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={4} md={4}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Edge
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={XORKEESIGN?.EXTENSIONLINK?.EDGE}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                        {/* <Grid container gap={6} justifyContent={'center'} alignItems={'center'}>
                            <img src={windows} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Windows
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid>
                        <Grid
                            container
                            gap={6}
                            mt="15px"
                            mb="15px"
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <img src={ubuntu} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Ubuntu Linux
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid>
                        <Grid container gap={6} justifyContent={'center'} alignItems={'center'}>
                            <img src={suseLinux} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Suse Linux
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid mb={5}>
                        <Grid>
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                Step2 - Download Xorkee Sign host application according to your
                                Operating System
                                {/* <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://chromewebstore.google.com/detail/signerdigital-digital-sig/glghokcicpikglmflbbelbgeafpijkkf">
                                <span style={{ color: 'blue', cursor: 'pointer' }}>click here</span>
                            </a> */}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                            p={'0 20px'}
                            mt={3}>
                            <Grid item xs={4} lg={2} sm={2} md={2}>
                                <img src={windows} alt="window" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={6} md={6}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Windows
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={XORKEESIGN?.HOSTAPPLICATION?.WINDOWS}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid>
                            <Grid item xs={4} lg={2} sm={4} md={4}>
                                <img src={ubuntu} alt="ubuntu" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={4} md={4}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Ubuntu Linux
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={XORKEESIGN?.HOSTAPPLICATION?.LINUX}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid>
                            <Grid item xs={4} lg={2} sm={4} md={4}>
                                <img src={mac} alt="mac" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={4} md={4}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Mac
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={XORKEESIGN?.HOSTAPPLICATION?.MAQ}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid>

                            {/* <Grid item xs={4} lg={2} sm={4} md={4}>
                                <img src={suseLinux} alt="window" width={'40px'} height={'40px'} />
                            </Grid>
                            <Grid item xs={4} lg={6} sm={4} md={4}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                    For Suse Linux
                                </Typography>
                            </Grid>
                            <Grid item xs={4} lg={4} sm={4} md={4}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={SIGNERDIGITAL?.HOSTAPPLICATION?.SUSELINUX}>
                                    <Button
                                        variant="cancelButton"
                                        startIcon={<DownloadForOfflineIcon />}>
                                        Download
                                    </Button>
                                </a>
                            </Grid> */}
                        </Grid>
                        {/* <Grid container gap={6} justifyContent={'center'} alignItems={'center'}>
                            <img src={windows} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Windows
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid>
                        <Grid
                            container
                            gap={6}
                            mt="15px"
                            mb="15px"
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <img src={ubuntu} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Ubuntu Linux
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid>
                        <Grid container gap={6} justifyContent={'center'} alignItems={'center'}>
                            <img src={suseLinux} alt="window" width={'40px'} height={'40px'} />
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '20px', lineHeight: '31px' }}>
                                For Suse Linux
                            </Typography>
                            <Button variant="primary" startIcon={<DownloadForOfflineIcon />}>
                                Download
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid display="flex" gap={2}>
                        <>
                            <Grid>
                                <Button
                                    variant="cancelButton"
                                    onClick={handleOkButtonDigitalSignModal}>
                                    {digitalSignGuideModalState.submitButtonText}
                                </Button>
                            </Grid>
                            {componentType === 'bulkSignature' && (
                                <Grid>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={handleProccedButtonDigitalSignModal}>
                                        {digitalSignGuideModalState.proceedButtonText}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default DigitalSignatureGuideModal
DigitalSignatureGuideModal.propTypes = {
  handleOkButtonDigitalSignModal: PropTypes.func,
  handleProccedButtonDigitalSignModal: PropTypes.func,
  digitalSignGuideModalState: PropTypes.object,
  componentType: PropTypes.string
}
