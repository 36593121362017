import React, { useContext, useState } from 'react'
import './Header.css'
import { Typography, Button, Menu, MenuItem, Grid, Avatar } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import profileicon from '../../assets/images/profileicon.svg'
import { EVENT_LOG_TYPES, SIDEBARNO_VISIBLE, USER_ROLE } from '../../constants/enum'
import HeaderTitle from './HeaderTitle/HeaderTitle'
import { getSession } from '../../util/helper'
import NotificationsPopup from '../NotificationsPopup/NotificationsPopup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import palette from '../../theme/palette'
import { RestrictionContext } from '../../contextapi/RestrictionContext'
import { ProfileContext } from '../../contextapi/ProfileContext'
import { useLogInvoiceDownloadEventMutation } from '../../store/services/userServices'

export default function Header () {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const location = useLocation()

  // const { data: profileData = {} } = useGetProfileQuery({})

  // Profile context api
  const { profile: profileData = {} } = useContext(ProfileContext)

  // Restriction context api
  const { restrictionValues } = useContext(RestrictionContext)

  // console.log(profileData)
  const noVisible = [SIDEBARNO_VISIBLE?.SUBSCRIPTION, SIDEBARNO_VISIBLE?.VERIFYSUBSCRIPTION]

  const userInfo = JSON.parse(getSession('user'))
  const [logOutEventLog] = useLogInvoiceDownloadEventMutation({})

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    const dataToSend = {
      auditEventType: EVENT_LOG_TYPES.LOGOUT.value,
      invoiceId: ''
    }
    logOutEventLog(dataToSend)
      .unwrap()
      .then((response) => {
        // toast.success(response?.message)
      })
      .catch((exception) => {
        console.log(exception)
      })
    sessionStorage.clear()
    navigate('/')
  }

  const handleProfile = () => {
    if (userInfo?.role === USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()) {
      navigate('/myProfileBusiness', {
        state: {
          HeaderTitle: 'My Profile'
        }
      })
    } else if (userInfo?.role === USER_ROLE.CORPORATE.toUpperCase()) {
      navigate('/myProfileCorporate', {
        state: {
          HeaderTitle: 'My Profile'
        }
      })
    } else {
      navigate('/myProfile', {
        state: {
          HeaderTitle: 'My Profile'
        }
      })
    }

    setAnchorEl(false)
  }

  return (
        <Grid
            container
            className={noVisible.includes(location.pathname) ? 'headerBoxHidden' : 'headerBox'}>
            <HeaderTitle />
            <Grid display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
                {(userInfo?.role === USER_ROLE.CORPORATE.toUpperCase() ||
                    userInfo?.role ===
                        USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()) && (
                    <Typography variant="modalLabelBold">
                        Documents consumed - {restrictionValues?.documentConsumed || 0}/
                        {restrictionValues?.documentAllowed || 0}
                    </Typography>
                )}
                <NotificationsPopup />
                <Grid sx={{ background: palette.secondary.light }} className="headerProfileBox">
                    <Avatar src={profileicon} sx={{ mr: '19px' }} />
                    <Typography variant="modalLabelSmall" sx={{ fontWeight: '600' }}>
                        {profileData?.name || ''}
                    </Typography>
                    <Button
                        endIcon={<ArrowDropDownIcon fontSize="large" color="action" />}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}></Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button'
                        }}
                        PaperProps={{
                          style: {
                            marginTop: 20,
                            width: 220,
                            color: palette.text.dark
                          }
                        }}>
                        <MenuItem onClick={handleProfile}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Grid>
  )
}
