import React from 'react'
import './index.css'
import { Outlet } from 'react-router-dom'
import { Box, Typography, Grid } from '@mui/material'
import lefttopback from '../../assets/images/lefttopback.png'
import righttopback from '../../assets/images/righttopback.png'
import header from '../../assets/images/header.svg'
import palette from '../../theme/palette'
import logo from '../../assets/headerLogo/finalDigiDocLogo.svg'

const index = () => {
  return (
        <Grid
            container
            className="landingBody"
            justifyContent="center"
            alignItems={'center'}
            sx={{
              background: `linear-gradient(89.91deg, ${palette.success.main} -3.86%, ${palette.success.light} 105.97%)`
            }}>
            <Box className="landingBox" sx={{ background: palette.primary.light }}>
                <img src={lefttopback} alt="pic" className="landingBackgroundImage" />

                <img src={righttopback} alt="pic" className="landingBackgroundImage right" />

                <Grid container className="landing_grid">
                    <Grid
                        item
                        md={6}
                        sx={{ backgroundColor: palette.background.light }}
                        className="landingBoxLeftGrid">
                        <div className="landingBoxLeftItems" style={{ margin: 0 }}>
                            <img src={logo} />

                            <Typography
                                sx={{
                                  fontSize: '17px',
                                  margin: '10px 0px 5px 0px',
                                  color: palette.text.newDark,
                                  textAlign: 'center'
                                }}>
                                Document Execution Platform from <br />
                                Stock Holding Corporation of India Ltd.
                            </Typography>
                        </div>
                        <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <img src={header} alt="pic" className="welcomeImage" />
                        </Grid>
                    </Grid>
                    <Outlet />
                </Grid>
            </Box>
        </Grid>
  )
}

export default index
