import React from 'react'
import './index.css'
import { TextField, Typography, InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'
import { PATTERN } from '../../constants/enum'

const NumberTextField = ({
  fieldLabel,
  onchange,
  onfocus,
  textFieldData,
  documentForm,
  sectionIndex
}) => {
  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <TextField
                    error={documentForm?.errors[`${textFieldData?.id}`]}
                    helperText={documentForm?.errors[`${textFieldData?.id}`]}
                    placeholder={textFieldData?.placeholderName}
                    value={
                        !documentForm?.form[`${textFieldData?.id}`] &&
                        documentForm?.form[`${textFieldData?.id}`] !== ''
                          ? textFieldData?.props?.prefilled
                          : documentForm?.form[`${textFieldData?.id}`]
                    }
                    required
                    id={textFieldData?.id}
                    name={`span${textFieldData?.id}`}
                    type="text"
                    fullWidth
                    onChange={(event) => {
                      console.log(PATTERN?.NUMBERONLY.test(event?.target?.value))
                      if (
                        event.target.value === '' ||
                            PATTERN?.NUMBERONLY.test(event?.target?.value)
                      ) {
                        onchange(event, textFieldData, sectionIndex)
                      }
                    }}
                    onFocus={(event) => onfocus(event, textFieldData)}
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                {textFieldData?.props?.prefix || ''}
                            </InputAdornment>
                      )
                    }}
                    inputProps={{
                      title: 'Leading and trailing spaces are not allowed'
                    }}
                    multiline={textFieldData?.type === 'textarea'}
                    disabled={textFieldData?.props?.disabled === 'true'}
                />
            </div>
        </div>
  )
}

export default NumberTextField

NumberTextField.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  textFieldData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
