export const TICKETSTABLEHEADERINDV = [
  'Ticket No',
  'Category of Issue',
  'Description',
  'Created Date',
  'Status',
  'Action'
]
export const TICKETSTABLEHEADERBUSINESS = [
  'Ticket No',
  'Category of Issue',
  'Description',
  'Created Date',
  'Created By',
  'Status',
  'Action'
]
export const TICKETSTABLEHEADERCORP = [
  'Ticket No',
  'Category of Issue',
  'Description',
  'Created Date',
  'Location',
  'Created By',
  'Status',
  'Action'
]

export const TICKETFILTERSTATUS = {
  ALL: {
    ID: 1,
    NAME: 'All'
  },
  NEW: {
    ID: 2,
    NAME: 'New'
  },
  INPROGRESS: {
    ID: 3,
    NAME: 'In progress'
  },
  LEVEL2SUPPORT: {
    ID: 4,
    NAME: 'Assigned to level 2 support'
  },
  LEVEL2PROGRESS: {
    ID: 5,
    NAME: 'In progress at level 2'
  },
  RESOLVED: {
    ID: 6,
    NAME: 'RESOLVED'
  }
}
