import { Button, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Help = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // Selected button state [FAQ, TICKETS]
  const [selected, setselected] = useState('')

  // Handle select faq button
  const handleFaq = () => {
    navigate('/help/faq', {
      state: {
        HeaderTitle: 'Help'
      }
    })
  }
  // Handle select faq button
  const handleAbout = () => {
    navigate('/help', {
      state: {
        HeaderTitle: 'Help'
      }
    })
  }
  // Handle select Ticket button
  const handleTickets = () => {
    navigate('/help/tickets', {
      state: {
        HeaderTitle: 'Tickets'
      }
    })
  }

  // Set selected button on location url change [help, tickets]
  useEffect(() => {
    if (location?.pathname) {
      const text = location?.pathname.split('/')
      setselected(text[text.length - 1])
    }
  }, [location?.pathname])

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <Grid
                spacing={2}
                container
                mb={3}
                sx={{
                  flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: 'column' }
                }}>
                <Grid item lg={6} md={12} xs={12} sm={12}>
                    <Button
                        variant={selected === 'help' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '5px 0px 0px 5px' }}
                        onClick={handleAbout}>
                        About
                    </Button>
                    <Button
                        variant={selected === 'faq' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 0px 0px 0px' }}
                        onClick={handleFaq}>
                        FAQ&apos;s
                    </Button>
                    <Button
                        variant={selected === 'tickets' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        onClick={handleTickets}>
                        Tickets
                    </Button>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        lg: 'flex-end',
                        md: 'flex-start',
                        sm: 'flex-start',
                        xs: 'flex-start'
                      }
                    }}>
                    {selected && selected === 'tickets' && (
                        <Button
                            variant="primary"
                            sx={{ width: '145px', height: '40px' }}
                            onClick={() =>
                              navigate('/addTicket', {
                                state: {
                                  HeaderTitle: 'Add Ticket'
                                }
                              })
                            }>
                            Add ticket
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Outlet />
        </Box>
  )
}

export default Help
