import { PAYMENTSTATUS } from '../constants/enum'

/**
 * Open Razorpay payment window and start payment process
 * @param {string} currency
 * @param {string} amount
 * @param {string} id
 */
export const paymentInitialized = ({ currency, amount, id, orderId, description = '', form }) => {
  return new Promise((resolve, reject) => {
    console.log('payment intitialized')
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEYID,
      amount: amount.toString(),
      currency,
      name: form?.name,
      description,
      image: '',
      order_id: orderId,
      handler: async function (response) {
        const dataToReturn = {
          razorpayResponse: JSON.stringify(response),
          razorpayOrderId: orderId,
          orderCreationId: id,
          paymentStatus: PAYMENTSTATUS?.SUCCESS
        }
        console.log('payment done', dataToReturn)
        resolve(dataToReturn)
      },
      prefill: {
        name: form?.name,
        email: form?.email
      },
      notes: {
        address: form?.address
      },
      theme: {
        color: '#33AFB9'
      }
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
    paymentObject.on('payment.failed', function (response) {
      const dataToReturn = {
        razorpayResponse: JSON.stringify(response),
        razorpayOrderId: orderId,
        orderCreationId: id,
        paymentStatus: PAYMENTSTATUS?.FAILURE
      }
      console.log('PAYMENT FAILED:RAZORPAY', response)
      reject(dataToReturn)
      // alert(response.error.code)
      // alert(response.error.description)
      // alert(response.error.source)
      // alert(response.error.step)
      // alert(response.error.reason)
      // alert(response.error.metadata.order_id)
      // alert(response.error.metadata.payment_id)
    })
  })
}
