import './CommentSection.css'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import addicon from '../../../../../assets/images/addicon.svg'
import commentView from '../../../../../assets/images/commentViewicon.svg'
import profileicon from '../../../../../assets/images/profileicon.svg'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useAddCommentMutation,
  useGetCommentsQuery,
  useGetDocumentPreviewByIdQuery,
  useSubmitChangeRequestMutation,
  useSubmitDocumentReviewCommentMutation
} from '../../../../../store/services/documentServices'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import Comment from './Comment'
import Reply from './Reply'

const CommentSection = () => {
  const navigate = useNavigate()
  const { documentId } = useParams()

  const { data: documentPreview = { content: '<div>Loading</div>' } } =
        useGetDocumentPreviewByIdQuery(documentId, {
          skip: !documentId
        })

  const { data: commentsArray = [] } = useGetCommentsQuery(documentId, {
    skip: !documentId
  })

  const [openAddCommentBox, setOpenAddCommentBox] = useState(false)
  const [openViewCommentBox, setopenViewCommentBox] = useState(true)
  const [commentText, setCommentText] = useState('')
  const [scrollDown, setScrollDown] = useState(false)
  const [replyBxInd, setReplyBxInd] = useState(-1) // visible reply box index
  const [submitDocumentReview] = useSubmitDocumentReviewCommentMutation()
  const [addComment] = useAddCommentMutation()
  const [submitChangeRequest] = useSubmitChangeRequestMutation({})

  const handleSubmitReview = () => {
    submitDocumentReview({ documentId })
      .unwrap()
      .then((response) => {
        console.log(response, 'response')
        toast.success(response?.message)
        navigate('/myDocument/reviewAndConsent', {
          state: {
            HeaderTitle: 'Review Consent'
          }
        })
      })
      .catch((exception) => {
        console.log('EXCEPTION:REVIEW SUBMIT DOCUMENT', exception)
      })
  }

  const handleSubmitChangeRequest = () => {
    submitChangeRequest({ documentId })
      .unwrap()
      .then((response) => {
        console.log(response, 'response')
        toast.success(response?.message)
        navigate('/myDocument', {
          state: {
            HeaderTitle: 'My Documents'
          }
        })
      })
      .catch((exception) => {
        console.log('EXCEPTION:SUBMIT CHANGE REQUEST', exception)
      })
  }

  const handleAddComment = () => {
    if (!commentText || commentText.trim() === '') {
      toast.error('Comment can not be empty')
      return
    }
    const data = {
      text: commentText
    }
    addComment({ payload: data, documentId })
      .then(() => {
        setCommentText('')
        setopenViewCommentBox(true)
        setOpenAddCommentBox(false)
        setScrollDown(true)
      })
      .catch((exception) => {
        console.log('EXCEPTION:ADD COMMENT', exception)
      })
  }

  useEffect(() => {
    if (scrollDown) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      setScrollDown(false)
    }
  }, [commentsArray])

  const handleClearComment = () => {
    setCommentText('')
    setOpenAddCommentBox(false)
    setopenViewCommentBox(true)
  }

  return (
        <Box p={2}>
            <Grid container spacing={2}>
                {/* Preview Section */}
                <Grid item lg={5} md={12} sm={12} xs={12}>
                    <Box className="preview_commentSection" id="previewDocument">
                        <Grid container justifyContent={'space-between'} mb={2}>
                            <img
                                src={commentView}
                                alt="addcomment"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenAddCommentBox(false)
                                  setopenViewCommentBox(true)
                                }}
                            />
                            {!documentPreview?.isOwner && (
                                <img
                                    src={addicon}
                                    alt="addcomment"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setopenViewCommentBox(false)
                                      setOpenAddCommentBox(true)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid overflow="scroll" sx={{ maxHeight: 'calc(100vh - 14rem)' }}>
                            {parse(documentPreview?.content) ? parse(documentPreview?.content) : ''}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={7} md={12} sm={12} xs={12}>
                    {/* View Comment */}
                    {openViewCommentBox && (
                        <Grid
                            p={3}
                            mb={2}
                            display={'flex'}
                            overflow="scroll"
                            sx={{ maxHeight: 'calc(100vh - 14rem)', backgroundColor: '#f5f7f9' }}
                            flexDirection={'column'}>
                            <Typography
                                variant="darkValueLarge"
                                component="div"
                                sx={{ marginBottom: '20px' }}>
                                Preview Comments
                            </Typography>
                            {commentsArray.map((comment, index) => {
                              return (
                                    <React.Fragment key={index}>
                                        <Comment
                                            isReply={false}
                                            username={comment.commentBy}
                                            profileicon={profileicon}
                                            comment={comment.text}
                                            date={comment.date}
                                            time={comment.time}
                                            status={comment.status}></Comment>
                                        {comment.replies.length > 0 &&
                                            comment.replies.map((reply, replyInd) => (
                                                <Comment
                                                    key={replyInd}
                                                    isReply={true}
                                                    username={reply.commentBy}
                                                    profileicon={profileicon}
                                                    comment={reply.text}
                                                    date={reply.date}
                                                    time={reply.time}></Comment>
                                            ))}
                                        <Reply
                                            expand={comment.id === replyBxInd}
                                            commentId={comment.id}
                                            setExpand={(commentId) => {
                                              setReplyBxInd(commentId)
                                            }}></Reply>
                                    </React.Fragment>
                              )
                            })}
                            {!commentsArray.length && 'No comments found'}
                        </Grid>
                    )}
                    {/* Add Comment */}
                    {openAddCommentBox && (
                        <Grid
                            p={3}
                            mb={2}
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{ backgroundColor: '#f5f7f9' }}>
                            <Typography
                                variant="darkValueLarge"
                                component="div"
                                sx={{ marginBottom: '10px' }}>
                                Write comment
                            </Typography>
                            <TextField
                                placeholder="Write your comment here..."
                                autoFocus
                                style={{ marginBottom: '20px' }}
                                multiline
                                required
                                value={commentText}
                                // eslint-disable-next-line no-undef
                                onChange={(e) => setCommentText(e.target.value)}
                            />
                            <Grid display="flex" justifyContent="flex-end" mb={2} gap="10px">
                                <Button variant="cancelButton" onClick={handleClearComment}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleAddComment}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {(openViewCommentBox || openAddCommentBox) && (
                        <Grid display="flex" gap="10px">
                            <Button
                                variant="cancelButton"
                                onClick={handleSubmitChangeRequest}
                                disabled={commentsArray?.length === 0}>
                                {!documentPreview?.isOwner
                                  ? 'Submit change request'
                                  : 'Send document for consent'}
                            </Button>
                            {!documentPreview?.isOwner && (
                                <Button variant="primary" onClick={handleSubmitReview}>
                                    Document looks good, i am ok to proceed
                                </Button>
                            )}
                        </Grid>
                    )}
                </Grid>

                {/* <Grid item lg={7}>
                    <div className="commentsection-proceedbuttons">
                        <Button variant="cancelButton">Submit change request</Button>
                        <Button variant="primary">Document looks good, i am ok to proceed</Button>
                    </div>
                </Grid> */}
            </Grid>
        </Box>
  )
}

export default CommentSection
