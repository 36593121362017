import React, { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useGetDocumentFilterStatusQuery } from '../../../store/services/documentServices'

const MyDocumentIndividualBa = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // Selected button state
  const [selected, setselected] = useState('')

  // Get all Status query
  const { data: documentStatusArray = [] } = useGetDocumentFilterStatusQuery({})

  // Handle select my document  button
  const handleMyDocumentTransaction = () => {
    navigate('/myDocument', {
      state: {
        HeaderTitle: 'My Documents'
      }
    })
  }
  // Handle select review and consent button
  const handleReviewConsentTransaction = () => {
    navigate('/myDocument/reviewAndConsent', {
      state: {
        HeaderTitle: 'Review Consent'
      }
    })
  }

  // Handle select multiple copies document button
  const handleMultipleCopiesTransaction = () => {
    navigate('/myDocument/multipleCopies', {
      state: {
        HeaderTitle: 'Multiple Copies Documents'
      }
    })
  }

  // Set selected button on location url change
  useEffect(() => {
    if (location?.pathname) {
      const text = location?.pathname.split('/')
      setselected(text[text.length - 1])
    }
  }, [location?.pathname])

  return (
        <Box sx={{ p: 2 }}>
            <Grid spacing={2} container mb={3} justifyContent="center">
                <Grid item lg={12} md={12} xs={12} sm={12}>
                    <Button
                        variant={selected === 'myDocument' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '5px 0px 0px 5px' }}
                        onClick={handleMyDocumentTransaction}>
                        My Documents
                    </Button>
                    <Button
                        variant={selected === 'reviewAndConsent' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 0px 0px 0px' }}
                        onClick={handleReviewConsentTransaction}>
                        Review and Consent Required
                    </Button>
                    <Button
                        variant={selected === 'multipleCopies' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        onClick={handleMultipleCopiesTransaction}>
                        Multiple Copies Documents
                    </Button>
                </Grid>
            </Grid>
            <Outlet context={{ documentStatusArray }} />
        </Box>
  )
}

export default MyDocumentIndividualBa
