import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Popover
} from '@mui/material'
import flag from '../../../assets/images/india-flag.svg'
import { useGetCityQuery } from '../../../store/services/userServices'
import { useNavigate } from 'react-router-dom'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import { useAddNewStaffUserMutation } from '../../../store/services/manageStaffUserServices'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { PATTERN } from '../../../constants/enum'
import { ADDUSERTYPE } from '../../../constants/manageStaffUserConstants'
import { toast } from 'react-toastify'
import CustomCard from '../../../components/CustomCard'
import { validator } from '../../../util/validator'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import info from '../../../assets/images/info.svg'
import { INFORMATION_MESSAGE } from '../../../constants/message'
import { PLACEHOLDER } from '../../../constants/placeholder'
import palette from '../../../theme/palette'

const AddNewUserBusiness = () => {
  // Navigate
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Profile data from context api
  const { profile } = useContext(ProfileContext)

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // Add new staff user form
  const [addNewUserForm, setAddNewUserForm] = useState({
    stateId: profile?.state?.id,
    cityId: '',
    errors: {
      email: 'error'
    }
  })

  // Get all cities according to state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: profile?.state?.id },
    {
      skip: !profile?.state?.id
    }
  )

  // Add new business staff user mutation
  const [addNewStaffUser] = useAddNewStaffUserMutation()

  /**
     * Handle add new user form changed
     * @param {object} event:Event object
     */
  const handleNewUserFormChange = (event) => {
    const errormessage = validator(event.target.name, event.target.value)
    console.log(addNewUserForm)
    setAddNewUserForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, ''),
      errors: {
        ...prev.errors,
        [event.target.name]: errormessage
      }
    }))
  }

  // Handle add user form submit
  const handleAddNewUser = () => {
    const dataToSend = {
      name: addNewUserForm?.name,
      email: addNewUserForm?.email,
      phone: addNewUserForm?.phone,
      stateId: profile?.state?.id,
      cityId: addNewUserForm?.cityId,
      pinCode: addNewUserForm?.pinCode,
      role: ADDUSERTYPE?.STAFF
    }

    console.log(dataToSend)
    if (addNewUserForm?.errors?.email === 'noerror') {
      setBackdropState(true)
      addNewStaffUser({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          toast.success(response?.message)
          navigate('/manageStaffUsers', {
            state: {
              HeaderTitle: 'Staff Users'
            }
          })
          console.log(response, 'response:ADD new staff user')
        })
        .catch((execption) => {
          setBackdropState(false)
        })
    }
  }

  /**
     * Handle prevent space
     * @param {Object} event: Event object
     */
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
        <Box p={3}>
            <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleAddNewUser()
                }}>
                <CustomCard headerTitle="Add staff account">
                    <Grid container spacing={3} p={3}>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ display: 'flex' }}>
                                Name
                                <img
                                    style={{ marginLeft: 10 }}
                                    src={info}
                                    alt="info"
                                    onClick={handleClick}
                                />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                    }}>
                                    <Box
                                        sx={{
                                          background: palette.primary.light,
                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                        }}
                                        className="documentTablePopoverBox">
                                        <Typography
                                            variant="darkValue"
                                            sx={{
                                              letterSpacing: '0.03em',
                                              lineHeight: '30px'
                                            }}>
                                            {INFORMATION_MESSAGE?.NAME_MESSAGE}
                                        </Typography>
                                    </Box>
                                </Popover>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                required
                                placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                                value={addNewUserForm?.name || ''}
                                name="name"
                                onChange={(event) => {
                                  if (
                                    event.target.value === '' ||
                                        PATTERN?.ALPHABETSONLY.test(event?.target?.value)
                                  ) {
                                    handleNewUserFormChange(event)
                                  }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                error={
                                    !(
                                      addNewUserForm?.errors?.email === 'error' ||
                                        addNewUserForm?.errors?.email === 'noerror'
                                    )
                                }
                                helperText={
                                    addNewUserForm?.errors?.email === 'error' ||
                                    addNewUserForm?.errors?.email === 'noerror'
                                      ? ''
                                      : addNewUserForm?.errors?.email
                                }
                                fullWidth
                                required
                                value={addNewUserForm?.email || ''}
                                type="email"
                                name="email"
                                onChange={handleNewUserFormChange}
                                onKeyDown={handlePreventSpace}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Phone
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={addNewUserForm?.phone || ''}
                                fullWidth
                                required
                                type="text"
                                id="phoneNumber"
                                name="phone"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 10,
                                  minlength: 10
                                }}
                                InputProps={{
                                  startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={flag} alt="flag" />
                                            &nbsp;+91 |
                                        </InputAdornment>
                                  )
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleNewUserFormChange(e)
                                  }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                State
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                disabled
                                fullWidth
                                required
                                value={profile?.state?.name}
                                type="text"
                                name="stateId"
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                City
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <Select
                                sx={{ fontSize: '16px' }}
                                value={addNewUserForm?.cityId || ''}
                                disabled={!profile?.state?.id}
                                labelId="cityId"
                                id="cityId"
                                name="cityId"
                                onChange={(event) => {
                                  setAddNewUserForm((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value
                                  }))
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                displayEmpty
                                MenuProps={MenuProps}
                                fullWidth
                                required>
                                <MenuItem value="" disabled>
                                    <Typography>Select City</Typography>
                                </MenuItem>
                                {cityArray &&
                                    cityArray.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>

                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ marginRight: '30px' }}>
                                Pin code
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={addNewUserForm?.pinCode || ''}
                                fullWidth
                                required
                                name="pinCode"
                                type="text"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 6,
                                  minlength: 6
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleNewUserFormChange(e)
                                  }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomCard>
                <Box>
                    <Button
                        variant="cancelButton"
                        sx={{ marginRight: '20px' }}
                        onClick={() =>
                          navigate('/manageStaffUsers', {
                            state: {
                              HeaderTitle: 'Staff Users'
                            }
                          })
                        }>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Add
                    </Button>
                </Box>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default AddNewUserBusiness
