import { createTheme } from '@mui/material'
import typography from './typography'
import palette from './palette'
const _theme = createTheme()
export const theme = createTheme({
  typography,
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.main,
          '&$error': {
            color: palette.error.main
          }
        }
      }
    },
    MuiButton: {
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      styleOverrides: {
        primary: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: 1,
          borderRadius: '5px',
          height: '51px',
          '&:hover': {
            boxShadow: 'none'
          },
          '&:disabled': {
            opacity: 0.5
          },
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          },
          background: `linear-gradient(89.91deg, ${palette.success.main} -3.86%, ${palette.success.light} 105.97%)`,
          color: palette.primary.main
        },
        cancelButton: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: 1,
          color: palette.text.main,
          height: '51px',
          border: `1px solid ${palette.text.main}`,
          background: palette.primary.main,
          borderRadius: '5px',
          '&:hover': {
            boxShadow: 'none',
            background: palette.primary.main
          },
          '&:disabled': {
            color: palette.text.main
            // backgroundColor: "#e7e7e7",
          },
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          }
        },
        selectedButton: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
          borderRadius: '5px',
          height: '48px',
          backgroundColor: palette.primary.light,
          color: palette.success.main,
          '&:hover': {
            boxShadow: 'none',
            background: palette.primary.light,
            color: palette.success.main
          },
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          }
        },
        tabSelected: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: 1,
          // width: "176px",
          height: '40px',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderTop: '15px solid #1caeb5',
            borderBottom: '15px solid transparent',
            height: 0,
            width: 0,
            right: 0,
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            bottom: '-28px'
          },
          '&:hover': {
            boxShadow: 'none'
          },
          background: 'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
          color: 'white',
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          }
        },
        tabUnselected: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '15px',
          color: palette.text.main,
          // width: '176px',
          height: '40px',
          border: `0.5px solid ${palette.text.light}`,
          background: palette.primary.light,
          '&:hover': {
            boxShadow: 'none',
            background: palette.primary.light
          },
          '&:disabled': {
            color: palette.text.main
            // backgroundColor: "#e7e7e7",
          },
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          }
        },
        tabDisabled: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '15px',
          color: palette.text.main,
          // width: '176px',
          height: '40px',
          border: `0.5px solid ${palette.text.light}`,
          background: palette.primary.light,
          '&:hover': {
            boxShadow: 'none',
            background: palette.primary.light
          },
          '&:disabled': {
            color: palette.text.main,
            backgroundColor: palette.text.light
          },
          [_theme.breakpoints.down('sm')]: {
            height: '48px'
          }
        },

        link: {
          boxShadow: 'none',
          textTransform: 'none',
          textDecoration: 'underline',
          fontWeight: 600,
          fontSize: '16px',
          color: palette.success.light,
          height: 'auto',
          border: 'none',
          background: palette.primary.main,
          borderRadius: '5px',
          padding: '0px',
          '&:hover': {
            boxShadow: 'none',
            background: 'transparent',
            textDecoration: 'underline'
          },
          '&:disabled': {
            color: palette.text.main,
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: '500',
          // ppadding: "17px 20px",
          color: palette.text.main,
          '&.Mui-selected': {
            color: palette.primary.dark
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-end'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.light,
          boxShadow: 'none'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: 0,
          '@media (min-width:600px)': {
            padding: 0
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '@media (max-width:320px)': {
            fontSize: '9px'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.light,
          border: 'none',
          boxShadow: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: palette.secondary.light,
          boxShadow: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.light,
          color: palette.text.main,
          '&.Mui-disabled': {
            backgroundColor: palette.editNumber.light
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px',
            [_theme.breakpoints.down('sm')]: {
              padding: '7.5px 14px'
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            padding: '13.5px 14px',
            [_theme.breakpoints.down('sm')]: {
              padding: '7.5px 14px'
            }
          },
          '& .MuiInputLabel-formControl': {
            fontSize: '0.933rem'
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -10px)',
            fontSize: '12px',
            paddingLeft: '0px',
            paddingRight: '5px',
            [_theme.breakpoints.down('sm')]: {
              fontSize: '12px'
            }
          },

          '& input::placeholder': {
            fontSize: '14px'
          },
          // this is styles for the new variants
          '&.subvariant-hovered': {
            '& .MuiInputBase-input:hover + fieldset': {
              border: `1px solid ${palette.text.main}`
            },
            '& .MuiInputBase-input:focus + fieldset': {
              border: `1px solid ${palette.text.main}`
            }
          }
        }
      }
    }
  }
})
