import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const signatureApi = createApi({
  reducerPath: 'signatureApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllRestrictionChecks'],
  endpoints: (builder) => ({
    getDocumentPreviewAndReviewUsersById: builder.query({
      query: (id) => ({
        url: `esign/getESignDetails/${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    submitSignatureRequest: builder.mutation({
      query: ({ payload }) => ({
        url: 'esign/markSignaturePositions',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    initializeEsignProcess: builder.mutation({
      query: ({ payload }) => ({
        url: 'esign/performESign',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    getDocumentPreviewAndSignModeByDocumentId: builder.query({
      query: (id) => ({
        url: `esign/getUserESignDetails/${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    initializeDigitalSignatureProcess: builder.mutation({
      query: ({ payload, docId }) => ({
        url: `esign/performDigitalESign/${docId}`,
        method: 'POST',
        fileUpload: true,
        isUploadDigitalSign: true,
        payload
      }),
      transformResponse: (response) => response
    }),
    getBulkDocumentPreviewAndReviewUsersById: builder.query({
      query: (id) => ({
        url: `bulkEsign/getESignDetails/${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    submitBulkSignaturePlacementRequest: builder.mutation({
      query: ({ payload }) => ({
        url: 'bulkEsign/markSignaturePositions',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetDocumentPreviewAndReviewUsersByIdQuery,
  useSubmitSignatureRequestMutation,
  useInitializeEsignProcessMutation,
  useInitializeDigitalSignatureProcessMutation,
  useGetDocumentPreviewAndSignModeByDocumentIdQuery,
  useGetBulkDocumentPreviewAndReviewUsersByIdQuery,
  useSubmitBulkSignaturePlacementRequestMutation
} = signatureApi
