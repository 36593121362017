import React from 'react'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import {
  ReviewStyledTableCell,
  ReviewStyledTableRow
} from '../../StyledComponents/TableStyles/ReviewTableStyles'
import cross from '../../assets/images/cross.svg'
import checked from '../../assets/images/checked.svg'
import { DOCUEMENTFILTERSTATUS } from '../../constants/documentConstans'
import PropTypes from 'prop-types'
const CommonTableReview = ({ tableData }) => {
  const tableHeaders = ['Parties', 'Email', 'Phone', 'Address', 'Status']

  return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeaders?.map((index) => (
                            <ReviewStyledTableCell key={index} align="left">
                                {index}
                            </ReviewStyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map((item, index) => (
                        <ReviewStyledTableRow key={index}>
                            <ReviewStyledTableCell scope="row">{item?.party}</ReviewStyledTableCell>
                            <ReviewStyledTableCell align="left">
                                {item?.email}
                            </ReviewStyledTableCell>
                            <ReviewStyledTableCell align="left">
                                {item?.phone}
                            </ReviewStyledTableCell>
                            <ReviewStyledTableCell align="left">
                                {item?.address}
                            </ReviewStyledTableCell>
                            <ReviewStyledTableCell align="left">
                                <div className="actionIcons">
                                    {(item?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID ||
                                        item?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.DOCUMENTSIGNED?.ID) && (
                                        <img
                                            src={checked}
                                            alt="checked"
                                            title="Submitted"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                    {(item?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID ||
                                        item?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID) && (
                                        <img
                                            src={cross}
                                            alt="deleteIcon"
                                            title="Requested"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            </ReviewStyledTableCell>
                        </ReviewStyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
  )
}

export default CommonTableReview
CommonTableReview.propTypes = {
  tableData: PropTypes?.array
}
