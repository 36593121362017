import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material'
import React, { useState } from 'react'
import eyeOffIcon from '../../../assets/images/eyeoff.svg'
import eyeOnIcon from '../../../assets/images/eyeon.svg'
import { useChangePasswordMutation } from '../../../store/services/userServices'
import { validator } from '../../../util/validator'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSession } from '../../../util/helper'
import { USER_ROLE } from '../../../constants/enum'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'

const ChangePassword = () => {
  const navigate = useNavigate()
  // Password texfield type change state
  const [passwordTypeState, setpasswordTypeState] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  })

  // Password change form
  const [passwordForm, setpasswordForm] = useState({
    form: {},
    errors: {
      newPassword: 'error',
      confirmPassword: 'error'
    }
  })
  const [changePassword] = useChangePasswordMutation({})

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // const [changePassword, { data }] = useChangePasswordMutation({})

  // Handle texfield type change['text','password']
  const handlePasswordType = (key) => {
    setpasswordTypeState((prev) => ({
      ...prev,
      [key]: !passwordTypeState[`${key}`]
    }))
  }

  const userInfo = JSON.parse(getSession('user'))

  // Handle form submit
  const handleFormSubmit = () => {
    if (
      passwordForm?.errors?.newPassword === 'noerror' &&
            passwordForm?.errors?.confirmPassword === 'noerror'
    ) {
      setBackdropState(true)
      changePassword({ payload: passwordForm?.form })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          if (
            userInfo?.role === USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()
          ) {
            navigate('/myProfileBusiness', {
              state: {
                HeaderTitle: 'My Profile'
              }
            })
          } else if (userInfo?.role === USER_ROLE.CORPORATE.split(' ')[0].toUpperCase()) {
            navigate('/myProfileCorporate', {
              state: {
                HeaderTitle: 'My Profile'
              }
            })
          } else {
            navigate('/myProfile', {
              state: {
                HeaderTitle: 'My Profile'
              }
            })
          }

          toast.success(response?.message)
        })
        .catch((execption) => {
          setBackdropState(false)
          console.log('EXCEPTION:CHANGE PASSWORD', execption)
          // toast.error(execption?.message)
        })
    }
  }

  // Handle textfield onChange
  const handleFormChange = async (event) => {
    const err = await validator(
      event.target.name,
      event.target.value,
      passwordForm?.form?.newPassword
    )

    console.log(err, 'error returned from validator')
    setpasswordForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [event.target.name]: event.target.value
      },
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))

    // Confirm password Validation
    if (event.target.name === 'newPassword' && passwordForm?.form?.confirmPassword) {
      console.log('errrr', err)
      let ConfirmPassErr
      if (
        passwordForm?.form?.confirmPassword &&
                event.target.value !== passwordForm?.form?.confirmPassword
      ) {
        ConfirmPassErr = 'Password and Confirm Password does not match'
      } else {
        ConfirmPassErr = passwordForm?.form?.confirmPassword
          ? 'noerror'
          : passwordForm?.error?.confirmPassword
      }
      setpasswordForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          confirmPassword: ConfirmPassErr
        }
      }))
    }

    console.log('passwordChangeFOrm:Component:CHangePASSWORD', passwordForm)
  }

  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }
  return (
        <Box sx={{ padding: '3%' }}>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleFormSubmit()
                }}>
                <Grid container spacing={5}>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '9px' }}>
                            Old Password
                        </Typography>
                        <TextField
                            required
                            type={passwordTypeState?.oldPassword ? 'text' : 'password'}
                            id="oldPassword"
                            name="oldPassword"
                            // inputProps={{
                            //   style: {
                            //     height: "10px",
                            //   },
                            // }}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handlePasswordType('oldPassword')}>
                                            {passwordTypeState?.oldPassword ? (
                                                <img src={eyeOnIcon} alt="Password" />
                                            ) : (
                                                <img src={eyeOffIcon} alt="Password" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            onChange={handleFormChange}
                            onKeyDown={handlePreventSpace}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '9px' }}>
                            New Password
                        </Typography>
                        <TextField
                            error={
                                !(
                                  passwordForm?.errors?.newPassword === 'error' ||
                                    passwordForm?.errors?.newPassword === 'noerror'
                                )
                            }
                            helperText={
                                passwordForm?.errors?.newPassword === 'error' ||
                                passwordForm?.errors?.newPassword === 'noerror'
                                  ? ''
                                  : passwordForm?.errors?.newPassword
                            }
                            required
                            id="newPassword"
                            name="newPassword"
                            type={passwordTypeState?.newPassword ? 'text' : 'password'}
                            // inputProps={{
                            //   style: {
                            //     height: "10px",
                            //   },
                            // }}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handlePasswordType('newPassword')}>
                                            {passwordTypeState?.newPassword ? (
                                                <img src={eyeOnIcon} alt="Password" />
                                            ) : (
                                                <img src={eyeOffIcon} alt="Password" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            onChange={handleFormChange}
                            onKeyDown={handlePreventSpace}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '9px' }}>
                            Confirm new password
                        </Typography>
                        <TextField
                            error={
                                !(
                                  passwordForm?.errors?.confirmPassword === 'error' ||
                                    passwordForm?.errors?.confirmPassword === 'noerror'
                                )
                            }
                            helperText={
                                passwordForm?.errors?.confirmPassword === 'error' ||
                                passwordForm?.errors?.confirmPassword === 'noerror'
                                  ? ''
                                  : passwordForm?.errors?.confirmPassword
                            }
                            required
                            id="confirmPassword"
                            name="confirmPassword"
                            type={passwordTypeState?.confirmPassword ? 'text' : 'password'}
                            // inputProps={{
                            //   style: {
                            //     height: "10px",
                            //   },
                            // }}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handlePasswordType('confirmPassword')}>
                                            {passwordTypeState?.confirmPassword ? (
                                                <img src={eyeOnIcon} alt="Password" />
                                            ) : (
                                                <img src={eyeOffIcon} alt="Password" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            onChange={handleFormChange}
                            onKeyDown={handlePreventSpace}
                        />
                    </Grid>
                </Grid>
                <Grid marginTop={4}>
                    <Button type="submit" variant="primary">
                        Update Password
                    </Button>
                </Grid>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default ChangePassword
