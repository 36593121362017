import { UPLOADFILESACCEPTED, maximumFileSize } from '../constants/enum'
import ERRORMESSAGES from '../constants/errormessages'

export const validator = (key, value, password = '') => {
  switch (key) {
    case 'email':
      return validateEmail(value)

    case 'password':
      return validatePassword(value)

    case 'newPassword':
      return validatePassword(value)

    case 'confirmPassword':
      return validateConfirmPassword(value, password)

    case 'panCard':
      return validatePan(value)

    case 'phone':
      return validatePhone(value)

    case 'ifscCode':
      return validateIfscCode(value)

    case 'confirmAccountNumber':
      return validateConfirmAccountNumber(value, password)

    case 'accountNumber':
      return validateAccountNumber(value)

    case 'name':
      return validateName(value)

    case 'fatherName':
      return validateFatherName(value)

    case 'referralEmail':
      return validateReferralEmail(value)

    case 'contactPerson':
      return validateName(value)
    case 'conmpanyName':
      return validateName(value)

    default:
      console.log('validation pending ')
      break
  }
}

/**
 * Validate email
 * @param {string} email
 * @returns string
 */
export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/i
  if (email === '') return ERRORMESSAGES.EMAILREQUIRED
  else if (!regex.test(email)) return ERRORMESSAGES.EMAILNOTVALID
  else return ERRORMESSAGES.NOERROR
}

/**
 * Validate email
 * @param {string} email
 * @returns string
 */
export const validateReferralEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/i
  if (email === '') return ERRORMESSAGES.NOERROR
  if (!regex.test(email)) return ERRORMESSAGES.EMAILNOTVALID
  else return ERRORMESSAGES.NOERROR
}

/**
 * Validate password
 * @param {String} password
 * @returns string
 */
export const validatePassword = (password) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/
  const lowercaseRegExp = /(?=.*?[a-z])/
  const digitsRegExp = /(?=.*?[0-9])/
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/
  const minLengthRegExp = /.{8,}/
  if (password === '') return ERRORMESSAGES.PASSWORDREQUIRED
  else if (!uppercaseRegExp.test(password)) return ERRORMESSAGES.PASSWORDUPPERCASE
  else if (!lowercaseRegExp.test(password)) return ERRORMESSAGES.PASSWORDLOWERCASE
  else if (!digitsRegExp.test(password)) return ERRORMESSAGES.PASSWORDDIGIT
  else if (!specialCharRegExp.test(password)) return ERRORMESSAGES.PASSWORDSPECCHAR
  else if (!minLengthRegExp.test(password)) return ERRORMESSAGES.PASSWORDMINLEN
  else return ERRORMESSAGES.NOERROR
}

/**
 * Validate Name while Pan Verification
 * @param {String} name
 * @returns string
 */
export const validateName = (name) => {
  const regex = /^[a-zA-Z ]+$/
  if (name) {
    const isAlphaNumeric = regex.test(name)
    console.log('Inside validation Name', name)
    const isWithinLengthLimit = name.length <= 40
    if (!name || name === '') return ERRORMESSAGES.NAMEISREQUIRED
    else if (!isAlphaNumeric && !isWithinLengthLimit) {
      return ERRORMESSAGES.NAMESPECIALCHARACTERSANDLENGTH
    } else if (!isAlphaNumeric) return ERRORMESSAGES.NAMESPECIALCHARACTERS
    else if (!isWithinLengthLimit) return ERRORMESSAGES.NAMELENGHT
    return ERRORMESSAGES.NOERROR
  }
}
/**
 * Validate Father's Name Pan Verification
 * @param {String} fName
 * @returns string
 */
export const validateFatherName = (fName) => {
  if (!fName || fName === '') return ERRORMESSAGES.FATHERNAMEISREQUIRED
  return ERRORMESSAGES.NOERROR
}
/**
 * Validate Confirm password
 * @param {String} password
 * @returns string
 */
export const validateConfirmPassword = (confirmPassword, password) => {
  if (confirmPassword === '') return ERRORMESSAGES.CONFIRMPASSREQUIRED
  else if (confirmPassword !== password) return ERRORMESSAGES.CONFIRMPASSNOMATCH
  else return ERRORMESSAGES.NOERROR
}

/**
 * Validate captcha
 * @param {string} captchagenerated : Automatically generated captcha
 * @param {string} captchaEntered  : Captcha entered by user
 * @returns string
 */
export const validateCaptcha = (captchagenerated, captchaEntered) => {
  console.log(captchagenerated !== captchaEntered, captchagenerated, captchaEntered)
  if (captchaEntered === '') return 'Captcha is required!'
  else if (captchagenerated !== captchaEntered) return 'This is not a valid captcha!'
  else return 'noerror'
}

/**
 *
 * @param {String} panCard: value entered
 * @returns  string
 */
export const validatePan = (panCard) => {
  const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  if (panCard === '') return ERRORMESSAGES.PANREQUIRED
  else if (!regex.test(panCard)) return ERRORMESSAGES.PANNOTVALID
  else return ERRORMESSAGES.NOERROR
}

/**
 *
 * @param {String} phoneNumber
 * @returns
 */
export const validatePhone = (phoneNumber) => {
  const phoneRegex = /^[0-9]{10}$/
  if (phoneNumber === '') return ERRORMESSAGES.NOERROR
  else if (!phoneRegex.test(phoneNumber)) return ERRORMESSAGES.PHONE_VALID
  else return ERRORMESSAGES.NOERROR
}

/**
 *
 * @param {String} ifscCode:String
 * @returns
 */
export const validateIfscCode = (ifscCode) => {
  const regex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/
  if (ifscCode === '') return ERRORMESSAGES?.IFSCCODEREQUIRED
  else if (!regex.test(ifscCode)) return ERRORMESSAGES?.IFSCCODENOTVAL
  else if (ifscCode.toString().length < 11) return ERRORMESSAGES?.IFSCCODEMINLEN
  else return 'noerror'
}

export const validateFile = (acceptedFile) => {
  const file = acceptedFile[0]
  const fileType = file.type
  const fileSize = file?.size
  if (!UPLOADFILESACCEPTED.includes(fileType)) {
    return ERRORMESSAGES?.FILENOTACCEPTED
  } else if (fileSize > maximumFileSize?.file5mb) {
    return ERRORMESSAGES?.FILELARGE
  } else return ''
}

/**
 * Validate Confirm Account number
 * @param {String} password
 * @returns string
 */
export const validateAccountNumber = (accNumber) => {
  return ERRORMESSAGES.NOERROR
}

/**
 * Validate Confirm Account number
 * @param {String} password
 * @returns string
 */
export const validateConfirmAccountNumber = (confirmAccNumber, accountNumber) => {
  if (confirmAccNumber === '') return ERRORMESSAGES.CONFIRMACCOUNTNUMBERREQUIRED
  else if (confirmAccNumber !== accountNumber) {
    return ERRORMESSAGES.CONFIRMACCOUNTNUMBERNOMATCH
  } else return ERRORMESSAGES.NOERROR
}

export const validateStampDuty = (stampDuty, minStampDuty, maxStampDuty) => {
  if (maxStampDuty && (stampDuty < minStampDuty || stampDuty > maxStampDuty)) {
    return `Stamp duty should be ≥ ${minStampDuty} and ≤ ${maxStampDuty}`
  } else if (!maxStampDuty && stampDuty < minStampDuty) {
    return `Stamp duty should be ≥ ${minStampDuty}`
  } else {
    return ERRORMESSAGES.NOERROR
  }
}
