/* eslint-disable */
import { Box, Button, Grid, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import cross from '../../../../assets/images/cross.svg';
import PropTypes from 'prop-types';
import { COMMON, PATTERN } from '../../../../constants/enum';
import palette from '../../../../theme/palette';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    background: palette.primary.light,
    boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
    borderRadius: '3px',
    p: 2
};

const PushMoneyModal = ({
    openPushMoneyModal,
    handlePushMoneyModalClose,
    handleSubmitPushMoneyModal,
    walletBankForm,
    setWalletBankForm
}) => {
    const handleWalletBankFormChange = (event) => {
        console.log('in');
        setWalletBankForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value.replace(/\s/gy, '')
        }));
        console.log(walletBankForm);
    };

    return (
        <div>
            <Modal
                open={openPushMoneyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmitPushMoneyModal();
                        }}>
                        <Grid
                            display="flex"
                            justifyContent="flex-end"
                            sx={{ cursor: 'pointer' }}
                            onClick={handlePushMoneyModalClose}>
                            <img src={cross} alt="cross" height="21px" width="21px" />
                        </Grid>
                        <Grid pl={2}>
                            <Typography
                                variant="labelLarge"
                                component="div"
                                sx={{ fontSize: '25px', fontWeight: 600 }}>
                                Note
                            </Typography>
                            <Typography
                                variant="darkValue"
                                component={'div'}
                                sx={{ fontSize: '15px' }}
                                mb={1}>
                                This money will go directly to your bank account associated with
                                DIGIDOC
                            </Typography>

                            <Grid mb={1}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '15px', lineHeight: '31px' }}>
                                    Enter amount to be sent
                                </Typography>
                                <TextField
                                    value={walletBankForm?.amountToAdd || ''}
                                    type="text"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {COMMON?.RUPPES || ''}
                                            </InputAdornment>
                                        )
                                    }}
                                    name="amountToAdd"
                                    id="amountToAdd"
                                    fullWidth
                                    required
                                    onChange={(event) => {
                                        if (
                                            event.target.value === '' ||
                                            PATTERN?.NUMBERONLY.test(event?.target?.value)
                                        ) {
                                            handleWalletBankFormChange(event);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid mb={3}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '15px', lineHeight: '31px' }}>
                                    Enter your profile password to authenticate
                                </Typography>
                                <TextField
                                    value={walletBankForm?.password || ''}
                                    type="password"
                                    name="password"
                                    id="password"
                                    fullWidth
                                    inputProps={{ autoComplete: 'new-password' }}
                                    required
                                    onChange={handleWalletBankFormChange}
                                />
                            </Grid>

                            <Button variant="primary" type="submit" sx={{ marginBottom: '10px' }}>
                                Submit request
                            </Button>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default PushMoneyModal;

PushMoneyModal.propTypes = {
    openPushMoneyModal: PropTypes.bool,
    handlePushMoneyModalClose: PropTypes.func,
    handleSubmitPushMoneyModal: PropTypes.func,
    walletBankForm: PropTypes.object,
    setWalletBankForm: PropTypes.func
};
