/* eslint-disable no-unused-vars */
import moment from 'moment'
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
import { DIGITALSIGNATUREPOSITION } from '../constants/signatureConstant'
import { PARTYTYPES } from '../constants/enum'

export const handleEsignModified = async (pdflink, signaturePosition) => {
  try {
    console.log('Under Esign Modified')
    // eslint-disable-next-line no-undef
    const res = await SignerDigital.getSelectedCertificate('', false, 128)
    const response = JSON.parse(res)
    console.log(response)

    // eslint-disable-next-line no-undef
    // const signDataResp = await SignerDigital.signPdfHash(
    //   'C486E16036713395BEB786116239F6D39809C6101C0FC1E79F43212BBFB2A19C', // pdf hash
    //   response?.CertThumbPrint,
    //   'SHA256'
    // )

    // console.log('signDataResp', signDataResp)
    // Send signDataResp to Server
    // injectSign1(signDataResp)

    // Now you can decide when to return
    return { err: false, data: response }
  } catch (error) {
    console.log(error, 'error from esign')
    // Send error to server or display the result in the browser.
    return { err: true, errmsg: error }
  }
}

export const handleEsign = async (path, pdflink, signaturePosition) => {
  //   const response = await fetch(
  //     'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/28c534b5-3613-49bb-bded-53c3b41aab69District-Report%20%2816%29.pdf'
  //   ) // Replace with the actual PDF link

  // const pdfArrayBuffer = await response.arrayBuffer()
  // const existingPdfBytes = pdfArrayBuffer

  // WOrking on this
  // const blob = await injectSignature('res', pdflink, signaturePosition)
  // return blob

  // const connectedSmartCard = handleCheckESignConnected()

  // if (!connectedSmartCard?.error) {
  // console.log(connectedSmartCard?.response)
  // eslint-disable-next-line no-undef
  const response = await SignerDigital.getSelectedCertificate('', false, 128) // cert, showExpired, key[https://learn.microsoft.com/en-us/dotnet/api/system.security.cryptography.x509certificates.x509keyusageflags?view=net-8.0]
    .then((res) => {
      const response = JSON.parse(res)
      console.log(response)
      // injectSignature(response, pdflink, signaturePosition)
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-undef
      SignerDigital.signPdfHash(
        'C486E16036713395BEB786116239F6D39809C6101C0FC1E79F43212BBFB2A19C',
        response?.CertThumbPrint,
        'SHA256'
      ).then(
        function (signDataResp) {
          console.log('signDataResp', signDataResp)
          // Send signDataResp to Server
          // injectSIgn1(signDataResp)
        },
        function (errmsg) {
          console.log('errmsg', errmsg)
          return { err: true, errmsg }

          // Send errmsg to server or display the result in browser.
        }
      )
    })
    .catch((error) => {
      console.log(error, 'erororr from esign')
      return { err: true, errmsg: error }
    })

  return response
  // } else {
  // console.log('no smartcard', connectedSmartCard?.errMsg)
  // return 'no smartCard'
  // }
  // Open the certificate store and get the selected certificate
}

// Check is Digital signature pendrive connected or not
export const handleCheckESignConnected = () => {
  // eslint-disable-next-line no-undef
  // SignerDigital.getPCSCReaders(true)
  //   .then(
  //     function (signDataResp) {
  //       console.log('signDataResp', signDataResp)
  //       // Send signDataResp to Server
  //       // injectSIgn1(signDataResp)
  //       return { error: false, response: signDataResp }
  //     },
  //     function (errMsg) {
  //       console.log('errMsg', errMsg)
  //       return { error: true, errMsg }
  //       // Send errmsg to server or display the result in browser.
  //     }
  //   )
  //   .catch((err) => {
  //     console.log('errrr', err)
  //   })

  // eslint-disable-next-line no-undef
  SignerDigital.getPCSCReaders(false)
    .then((res) => {
      console.log('ress from usb', res)
    })
    .catch((err) => {
      console.log('err from usb', err)
    })
}

// Get position co-ordinates array according to its party type
export const getPositionCoordinatesArray = (currentUserDocumentInformation) => {
  try {
    const positionCordList = []
    const gapSize = DIGITALSIGNATUREPOSITION?.GAPSIZE?.POS

    if (
      currentUserDocumentInformation.partyId === PARTYTYPES?.FIRSTPARTY?.ID ||
            currentUserDocumentInformation.partyId === PARTYTYPES?.SECONDPARTY?.ID
    ) {
      // Array of only the parties that are same with the current user
      // for ex: if current user is first party then all the first party in the document are filtered
      const partyFilteredArray = currentUserDocumentInformation?.userList.filter(
        (obj) => obj?.partyId === currentUserDocumentInformation.partyId
      )

      // Sort the partyFilteredArray array based on the 'priority' field
      const sortedPriorityArray = partyFilteredArray.sort(
        (a, b) => a?.priority - b?.priority
      )

      // Find the index of the object with the current user party
      const indexOfCurrentParty = sortedPriorityArray.findIndex(
        (obj) => obj.email === currentUserDocumentInformation.email
      )

      const initialxpos =
                currentUserDocumentInformation.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                  ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.XPOS
                  : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.XPOS

      const xpos =
                currentUserDocumentInformation.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                  ? initialxpos + gapSize * indexOfCurrentParty
                  : initialxpos - gapSize * indexOfCurrentParty

      const ypos =
                currentUserDocumentInformation.partyId === PARTYTYPES?.SECONDPARTY?.ID
                  ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.YPOS
                  : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.YPOS

      for (let i = 0; i < currentUserDocumentInformation?.pages - 1; i++) {
        const position = {
          page: i,
          posx: xpos,
          posy: ypos
        }
        positionCordList.push(position)
      }
    }
    // Will Work both for Party and Witness, as we need witness signature only on the marked position
    const markedPosition = {
      page: currentUserDocumentInformation.pageNumber - 1,
      posx: currentUserDocumentInformation.positionX,
      posy: currentUserDocumentInformation.positionY
    }

    positionCordList.push(markedPosition)

    return positionCordList
  } catch (error) {
    console.log('in error', error)
  }
}

/**
 * Handle extract name from digital certificate
 * @param {object} signatureResponse: Digital certificate response
 * @returns name
 */
export const getNameByDigitalCertificate = (signatureResponse) => {
  const certificateInformationArray = signatureResponse?.SelCertSubject.split(',')
  let name = ''
  // console.log(certificateInformationArray, 'certificateInformationArray')
  certificateInformationArray.forEach((item, index) => {
    if (item.toLowerCase().includes('cn')) {
      name = certificateInformationArray[index].split('=')[1]
    }
  })
  return name
}

// Add signature box in pdf
export const injectSignature = async (
  signatureResponse,
  pdflink,
  signaturePositionList,
  currentUserIpAddress
) => {
  console.log(signatureResponse, pdflink)
  // const certificateInformationArray = signatureResponse?.SelCertSubject.split(',')
  // let name = ''
  // // console.log(certificateInformationArray, 'certificateInformationArray')
  // certificateInformationArray.forEach((item, index) => {
  //   if (item.toLowerCase().includes('cn')) {
  //     name = certificateInformationArray[index].split('=')[1]
  //   }
  // })

  const name = await getNameByDigitalCertificate(signatureResponse)

  const response = await fetch(pdflink) // Replace with the actual PDF link
  const pdfArrayBuffer = await response.arrayBuffer()
  const existingPdfBytes = pdfArrayBuffer

  // Load a PDFDocument from the existing PDF bytes
  // const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Get the first page of the document
  // const pages = pdfDoc.getPages()

  // Fonts
  // const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)

  // Add header and footer in all the pages od the document

  // let x = 470
  // for (let i = 0; i < signaturePositionList?.length; i++) {
  //   const page = pages[signaturePositionList[i]?.page]
  //   // const { width, height } = page.getSize()

  //   // Add header
  //   page.drawText('Digitally Signed By:-', {
  //     x: signaturePositionList[i]?.posx,
  //     y: signaturePositionList[i]?.posy,
  //     size: 7,
  //     font: timesRomanFontBold
  //   })
  //   page.drawText(`Name as per aadhaar: ${name}`, {
  //     x: signaturePositionList[i]?.posx,
  //     y: signaturePositionList[i]?.posy - 8,
  //     size: 7,
  //     font: timesRomanFontBold
  //   })
  //   page.drawText(`Location: IP : ${currentUserIpAddress}`, {
  //     x: signaturePositionList[i]?.posx,
  //     y: signaturePositionList[i]?.posy - 8 * 2,
  //     size: 7,
  //     font: timesRomanFontBold
  //   })
  //   page.drawText('Reason: StockHolding DigiDoc Signature', {
  //     x: signaturePositionList[i]?.posx,
  //     y: signaturePositionList[i]?.posy - 8 * 3,
  //     size: 7,
  //     font: timesRomanFontBold
  //   })

  //   const date = moment()

  //   // Format the date using the desired pattern
  //   const formattedDate = date.format('ddd MMM DD HH:mm:ss [IST] YYYY')

  //   page.drawText(`Date: ${formattedDate}`, {
  //     x: signaturePositionList[i]?.posx,
  //     y: signaturePositionList[i]?.posy - 8 * 4,
  //     size: 7,
  //     font: timesRomanFontBold
  //   })
  //   // x += 120
  // }

  // const pdfDOc23 = await pdfDoc.save()
  const pdfBlob = await new Blob([existingPdfBytes], { type: 'application/pdf' })
  // const link = document.createElement('a')
  // link.href = URL.createObjectURL(pdfBlob)
  // link.download = 'generated.pdf'
  // link.click()
  return pdfBlob
}
