import './Resetpassword.css'
import React, { useState } from 'react'
import { Typography, Grid, TextField, Button, InputAdornment, IconButton } from '@mui/material'
import eyeOnIcon from '../../../../assets/images/eyeon.svg'
import eyeOffIcon from '../../../../assets/images/eyeoff.svg'
import { validator } from '../../../../util/validator'
import { useResetPasswordMutation } from '../../../../store/services/userServices'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const Resetpassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  // Reset Password mutation
  const [resetPassword] = useResetPasswordMutation({})

  const { token } = useParams()
  const navigate = useNavigate()

  const [resetForm, setResetForm] = useState({
    form: {},
    errors: {
      password: 'error',
      confirmPassword: 'error'
    }
  })

  // Handle reset password form submit
  const handleSubmit = () => {
    const dataToSend = {
      token,
      password: resetForm.form.password
    }
    console.log('dataToSend:Componet:ResetPassword', dataToSend)
    if (
      resetForm.errors.password === 'noerror' &&
            resetForm.errors.confirmPassword === 'noerror'
    ) {
      resetPassword({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          console.log(response, 'fulfil:ResetPassword')
          navigate('/')
          toast.success(response?.message)
        })
        .catch((execption) => {
          console.log('exception:REsetPassword', execption)
        })
    }
  }

  /**
     * Handle from change
     * @param {object} event: event object
     */
  const handleResetFormChange = (event) => {
    let err

    if (event.target.name === 'password' && resetForm.form.confirmPassword) {
      // err = validator(event.target.name, event.target.value);
      if (
        resetForm.form.confirmPassword &&
                event.target.value !== resetForm.form.confirmPassword
      ) {
        err = 'Password and Confirm Password does not match'
      } else {
        err = resetForm.form.confirmPassword ? 'noerror' : resetForm.error.confirmPassword
      }
      setResetForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          confirmPassword: err
        }
      }))
    } else {
      err = validator(event.target.name, event.target.value, resetForm?.form.password)
      setResetForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          [event.target.name]: err
        }
      }))
    }
  }

  return (
        <Grid item md={6} className="resetpasswordRightGrid">
            <div className="resetpasswordHead">
                <Typography variant="labelLarge" sx={{ lineHeight: '64px' }}>
                    Reset your password
                </Typography>
                <Typography variant="modalLabel" sx={{ fontSize: '18px', marginBottom: '39px' }}>
                    Please enter your new password
                </Typography>
            </div>

            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleSubmit()
                }}>
                <TextField
                    error={
                        !(
                          resetForm.errors.password === 'error' ||
                            resetForm.errors.password === 'noerror'
                        )
                    }
                    helperText={
                        resetForm.errors.password === 'error' ||
                        resetForm.errors.password === 'noerror'
                          ? ''
                          : resetForm.errors.password
                    }
                    required
                    name="password"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Enter new password"
                    style={{ marginBottom: '23px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword
                                      ? (
                                        <img src={eyeOnIcon} alt="Password" />
                                        )
                                      : (
                                        <img src={eyeOffIcon} alt="Password" />
                                        )}
                                </IconButton>
                            </InputAdornment>
                      )
                    }}
                    onChange={handleResetFormChange}
                />
                <TextField
                    error={
                        !(
                          resetForm.errors.confirmPassword === 'error' ||
                            resetForm.errors.confirmPassword === 'noerror'
                        )
                    }
                    helperText={
                        resetForm.errors.confirmPassword === 'error' ||
                        resetForm.errors.confirmPassword === 'noerror'
                          ? ''
                          : resetForm.errors.confirmPassword
                    }
                    required
                    ß
                    name="confirmPassword"
                    id="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm new password"
                    style={{ marginBottom: '22px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword
                                      ? (
                                        <img src={eyeOnIcon} alt="Password" />
                                        )
                                      : (
                                        <img src={eyeOffIcon} alt="Password" />
                                        )}
                                </IconButton>
                            </InputAdornment>
                      )
                    }}
                    onChange={handleResetFormChange}
                />

                <Button variant="primary" type="submit" fullWidth>
                    Submit
                </Button>
            </form>
        </Grid>
  )
}

export default Resetpassword
