/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { Badge, Button, Divider, Grid, Menu, MenuItem, Select, Typography } from '@mui/material'
import NotificationImage from '../../assets/images/notification.svg'
import './NotificationsPopup.css'
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
// import palette from '../../theme/palette'
import {
  useLazyGetAllNotificationsQuery,
  useLazyMarkNotificationReadQuery
} from '../../store/services/commonServices'
import { useLocation } from 'react-router-dom'
// import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';

const NotificationsPopup = () => {
  const location = useLocation()

  const readOptions = [
    {
      id: 1,
      name: 'All'
    },
    {
      id: 2,
      name: 'Read'
    },
    {
      id: 3,
      name: 'Unread'
    }
  ]
  // Notification popup state
  const [notificationBadge, setNotificationBadge] = useState(null)

  // Notification list state
  const [notificationResponse, setNotificationResponse] = useState([])

  const [unReadNotificationCount, setUnReadNotificationCount] = useState(0)

  const [FilterNotificationList, setFilterNotificationList] = useState([])

  // const { data } = useGetAllNotificationsQuery({ viaAuth: 1 })

  // Get all notification list query
  const [getAllNotification] = useLazyGetAllNotificationsQuery({})

  // Mark notification as read
  const [markNotificationAsRead] = useLazyMarkNotificationReadQuery({})

  // Automatically reload the all notifications on page route changes
  useEffect(() => {
    handleGetAllNotificationList()
  }, [location.pathname])

  const handleGetAllNotificationList = () => {
    getAllNotification()
      .unwrap()
      .then((response) => {
        console.log('response--->>>', response)
        setNotificationResponse(response)
        setFilterNotificationList(response?.notifications)
        setUnReadNotificationCount(
          response?.notifications.filter((notification) => !notification?.isRead).length
        )
      })
      .catch((exception) => {
        console.log('exception', exception)
      })
  }

  // Filter option
  const [filter, setFilter] = useState({
    readOption: 1
  })

  /**
     * Handle set readOption state and filter the notification list according to the option
     * @param {object} e: Event object
     */
  const filterSelectChangeHandler = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))

    if (e.target.value === 1) {
      setFilterNotificationList(notificationResponse?.notifications)
    } else if (e.target.value === 2) {
      setFilterNotificationList(
        notificationResponse?.notifications?.filter((item) => item?.isRead)
      )
    } else {
      setFilterNotificationList(
        notificationResponse?.notifications?.filter((item) => !item?.isRead)
      )
    }
    console.log('---...notif', unReadNotificationCount, FilterNotificationList)
  }

  /**
     * Handle open notification popup
     * @param {object} event: Event object
     */
  const handleOpenUserMenu = (event) => {
    setNotificationBadge(event.currentTarget)
    setFilter((prev) => ({
      ...prev,
      readOption: 1
    }))
  }

  // Handle Close notification popup
  const handleCloseUserMenu = () => {
    setNotificationBadge(null)
  }

  // Handle mark notification as read and get all the updated  notifications
  const handleMarkAllNotificationAsRead = () => {
    const params = {
      type: 'all'
    }
    markNotificationAsRead(params)
      .unwrap()
      .then((response) => {
        handleGetAllNotificationList()
        console.log('response', response)
      })
      .catch((exception) => {
        console.log('exception', exception)
      })
  }

  return (
        <>
            <Badge
                badgeContent={unReadNotificationCount}
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#fff',
                    borderRadius: '50%',
                    background: 'linear-gradient(90deg, #30A772 -21.32%, #06B5FA 125.19%)',
                    fontSize: '12px',
                    fontWeight: 'bold'
                  },
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0px 30px',
                  cursor: 'pointer'
                }}
                className="notificationsBadge mr-9"
                onClick={handleOpenUserMenu}>
                <img className="notification" src={NotificationImage} alt="Notifications" />
            </Badge>
            <Menu
                sx={{ mt: '45px' }}
                id="notification-menu"
                anchorEl={notificationBadge}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                PaperProps={{
                  style: {
                    boxShadow: '0 12px 27px 0 rgba(0,0,0,0.15)',
                    borderRadius: '5px',
                    border: '0.5px solid rgba(221, 221, 221,.5)',
                    marginLeft: '3px',
                    height: '400px',
                    width: '440px',
                    paddingTop: '0px !important'
                  }
                }}
                open={Boolean(notificationBadge)}
                onClose={handleCloseUserMenu}
                MenuListProps={{ sx: { pt: 0 } }}>
                <Grid
                    container
                    sx={{
                      top: 0,
                      position: 'sticky',
                      padding: '10px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: 'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)'
                    }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                              color: '#fff',
                              fontSize: '14px',
                              fontWeight: '600',
                              letterSpacing: '0.4px',
                              marginRight: '10px'
                            }}>
                            Notifications
                        </Typography>
                        <Grid item>
                            <Select
                                value={filter?.readOption || ''}
                                name="readOption"
                                onChange={filterSelectChangeHandler}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px',
                                  height: '23px',
                                  // width: "9vw",
                                  background:
                                        'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
                                  color: '#fff',
                                  fontWeight: 500
                                }}
                                // MenuProps={MenuProps}
                                fullWidth>
                                {readOptions?.length > 0 &&
                                    readOptions?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            sx={{ color: '#fff', textTransform: 'full-size-kana' }}
                            endIcon={<CheckCircleOutlineIcon />}
                            onClick={handleMarkAllNotificationAsRead}
                            disabled={!unReadNotificationCount}>
                            Mark all as read
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                    {FilterNotificationList.length > 0 &&
                        FilterNotificationList.map((item, i) => {
                          return (
                                <div key={`notifications${i}`}>
                                    <Grid>
                                        <Grid
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'top',
                                              paddingLeft: '15px'
                                            }}>
                                            {!item?.isRead && (
                                                <FiberManualRecordSharpIcon
                                                    sx={{
                                                      color: 'rgb(51, 175, 185)',
                                                      height: '10px',
                                                      width: '10px',
                                                      mt: '20px'
                                                    }}
                                                />
                                            )}
                                            <Typography
                                                className="anchorNotification-text"
                                                onClick={handleCloseUserMenu}>
                                                {item?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              padding: '10px 16px 14px 37px'
                                            }}>
                                            <Typography
                                                sx={{
                                                  color: '#5C697C',
                                                  fontSize: '12px',
                                                  fontWeight: 500
                                                }}>
                                                {item?.timeOfNotification}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {i + 1 === FilterNotificationList?.length ? null : (
                                        <Divider
                                            sx={{
                                              borderBottom: '0.5px solid rgba(204,208,213,0.3)'
                                            }}
                                        />
                                    )}
                                </div>
                          )
                        })}
                    {FilterNotificationList.length === 0 && (
                        <Grid
                            container
                            sx={{
                              height: '32vh',
                              position: 'relative',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              flexDirection: 'column'
                            }}>
                            <Grid
                                item
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  textAlign: 'center',
                                  maxWidth: 'calc(100vw - 70vw)'
                                }}>
                                <Typography
                                    sx={{
                                      color: '#000',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      marginBottom: '4px'
                                    }}>
                                    No notifications yet
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 300, opacity: 0.7, fontSize: '13px' }}>
                                    We'll let you know when we've got something new for you.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Menu>
        </>
  )
}
export default NotificationsPopup
