/* eslint-disable react/prop-types */
import { Box } from '@mui/material'
import React from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
// import calendericon from '../../assets/images/calendericon.svg'
import './DateRange.css'
import palette from '../../theme/palette'

export default function DateRangeComponent ({ dateRange, setDateRange, maxDate }) {
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection'
  //   }
  // ])
  return (
        <>
            <Box sx={{ zIndex: 4, position: 'relative' }}>
                <DateRangePicker
                    onChange={(item) => {
                      console.log(item)
                      setDateRange([item.selection])
                    }}
                    maxDate={maxDate}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    direction="horizontal"
                    rangeColors={[palette.success.dark, palette.success.dark]}
                />
            </Box>
        </>
  )
}
