import React, { useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../../theme/palette'
import { Link } from 'react-router-dom'
import { useAcceptTermsMutation } from '../../../store/services/commonServices'
import { toast } from 'react-toastify'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import { TERMTYPES } from '../../../constants/enum'

// import { PATTERN } from '../../../../constants/enum'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
  borderRadius: '3px',
  p: 4
}

const TermsCheckModal = ({
  openTermsModal,
  handleTermsModalClose,
  showPrivacyPolicy,
  showDisclaimer,
  showTerms
}) => {
  const [backdropState, setBackdropState] = useState(false)

  // Mutation for accepting Terms
  const [acceptTerms] = useAcceptTermsMutation({})

  const handleOnSubmit = () => {
    setBackdropState(true)

    const dataToSend = {
      terms: showTerms,
      disclaimer: showDisclaimer,
      privacy: showPrivacyPolicy
    }
    acceptTerms({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        handleTermsModalClose()
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('EXCEPTION:CHECK PAN KYC', execption)
      })
  }

  return (
        <Modal
            open={openTermsModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                {/* {!otp && ( */}
                <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleOnSubmit()
                    }}>
                    <div className="aadharmodal_box">
                        <Typography
                            variant="labelLarge"
                            component="div"
                            sx={{
                              fontSize: '25px',
                              marginBottom: '20px',
                              fontWeight: 600,
                              textAlign: 'center'
                            }}>
                            Terms & Conditions
                        </Typography>
                        <Typography
                            variant="labelSmall"
                            component="div"
                            sx={{ fontSize: '20px', marginBottom: '20px', fontWeight: 600 }}>
                            We have updated our Terms and Conditions, please check the updated terms
                            below.
                        </Typography>
                        <div>
                            {showTerms && (
                                <FormControlLabel
                                    sx={{ mb: '15px', mr: 0 }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {}}
                                            sx={{
                                              color: 'var(--g2)',
                                              '&.Mui-checked': {
                                                color: '#33AFB9'
                                              }
                                            }}
                                            required
                                            name="paymentTerms"
                                            id="paymentTerms"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree with &nbsp;
                                            <Link
                                                to={`/termsAndConditions/${TERMTYPES.TERMSANDCONDITIONS.value}`}
                                                className="text-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                color="var(--g7)">
                                                Terms and Conditions.
                                            </Link>
                                        </Typography>
                                    }
                                />
                            )}

                            {showPrivacyPolicy && (
                                <FormControlLabel
                                    sx={{ mb: '15px', mr: 0, width: '100%' }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {}}
                                            sx={{
                                              color: 'var(--g2)',
                                              '&.Mui-checked': {
                                                color: '#33AFB9'
                                              }
                                            }}
                                            required
                                            name="privacyPolicy"
                                            id="privacyPolicy"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree with &nbsp;
                                            <Link
                                                to={`/termsAndConditions/${TERMTYPES.PRIVACYPOLICY.value}`}
                                                className="text-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                color="var(--g7)">
                                                Privacy Policy.
                                            </Link>
                                        </Typography>
                                    }
                                />
                            )}
                            {showDisclaimer && (
                                <FormControlLabel
                                    sx={{ mb: '15px', mr: 0, width: '100%' }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {}}
                                            sx={{
                                              color: 'var(--g2)',
                                              '&.Mui-checked': {
                                                color: '#33AFB9'
                                              }
                                            }}
                                            required
                                            name="disclaimer"
                                            id="disclaimer"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree with &nbsp;
                                            <Link
                                                to={`/termsAndConditions/${TERMTYPES.DISCLAIMER.value}`}
                                                className="text-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                color="var(--g7)">
                                                Disclaimer.
                                            </Link>
                                        </Typography>
                                    }
                                />
                            )}
                        </div>
                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ width: '120px', marginTop: '10px' }}>
                            Agree
                        </Button>
                    </div>
                </form>

                {backdropState && <BackdropLoader backdropState={backdropState} />}
            </Box>
        </Modal>
  )
}

export default TermsCheckModal

TermsCheckModal.propTypes = {
  openTermsModal: PropTypes.bool,
  handleTermsModalClose: PropTypes.func,
  showPrivacyPolicy: PropTypes.bool,
  showDisclaimer: PropTypes.bool,
  showTerms: PropTypes.bool
}
