import React from 'react'
import { Modal, Box, Grid, Typography, Button } from '@mui/material'
import { MESSAGE_CONFIRMATION } from '../../constants/message'
import { removeSession } from '../../util/helper'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: palette.primary.light,
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '3px',
  p: 4
}
export default function ConfirmationModal (props) {
  const handleAllClose = () => {
    props.handleAll()
    props.handleConfirmationModalClose()
    removeSession('token')
  }

  return (
        <div>
            <Modal
                open={props.openConfirmationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container direction="row" justify-content="flex-start" marginBottom={4}>
                        <Typography variant="labelLarge">{MESSAGE_CONFIRMATION.TEXT}</Typography>
                    </Grid>

                    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item>
                            <Button variant="primary" onClick={handleAllClose}>
                                Yes
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="cancelButton"
                                onClick={props.handleConfirmationModalClose}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
  )
}

ConfirmationModal.propTypes = {
  openConfirmationModal: PropTypes.bool,
  handleConfirmationModalClose: PropTypes.func,
  handleAll: PropTypes.func
}
