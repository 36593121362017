import { styled } from '@mui/material'

// For divider mui
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2)
  }
}))

export default Root
