import { Box, Button, Grid, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles'
import { validator } from '../../util/validator'
import { PATTERN } from '../../constants/enum'
import { useGetAccountTypesQuery } from '../../store/services/commonServices'
import palette from '../../theme/palette'
import errormessages from '../../constants/errormessages'
// import { EXCEPTTHISSYMBOLS } from '../../constants/enum'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: palette.primary.light,
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '3px',
  p: 3
}

const BankAccountModal = ({
  bankAccountDetails,
  setBankAccountDetails,
  openBankModal,
  bankAccountModalState,
  handleBankModalClose,
  handleSubmitBankModal
}) => {
  //   const exceptThisSymbols = EXCEPTTHISSYMBOLS

  const { data: accountTypes = [] } = useGetAccountTypesQuery({})

  const handleBankAccountFormChange = (event) => {
    const err = validator(
      event.target.name,
      event.target.value,
      bankAccountDetails?.accountNumber
    )
    setBankAccountDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, ''),
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))

    if (event.target.name === 'accountNumber' && bankAccountDetails?.confirmAccountNumber) {
      let ConfirmAccNoErr
      if (
        bankAccountDetails?.confirmAccountNumber &&
                event.target.value !== bankAccountDetails?.confirmAccountNumber
      ) {
        ConfirmAccNoErr = errormessages?.CONFIRMACCOUNTNUMBERNOMATCH
      } else {
        ConfirmAccNoErr = bankAccountDetails?.confirmAccountNumber
          ? 'noerror'
          : bankAccountDetails?.error?.confirmAccountNumber
      }
      setBankAccountDetails((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
        errors: {
          ...prev.errors,
          confirmAccountNumber: ConfirmAccNoErr
        }
      }))
    }
  }

  const handleIfscCodeChange = (event) => {
    const err = validator(event.target.name, event.target.value)
    setBankAccountDetails((prev) => ({
      ...prev,
      errors: {
        ...prev?.errors,
        [event.target.name]: err
      },
      [event.target.name]: event.target.value.toUpperCase()
    }))
  }
  return (
        <div>
            <Modal
                open={openBankModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <form
                        onSubmit={(event) => {
                          event.preventDefault()
                          handleSubmitBankModal()
                        }}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="labelLarge">
                                    {bankAccountModalState?.label}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Bank Name</Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            value={bankAccountDetails?.bankName || ''}
                                            required
                                            type="text"
                                            fullWidth
                                            name="bankName"
                                            id="bankName"
                                            onChange={handleBankAccountFormChange}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Account Number</Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            error={
                                                !(
                                                  bankAccountDetails?.errors?.accountNumber ===
                                                        'error' ||
                                                    bankAccountDetails?.errors?.accountNumber ===
                                                        'noerror'
                                                )
                                            }
                                            helperText={
                                                bankAccountDetails?.errors?.accountNumber ===
                                                    'error' ||
                                                bankAccountDetails?.errors?.accountNumber ===
                                                    'noerror'
                                                  ? ''
                                                  : bankAccountDetails?.errors?.accountNumber
                                            }
                                            value={bankAccountDetails?.accountNumber || ''}
                                            required
                                            type="text"
                                            inputProps={{
                                              type: 'text',
                                              minlength: 9,
                                              maxlength: 18
                                            }}
                                            fullWidth
                                            name="accountNumber"
                                            id="accountNumber"
                                            onChange={(event) => {
                                              if (
                                                event.target.value === '' ||
                                                    PATTERN?.NUMBERONLY.test(event?.target?.value)
                                              ) {
                                                handleBankAccountFormChange(event)
                                              }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">
                                            Confirm Account Number
                                        </Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            error={
                                                !(
                                                  bankAccountDetails?.errors
                                                    ?.confirmAccountNumber === 'error' ||
                                                    bankAccountDetails?.errors
                                                      ?.confirmAccountNumber === 'noerror'
                                                )
                                            }
                                            helperText={
                                                bankAccountDetails?.errors?.confirmAccountNumber ===
                                                    'error' ||
                                                bankAccountDetails?.errors?.confirmAccountNumber ===
                                                    'noerror'
                                                  ? ''
                                                  : bankAccountDetails?.errors
                                                    ?.confirmAccountNumber
                                            }
                                            value={bankAccountDetails?.confirmAccountNumber || ''}
                                            required
                                            type="text"
                                            inputProps={{
                                              type: 'text',
                                              minlength: 9,
                                              maxlength: 18
                                            }}
                                            fullWidth
                                            name="confirmAccountNumber"
                                            id="confirmAccountNumber"
                                            onChange={(event) => {
                                              if (
                                                event.target.value === '' ||
                                                    PATTERN?.NUMBERONLY.test(event?.target?.value)
                                              ) {
                                                handleBankAccountFormChange(event)
                                              }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">
                                            Account Holder Name
                                        </Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            value={bankAccountDetails?.accountHolderName || ''}
                                            required
                                            type="text"
                                            fullWidth
                                            name="accountHolderName"
                                            id="accountHolderName"
                                            onChange={handleBankAccountFormChange}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Bank IFSC Code</Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            error={
                                                !(
                                                  bankAccountDetails?.errors?.ifscCode ===
                                                        'error' ||
                                                    bankAccountDetails?.errors?.ifscCode ===
                                                        'noerror'
                                                )
                                            }
                                            helperText={
                                                bankAccountDetails?.errors?.ifscCode === 'error' ||
                                                bankAccountDetails?.errors?.ifscCode === 'noerror'
                                                  ? ''
                                                  : bankAccountDetails?.errors?.ifscCode
                                            }
                                            value={bankAccountDetails?.ifscCode || ''}
                                            name="ifscCode"
                                            id="ifscCode"
                                            inputProps={{
                                              type: 'text',
                                              maxlength: 11,
                                              minlength: 11
                                            }}
                                            required
                                            type="text"
                                            fullWidth
                                            onChange={handleIfscCodeChange}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Type of Account</Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <Select
                                            value={bankAccountDetails?.accountType || ''}
                                            required
                                            name="accountType"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{ fontSize: '16px' }}
                                            MenuProps={MenuProps}
                                            fullWidth
                                            onChange={(event) => {
                                              console.log(event.target.value)
                                              setBankAccountDetails((prev) => ({
                                                ...prev,
                                                [event.target.name]: event.target.value
                                              }))
                                            }}>
                                            <MenuItem value="" disabled>
                                                <Typography>Select Account type</Typography>
                                            </MenuItem>
                                            {accountTypes.length > 0 &&
                                                accountTypes.map((item) => (
                                                    <MenuItem
                                                        sx={{ fontSize: '16px' }}
                                                        key={item.id}
                                                        value={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="cancelButton"
                                    onClick={handleBankModalClose}
                                    sx={{ marginRight: '10px' }}>
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    {bankAccountModalState?.button}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
  )
}

export default BankAccountModal

BankAccountModal.propTypes = {
  bankAccountDetails: PropTypes.object,
  setBankAccountDetails: PropTypes.func,
  openBankModal: PropTypes.bool,
  handleBankModalClose: PropTypes.func,
  handleSubmitBankModal: PropTypes.func,
  bankAccountModalState: PropTypes.object
}
