export const socialData = (provider, data) => {
  let modifedData = {}
  switch (provider) {
    case 'google':
      console.log('in google')
      modifedData = {
        provider,
        email: data.email || '',
        name: data.name || ''
      }
      return modifedData

    case 'facebook':
      console.log('in facebook')
      modifedData = {
        provider,
        email: data.email || '',
        name: data.name || '',
        id: data.id || ''
      }
      return modifedData

    default:
      console.log('validation pending ')
      break
  }
}
