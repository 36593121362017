import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  styled,
  Select,
  MenuItem
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import hand from '../../../../assets/images/hand.svg'
import arrow from '../../../../assets/images/arrow.svg'
import { useGetCityQuery, useGetStateQuery } from '../../../../store/services/userServices'
import { useGetBillingInfoQuery } from '../../../../store/services/subscription'
import {
  useGenerateOrderIdMutation,
  useUpdatePaymentStatusMutation
} from '../../../../store/services/paymentServices'
import { PATTERN, PAYMENT_TYPE } from '../../../../constants/enum'
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import { paymentInitialized } from '../../../../util/razorpayPayment'
import palette from '../../../../theme/palette'

// For divider mui
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2)
  }
}))

const VerifyRenewedSubscription = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [verifyForm, setVerifyForm] = useState({})

  // Get Billing information data
  const { data: billingData = {}, isSuccess } = useGetBillingInfoQuery({})

  // States Array
  const { data: stateArray = [] } = useGetStateQuery({})

  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: verifyForm.stateId },
    {
      skip: !verifyForm.stateId
    }
  )

  // Generate razorpay orderId
  const [generateOrderId] = useGenerateOrderIdMutation({})

  // Payment successfull update the transaction
  const [updateTransaction] = useUpdatePaymentStatusMutation()

  const [backdropState, setBackdropState] = useState(false)

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  // useEffect(() => {
  //   loadScript("https://checkout.razorpay.com/v1/checkout.js");
  // });

  // Set form data on verifyDetails api success
  useEffect(() => {
    console.log('useEffect')
    if (state && isSuccess) {
      setVerifyForm({
        name: billingData?.name,
        email: billingData?.email,
        gstNumber: billingData?.gst,
        address: billingData?.address,
        stateId: billingData?.state?.id,
        cityId: billingData?.city?.id,
        pinCode: billingData?.pinCode,
        planId: state?.id,
        planName: state?.type,
        currency: state?.currency,
        tenure: state?.tenure,
        cost: state?.amount,
        tax: state?.total - state?.amount,
        total: state?.total
      })
    }
    // else {
    //   navigate('/subscription')
    // }
  }, [billingData, isSuccess, state])

  /**
     * Handle form change
     * @param {*} event
     */
  const handleUpdateBilling = (event) => {
    if (event.target.name === 'stateId') {
      setVerifyForm((prev) => ({
        ...prev,
        cityId: '',
        [event.target.name]: event.target.value
      }))
    } else {
      setVerifyForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      }))
    }
    console.log(verifyForm)
  }

  // /**
  //    * Open Razorpay payment window and start payment process
  //    * @param {string} currency
  //    * @param {string} amount
  //    * @param {string} id
  //    */
  // const paymentInitialized = ({ currency, amount, id, description = '' }) => {
  //   console.log('Oayment intitialized')
  //   const options = {
  //     key: process.env.REACT_APP_RAZORPAY_KEYID,
  //     amount: amount.toString(),
  //     currency,
  //     name: verifyForm?.name,
  //     description,
  //     image: '',
  //     order_id: id,
  //     handler: async function (response) {
  //       const dataToSend = {
  //         paymentGateway: response,
  //         email: verifyForm?.email,
  //         gstNumber: verifyForm?.gstNumber,
  //         address: verifyForm?.address,
  //         stateId: verifyForm?.stateId,
  //         cityId: verifyForm?.cityId,
  //         planId: state?.id,
  //         transactionType: PAYMENT_TYPE.SUBSCRIPTION
  //       }
  //       handleUpdatePayment(dataToSend)

  //       // console.log(dataToSend)
  //     },
  //     prefill: {
  //       name: verifyForm?.name,
  //       email: verifyForm?.email
  //     },
  //     notes: {
  //       address: verifyForm?.address
  //     },
  //     theme: {
  //       color: '#33AFB9'
  //     }
  //   }

  //   const paymentObject = new window.Razorpay(options)
  //   paymentObject.open()
  //   paymentObject.on('payment.failed', function (response) {
  //     const dataToSend = {
  //       razorpayResponse: JSON.stringify(response),
  //       razorpayOrderId: id,
  //       paymentStatus: PAYMENTSTATUS?.FAILURE
  //     }
  //     handleUpdatePayment(dataToSend)
  //     // alert(response.error.code)
  //     // alert(response.error.description)
  //     // alert(response.error.source)
  //     // alert(response.error.step)
  //     // alert(response.error.reason)
  //     // alert(response.error.metadata.order_id)
  //     // alert(response.error.metadata.payment_id)
  //   })
  // }

  const handleUpdatePayment = (paymentStatusResponse) => {
    const dataToSend = {
      paymentGateway: paymentStatusResponse,
      address: {
        address: verifyForm?.address,
        stateId: verifyForm?.stateId,
        cityId: verifyForm?.cityId,
        pinCode: verifyForm?.pinCode
      },
      email: verifyForm?.email,
      gstNumber: verifyForm?.gstNumber,
      planId: state?.id,
      transactionType: PAYMENT_TYPE.SUBSCRIPTION
    }
    updateTransaction({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        navigate('/paymentSuccessful', {
          state: {
            HeaderTitle: 'Thank You',
            planName: verifyForm?.planName
          }
        })
      })
      .catch((execption) => {
        console.log('exception:Paymnent:FAIL', execption)
      })
  }

  const handleFormSubmit = async () => {
    setBackdropState(true)
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    console.log('form submit')

    const dataToSend = {
      planId: verifyForm?.planId,
      transactionType: PAYMENT_TYPE?.SUBSCRIPTION
    }
    generateOrderId({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        paymentInitialized(response)
          .then((paymentStatusResponse) => {
            handleUpdatePayment(paymentStatusResponse)
          })
          .catch((paymentStatusexception) => {
            handleUpdatePayment(paymentStatusexception)
          })
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('Order failed:Backend Failed', execption)
      })
  }

  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  return (
        <Box>
            <Grid container spacing={3}>
                <Grid item lg={6} sm={12} xs={12}>
                    <Typography variant="labelLarge" component="div" mb={2}>
                        Verify billing details
                    </Typography>
                    <Typography
                        mb={2}
                        variant="darkValue"
                        component="div"
                        sx={{ textAlign: 'left', fontSize: '15px' }}>
                        Ad elit reprehenderit aliquip amet officia exercitation eiusmod mollit
                        dolore. Fugiat culpa amet ut est duis exercitation.Aute reprehenderit
                        consectetur anim sint ut ex velit qui et minim cillum enim labore. Excepteur
                        excepteur commodo tempor ullamco.
                    </Typography>
                    <form
                        onSubmit={(event) => {
                          event.preventDefault()
                          handleFormSubmit()
                        }}>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">
                                    {billingData?.role === 'BUSINESS' ? 'Name' : 'Company name '}
                                </Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <TextField
                                    required
                                    value={verifyForm?.name || ''}
                                    id="name"
                                    name="name"
                                    fullWidth
                                    onChange={handleUpdateBilling}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">Email address</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <TextField
                                    required
                                    value={verifyForm?.email || ''}
                                    id="email"
                                    name="email"
                                    fullWidth
                                    onChange={handleUpdateBilling}
                                    onKeyDown={handlePreventSpace}
                                />
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">GST number</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <TextField
                                    value={verifyForm?.gstNumber || ''}
                                    inputProps={{
                                      type: 'text',
                                      maxlength: 15,
                                      requiredminlength: 15
                                    }}
                                    id="gstNumber"
                                    name="gstNumber"
                                    fullWidth
                                    onChange={(event) => {
                                      setVerifyForm((prev) => ({
                                        ...prev,
                                        [event.target.name]: event.target.value.toUpperCase()
                                      }))
                                    }}
                                    onKeyDown={handlePreventSpace}
                                />
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">Address</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <TextField
                                    required
                                    value={verifyForm?.address || ''}
                                    id="address"
                                    name="address"
                                    fullWidth
                                    onChange={handleUpdateBilling}
                                />
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">State</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <Select
                                    required
                                    value={verifyForm?.stateId || ''}
                                    sx={{ fontSize: '16px' }}
                                    labelId="state"
                                    id="demo-simple-select"
                                    name="stateId"
                                    fullWidth
                                    MenuProps={MenuProps}
                                    onChange={handleUpdateBilling}>
                                    {stateArray &&
                                        stateArray.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">City</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <Select
                                    required
                                    value={verifyForm?.cityId || ''}
                                    sx={{ fontSize: '16px' }}
                                    labelId="cityId"
                                    id="cityId"
                                    // inputProps={{ 'aria-label': 'Without label' }}
                                    name="cityId"
                                    fullWidth
                                    MenuProps={MenuProps}
                                    displayEmpty
                                    onChange={(event) => {
                                      setVerifyForm((prev) => ({
                                        ...prev,
                                        [event.target.name]: event.target.value
                                      }))
                                    }}>
                                    <MenuItem value="" disabled>
                                        <Typography>Select City</Typography>
                                    </MenuItem>
                                    {cityArray &&
                                        cityArray.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item display="flex" mb={3}>
                            <Grid display="flex" flexBasis="20%" alignItems="center">
                                <Typography variant="darkValue">Pin Code</Typography>
                            </Grid>
                            <Grid display="flex" flexBasis="80%">
                                <TextField
                                    value={verifyForm?.pinCode || ''}
                                    id="pinCode"
                                    name="pinCode"
                                    type="text"
                                    inputProps={{
                                      type: 'text',
                                      // inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                      maxlength: 6,
                                      minlength: 6
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                            PATTERN?.NUMBERONLY.test(e?.target?.value)
                                      ) {
                                        setVerifyForm((prev) => ({
                                          ...prev,
                                          [event.target.name]:
                                                    event.target.value.toUpperCase()
                                        }))
                                      }
                                    }}
                                    onKeyDown={handlePreventSpace}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={8}>
                            <Button
                                variant="primary"
                                type="submit"
                                endIcon={
                                    <img alt="arrow" src={arrow} style={{ marginLeft: '7px' }} />
                                }>
                                Proceed to payment
                            </Button>
                        </Grid>
                    </form>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <Grid display="flex" justifyContent="flex-end" m={1}>
                        <Link to="/subscription" className="text-link">
                            Change Plan
                        </Link>
                    </Grid>
                    <Box
                        sx={{
                          bordeRadius: '5px',
                          background: `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`,
                          boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`
                        }}
                        className="verifyBillingRightGridBox">
                        <Grid container p={3} display="flex">
                            <Grid display="flex" flexBasis="20%">
                                <img alt="hand" src={hand} height="89px" width="80px" />
                            </Grid>
                            <Grid display="flex" flexDirection="column" flexBasis="80%">
                                <Typography
                                    component="div"
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: '20px',
                                      color: palette.primary.light,
                                      marginBottom: '20px'
                                    }}>
                                    Selected Plan
                                </Typography>
                                <Typography
                                    variant="modalLabel"
                                    component="div"
                                    sx={{ color: palette.primary.light, marginBottom: '10px' }}>
                                    Plan Select -
                                    <span style={{ fontWeight: 600 }}>{verifyForm?.planName}</span>
                                </Typography>
                                <Typography
                                    variant="modalLabel"
                                    component="div"
                                    sx={{ color: palette.primary.light, marginBottom: '10px' }}>
                                    Tenure -
                                    <span style={{ fontWeight: 600 }}>
                                        {verifyForm?.tenure} months
                                    </span>
                                </Typography>
                                <Typography
                                    variant="modalLabel"
                                    component="div"
                                    sx={{ color: palette.primary.light, marginBottom: '10px' }}>
                                    Cost -
                                    <span style={{ fontWeight: 600 }}>
                                        {verifyForm?.currency} {verifyForm?.cost}
                                    </span>
                                </Typography>
                                <Typography
                                    variant="modalLabel"
                                    component="div"
                                    sx={{ color: palette.primary.light, marginBottom: '20px' }}>
                                    Tax (cgst + igst) -
                                    <span style={{ fontWeight: 600 }}>
                                        {verifyForm?.currency} {verifyForm?.tax}
                                    </span>
                                </Typography>
                                <Root>
                                    <Divider
                                        sx={{
                                          background: palette.primary.light,
                                          width: '282px',
                                          marginBottom: '20px'
                                        }}
                                    />
                                </Root>
                                <Typography
                                    variant="modalLabel"
                                    sx={{ color: palette.primary.light }}>
                                    Total -
                                    <span style={{ fontWeight: 600 }}>
                                        {verifyForm?.currency} {verifyForm?.total}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {backdropState && <BackdropLoader backdropState={backdropState} />}
            </Grid>
        </Box>
  )
}

export default VerifyRenewedSubscription
