import React, { useState } from 'react'

import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewDocument = ({ file }) => {
  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
        <>
            <Grid container>
                <Grid item lg={12}>
                    {file && (
                        // <div id="pdf-container" className="details_rightgrid-box">
                        <Document
                            size="A4"
                            file={file} // Path to your PDF file
                            workerSrc={workerSrc}
                            onLoadSuccess={onDocumentLoadSuccess}
                            // onSourceError={(error) =>
                            //   alert('Error while retrieving document source! ' + error.message)
                            // }
                            // onSourceSuccess={() => alert('Document source retrieved!')}
                            // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                        >
                            {Array.from({ length: numPages }, (_, index) => index + 1).map(
                              (pageNumber) => (
                                    <React.Fragment key={pageNumber}>
                                        <Page key={pageNumber} pageNumber={pageNumber} width={594}>
                                            <div className="pdf_page_break" />
                                            {/* {signatureBoxsList
                                                  .filter((box) => box.pageNumber === pageNumber)
                                                  .map((box, index) => (
                                                        <>
                                                            <Grid
                                                                height="100px"
                                                                width="300px"
                                                                sx={{
                                                                  border: '0.5px solid lightblue',
                                                                  position: 'absolute',
                                                                  background: 'lightblue',
                                                                  left: box.xPos,
                                                                  top: box.yPos,
                                                                  width: box.width,
                                                                  height: box.height
                                                                }}>
                                                                <Box className="areaArrow">
                                                                    {box?.name}
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                  ))} */}
                                        </Page>
                                    </React.Fragment>
                              )
                            )}
                        </Document>
                        // {/* </div> */}
                    )}
                </Grid>
            </Grid>
        </>
  )
}

export default PreviewDocument

PreviewDocument.propTypes = {
  file: PropTypes?.string
}
