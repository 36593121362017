import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import {
  useGetProfilePanDetailsQuery,
  useUpdatePanDetailsMutation
} from '../../../store/services/userServices'
import DatePickerComponent from '../../../components/DatePicker/DatePickerComponent'
import { validator } from '../../../util/validator'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import errormessages from '../../../constants/errormessages'

const EditPanDetails = () => {
  const navigate = useNavigate()
  const [backdropState, setBackdropState] = useState(false)

  const { data: panData = {} } = useGetProfilePanDetailsQuery({})

  const [updatePanDetailForm, setUpdatePanDetailForm] = useState({
    errors: {
      panNumber: 'noerror',
      name: 'noerror',
      fname: 'noerror',
      dob: 'noerror'
    }
  })

  const [updatePanDetails] = useUpdatePanDetailsMutation({})

  useEffect(() => {
    if (panData) {
      setUpdatePanDetailForm({
        name: panData.name,
        dob: moment(panData.dob, 'DD/MM/YYYY').toDate(),
        panNumber: panData.panNumber,
        fname: panData.fname,
        requestId: panData.id,

        errors: {
          name: validator('name', panData?.name),
          panNumber: validator('panCard', panData?.panNumber),
          fname: validator('fatherName', panData?.fname)
        }
      })
    }
  }, [panData])

  const handlePanNumberChange = (event) => {
    const err = validator(event?.target?.name, event?.target?.value)

    setUpdatePanDetailForm((prev) => ({
      ...prev,
      panNumber: event?.target?.value?.toUpperCase(),
      errors: {
        ...prev.errors,
        panNumber: err
      }
    }))
  }

  const handleNameChange = (event) => {
    const err = validator(event?.target?.name, event?.target?.value)

    setUpdatePanDetailForm((prev) => ({
      ...prev,
      name: event?.target?.value.replace(/\s/gy, ''),
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))
  }

  // const handleFatherNameChange = (event) => {
  //   const err = validator(event?.target?.name, event?.target?.value)

  //   setUpdatePanDetailForm((prev) => ({
  //     ...prev,
  //     fname: event?.target?.value.replace(/\s/gy, ''),
  //     errors: {
  //       ...prev.errors,
  //       fname: err
  //     }
  //   }))
  // }

  const handleDateOfBirthChange = (date) => {
    // Calculate today's date
    const today = new Date()

    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    if (date < minDate) {
      setUpdatePanDetailForm((prev) => ({
        ...prev,
        dob: date
      }))
    } else {
      toast.error(errormessages.DOBERROR)
    }
  }

  const handleSubmit = () => {
    console.log(' Inside SUbmit', updatePanDetailForm)
    if (
      updatePanDetailForm?.errors?.panNumber === 'noerror' &&
            updatePanDetailForm?.errors?.name === 'noerror'
    ) {
      setBackdropState(true)
      console.log(' Inside SUbmit', updatePanDetailForm)
      const dataToSend = {
        requestId: updatePanDetailForm?.requestId,
        panNumber: updatePanDetailForm?.panNumber,
        name: updatePanDetailForm?.name,
        fatherName: updatePanDetailForm?.fname,
        dob: moment(updatePanDetailForm?.dob).format('DD-MM-YYYY')
      }
      updatePanDetails({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          toast.success(response?.message)
          navigate('/myProfile/panDetails')
          // setOtp(true)
        })
        .catch((execption) => {
          setBackdropState(false)

          console.log('EXCEPTION:CHECK PAN KYC', execption)
        })
    }
  }

  return (
        <Box sx={{ padding: '3%' }}>
            <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                <Grid container spacing={5} marginBottom={4}>
                    <Grid lg={4} md={6} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Pan Number
                        </Typography>
                        <TextField
                            error={
                                !(
                                  updatePanDetailForm?.errors?.panNumber === 'error' ||
                                    updatePanDetailForm?.errors?.panNumber === 'noerror'
                                )
                            }
                            helperText={
                                updatePanDetailForm?.errors?.panNumber === 'error' ||
                                updatePanDetailForm?.errors?.panNumber === 'noerror'
                                  ? ''
                                  : updatePanDetailForm?.errors?.panNumber
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 10,
                              minlength: 10
                            }}
                            required
                            type="text"
                            id="panCard"
                            name="panCard"
                            onChange={handlePanNumberChange}
                            value={updatePanDetailForm?.panNumber}></TextField>
                    </Grid>

                    <Grid lg={4} md={6} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', marginRight: '10px' }}>
                            Name
                        </Typography>
                        <TextField
                            error={
                                !(
                                  updatePanDetailForm?.errors?.name === 'error' ||
                                    updatePanDetailForm?.errors?.name === 'noerror'
                                )
                            }
                            helperText={
                                updatePanDetailForm?.errors?.name === 'error' ||
                                updatePanDetailForm?.errors?.name === 'noerror'
                                  ? ''
                                  : updatePanDetailForm?.errors?.name
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 40
                            }}
                            required
                            type="text"
                            id="name"
                            name="name"
                            onChange={handleNameChange}
                            value={updatePanDetailForm?.name}></TextField>
                    </Grid>
                    {/* <Grid lg={4} md={6} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Father&apos;s Name
                        </Typography>
                        <TextField
                            error={
                                !(
                                  updatePanDetailForm?.errors?.fname === 'error' ||
                                    updatePanDetailForm?.errors?.fname === 'noerror'
                                )
                            }
                            helperText={
                                updatePanDetailForm?.errors?.fname === 'error' ||
                                updatePanDetailForm?.errors?.fname === 'noerror'
                                  ? ''
                                  : updatePanDetailForm?.errors?.fname
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 40
                            }}
                            required
                            type="text"
                            id="fatherName"
                            name="fatherName"
                            onChange={handleFatherNameChange}
                            value={updatePanDetailForm?.fname}
                        />
                    </Grid> */}
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Date Of Birth
                        </Typography>
                        <DatePickerComponent
                            date={updatePanDetailForm?.dob}
                            setDate={handleDateOfBirthChange}
                            maxDate={new Date()}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} marginBottom={4}></Grid>

                <Grid marginTop={10}>
                    <Button type="submit" variant="primary">
                        Update info
                    </Button>
                </Grid>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditPanDetails
