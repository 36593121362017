/* eslint-disable react/prop-types */
import axios from 'axios'
import React, { createContext, useState, useEffect } from 'react'

// Create the context
export const IpContext = createContext()

// Provider component
export const IpProvider = ({ children }) => {
  const [currentUserIpAddress, setCurrentUserIpAddress] = useState()

  useEffect(() => {
    try {
      axios
        .get('https://api.ipify.org?format=json')
        .then((data) => {
          data?.data?.ip !== ''
            ? setCurrentUserIpAddress(data?.data?.ip)
            : setCurrentUserIpAddress('')
        })
        .catch((error) => console.log(error))
    } catch (e) {
      throw Error({ message: e })
    }
  }, [])

  // Provide the Ip address function to the children components
  return <IpContext.Provider value={{ currentUserIpAddress }}>{children}</IpContext.Provider>
}
