import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { RestrictionContext } from '../../../contextapi/RestrictionContext'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { ROLES } from '../../../constants/enum'

const HeaderTitle = () => {
  const location = useLocation()
  const [headertext, setHeadertext] = useState('')
  const { restrictionValues, tempSubscriptionStatus } = useContext(RestrictionContext)
  const { profile } = useContext(ProfileContext)
  const navigate = useNavigate()

  // const headerContent = [
  //   {
  //     title: 'Home',
  //     route: '/Home'
  //   },
  //   {
  //     title: 'My Document',
  //     route: '/myDocument'
  //   },
  //   {
  //     title: 'Create Document',
  //     route: '/createDocument'
  //   },
  //   {
  //     title: 'Create Document',
  //     route: '/documentDetails'
  //   },
  //   {
  //     title: 'Create Document',
  //     route: '/paymentSummary'
  //   },
  //   {
  //     title: 'Comment',
  //     route: '/commentSection'
  //   },

  //   {
  //     title: 'Signature',
  //     route: '/signature'
  //   },
  //   {
  //     title: "FAQ's",
  //     route: '/help'
  //   },
  //   {
  //     title: 'Tickets',
  //     route: '/help/tickets'
  //   },
  //   {
  //     title: 'Add Ticket',
  //     route: '/addTicket'
  //   },
  //   {
  //     title: 'Ticket Details',
  //     route: '/ticketSummary'
  //   },
  //   {
  //     title: '',
  //     route: '/subscription'
  //   },
  //   {
  //     title: 'My Profile',
  //     route: '/myProfile'
  //   },
  //   {
  //     title: '',
  //     route: '/verifySubscription'
  //   },
  //   {
  //     title: 'Change Password',
  //     route: '/myProfile/changePassword'
  //   },
  //   {
  //     title: 'Edit Profile',
  //     route: '/myProfile/editProfile'
  //   },
  //   {
  //     title: 'Create Document',
  //     route: 'customDocument'
  //   }
  // ]

  useEffect(() => {
    // const headerText = headerContent.find((item) => {
    //   console.log('path', item.route, location.pathname, location.pathname.split('/'))
    //   return (
    //     item.route === location.pathname ||
    //             location.pathname.split('/').includes(item.route)
    //   )
    // })
    // // const headerTextByParam = headerContent.find((item) =>
    // //   item.route.includes(location.pathname.split('/')[1])
    // // )
    // // const headerTextdata = headerText
    // // console.log('in useEffect called', location.pathname, headerText)
    // console.log('headerText', headerText)
    setHeadertext(location?.state?.HeaderTitle)
    // Checking conditions for profile verification and subscription purchased
    // 1.) For business user the profile verified parameter is already true , so for BA the condition is managed using isKycCompleted paramter in Restriction Context
    // 2.) For Corporate user as there is no kyc process the condition is managed using profileVerified parameter in Restriction Context
    const subscriptionStatus =
            tempSubscriptionStatus !== null
              ? tempSubscriptionStatus
              : restrictionValues?.subscriptionPurchased

    if (
      !subscriptionStatus &&
            !restrictionValues?.isSubscriptionCanceled &&
            restrictionValues?.profileVerified &&
            profile?.role === ROLES?.CORPORATE
    ) {
      console.warn('I am here to navigate subscription ====>', restrictionValues)
      // navigate subscription
      navigate('/subscription')
    }

    if (
      !subscriptionStatus &&
            !restrictionValues?.isSubscriptionCanceled &&
            restrictionValues?.isKycCompleted &&
            profile?.role === ROLES?.BUSINESS_ASSOCIATE
    ) {
      navigate('/subscription')
    }
  }, [location.pathname])

  return (
        <Grid sx={{ ml: { lg: 1 } }}>
            <Typography variant="labelLarge">{headertext || ''}</Typography>
        </Grid>
  )
}

export default HeaderTitle
