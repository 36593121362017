const palette = {
  mode: 'light',
  primary: {
    light: '#fff',
    main: '#F5F5F5',
    dark: '#000'
  },
  secondary: {
    light: '#F5F7F9',
    main: '#F4FDFF',
    dark: '#E5E5E5'
  },
  text: {
    light: '#bfbfbf',
    main: '#003A51',
    dark: '#555555',
    newDark: '#003a51'
  },
  error: {
    light: '#F23A3A',
    main: '#db3131'
  },
  success: {
    light: '#06B5FA',
    main: '#30a772',
    dark: '#33AFB9'
  },
  sidebar: {
    light: '#0f2243',
    main: '#31ADB1',
    dark: '#39ac31'
  },
  yellowShades: {
    main: '#FFF495'
  },
  editNumber: {
    light: '#e4e4e4',
    main: '#42B5FA'
  },
  background: {
    light: '#edfeff'
  }
}
export default palette
