import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const reviewConsentApi = createApi({
  reducerPath: 'reviewConsentApi',
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({})
})

export const { useSubmitDocumentReviewMutation } = reviewConsentApi
