import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Box, CardHeader, Popover, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'
import info from '../../assets/images/info.svg'
import { CREATEDOCUMENTMESSAGE } from '../../constants/message'

export default function CustomCard ({ headerTitle, children, action, height }) {
  const [anchorEl, setAnchorEl] = React.useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
        <Card
            sx={{
              border: `0.5px solid ${palette.text.light}`,
              height: { height },
              backgroundColor: palette.primary.light,
              borderRadius: '5px',
              marginBottom: ' 30px'
            }}>
            <CardHeader
                action={action}
                sx={{
                  borderBottom: `0.5px solid ${palette.text.light}`,
                  backgroundColor: palette.secondary.light
                }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{headerTitle}</Typography>
                        {headerTitle === 'How many copies of document do you want?' && (
                            <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}>
                                <span className="DocumentsTableActionInfoIcon">
                                    <img src={info} alt="info" onClick={handleClick} />
                                </span>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                    }}>
                                    <Box
                                        sx={{
                                          background: palette.primary.light,
                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                        }}
                                        className="documentTablePopoverBox">
                                        <Typography
                                            variant="darkValue"
                                            sx={{
                                              letterSpacing: '0.03em',
                                              lineHeight: '30px'
                                            }}>
                                            {CREATEDOCUMENTMESSAGE?.map((item, indexImpNotes) => (
                                                <div
                                                    className="popover_box-details1"
                                                    key={indexImpNotes}>
                                                    <Typography
                                                        variant="modalLabelSmall"
                                                        sx={{
                                                          color: '#555555',
                                                          letterSpacing: '0.03em',
                                                          lineHeight: '30px'
                                                        }}>
                                                        {item}
                                                    </Typography>
                                                </div>
                                            ))}
                                        </Typography>
                                    </Box>
                                </Popover>
                            </div>
                        )}
                    </div>
                }
                titleTypographyProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                  color: palette.text.dark
                }}></CardHeader>
            <CardContent>{children}</CardContent>
        </Card>
  )
}
CustomCard.propTypes = {
  headerTitle: PropTypes?.string,
  children: PropTypes.node,
  action: PropTypes.object,
  height: PropTypes.string
}
