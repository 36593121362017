import { Box, Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import palette from '../../theme/palette'

const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selected, setselected] = useState('myProfile')

  const handleMyProfile = () => {
    // setselected("myProfile");
    navigate('/myProfile', {
      state: {
        HeaderTitle: 'My Profile'
      }
    })
  }
  const handleChangePassword = () => {
    // setselected("changePassword");
    navigate('/myProfile/changePassword', {
      state: {
        HeaderTitle: 'Change Password'
      }
    })
  }

  const handlePanDetails = () => {
    // setselected("changePassword");
    navigate('/myProfile/panDetails', {
      state: {
        HeaderTitle: 'Pan Details'
      }
    })
  }

  useEffect(() => {
    console.log('im here location')
    if (location?.pathname) {
      const text = location?.pathname.split('/')
      setselected(text[text.length - 1])
      console.log(location?.pathname)
    }
  }, [location?.pathname])

  return (
    <Box p={3} mt={2}>
      <Box sx={{
        border: `0.5px solid ${palette.text.light}`,
        background: palette.secondary.light,
        borderRadius: '5px'
      }}>
        <Grid display="flex" flexDirection="row" justifyContent="center" mt="-20px">
          <Button
            variant={
              selected === 'myProfile' || selected === 'editProfile'
                ? 'tabSelected'
                : 'tabUnselected'
            }
            sx={{ borderRadius: '5px 0px 0px 5px' }}
            onClick={handleMyProfile}>
            My Profile
          </Button>
          <Button
            variant={selected === 'changePassword' ? 'tabSelected' : 'tabUnselected'}
            sx={{ borderRadius: '0px 0px 0px 0px' }}
            onClick={handleChangePassword}>
            Change Password
          </Button>
          <Button
            variant={selected === 'panDetails' || selected === 'editPanDetails' ? 'tabSelected' : 'tabUnselected'}
            sx={{ borderRadius: '0px 5px 5px 0px' }}
            onClick={handlePanDetails}>
            Pan Details
          </Button>
        </Grid>
        <Outlet />
        {/* {selected ? <MyProfile /> : <ChangePassword />} */}
      </Box>
    </Box>
  )
}

export default Profile
