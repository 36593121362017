import {
  Box,
  Paper,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import './DocumentsTable.css'
import html2pdf from 'html2pdf.js'
import viewicon from '../../../../assets/actions/actionView.svg'
import editicon from '../../../../assets/actions/actionEdit.svg'
// import downloadicon from '../../../../assets/actions/actionDownload.svg'
import deleteicon from '../../../../assets/actions/actionDelete.svg'
// import emailicon from '../../../../assets/actions/actionEmail.svg'
import paymenticon from '../../../../assets/actions/actionMakePayment.svg'
import info from '../../../../assets/images/info.svg'
import {
  useLazyGetClEstampDataByDocumentIdQuery,
  useLazyGetCreateCopyOfDocumentQuery,
  useLinkClPdfWithEstampPendingDocMutation,
  useRemoveDocumentByIdMutation,
  useSendDocumentPdfEmailMutation
} from '../../../../store/services/documentServices'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import { DOCUEMENTFILTERSTATUS } from '../../../../constants/documentConstans'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import AlertModal from '../../../../components/AlertModal/AlertModal'
import {
  ALERT_MESSAGE,
  COPYDOCUMENTSUCCESSFULMESSAGE,
  DOCUMENTMESSAGE
} from '../../../../constants/message'
import palette from '../../../../theme/palette'
import bulkSignatureIcon from '../../../../assets/actions/actionBulkSignature.svg'
import emailSend from '../../../../assets/actions/sendEmail.svg'
import downloadicon from '../../../../assets/actions/downloadIcon.svg'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import copyAction from '../../../../assets/actions/actionCopy.svg'
// import moment from 'moment'
import { formatDate } from '../../../../util/helper'
// import { generateDocument } from '../../../../util/generateClDocument'
import CommonMessageModal from '../../../../components/commonMessageModal/CommonMessageModal'
import { generateStampPaper } from '../../../../util/stampPaperHelper'

const DocumentsTable = ({
  documentTableData,
  refetch,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [docID, setDocID] = useState('')

  // Delete document by id
  const [removeDocumentById] = useRemoveDocumentByIdMutation({})

  // pagination
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  // Message modal state
  const [openCommonModal, setOpenCommonModal] = useState({})

  // Delete document by id
  const [sendDocumentEmail] = useSendDocumentPdfEmailMutation({})

  // Create copy of document by id
  const [createCopyDocument] = useLazyGetCreateCopyOfDocumentQuery()

  // Upload document pdf on successfull generation of pdf with estamp data
  const [uploadDocumentPdf] = useLinkClPdfWithEstampPendingDocMutation()

  // Regenrate Cl Estamp data
  const [regenrateEstampData] = useLazyGetClEstampDataByDocumentIdQuery({})

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Alert modal state
  const [openAlertModal, setOpenAlertModal] = useState(false)

  // Alert Modal description
  const alertModalState = {
    title: 'Alert',
    message: ALERT_MESSAGE.DELETEDOCUMENT,
    cancelButtonText: 'No',
    submitButtonText: 'I agree'
  }

  const documentTableHeaders = [
    'Document ID(URN)',
    'Article Type',
    'Template Name',
    'Document Type',
    'Status',
    'Owner Name',
    'Created At',
    'Action'
  ]

  const previewIcon = [
    DOCUEMENTFILTERSTATUS?.DISPATCHED?.ID,
    DOCUEMENTFILTERSTATUS?.DELIVERED?.ID,
    DOCUEMENTFILTERSTATUS?.CHANGESREQUESTED?.ID
  ]

  /**
     * Handle edit icon
     * @param {object} document: Document selected
     */
  const handleEditIcon = (document) => {
    if (document.isUploaded) {
      navigate(`/documentDetails/${document?.name}`, {
        state: {
          HeaderTitle: 'Create Document'
        }
      })
    } else {
      navigate(`/customDocument/${document?.name}`, {
        state: {
          HeaderTitle: 'Create Document'
        }
      })
    }
    console.log(document)
    // navigate(`/customDocument/${document?.name}`, {
    //   state: {
    //     HeaderTitle: 'Create Document'
    //   }
    // })
  }

  const handleDeleteIcon = (documentData) => {
    setOpenAlertModal(true)
    setDocID(documentData?.id)
    console.log(docID, 'documentID')
  }

  /**
     * Handle Delete icon
     * @param {object} document: Document selected
     */
  const handleSubmitAlertModal = () => {
    console.log(docID, 'docID')
    setBackdropState(true)
    removeDocumentById(docID)
      .unwrap()
      .then((response) => {
        setBackdropState(false)

        setDocID('')
        setOpenAlertModal(false)
        toast.success(response?.message)
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  /**
     * Handle Preview icon
     * @param {object} document: Document selected
     */
  const handlePreviewIcon = (document) => {
    console.log(document)
    if (document?.status?.id === DOCUEMENTFILTERSTATUS?.SIGNATURESREQUESTED?.ID) {
      return navigate(`/SignatureRequestedPreview/${document?.name}`, {
        state: {
          HeaderTitle: 'Preview Document'
        }
      })
    }
    if (document?.status?.id === DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID) {
      return navigate(`/signature/${document?.name}`, {
        state: {
          HeaderTitle: 'Signature'
        }
      })
    }
    navigate(`/commentSection/${document?.name}`, {
      state: {
        HeaderTitle: 'Comment'
      }
    })
  }

  /**
     * Handle payment icon
     * @param {object} document: Document selected
     */
  const handlePaymentIcon = (document) => {
    if (document.isUploaded) {
      navigate(`/paymentSummary/${document?.name}`, {
        state: {
          HeaderTitle: 'Create Document'
        }
      })
    } else {
      navigate(`/customDocument/${document?.name}`, {
        state: {
          HeaderTitle: 'Create Document'
        }
      })
    }
    console.log(document)
    // navigate(`/customDocument/${document?.name}`, {
    //   state: {
    //     HeaderTitle: 'Create Document'
    //   }
    // })
  }

  const handleShowPreviewScreen = (documentId) => {
    navigate(`/preview-screen/${documentId}`)
  }

  const handleSendEmail = (documentId) => {
    setBackdropState(true)

    sendDocumentEmail(documentId)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  const handleCopyAction = (documentId) => {
    setBackdropState(true)

    createCopyDocument(documentId)
      .unwrap()
      .then((response) => {
        refetch()
        setBackdropState(false)
        setOpenCommonModal({
          open: true,
          message: COPYDOCUMENTSUCCESSFULMESSAGE(response?.data),
          buttonText: 'Ok'
        })
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  /**
     * Regenrate the estamp data for the selected document [status: EstampRegenrated]
     * @param {string} documentId : selected document id
     */
  const handleRegenrateEstamp = (documentId) => {
    setBackdropState(true)
    regenrateEstampData({ documentId })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        console.log('reponse', response)
        const extraFieldResponse = {
          stateHeading: response?.estampDataResponse?.stampPaperStateHeading,
          belowHeadingTitle: response?.estampDataResponse?.stampPaperStateSecondHeading + response?.estampDataResponse.certificateNo
        }
        handleSendContentPdf(
          response?.estampDataResponse?.stateId,
          response?.estampDataResponse,
          extraFieldResponse,
          documentId,
          response?.templatePreviewResponse?.content
        )
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  /**
     * Handle make new pdf with cl response object and link with document
     * @param {string} stateId: StateId of the document
     * @param {object} clResponse: Cl repose object
     * @param {object} extraFieldResponse: Extra fields required for stamp paper generation [stateHeading, belowHeadingTitle]
     * @param {string} documentId: Document id
     * @param {string} htmlContent: Html preview string
     */
  const handleSendContentPdf = async (stateId, clResponse, extraFieldResponse, documentId, htmlContent) => {
    setBackdropState(true)
    const pdf = await html2pdf()
      .from(htmlContent)
      .set({
        filename: 'output23061999111.pdf',
        margin: [10, 10, 30, 10],
        image: { type: 'jpeg', quality: 1 },
        pageBreak: { mode: ['avoid-all', 'css', 'legacy'] }
      })
      .outputPdf('blob')
    // .save()

    // Add Cl Details
    // const newPdfBlob = await generateDocument(pdf, 'system', clResponse)

    const newPdfBlob = await generateStampPaper(stateId, pdf, 'system', clResponse, extraFieldResponse)

    // const newPdfBlob = await generateDocument(pdf, 'system', clResponse)

    // const pdfBlob = new Blob([pdf], { type: 'application/pdf' })
    // console.log(pdfBlob, 'pdfBlob')

    // const formData = new FormData()
    // formData.append('file', pdfBlob, 'output23061999111.pdf')

    // console.log(formData, 'formDataformDataformData')

    const dataToSend = {
      file: newPdfBlob
    }

    uploadDocumentPdf({ payload: dataToSend, documentId })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(DOCUMENTMESSAGE?.CERTIFICATEGENERATED)
        // setSignatureModalDataState({
        //   estampId: clResponse?.certificateNo,
        //   urn: clResponse?.urn
        // })
        // setSignatureModalState(true)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {documentTableHeaders.length > 0 &&
                                documentTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                        {HeaderName === 'Action' && (
                                            <>
                                                <span className="DocumentsTableActionInfoIcon">
                                                    <img
                                                        src={info}
                                                        alt="info"
                                                        onClick={handleClick}
                                                    />
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right'
                                                    }}>
                                                    <Box
                                                        sx={{
                                                          background: palette.primary.light,
                                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                                        }}
                                                        className="documentTablePopoverBox">
                                                        <Typography
                                                            variant="darkValue"
                                                            sx={{
                                                              letterSpacing: '0.03em',
                                                              lineHeight: '30px'
                                                            }}>
                                                            {DOCUMENTMESSAGE?.DOCUMENTTABLEINFO}
                                                        </Typography>
                                                    </Box>
                                                </Popover>
                                            </>
                                        )}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {documentTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (
                            //     paginationFilter?.rowsPerPage > 0
                            //     ? documentTableData?.slice(
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage,
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage +
                            //               paginationFilter?.rowsPerPage
                            //       )
                            //     : documentTableData
                            // )
                            documentTableData.map((documentTableData, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell scope="row">
                                        {documentTableData?.name} ({documentTableData?.urnNumber})
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleCategory?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleTemplate?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.docDelType || 'NA'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.status?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.user?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {formatDate(documentTableData?.createdOnTimeStamp)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="actionIcons">
                                            {(documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.INDRAFT?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.CONSENTREQUESTED
                                                      ?.ID) && (
                                                <>
                                                    <img
                                                        src={editicon}
                                                        alt="editIcon"
                                                        title="Edit document"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                          handleEditIcon(documentTableData)
                                                        }
                                                    />
                                                    <img
                                                        src={deleteicon}
                                                        alt="deleteIcon"
                                                        title="Delete document"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                          handleDeleteIcon(documentTableData)
                                                        }
                                                    />
                                                </>
                                            )}
                                            {/* {documentTableData.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID && (
                                        <div className="actionIcons">
                                            <img
                                                src={editicon}
                                                alt="editIcon"
                                                title="Edit document"
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    )} */}
                                            {(documentTableData.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.CONSENTREQUIRED?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.SIGNATURESREQUESTED
                                                      ?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.CONSENTREQUESTED
                                                      ?.ID) && (
                                                // <div className="actionIcons">
                                                <img
                                                    onClick={() =>
                                                      handlePreviewIcon(documentTableData)
                                                    }
                                                    src={viewicon}
                                                    alt="viewicon"
                                                    title="Preview document"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                // </div>
                                            )}
                                            {previewIcon.includes(documentTableData.status?.id) && (
                                                // <div className="actionIcons">
                                                <img
                                                    src={viewicon}
                                                    alt="viewIcon"
                                                    title="Preview document"
                                                    style={{ cursor: 'pointer' }}
                                                />

                                            // </div>
                                            )}
                                            {documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.SIGNATUREPLACEMENT?.ID && (
                                                // <div className="actionIcons">
                                                <img
                                                    onClick={() => {
                                                      navigate(
                                                            `/previewEsignPage/${documentTableData?.name}`,
                                                            {
                                                              state: {
                                                                HeaderTitle:
                                                                        'Signature Selection'
                                                              }
                                                            }
                                                      )
                                                    }}
                                                    src={bulkSignatureIcon}
                                                    alt="Signature"
                                                    title="Signature Selection"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                // </div>
                                            )}
                                            {documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && (
                                                // <div className="actionIcons">
                                                <img
                                                    onClick={() => {
                                                      navigate(
                                                            `/previewDocument/${documentTableData?.id}`,
                                                            {
                                                              state: {
                                                                HeaderTitle: 'Document'
                                                              }
                                                            }
                                                      )
                                                    }}
                                                    src={viewicon}
                                                    alt="ViewIcon"
                                                    title="Preview Document"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                // </div>
                                            )}
                                            {documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID && (
                                                <>
                                                    <img
                                                        onClick={() =>
                                                          handlePaymentIcon(documentTableData)
                                                        }
                                                        src={paymenticon}
                                                        alt="payment"
                                                        title="Payment"
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <img
                                                        src={deleteicon}
                                                        alt="deleteIcon"
                                                        title="Delete document"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                          handleDeleteIcon(documentTableData)
                                                        }
                                                    />
                                                </>
                                            )}
                                            {documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.INPROCESS?.ID && (
                                                // <div className="actionIcons">
                                                <img
                                                    src={viewicon}
                                                    alt="viewIcon"
                                                    title="Preview document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                      handleShowPreviewScreen(
                                                        documentTableData?.id
                                                      )
                                                    }
                                                />
                                            )}
                                            {(documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.DELIVERED?.ID ||
                                                documentTableData?.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.INPROCESS?.ID) && (
                                                // <div className="actionIcons">
                                                <>
                                                    <a
                                                        style={{ paddingTop: '5px' }}
                                                        href={documentTableData?.downloadUrl}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        <img
                                                            src={downloadicon}
                                                            alt="download icon"
                                                            title="Download document"
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </a>
                                                    <img
                                                        src={emailSend}
                                                        alt="email icon"
                                                        title="Email document"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                          handleSendEmail(documentTableData?.id)
                                                        }}
                                                    />
                                                </>
                                            )}
                                            {documentTableData?.owner === true &&
                                                documentTableData?.isUploaded === false &&
                                                documentTableData?.isBulk === false &&
                                                (documentTableData?.status.id ===
                                                    DOCUEMENTFILTERSTATUS?.INPROCESS?.ID ||
                                                    documentTableData?.status.id ===
                                                        DOCUEMENTFILTERSTATUS?.DISPATCHED?.ID ||
                                                    documentTableData?.status.id ===
                                                        DOCUEMENTFILTERSTATUS?.DELIVERED?.ID ||
                                                    documentTableData?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.READYFORDISPATCH
                                                          ?.ID ||
                                                    documentTableData?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.READYFORHANDOVER
                                                          ?.ID ||
                                                    documentTableData?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.HANDOVER?.ID ||
                                                    documentTableData?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.RETURNED?.ID ||
                                                    documentTableData?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.HANDEDOVER?.ID) && (
                                                    // <div className="actionIcons">
                                                    <>
                                                        <img
                                                            src={copyAction}
                                                            alt="copy icon"
                                                            title="Copy document"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                              handleCopyAction(
                                                                documentTableData?.id
                                                              )
                                                            }}
                                                        />
                                                    </>
                                            )}

                                            {documentTableData?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.ESTAMPREGENERATED?.ID && (
                                                // <div className="actionIcons">
                                                <>
                                                    <img
                                                        src={emailSend}
                                                        alt="Regenrate Estamp"
                                                        title="Regenrate Estamp"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                          handleRegenrateEstamp(
                                                            documentTableData?.id
                                                          )
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {documentTableData.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* Pagination */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Alert confirmation modal */}
            <AlertModal
                openAlertModal={openAlertModal}
                handleAlertModalClose={() => setOpenAlertModal(false)}
                handleSubmitAlertModal={handleSubmitAlertModal}
                alertModalState={alertModalState}
            />

            {/* Common Message Modal  */}
            {openCommonModal?.open && (
                <CommonMessageModal
                    CommonModalState={openCommonModal}
                    handleOkButtonCommonModal={() => {
                      setOpenCommonModal({
                        open: false,
                        message: ''
                      })
                    }}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Paper>
  )
}

export default DocumentsTable
DocumentsTable.propTypes = {
  documentTableData: PropTypes.array,
  refetch: PropTypes.func,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
