import React from 'react'
import './PreviewDocument.css'
import { useParams } from 'react-router-dom'
// import { useGetDocumentPreviewByIdQuery } from '../../../../store/services/documentServices'
import parse from 'html-react-parser'
// import { Button } from '@mui/material'
// import html2pdf from 'html2pdf.js'
import PropTypes from 'prop-types'
const PreviewDocument = ({ documentPreview }) => {
  const { documentId } = useParams()

  console.log('documentIDDD', documentId)
  // const [htmlContent, sethtmlContent] = useState()

  // const { data: documentPreview = { content: '<div>API Error no respone found</div>' } } =
  //       useGetDocumentPreviewByIdQuery(documentId, {
  //         skip: !documentId
  //       })

  // const documentPreviewConstant = `<div class="previewDocumentContainer">
  // <h3>
  // AGREEMENT TO SELL
  //  </h3>
  //  <p>
  // This AGREEMENT TO SELL is executed at New Delhi,on This ____ day of ___________________ by  and        between; <br>
  //       Sh.        <span id="span1">________</span> <span id="span2">________</span>  S/o <span id="span7">________</span> R/o _____hereinafter called "THE FIRST PARTY".The  expression Sh. <span id="span1">________</span> <br>
  // <span id="span2">________</span> S/o  <span id="span7">________</span> R/o_____,hereinafter called "THE SECOND PARTY".The terms the "FIRST PARTY" and the  <br>
  //   "SECOND PARTY" wherever they occur in the body of this ,Agreement to Sell shall mean and include them, <br>
  //  their legal heirs, successors, legal representatives administrators executors,         transferee(s),             beneficiary(ies), <br>
  // legatee(s),probatee(s), nominees and  assignee(s).     AND WHEREAS the FIRST PARTY for his bonafide needs<br>
  //  and requirements have agreed to sell, convey,        transfer        and assign to the SECOND PARTY and the SECOND <br>
  //  PARTY has agreed to purchase the alongwith  proportionate,   undivided,indivisibleand impartible ownership <br>
  // rights in the said freehold land underneath the said building    measuring ____ square yards,
  //   bearing No. ________, situated at <span id="span5">________</span> ,  with all rights,        title and  interest, easements, privileges and appurtenances ,<br>
  //   thereto with all fittings, fixtures,electricity and        water connections, structure standing  thereon, with all <br>
  //   rights in common driveway, entrances,  passages, staircase and other common facilities and amenities<br><br><br>
  //   provided therein, hereinafter referred to as      "THE SAID        PORTION OF THE SAID PROPERTY" for a total sale <br>
  //   consideration of Rs. <span id="span21">________</span> (Rupees        ______________        ____________________________). <br>
  //   NOW THIS AGREEMENT TO SELL WITNESSETH AS UNDER :-   That in consideration of the sum of <br>
  //   Rs. ____________/- (Rupees _____________________________) out of which a sum of security <span id="span23">________</span>) , as <br>
  //   advance money has been  Rs.  ______       received by the FIRST PARTY from the SECOND PARTY, in the <br>
  //   following manner;        the receipt of which the FIRST PARTY hereby admits and     acknowledges and the remaining
  //   balance sum of Rs.  _________/- (Rupees ____________________________),<br><br><br>
  //   will be received by the FIRST PARTY from the SECOND PARTY,   at the time of registration of the Sale Deed,  <br>
  //   the FIRST    PARTY doth hereby agree to grant, convey, sell,  transfer and ,  assign all his rights, titles and  <br>
  //   interests in the said portion of the said property,having  email  address  <span id="span9">________</span>   with proportionate <br>
  //   indivisible and impartible  ownership rights in the freehold land underneath the said building to the SECOND PARTY, <br>
  //   on the terms and conditions  herein contained provided that nothing herein stated shall  confer or deemed to have conferred<br>
  //   upon the SECOND PARTY exclusively any right or title to the common  maintainence amount    <br>
  //   <span id="span21">_____________</span> staircase, overhead water tanks,sewers, water meters and other common facilities   <br>
  //   to the  exclusion of the FIRST PARTY and or the SECOND PARTY  or ownersor occupants of <br>
  //   the other units   of the said    building.  That the actual physical vacant possession of the <br>
  //   said portion of the said property will be delivered  by the  FIRST PARTY to the SECOND PARTY,<br>
  //   at the time of the <br> registration of the Sale Deed, after receiving the        full        <br>
  //  consideration.       That on or before <span id="span3">________</span> , the FIRST PARTY<br>
  //  will execute  and get the Sale Deed of the said portion        of the said property registered,<br>
  //  in favour of the SECOND PARTY or his nominees, on receipt of the full and        final balance amount, <br>
  //  failing which either party shall be entitled to get the Sale Deed registered        through        the court<br>
  //  of law by SPECIFIC PERFORMANCE OF THE CONTRACT, at the cost and expenses <br>of the defaulting        party.
  //   </p>
  // </div>`

  // useEffect(() => {
  //   let htmlContent=new DOMParser().parseFromString(documentPreview,'text/html')
  //   let body=htmlContent.body
  //   let documentPreviewDiv=document.getElementById('content')
  //   documentPreviewDiv.appendChild(body)
  // }, [documentId])
  // const handlePdf = () => {
  //   // const element = document.getElementById('previewDocument')
  //   // element.innerHTML = htmlContent

  //   html2pdf()
  //     .set({ filename: 'output.pdf', margin: 10, image: { type: 'jpeg', quality: 1 } })
  //     .from(documentPreview?.content)
  //     .save()
  //   console.log('im there')
  // }

  return (
        <>
            {/* <Button onClick={handlePdf}>Make pdf</Button> */}
            {parse(documentPreview?.content) ? parse(documentPreview?.content) : ''}
            {/* {parse(documentPreviewConstant)} */}
        </>
  )
}

export default PreviewDocument

PreviewDocument.propTypes = {
  documentPreview: PropTypes.object
}
