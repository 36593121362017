import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({
    getPlanUser: builder.query({
      query: (type) => ({
        url: `plans/all?type=${type}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getBillingInfo: builder.query({
      query: () => ({
        url: 'plans/billing',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    updateBillingInfo: builder.mutation({
      query: () => ({
        url: 'plans/billing/update',
        method: 'POST'
      }),
      transformResponse: (response) => response?.data
    }),
    userPlanMapping: builder.query({
      query: (payload) => ({
        url: 'plans/user/subscribe',
        payload,
        method: 'PUT'
      }),
      transformResponse: (response) => response?.data
    })
  })
})

export const {
  useGetPlanUserQuery,
  useGetBillingInfoQuery,
  useUpdateBillingInfoMutation,
  useLazyUserPlanMappingQuery
} = subscriptionApi
