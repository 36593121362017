import React from 'react'
import { Box, Grid, Modal, Typography } from '@mui/material'
import cross from '../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 630,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const NotesModal = ({ notesModalState, handleNotesModalClose }) => {
  return (
        <Modal
            open={notesModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handleNotesModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2}>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{
                          fontSize: '25px',
                          marginBottom: '10px',
                          fontWeight: 600,
                          wordBreak: 'break-all'
                        }}>
                        {notesModalState.title}
                    </Typography>

                    <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '15px', lineHeight: '31px', wordBreak: 'break-all' }}>
                            {notesModalState.message}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default NotesModal

NotesModal.propTypes = {
  notesModalState: PropTypes.object,
  handleNotesModalClose: PropTypes.func,
  handleSubmitAlertModal: PropTypes.func,
  alertModalState: PropTypes.object
}
