import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './SessionExpired.css'
const SessionExpired = () => {
  const navigate = useNavigate()
  return (
        <Box className="page_404">
            <Grid>
                <Grid item lg={12} xs={12}>
                    <Grid mt={20} mb={3}>
                        <Typography sx={{ fontSize: '80px' }}>OOPS!</Typography>
                    </Grid>
                    <Grid>
                        <Typography component="h1">
                            Look like you&apos;re session has expired
                        </Typography>
                        <Typography mb={2}>Please login again!</Typography>
                        <Button
                            variant="primary"
                            onClick={() => {
                              navigate('/')
                            }}
                            className="link_404">
                            Go to Login
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
  )
}

export default SessionExpired
