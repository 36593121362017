import React from 'react'
import PropTypes from 'prop-types'
import { STATUS } from '../../constants/enum'
import { Typography } from '@mui/material'
import palette from '../../theme/palette'
const TableStatus = ({ status }) => {
  return status === STATUS?.ACTIVE
    ? (
        <Typography color={palette.success.main}>{status}</Typography>
      )
    : (
        <Typography color={palette.error.light}>{status}</Typography>
      )
}

export default TableStatus

TableStatus.propTypes = {
  status: PropTypes.string
}
