import React, { useContext, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Popover
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import flag from '../../../assets/images/india-flag.svg'
import { PATTERN, SUB_ROLE } from '../../../constants/enum'
import {
  ADDUSEROPTIONSARRAYCOOWNER,
  ADDUSERTYPE
} from '../../../constants/manageStaffUserConstants'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import {
  useAddNewStaffUserMutation,
  useGetManagerListByUserQuery
} from '../../../store/services/manageStaffUserServices'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import { getSession } from '../../../util/helper'
import CustomCard from '../../../components/CustomCard'
import palette from '../../../theme/palette'
import { validator } from '../../../util/validator'
import errormessages from '../../../constants/errormessages'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import info from '../../../assets/images/info.svg'
import { INFORMATION_MESSAGE } from '../../../constants/message'
import { PLACEHOLDER } from '../../../constants/placeholder'

const AddNewUserCorporate = () => {
  // Navigate
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Profile context user profile data
  const { profile } = useContext(ProfileContext)

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // User role
  const userRole = JSON.parse(getSession('user')).subRole

  // Corporate email domain
  const corporateDomainName = profile?.email?.split('@')[1] || 'default.com'

  // Role option
  const roleOptions = ADDUSEROPTIONSARRAYCOOWNER

  // Add staff user
  const [addStaffUserForm, setAddStaffUserForm] = useState({
    managerId: '',
    role: userRole === SUB_ROLE?.OWNER ? ADDUSERTYPE?.ADMIN : ADDUSERTYPE?.STAFF,
    errors: {
      email: 'error'
    }
  })

  // Manager list get query
  const { data: managerList = [] } = useGetManagerListByUserQuery({})

  // Add staff user mutation
  const [addStaffUser] = useAddNewStaffUserMutation()

  /**
     * Change values in Staff User form
     * @param {object} event:event textfield object
     */
  const handleAddStaffUserFormChange = (event) => {
    setAddStaffUserForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    console.log(addStaffUserForm)
  }

  /**
     * Handle radio button form change
     * @param {object} event:Even object
     */
  const handleAddStaffRadioFormChange = (event) => {
    if (managerList?.length > 0) {
      setAddStaffUserForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      }))
    } else {
      setAddStaffUserForm((prev) => ({
        ...prev,
        [event.target.name]: 'admin'
      }))
      toast.error(errormessages?.NOMANGERLIST)
    }
  }

  /**
     * Change values in Staff User form
     * @param {object} event:event textfield object
     */
  const handleAddStaffUserEmailChange = (event) => {
    const errormessage = validator(
      event.target.name,
            `${event.target.value}@${corporateDomainName}`
    )

    setAddStaffUserForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, ''),
      errors: {
        email: errormessage
      }
    }))
    console.log(addStaffUserForm)
  }

  /**
     * Handle prevent space
     * @param {Object} event: Event object
     */
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  // Handle form submit (add staff user)
  const handleAddnewStaffUserFormSubmit = () => {
    const dataToSend = {
      name: addStaffUserForm?.name,
      email: `${addStaffUserForm?.email}@${corporateDomainName}`,
      phone: addStaffUserForm?.phone,
      role: addStaffUserForm?.role,
      manager: addStaffUserForm?.managerId,
      location: addStaffUserForm?.location,
      latitude: 0.0,
      longitude: 0.0
    }
    console.log('form submit', addStaffUserForm)
    if (addStaffUserForm?.errors?.email === 'noerror') {
      setBackdropState(true)
      addStaffUser({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          toast.success(response?.message)
          navigate('/manageStaffUsers', {
            state: {
              HeaderTitle: 'Staff Users'
            }
          })
          console.log(response)
        })
        .catch((exception) => {
          setBackdropState(false)
          console.log(exception)
        })
    }
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
        <Box p={3} mt={2}>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleAddnewStaffUserFormSubmit()
                }}>
                <CustomCard headerTitle="Add staff account">
                    <Grid container spacing={3} p={3}>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ display: 'flex' }}>
                                Name
                                <img
                                    style={{ marginLeft: 10 }}
                                    src={info}
                                    alt="info"
                                    onClick={handleClick}
                                />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                    }}>
                                    <Box
                                        sx={{
                                          background: palette.primary.light,
                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                        }}
                                        className="documentTablePopoverBox">
                                        <Typography
                                            variant="darkValue"
                                            sx={{
                                              letterSpacing: '0.03em',
                                              lineHeight: '30px'
                                            }}>
                                            {INFORMATION_MESSAGE?.NAME_MESSAGE}
                                        </Typography>
                                    </Box>
                                </Popover>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={addStaffUserForm?.name || ''}
                                fullWidth
                                required
                                name="name"
                                type="name"
                                placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                                onChange={(event) => {
                                  if (
                                    event.target.value === '' ||
                                        PATTERN?.ALPHABETSONLY.test(event?.target?.value)
                                  ) {
                                    handleAddStaffUserFormChange(event)
                                  }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                error={
                                    !(
                                      addStaffUserForm?.errors?.email === 'error' ||
                                        addStaffUserForm?.errors?.email === 'noerror'
                                    )
                                }
                                helperText={
                                    addStaffUserForm?.errors?.email === 'error' ||
                                    addStaffUserForm?.errors?.email === 'noerror'
                                      ? ''
                                      : addStaffUserForm?.errors?.email
                                }
                                fullWidth
                                value={addStaffUserForm?.email || ''}
                                required
                                name="email"
                                type="text"
                                InputProps={{
                                  endAdornment: (
                                        <InputAdornment position="end">
                                            @{corporateDomainName}
                                        </InputAdornment>
                                  )
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        /^[a-zA-Z0-9\-_.]+$/.test(e?.target?.value)
                                  ) {
                                    handleAddStaffUserEmailChange(e)
                                  }
                                }}
                                onKeyDown={handlePreventSpace}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Phone
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={addStaffUserForm?.phone || ''}
                                fullWidth
                                required
                                type="text"
                                name="phone"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 10,
                                  minlength: 10
                                }}
                                id="phoneNumber"
                                InputProps={{
                                  startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={flag} alt="flag" />
                                            &nbsp;+91 |
                                        </InputAdornment>
                                  )
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleAddStaffUserFormChange(e)
                                  }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomCard>
                <Grid container>
                    <Grid item lg={6} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {userRole === SUB_ROLE?.OWNER && (
                            <>
                                <Typography variant="darkValueLarge" component="div" mb={2}>
                                    Please select role
                                </Typography>
                                <FormControl sx={{ marginBottom: '10px' }}>
                                    <RadioGroup
                                        name="role"
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        value={addStaffUserForm?.role}
                                        onChange={handleAddStaffRadioFormChange}>
                                        {roleOptions.length > 0 &&
                                            roleOptions.map((role, index) => (
                                                <FormControlLabel
                                                    key={role?.id}
                                                    value={role?.value}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                              color: 'var(--g2)',
                                                              '&.Mui-checked': {
                                                                color: palette.success.dark
                                                              }
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="labelSmall"
                                                            sx={{
                                                              color: 'var(--g6)',
                                                              fontSize: '14px'
                                                            }}>
                                                            {role?.label}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                    </RadioGroup>
                                </FormControl>
                                {addStaffUserForm?.role === ADDUSERTYPE?.ADMIN && (
                                    <Grid container mb={3}>
                                        <Grid
                                            item
                                            lg={6}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            display="flex"
                                            alignItems="Center">
                                            <Typography component="div" variant="darkValue" mr={6}>
                                                Location
                                            </Typography>

                                            <TextField
                                                value={addStaffUserForm?.location || ''}
                                                fullWidth
                                                required
                                                type="text"
                                                placeholder="Enter Location"
                                                id="location"
                                                name="location"
                                                onChange={handleAddStaffUserFormChange}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {addStaffUserForm?.role === ADDUSERTYPE?.STAFF && (
                                    <Grid container mb={3}>
                                        <Grid
                                            item
                                            lg={6}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            display="flex"
                                            alignItems="Center">
                                            <Typography
                                                component="div"
                                                variant="darkValue"
                                                sx={{ marginRight: '30px' }}>
                                                Select Manager
                                            </Typography>
                                            <Select
                                                name="managerId"
                                                value={addStaffUserForm?.managerId}
                                                onChange={(event) => {
                                                  setAddStaffUserForm((prev) => ({
                                                    ...prev,
                                                    [event.target.name]: event.target.value
                                                  }))
                                                }}
                                                displayEmpty
                                                inp
                                                utProps={{ 'aria-label': 'Without label' }}
                                                sx={{
                                                  fontSize: '16px'
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                required>
                                                <MenuItem value="" disabled>
                                                    <Typography>Select admin</Typography>
                                                </MenuItem>
                                                {managerList.length > 0 &&
                                                    managerList.map((admin, index) => (
                                                        <MenuItem key={admin?.id} value={admin?.id}>
                                                            <Typography>{admin?.name}</Typography>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                        <Grid>
                            <Button
                                variant="cancelButton"
                                sx={{ marginRight: '20px' }}
                                onClick={() =>
                                  navigate('/manageStaffUsers', {
                                    state: {
                                      HeaderTitle: 'Staff Users'
                                    }
                                  })
                                }>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default AddNewUserCorporate
