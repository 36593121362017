import React, { useState } from 'react'
import verified from '../../assets/images/tickgreen.svg'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import palette from '../../theme/palette'
import { useSendDigiAuthCodeMutation } from '../../store/services/commonServices'
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader'
import { ALERT_MESSAGE } from '../../constants/message'
import { toast } from 'react-toastify'

const KycAcknowledgement = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [backdropState, setBackdropState] = useState(false)

  const [sendCode] = useSendDigiAuthCodeMutation({})
  // Access URL search parameters (query string)
  const searchParams = new URLSearchParams(location.search)

  // Access specific query parameters
  const paramValue = searchParams.get('code')
  const paramValue2 = searchParams.get('state')
  const error = searchParams.get('error')
  const errorDescription = searchParams.get('error_description')
  console.log(paramValue)
  console.log(paramValue2)

  const handleClick = () => {
    setBackdropState(true)
    const dataToSend = {
      code: paramValue
    }
    console.log('Payload====>', dataToSend)
    if (error === null || error === undefined) {
      sendCode({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          toast.success(response?.message)
          setBackdropState(false)
          navigate('/home')
        })
        .catch((error) => {
          setBackdropState(false)
          navigate('/home')
          console.log(error)
        })
    } else {
      navigate('/home')
    }
  }

  return (
        <Grid
            container
            className="landingBody"
            justifyContent="center"
            alignItems={'center'}
            sx={{
              background: `linear-gradient(89.91deg, ${palette.success.main} -3.86%, ${palette.success.light} 105.97%)`
            }}>
            <Box p={1} mt={8} sx={{ background: 'white', padding: 8, borderRadius: 8 }}>
                <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                    {error === null || error === undefined ? (
                        <img
                            src={verified}
                            alt="done"
                            height="60px"
                            width="60px"
                            style={{ marginBottom: '10px' }}
                        />
                    ) : (
                        <></>
                    )}
                    <></>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '60px', marginBottom: '20px' }}>
                        {error !== null
                          ? ALERT_MESSAGE.DIGILOCKERAUTHORIZATIONFAILED
                          : ALERT_MESSAGE.DIGILOCKERAUTHORIZATIONSUCCESSFULL}
                    </Typography>
                </Grid>
                <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="darkValue" sx={{ fontSize: '15px', lineHeight: '31px' }}>
                        {error !== null ? errorDescription : ALERT_MESSAGE.DIGILOCKERACKFORCONSENT}
                    </Typography>
                    <Button
                        variant="primary"
                        onClick={() => {
                          handleClick()
                        }}
                        sx={{ marginTop: 5 }}>
                        Go To Dashboard
                    </Button>
                </Grid>
                {backdropState && <BackdropLoader backdropState={backdropState} />}
            </Box>
        </Grid>
  )
}

export default KycAcknowledgement
