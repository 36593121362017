import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { USER_ROLE } from '../../constants/enum'
import { useGetDocumentFilterStatusQuery } from '../../store/services/documentServices'

// import { getSession } from '../../util/helper'
import MyDocumentCorporate from './MyDocumentsCorporate/MyDocumentCorporate'
import { ProfileContext } from '../../contextapi/ProfileContext'
import MyDocumentIndividualBa from './MyDocumentIndvidual&Ba/MyDocumentIndividualBa'
const MyDocument = () => {
  // Profile context api
  const { profile } = useContext(ProfileContext)

  // Get all States query
  const { data: documentStatusArray = [] } = useGetDocumentFilterStatusQuery({})

  return (
        <Box sx={{ padding: '1% 3%' }}>
            {profile?.role === USER_ROLE.CORPORATE.toUpperCase() ? (
                <>
                    <MyDocumentCorporate documentStatusArray={documentStatusArray} />
                </>
            ) : (
                <>
                    <MyDocumentIndividualBa documentStatusArray={documentStatusArray} />
                </>
            )}
        </Box>
  )
}

export default MyDocument
