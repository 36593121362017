import React, { useEffect, useState } from 'react'
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, Button, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useGetDocumentPdfByIdQuery } from '../../../../store/services/documentServices'
import { useNavigate, useParams } from 'react-router-dom'
import './PreviewScreenPdf.css'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import CancelIcon from '@mui/icons-material/Cancel'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewScreenPdf = ({ file }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [numPages, setNumPages] = useState(null)

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(true)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  // Get document Preview and Review section data
  // eslint-disable-next-line no-unused-vars
  const { data } = useGetDocumentPdfByIdQuery(id, {
    skip: !id
  })

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup () {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  return (
        <>
            {/* <Box sx={{ p: 2 }}> */}
            {/* <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button variant="cancelButton">Back</Button>
                </Grid>
            </Grid> */}

            <Grid container>
                <Grid item lg={7}>
                    <Box sx={{ marginTop: '20px' }} mb="20px" ml="40px">
                        <Grid
                            className="details_rightgrid-box"
                            sx={{ maxHeight: 'calc(100vh - 10rem)' }}>
                            {data?.fileUri && (
                                // <div id="pdf-container" className="details_rightgrid-box">
                                <Document
                                    size="A4"
                                    file={data?.fileUri} // Path to your PDF file
                                    workerSrc={workerSrc}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    // loading={}
                                    // onSourceError={(error) =>
                                    //   alert('Error while retrieving document source! ' + error.message)
                                    // }
                                    // onSourceSuccess={() => alert('Document source retrieved!')}
                                    // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                                >
                                    {Array.from({ length: numPages }, (_, index) => index + 1).map(
                                      (pageNumber) => (
                                            <React.Fragment key={pageNumber}>
                                                <Page
                                                    loading={'Please wait!'}
                                                    onLoadSuccess={() => {
                                                      console.log(
                                                        'hi in her',
                                                        pageNumber,
                                                        numPages
                                                      )
                                                      if (pageNumber === numPages) {
                                                        setBackdropState(false)
                                                      }
                                                    }}
                                                    key={pageNumber}
                                                    pageNumber={pageNumber}
                                                    width={780}>
                                                    <div className="pdf_page_break" />
                                                </Page>
                                            </React.Fragment>
                                      )
                                    )}
                                </Document>
                                // </div>
                            )}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={5} justifyContent={'flex-end'} sx={{ display: 'flex' }}>
                    <Button
                        variant="cancelButton"
                        onClick={() => {
                          navigate(-1)
                        }}>
                        Back
                    </Button>
                </Grid>
                {backdropState && <BackdropLoader backdropState={backdropState} />}
            </Grid>
            {/* </Box> */}
        </>
  )
}

export default PreviewScreenPdf
PreviewScreenPdf.propTypes = {
  file: PropTypes?.string
}
