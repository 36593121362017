import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React from 'react'

import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import { getSession } from '../../../../util/helper'
import {
  WALLETHEADERBUSINESS,
  WALLETHEADERCORPORATE
} from '../../../../constants/walletAndTransactionConstants'
import PropTypes from 'prop-types'
import palette from '../../../../theme/palette'

const WalletAndTransactionTable = ({
  transactionsArray = [],
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  // pagination
  //   const [page, setPage] = useState(0)
  //   const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))
    // setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))
    // console.log(event.target.value, parseInt(event.target.value, 10))
    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  const role = JSON.parse(getSession('user')).role

  const walletTableDataHeaders =
        role === 'BUSINESS' ? WALLETHEADERBUSINESS : WALLETHEADERCORPORATE

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 33rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {walletTableDataHeaders.length > 0 &&
                                walletTableDataHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {walletTableDataHeaders?.map((index) => (
                                        <StyledTableCell key={index?.id}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (rowsPerPage > 0
                            //   ? transactionsArray?.slice(
                            //     page * rowsPerPage,
                            //     page * rowsPerPage + rowsPerPage
                            //   )
                            //   : transactionsArray
                            // )
                            transactionsArray?.map((transactionData, index) => (
                                <TableRow key={`${transactionData?.id}${index}`}>
                                    <StyledTableCell scope="row">
                                        {transactionData?.transId}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {transactionData?.date}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {transactionData?.transactionBy}
                                    </StyledTableCell>
                                    {role === 'CORPORATE' && (
                                        <StyledTableCell align="left">
                                            {transactionData?.location}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                        {transactionData?.remarks}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        Rs.{transactionData?.amount.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {transactionData?.transactionType}
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {transactionsArray?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* Pagination */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
  )
}

export default WalletAndTransactionTable

WalletAndTransactionTable.propTypes = {
  transactionsArray: PropTypes.array,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
