import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAddCommentReplyMutation } from '../../../../../store/services/documentServices'
import { toast } from 'react-toastify'

const Reply = ({ commentId, expand, setExpand }) => {
  const [addReply] = useAddCommentReplyMutation()
  const [replyText, setReplyText] = useState('')

  const hideAddReplyBox = () => {
    setExpand(-1)
  }

  const handleAddReply = () => {
    if (!replyText || replyText.trim() === '') {
      toast.error('Reply can not be empty')
      return
    }
    const payload = {
      text: replyText
    }
    addReply({ payload, commentId })
      .then((response) => {
        setReplyText('')
        hideAddReplyBox()
      })
      .catch((exception) => {
        console.log('EXCEPTION:ADD REPLY', exception)
      })
  }

  const handleHideReply = () => {
    setReplyText('')
    setExpand(-1)
  }

  return (
        <>
    {
        expand
          ? (
            <>
            <TextField
                placeholder="Write your reply here..."
                style={{ marginBottom: '20px' }}
                multiline
                required
                autoFocus
                value={replyText}
                // eslint-disable-next-line no-undef
                onChange={(e) => setReplyText(e.target.value)}
                 />
                <Grid display="flex" justifyContent="flex-end" mb={2} gap= "10px">
                <Button variant="cancelButton" onClick={handleHideReply}>Cancel</Button>
                <Button variant="primary" onClick={handleAddReply}>Save</Button>
                </Grid>
            </>
            )
          : (
            <Typography
            variant="darkValueLarge"
            component="div"
            onClick = {() => setExpand(commentId)}
            sx={{ marginBottom: '20px', marginLeft: '30px', cursor: 'pointer', textDecoration: 'underline' }}>
            Reply to comment
            </Typography>
            )
    }
  </>
  )
}

export default Reply

Reply.propTypes = {
  commentId: PropTypes.number,
  expand: PropTypes.bool,
  setExpand: PropTypes.func
}
