/* eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import './Subscription.css';
import { Typography, Grid, Divider, styled, Button, Box } from '@mui/material';
import freeicon from '../../assets/images/freeicon.png';
import bronzeicon from '../../assets/images/bronzeicon.png';
import silvericon from '../../assets/images/silvericon.png';
import goldicon from '../../assets/images/goldicon.png';
import tick from '../../assets/images/tickgreen.svg';
import tickselected from '../../assets/images/tickwhite.svg';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION, SUBSCRIPTIONTYPES, USER_ROLE } from '../../constants/enum';
import {
    useGetPlanUserQuery,
    useLazyUserPlanMappingQuery
} from '../../store/services/subscription';
import { getSession } from '../../util/helper';
import palette from '../../theme/palette';
import { RestrictionContext } from '../../contextapi/RestrictionContext';

// For divider mui
const Root = styled('div')(({ theme }) => ({
    width: '220px',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2)
    }
}));

export default function Subscription() {
    // User role from local storage
    const role = JSON.parse(getSession('user')).role || '';

    // useNavigate from react-router-dom
    const navigate = useNavigate();

    // restriction values to navigate to home in case subscription is already purchased
    const { restrictionValues } = useContext(RestrictionContext);

    // Selected Plan State
    const [selectedPlan, setSelectedPlan] = useState();

    // Get all Plan data by user role ['Corporate','Business']
    const { data: PlanData = [] } = useGetPlanUserQuery(SUBSCRIPTIONTYPES?.FRESH);

    // Map user with plan selected only when freemium (amount=0) plan is selected
    const [userPlanSelected] = useLazyUserPlanMappingQuery();

    // Plan Logo
    const planLogo = ['noIcon', freeicon, bronzeicon, silvericon, goldicon];

    useEffect(() => {
        console.log('In subscription======>', restrictionValues?.subscriptionPurchased);
        if (restrictionValues?.subscriptionPurchased) {
            navigate('/Home', {
                state: {
                    HeaderTitle: 'Home'
                }
            });
        }
    }, [restrictionValues]);

    /**
     * Set selected plan
     * @param {object} selectedPlan: Plan Information
     */
    const handleSelectedPlan = (selectedPlan) => {
        setSelectedPlan(selectedPlan);
        console.log(selectedPlan);
    };

    // Handle plan submit
    const handlePlanSubmit = () => {
        // PLan selected freemium map user with plan else navigate for billing
        // if (selectedPlan?.amount === 0) {
        //     userPlanSelected(selectedPlan?.id)
        //         .then((response) => {
        //             console.log('user', response);
        //             if (response?.isSuccess) {
        //                 navigate('/Home', {
        //                     state: {
        //                         HeaderTitle: 'Home'
        //                     }
        //                 });
        //             }
        //         })
        //         .catch((exception) => {
        //             console.log('EXCEPTION:SUBSCRIPTION:USER NOT MAPED', exception);
        //         });
        // } else navigate('/verifySubscription', { state: selectedPlan });
        navigate('/verifySubscription', { state: selectedPlan });
    };

    return (
        <Box p={2}>
            <Grid container mb={5}>
                <Typography variant="labelLarge" mb={3}>
                    Subscribe to the plan of your choice
                </Typography>
                <Typography variant="modalLabel">
                    {role === USER_ROLE.CORPORATE.toUpperCase()
                        ? SUBSCRIPTION.SUBSCRIPTIONHEADCORPORATE
                        : SUBSCRIPTION.SUBSCRIPTIONHEADBUSINESS}
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                {PlanData.length > 0 &&
                    PlanData?.map((planItem, value) => (
                        <Grid key={value} item lg={3} md={6} sm={12} xs={12}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                p={2}
                                sx={{
                                    color:
                                        selectedPlan?.id === planItem?.id && palette.primary.light,
                                    background:
                                        selectedPlan?.id === planItem?.id
                                            ? `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`
                                            : palette.secondary.light,
                                    border: `0.5px solid ${palette.text.light}`
                                }}
                                className={
                                    selectedPlan?.id === planItem?.id
                                        ? 'planBoxesSelected'
                                        : 'planBoxes'
                                }>
                                <img alt="planLogo" src={planLogo[`${planItem?.id}`]} />
                                <Typography
                                    variant="labelLarge"
                                    mt={5}
                                    mb={3}
                                    sx={{
                                        color: `${
                                            selectedPlan?.id === planItem?.id
                                                ? palette?.primary?.light
                                                : palette.text.main
                                        }`
                                    }}>
                                    {planItem?.currency} {planItem?.amount}
                                </Typography>
                                <Typography
                                    variant="darkValueLarge"
                                    sx={{
                                        color: `${
                                            selectedPlan?.id === planItem?.id
                                                ? palette?.primary?.light
                                                : palette.success.main
                                        }`
                                    }}>
                                    {planItem?.type}
                                </Typography>
                                <Root>
                                    <Divider
                                        style={{
                                            marginBottom: '29px',
                                            marginTop: '30px',
                                            background: palette.primary.light
                                        }}
                                    />
                                </Root>
                                <Grid sx={{ height: '35vh' }}>
                                    {planItem?.details.map((item, key) => (
                                        <Grid display={'flex'} key={key}>
                                            <img
                                                alt="tick"
                                                className="planServicesLogo"
                                                src={
                                                    selectedPlan?.id === planItem?.id
                                                        ? tickselected
                                                        : tick
                                                }
                                            />
                                            <Typography>{item}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant={
                                            selectedPlan?.id === planItem?.id
                                                ? 'selectedButton'
                                                : 'primary'
                                        }
                                        sx={{ width: '153px' }}
                                        onClick={() => handleSelectedPlan(planItem)}>
                                        {selectedPlan?.id === planItem?.id
                                            ? 'Selected'
                                            : 'Choose Plan'}
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}

                {!PlanData.length && (
                    <Typography ml={2} mt={2} color="error">
                        No Plan Data Found
                    </Typography>
                )}
            </Grid>
            <Grid display="flex" justifyContent="flex-end">
                <Button
                    sx={{ marginTop: '20px' }}
                    variant="primary"
                    onClick={handlePlanSubmit}
                    disabled={!selectedPlan}>
                    Proceed
                </Button>
            </Grid>
        </Box>
    );
}
