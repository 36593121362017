import {
  MenuItem,
  Button,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Select,
  Box
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ManageStaffUsersTable from './components/ManageStaffUsersTable/ManageStaffUsersTable'
import search from '../../assets/images/searchicon.svg'
import { PLACEHOLDER } from '../../constants/placeholder'
import { getSession } from '../../util/helper'
import {
  CORPORATFILTERROLE,
  PAGINATIONINITIALS,
  ROLES,
  STATUS,
  STATUSFILTER,
  USER_ROLE
} from '../../constants/enum'
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles'
import { useGetStaffUserDataQuery } from '../../store/services/manageStaffUserServices'
import { ProfileContext } from '../../contextapi/ProfileContext'
import { toast } from 'react-toastify'
import { useGetFilterLocationQuery } from '../../store/services/commonServices'
import { CORPORATECONFIGURATION } from '../../constants/configurationConstant'
import { RestrictionContext } from '../../contextapi/RestrictionContext'

const ManageStaffUsers = () => {
  const navigate = useNavigate()

  // Filter initial object
  const filterObj = {
    status: '',
    location: '',
    search: '',
    role: ''
  }

  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Profile context api
  const { profile, setProfile } = useContext(ProfileContext)

  // User info from the SessionStorage
  const userInfo = JSON.parse(getSession('user'))

  // Filter state
  const [filter, setFilter] = useState(filterObj)

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  // Corporate manage staff filter configuration object
  const corporateFilterConfig = CORPORATECONFIGURATION[`${profile?.subRole}`]

  // Restriction Context
  const { handleRestriction } = useContext(RestrictionContext)

  console.log(corporateFilterConfig, '02yVeR^0')

  // Get staff user data query
  const { data: { list = [], totalCount = 0 } = {}, isLoading } = useGetStaffUserDataQuery({
    page: paginationFilter?.page,
    size: paginationFilter?.rowsPerPage,
    search: filter?.search,
    status: filter?.status,
    location: filter?.location,
    role: filter?.role
  })

  // Get staff user Location filter query
  const { data: locationFilterList = [] } = useGetFilterLocationQuery(profile?.role, {
    skip: profile?.role !== ROLES?.CORPORATE
  })

  const activeStaffData = list.filter((item) => item.status === STATUS?.ACTIVE)

  /**
     * Handle Select  filter change
     * @param {object} event: Event object
     */
  const handleSelectFilterChange = (event) => {
    console.log(profile, setProfile)
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  // check restriction values
  const handleCheckRestrictionValues = () => {
    const errorMessage = handleRestriction()
    console.log('Error message===>', errorMessage)
    if (errorMessage) return toast.error(errorMessage)
    else {
      handleAddNewUserButton()
    }
  }

  // Handle add new user button according to the user role
  const handleAddNewUserButton = () => {
    console.log(profile, setProfile)
    if (userInfo?.role === USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()) {
      if (activeStaffData.length >= 5) {
        toast.error('Only 5 staff Users are allowed')
      } else {
        navigate('/addNewUserBusiness', {
          state: {
            HeaderTitle: 'Add new user'
          }
        })
      }
    } else {
      navigate('/addNewUserCorporate', {
        state: {
          HeaderTitle: 'Add new user'
        }
      })
    }
  }

  /**
     * Handle filter search change
     * @param {object} event: Event object
     */
  const handleSearchTextfield = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    resetPage()
  }

  // Reset page to the initial page [initial Page=0]
  const resetPage = () => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: PAGINATIONINITIALS?.PAGE
    }))
  }

  return (
        <Box p={2}>
            <Grid container spacing={3} justifyContent="space-between" alignItems="center" mb={2}>
                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="darkValueLarge">List of staff user accounts</Typography>
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'flex-start', md: 'flex-start', lg: 'flex-end' },
                      mr: 1
                    }}>
                    <Button variant="primary" onClick={handleCheckRestrictionValues}>
                        Add new user
                    </Button>
                </Grid>
            </Grid>
            {userInfo?.role === USER_ROLE.CORPORATE.toUpperCase() && (
                <Grid container spacing={3} marginBottom={3}>
                    <Grid item lg={4} md={12} xs={12} sx={{ display: 'flex' }}>
                        <TextField
                            value={filter?.search}
                            name="search"
                            id="search"
                            placeholder={PLACEHOLDER.SEARCHMANAGESTAFFUSERS}
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              style: {
                                borderRadius: '0px'
                              },
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility">
                                            <img src={search} alt="search" />
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                            onChange={handleSearchTextfield}
                        />
                    </Grid>
                    <Grid item lg={3} md={12} xs={12}>
                        <Select
                            name="status"
                            value={filter?.status || ''}
                            onChange={handleSelectFilterChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                              fontSize: '16px'
                            }}
                            MenuProps={MenuProps}
                            fullWidth>
                            <MenuItem value="">
                                <Typography>All status</Typography>
                            </MenuItem>
                            {STATUSFILTER?.length > 0 &&
                                STATUSFILTER?.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item?.id}
                                        value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    {corporateFilterConfig?.manageStaffUsersFilter?.locationFilter && (
                        <Grid item lg={3} md={12} xs={12}>
                            <Select
                                name="location"
                                value={filter?.location || ''}
                                onChange={handleSelectFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px'
                                }}
                                MenuProps={MenuProps}
                                fullWidth>
                                <MenuItem value="">
                                    <Typography>All locations</Typography>
                                </MenuItem>
                                {locationFilterList?.length > 0 &&
                                    locationFilterList?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    )}
                    {corporateFilterConfig?.manageStaffUsersFilter?.roleFilter && (
                        <Grid item lg={2} md={12} xs={12}>
                            <Select
                                name="role"
                                value={filter?.role || ''}
                                onChange={handleSelectFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px'
                                }}
                                MenuProps={MenuProps}
                                fullWidth>
                                <MenuItem value="">
                                    <Typography>Select role</Typography>
                                </MenuItem>
                                {CORPORATFILTERROLE?.length > 0 &&
                                    CORPORATFILTERROLE?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12} marginBottom={4}>
                <ManageStaffUsersTable
                    staffUserTableData={list}
                    isLoading={isLoading}
                    paginationFilter={paginationFilter}
                    setPaginationFilter={setPaginationFilter}
                    totalCount={totalCount}
                />
            </Grid>
        </Box>
  )
}

export default ManageStaffUsers
