import React, { useEffect, useState } from 'react'
import verified from '../../../assets/images/tickgreen.svg'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const ThankYouScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [subscriptionPlan, setSubscriptionPlan] = useState('')

  useEffect(() => {
    // const headerText = headerContent.find((item) => {
    //   console.log('path', item.route, location.pathname, location.pathname.split('/'))
    //   return (
    //     item.route === location.pathname ||
    //             location.pathname.split('/').includes(item.route)
    //   )
    // })
    // // const headerTextByParam = headerContent.find((item) =>
    // //   item.route.includes(location.pathname.split('/')[1])
    // // )
    // // const headerTextdata = headerText
    // // console.log('in useEffect called', location.pathname, headerText)
    // console.log('headerText', headerText)
    setSubscriptionPlan(location?.state?.planName)
  }, [location.pathname])

  return (
        <Box p={1} mt={10}>
            <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                <img
                    src={verified}
                    alt="done"
                    height="60px"
                    width="60px"
                    style={{ marginBottom: '20px' }}
                />
                <Typography
                    variant="labelLarge"
                    component="div"
                    sx={{ fontSize: '60px', marginBottom: '20px' }}>
                    Thank You!
                </Typography>
            </Grid>
            <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                <Typography
                    variant="darkValue"
                    sx={{ fontSize: '15px', lineHeight: '31px', marginBottom: '20px' }}>
                    Thank You for choosing DIGIDOC. You have successfully purchased&nbsp;
                    {subscriptionPlan} subscription
                </Typography>
                <Button
                    variant="primary"
                    onClick={() => {
                      navigate('/Home', {
                        state: {
                          HeaderTitle: 'Home'
                        }
                      })
                    }}
                    className="link_404">
                    Go to Home
                </Button>
            </Grid>
        </Box>
  )
}

export default ThankYouScreen
