import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../assets/images/cross.svg'
import caution from '../../assets/images/caution.svg'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 630,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const AlertModal = ({
  openAlertModal,
  handleAlertModalClose,
  handleSubmitAlertModal,
  alertModalState
}) => {
  return (
        <Modal
            open={openAlertModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handleAlertModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2}>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '25px', marginBottom: '10px', fontWeight: 600 }}>
                        {alertModalState.title}

                        {alertModalState.title === 'Caution' && <img src={caution} style={ { marginBottom: 0 }}/> }
                    </Typography>

                    <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '15px', lineHeight: '31px' }}>
                            {alertModalState.message}
                        </Typography>
                    </Grid>

                    <Grid display="flex" gap={2}>
                        <Grid>
                            <Button variant="cancelButton" onClick={handleAlertModalClose}>
                                {alertModalState.cancelButtonText}
                            </Button>
                        </Grid>
                        <Grid>
                            <Button variant="primary" onClick={handleSubmitAlertModal}>
                                {alertModalState.submitButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default AlertModal

AlertModal.propTypes = {
  openAlertModal: PropTypes.bool,
  handleAlertModalClose: PropTypes.func,
  handleSubmitAlertModal: PropTypes.func,
  alertModalState: PropTypes.object
}
