/* eslint react/prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import righticon from '../../assets/images/rightarrow.svg'
import lefticon from '../../assets/images/leftarrow.svg'
import righticonend from '../../assets/images/rightarrowend.svg'
import lefticonend from '../../assets/images/leftarrowend.svg'
import { Box, IconButton, TablePagination, useTheme } from '@mui/material'

// Pagination next previous icons and logic
function TablePaginationActions (props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === 'rtl' ? (
                    <img src={righticonend} alt="right" />
                ) : (
                    <img src={lefticonend} alt="left" />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === 'rtl' ? (
                    <img src={righticon} alt="right" />
                ) : (
                    <img src={lefticon} alt="left" />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === 'rtl' ? (
                    <img src={lefticon} alt="left" />
                ) : (
                    <img src={righticon} alt="right" />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === 'rtl' ? (
                    <img src={lefticonend} alt="left" />
                ) : (
                    <img src={righticonend} alt="right" />
                )}
            </IconButton>
        </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const CustomTablePagination = ({
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage
}) => {
  return (
        <>
            {/* {count > 9 && ( */}
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20, 30, { label: 'All', value: -1 }]}
                count={count}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            {/* )} */}
        </>
  )
}

export default CustomTablePagination
