import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import viewicon from '../../../../assets/actions/actionView.svg'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import { useNavigate } from 'react-router-dom'
import emailSend from '../../../../assets/actions/sendEmail.svg'
import downloadicon from '../../../../assets/actions/downloadIcon.svg'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import palette from '../../../../theme/palette'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import moment from 'moment'
import { formatDate } from '../../../../util/helper'
import { useSendDocumentPdfEmailMutation } from '../../../../store/services/documentServices'

const IndividualPhysialDocumentTable = ({
  physicalDocument = [],
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  const navigate = useNavigate()

  // pagination
  //   const [page, setPage] = useState(0)
  //   const [rowsPerPage, setRowsPerPage] = useState(10)

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  // send document email by id
  const [sendDocumentEmail] = useSendDocumentPdfEmailMutation({})

  const handleChangePage = (event, newPage) => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))

    // setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))
    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  const documentTableHeaders = [
    'Document ID(URN)',
    'Awb number',
    'Article Type',
    'Template Name',
    'Status',
    'Created At',
    'Action'
  ]

  const handleShowPreviewScreen = (documentId) => {
    navigate(`/preview-screen/${documentId}`)
  }

  const handleSendEmail = (documentId) => {
    setBackdropState(true)

    sendDocumentEmail(documentId)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {documentTableHeaders.length > 0 &&
                                documentTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {documentTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (rowsPerPage > 0
                            //   ? physicalDocument?.slice(
                            //     page * rowsPerPage,
                            //     page * rowsPerPage + rowsPerPage
                            //   )
                            //   : physicalDocument
                            // )
                            physicalDocument?.map((documentTableData, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell scope="row">
                                        {documentTableData?.name} ({documentTableData?.urnNumber})
                                    </StyledTableCell>
                                    <StyledTableCell scope="row">
                                        {documentTableData?.awbNumber}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleCategory?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleTemplate?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.status?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {formatDate(documentTableData?.createdOnTimeStamp)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="actionIcons">
                                            <>
                                                <img
                                                    src={viewicon}
                                                    alt="viewIcon"
                                                    title="Preview document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                      handleShowPreviewScreen(
                                                        documentTableData?.id
                                                      )
                                                    }
                                                />
                                                <a
                                                    style={{ paddingTop: '5px' }}
                                                    href={documentTableData?.downloadUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img
                                                        src={downloadicon}
                                                        alt="download icon"
                                                        title="Download document"
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </a>
                                                <img
                                                    src={emailSend}
                                                    alt="email icon"
                                                    title="Email document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                      handleSendEmail(documentTableData?.id)
                                                    }}
                                                />
                                            </>
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {physicalDocument.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* pagination calling here */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Paper>
  )
}

export default IndividualPhysialDocumentTable
IndividualPhysialDocumentTable.propTypes = {
  physicalDocument: PropTypes.array,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
