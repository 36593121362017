import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './NotFound.css'
// import PageNotFound from '../../assets/images/pageNotFound.png'

const NotFound = () => {
  const navigate = useNavigate()
  return (
            <Box className="page_404">
                <Grid>
                    <Grid item lg={12} xs={12}>
                            <Grid mt={20} mb={3}>
                                <Typography sx={{ fontSize: '80px' }}>404</Typography>
                            </Grid>
                            <Grid>
                                <Typography component="h1">Look like you&apos;re lost</Typography>
                                <Typography mb={2}>the page you are looking for not avaible!</Typography>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                      navigate('/Home', {
                                        state: {
                                          HeaderTitle: 'Home'
                                        }
                                      })
                                    }}
                                    className="link_404">
                                    Go to Home
                                </Button>
                            </Grid>
                    </Grid>
                </Grid>
            </Box>
  )
}

export default NotFound
