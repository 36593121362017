import React, { useState } from 'react'

import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
// import CancelIcon from '@mui/icons-material/Cancel'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewScreen = ({ file }) => {
  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
        <>
            <Grid container>
                <Grid item lg={12}>
                    {file && (
                        <div id="pdf-container" className="details_rightgrid-box">
                            <Document
                                size="A4"
                                file={file} // Path to your PDF file
                                workerSrc={workerSrc}
                                onLoadSuccess={onDocumentLoadSuccess}
                                // onSourceError={(error) =>
                                //   alert('Error while retrieving document source! ' + error.message)
                                // }
                                // onSourceSuccess={() => alert('Document source retrieved!')}
                                // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                            >
                                {Array.from({ length: numPages }, (_, index) => index + 1).map(
                                  (pageNumber) => (
                                        <React.Fragment key={pageNumber}>
                                            <Page
                                                key={pageNumber}
                                                pageNumber={pageNumber}
                                                width={594}>
                                                <div className="page-break" />
                                            </Page>
                                        </React.Fragment>
                                  )
                                )}
                            </Document>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
  )
}

export default PreviewScreen

PreviewScreen.propTypes = {
  isDocumentContainerClickable: PropTypes?.bool,
  handlePdfClick: PropTypes?.func,
  signatureBoxsList: PropTypes?.array,
  file: PropTypes?.string
}
