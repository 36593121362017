/* eslint-disable */
import { Grid, MenuItem, Popover, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import calendericon from '../../../assets/images/calendericon.svg';
import DateRangeComponent from '../../../components/DateRange/DateRange';
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles';
import { PAGINATIONINITIALS } from '../../../constants/enum';
import { useOutletContext } from 'react-router-dom';
import { useGetAllMultipleCopyDocumentsQuery } from '../../../store/services/documentServices';
import MultipleCopyDocumentsTable from './components/MultipleCopyDocumentsTable';

const MultipleCopiesDocument = () => {
    // Filter initial object
    const filterObj = {
        status: '',
        dateRange: [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
                skip: true
            }
        ]
    };
    const intialPaginationFilterState = {
        page: PAGINATIONINITIALS?.PAGE,
        rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
    };

    const { documentStatusArray } = useOutletContext();

    // Filter state
    const [filter, setFilter] = useState(filterObj);

    // Pagination Filter
    const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState);
    const [anchorEl, setAnchorEl] = useState(null);

    const {
        data: { list = [], totalCount = 0 } = {},
        isSuccess,
        isLoading,
        refetch
    } = useGetAllMultipleCopyDocumentsQuery({
        page: paginationFilter?.page,
        size: paginationFilter?.rowsPerPage,
        status: filter?.status,
        fromDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.startDate).format('YYYY-MM-DD')
            : '',
        toDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.endDate).format('YYYY-MM-DD')
            : ''
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Handle filter status change
     * @param {object} event: Event object
     */
    const handleStatusChange = (event) => {
        setFilter((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
        resetPage();
    };

    /**
     * Handle filter date range change
     * @param {array} item: date range array
     */
    const dateRangeHandler = (item) => {
        const dateRangeValue = [
            {
                startDate: item[0]?.startDate,
                endDate: item[0]?.endDate,
                key: 'selection',
                skip: false
            }
        ];
        setFilter((prev) => ({
            ...prev,
            dateRange: dateRangeValue
        }));
        resetPage();
    };

    // Reset page to the initial page [initial Page=0]
    const resetPage = () => {
        setPaginationFilter((prev) => ({
            ...prev,
            page: PAGINATIONINITIALS?.PAGE
        }));
    };

    return (
        <>
            <Grid container spacing={2} mb={3}>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <div className="date-range-input-container">
                        <TextField
                            aria-describedby={id}
                            onClick={handleClick}
                            type="text"
                            value={
                                !filter?.dateRange[0].skip
                                    ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                    : 'Date Range'
                            }
                            readOnly
                            fullWidth
                        />
                        <img src={calendericon} alt="calender" className="calendar-icon" />
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}>
                        <DateRangeComponent
                            maxDate={new Date()}
                            dateRange={filter?.dateRange}
                            setDateRange={dateRangeHandler}
                        />
                    </Popover>
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Select
                        name="status"
                        value={filter?.status}
                        onChange={handleStatusChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        MenuProps={MenuProps}>
                        <MenuItem value="" disabled>
                            <Typography>Select status</Typography>
                        </MenuItem>
                        {documentStatusArray &&
                            documentStatusArray.map((item, index) => (
                                <MenuItem key={index} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
            </Grid>
            <MultipleCopyDocumentsTable
                refetch={refetch}
                documentTableData={list}
                isLoading={isLoading}
                paginationFilter={paginationFilter}
                setPaginationFilter={setPaginationFilter}
                totalCount={totalCount}
            />
        </>
    );
};

export default MultipleCopiesDocument;
