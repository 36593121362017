/* eslint-disable no-unused-vars */
import React from 'react'
import './index.css'
import { TextField, Typography, InputAdornment, TextareaAutosize } from '@mui/material'
import PropTypes from 'prop-types'

const TextAreafield = ({
  fieldLabel,
  onchange,
  onfocus,
  textFieldData,
  documentForm,
  sectionIndex
}) => {
  const pattern = '^\\S(.*\\S)?$'
  return (
        <div className="FieldBox">
            {/* <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div> */}
            <div className="FieldBoxInput" style={{ flexBasis: '100%' }}>
                {/* <TextareaAutosize /> */}
                <TextField
                    error={documentForm?.errors[`${textFieldData?.id}`]}
                    helperText={documentForm?.errors[`${textFieldData?.id}`]}
                    placeholder={textFieldData?.placeholderName}
                    value={
                        !documentForm?.form[`${textFieldData?.id}`] &&
                        documentForm?.form[`${textFieldData?.id}`] !== ''
                          ? textFieldData?.props?.prefilled
                          : documentForm?.form[`${textFieldData?.id}`]
                    }
                    required
                    id={textFieldData?.id}
                    name={`span${textFieldData?.id}`}
                    type={textFieldData?.type}
                    fullWidth
                    onChange={(event) => onchange(event, textFieldData, sectionIndex)}
                    onFocus={(event) => onfocus(event, textFieldData)}
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                {textFieldData?.props?.prefix || ''}
                            </InputAdornment>
                      )
                    }}
                    inputProps={{
                      pattern,
                      title: 'Leading and trailing spaces are not allowed'
                    }}
                    multiline
                    minRows={4}
                    maxRows={5}
                    disabled={textFieldData?.props?.disabled === 'true'}
                />
            </div>
        </div>
  )
}
export default TextAreafield

TextAreafield.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  textFieldData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
