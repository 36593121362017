import React from 'react'
import { IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { StyledTooltip } from '../../StyledComponents/TootipStyles/StyledTooltip'
import { ErrorOutline } from '@mui/icons-material'
const CustomErrorTooltip = ({
  information = {},
  openTooltipId,
  handleTooltipClose,
  handleTooltipOpen
}) => {
  return (
        <StyledTooltip
            placement="right"
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleTooltipClose}
            // onClose={handleStyledTooltipClose}
            open={openTooltipId === information?.id}
            // disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
                <>
                    <div className="popover_box-details1" key={information?.id}>
                        <Typography
                            variant="modalLabel"
                            sx={{
                              color: '#555555',
                              letterSpacing: '0.03em',
                              lineHeight: '30px'
                            }}>
                            {information?.message}
                        </Typography>
                    </div>
                </>
            }
            arrow>
            <IconButton sx={{ color: '#db3131' }} onClick={handleTooltipOpen}>
                <ErrorOutline />
            </IconButton>
        </StyledTooltip>
  )
}

export default CustomErrorTooltip
CustomErrorTooltip.propTypes = {
  information: PropTypes.object,
  openTooltipId: PropTypes.number,
  setOpenTooltip: PropTypes.func,
  handleTooltipOpen: PropTypes.func,
  handleTooltipClose: PropTypes.func
}
