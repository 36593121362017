/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { ProfileContext, ProfileProvider } from '../contextapi/ProfileContext'
import { RestrictionProvider } from '../contextapi/RestrictionContext'
import { getSession } from '../util/helper'
import './Routes.css'
import ResponsiveDrawer from '../components/CustomDrawer/ResponsiveDrawer'
import { ROLES } from '../constants/enum'
import { IpProvider } from '../contextapi/IpContext'

// Private Routes used to check user is logged in or not
export const PrivateRoute = ({ children }) => {
  const isLoggedIn = JSON.parse(getSession('user'))
  if (!isLoggedIn) {
    return <Navigate to="/" replace />
  }

  return (
        <>
            <ProfileProvider>
                <RestrictionProvider>
                    <ResponsiveDrawer childrenComponent={children} />
                </RestrictionProvider>
            </ProfileProvider>
        </>
  )
}

// Public routes available for all users without login
export const PublicRoute = ({ children }) => {
  const isLoggedIn = JSON.parse(getSession('user'))
  if (isLoggedIn) {
    return <Navigate to="/Home" replace />
  }

  return <>{children}</>
}

export const SubscriptionRoute = ({ children }) => {
  const contextData = useContext(ProfileContext)
  console.log(contextData)

  const loggedInUserDetails = JSON.parse(getSession('user'))

  if (
    (loggedInUserDetails?.role === ROLES?.CORPORATE ||
            loggedInUserDetails?.role === ROLES?.BUSINESS_ASSOCIATE) &&
        !loggedInUserDetails?.isSubscription
  ) {
    return (
            <>
                <IpProvider>
                    <ProfileProvider>
                        <RestrictionProvider>
                            <ResponsiveDrawer childrenComponent={children} />
                        </RestrictionProvider>
                    </ProfileProvider>
                </IpProvider>
            </>
    )
  }
  return <Navigate to="/Home" replace />
}
