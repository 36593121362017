import React from 'react'
import verified from '../../../assets/images/tickgreen.svg'
import { Box, Grid, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

const ThankYou = () => {
  const location = useLocation()
  return (
        <Box p={1} mt={10}>
            <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                <img
                    src={verified}
                    alt="done"
                    height="60px"
                    width="60px"
                    style={{ marginBottom: '10px' }}
                />
                <Typography
                    variant="labelLarge"
                    component="div"
                    sx={{ fontSize: '60px', marginBottom: '20px' }}>
                    Thank You!
                </Typography>
            </Grid>
            <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                <Typography variant="darkValue" sx={{ fontSize: '15px', lineHeight: '31px' }}>
                    Thank You! for choosing DIGIDOC. Your request with &nbsp;
                    <Link to="/myDocument" target="_blank" className="text-link">
                        {location?.state?.documentId}
                    </Link>
                    &nbsp; has been submitted, as a next step we will initiate the signature
                    process. You can find updates in &nbsp;
                    <Link to="/myDocument" className="text-link" color="var(--g7)">
                        My Documents
                    </Link>{' '}
                    section.
                </Typography>
            </Grid>
        </Box>
  )
}

export default ThankYou
