import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const manageStaffUserApi = createApi({
  reducerPath: 'manageStaffUserApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllStaffUser', 'getAllManagerList', 'getStaffUserById'],
  endpoints: (builder) => ({
    getFilterLocation: builder.query({
      query: () => ({
        url: 'filter',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getManagerListByUser: builder.query({
      query: () => ({
        url: 'staff/manager',
        method: 'GET'
      }),
      providesTags: ['getAllManagerList'],
      transformResponse: (response) => response?.data
    }),
    getStaffUserData: builder.query({
      query: (params) => ({
        url: 'staff/user',
        method: 'GET',
        params
      }),
      providesTags: ['getAllStaffUser'],
      transformResponse: (response) => response?.data
    }),

    addNewStaffUser: builder.mutation({
      query: ({ payload }) => ({
        url: 'staff/user',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllStaffUser', 'getAllManagerList'],
      transformResponse: (response) => response
    }),
    updatestaffUser: builder.mutation({
      query: ({ payload, userId }) => ({
        url: `staff/user?staffId=${userId}`,
        method: 'PUT',
        payload
      }),
      invalidatesTags: ['getAllStaffUser', 'getAllManagerList', 'getStaffUserById'],
      transformResponse: (response) => response
    }),
    getStaffUserById: builder.query({
      query: (staffUserId) => ({
        url: `staff/user/info?staffId=${staffUserId}`,
        method: 'GET'
      }),
      providesTags: ['getStaffUserById'],
      transformResponse: (response) => response?.data
    })
  })
})

export const {
  useGetStaffUserDataQuery,
  useAddNewStaffUserMutation,
  useGetManagerListByUserQuery,
  useUpdatestaffUserMutation,
  useGetStaffUserByIdQuery
} = manageStaffUserApi
