import React, { useState } from 'react'

import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'
// import CancelIcon from '@mui/icons-material/Cancel'
import '../BulkSignatureSelection'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewBulkDocumentPdf = ({
  isDocumentContainerClickable,
  handlePdfClick,
  signatureBoxsList,
  file
}) => {
  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
        <>
            <Grid container>
                {/* {isDocumentContainerClickable && (
                    <Typography>
                        Please click anyWhere on the pdf to draw a box for the signature
                    </Typography>
                )} */}
                <Grid item lg={12}>
                    {file && (
                        <div id="pdf-container" className="details_rightgrid-box">
                            <Document
                                size="A4"
                                file={file} // Path to your PDF file
                                workerSrc={workerSrc}
                                onLoadSuccess={onDocumentLoadSuccess}
                                // onSourceError={(error) =>
                                //   alert('Error while retrieving document source! ' + error.message)
                                // }
                                // onSourceSuccess={() => alert('Document source retrieved!')}
                                // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                            >
                                {Array.from({ length: numPages }, (_, index) => index + 1).map(
                                  (pageNumber) => (
                                        <React.Fragment key={pageNumber}>
                                            <Page
                                                key={pageNumber}
                                                pageNumber={pageNumber}
                                                width={594}
                                                onClick={(event) =>
                                                  handlePdfClick(event, pageNumber, numPages)
                                                }>
                                                <div className="page-break" />
                                                {signatureBoxsList
                                                  .filter((box) => box.pageNumber === pageNumber)
                                                  .map((box, index) => (
                                                        <>
                                                            <Grid
                                                                height="80px"
                                                                width="200px"
                                                                sx={{
                                                                  border: '0.5px solid lightblue',
                                                                  position: 'absolute',
                                                                  background: 'lightblue',
                                                                  left: box.xPos,
                                                                  top: box.yPos,
                                                                  width: box.width,
                                                                  height: box.height
                                                                }}>
                                                                <Box className="areaArrow">
                                                                    {box?.name}
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                  ))}
                                            </Page>
                                        </React.Fragment>
                                  )
                                )}
                            </Document>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>

  // <div>
  //     {isDocumentContainerClickable && (
  //         <Typography>
  //             Please click anyWhere on the pdf to draw a box for the signature
  //         </Typography>
  //     )}
  //     <Document
  //         size="A4"
  //         file="https://digidocblobstorage.blob.core.windows.net/digidoc-dev/output-3.pdf" // Path to your PDF file
  //         workerSrc={workerSrc}
  //         onLoadSuccess={onDocumentLoadSuccess}>
  //         {Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber) => (
  //             <React.Fragment key={pageNumber}>
  //                 <Page
  //                     key={pageNumber}
  //                     pageNumber={pageNumber}
  //                     width={595}
  //                     onClick={(event) => handlePdfClick(event, pageNumber)}>
  //                     <div className="page-break" />
  //                 </Page>
  //                 {signatureBoxsList
  //                   .filter((box) => box.pageNumber === pageNumber)
  //                   .map((box, index) => (
  //                         <>
  //                             <div
  //                                 className="drag-box"
  //                                 key={index}
  //                                 style={{
  //                                   position: 'absolute',
  //                                   border: '1px solid black',
  //                                   left: box.x,
  //                                   top: box.y,
  //                                   width: box.width,
  //                                   height: box.height
  //                                 }}></div>
  //                             <div
  //                                 style={{
  //                                   position: 'absolute',
  //                                   width: '10px',
  //                                   height: '10px',
  //                                   borderRadius: '50%',
  //                                   background: 'red',
  //                                   top: `${box.centerY - 5}px`,
  //                                   left: `${box.centerX - 5}px`
  //                                 }}></div>
  //                         </>
  //                   ))}
  //             </React.Fragment>
  //         ))}
  //     </Document>
  // </div>
  )
}

export default PreviewBulkDocumentPdf

PreviewBulkDocumentPdf.propTypes = {
  isDocumentContainerClickable: PropTypes?.bool,
  handlePdfClick: PropTypes?.func,
  signatureBoxsList: PropTypes?.array,
  file: PropTypes?.string
}
