import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

const BackdropLoader = ({ backdropState }) => {
  return (
        <div>
            <Backdrop sx={{ color: palette.success.dark, zIndex: '99999' }} open={backdropState}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
  )
}

export default BackdropLoader

BackdropLoader.propTypes = {
  backdropState: PropTypes.bool
}
