import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme/theme'
// Importing toastify module
import { ToastContainer, Zoom } from 'react-toastify'

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css'
// import { ProfileProvider } from './contextapi/ProfileContext'
// import { getSession } from './util/helper'

// toast-configuration method,
// it is compulsory method.
const root = ReactDOM.createRoot(document.getElementById('root'))
// const isLoggedIn = getSession('token')
root.render(
    // <React.StrictMode>
    // <GoogleOAuthProvider clientId='438186647001-3d96qhr9cbots7u2oaj59tnop2s3uis3.apps.googleusercontent.com'>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            {/* <ProfileProvider> */}
            <App />
            {/* </ProfileProvider> */}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                closeOnClick
                transition={Zoom}
                theme="light"
                draggable={false}
                pauseOnHover
                newestOnTop
                progressClassName="toastProgress"
                limit={1}
            />
        </ThemeProvider>
    </Provider>

  // </GoogleOAuthProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
