import React, { useState } from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
// import palette from '../../../../../theme/palette'
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
// import PreviewDocument from '../../PreviewDocument/PreviewDocument'
// import { useNavigate } from 'react-router-dom'
import palette from '../../../../theme/palette'
// import palette from '../../theme/palette'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 'calc(100vw-21rem)',
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2,
  overflow: 'scroll',
  width: '50%',
  height: '90%'
}

const PreviewDocumentPdfModal = ({
  previewDocumentPdfModalState,
  handlePrevieDocumentPdfModalClose,
  handleFormSubmit
}) => {
  //   const navigate = useNavigate()

  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
        <Modal
            open={previewDocumentPdfModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handlePrevieDocumentPdfModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2} container>
                    <Typography
                        variant="modalLabel"
                        component="div"
                        sx={{
                          //   fontSize: '25px',
                          marginBottom: '10px',
                          //   fontWeight: 600,
                          wordBreak: 'break-all'
                        }}>
                        This is the Preview of the document that you are trying to upload, Please
                        continue if this is correct.&nbsp;&nbsp;
                        <Button
                            variant="link"
                            sx={{ background: '#fff' }}
                            onClick={handleFormSubmit}>
                            Continue
                        </Button>
                    </Typography>

                    {/* <Grid item lg={5} justifyContent={'flex-end'} sx={{ display: 'flex' }}>
                        <Button
                            variant="cancelButton"
                            onClick={() => {
                              navigate(-1)
                            }}>
                            Back
                        </Button>
                    </Grid> */}
                </Grid>
                <Grid mb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <Grid container> */}
                    {/* <Grid item lg={7}> */}
                    {/* <Box sx={{ marginTop: '20px' }} mb="20px" ml="40px"> */}
                    <Grid
                        className="details_rightgrid-box"
                        sx={{ maxHeight: 'calc(100vh - 17rem)' }}>
                        {previewDocumentPdfModalState?.fileUrl && (
                            // <div id="pdf-container" className="details_rightgrid-box">
                            <Document
                                size="A4"
                                file={previewDocumentPdfModalState?.fileUrl} // Path to your PDF file
                                workerSrc={workerSrc}
                                onLoadSuccess={onDocumentLoadSuccess}
                                // loading={}
                                // onSourceError={(error) =>
                                //   alert('Error while retrieving document source! ' + error.message)
                                // }
                                // onSourceSuccess={() => alert('Document source retrieved!')}
                                // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                            >
                                {/* {Array.from({ length: numPages }, (_, index) => index + 1).map(
                                  (pageNumber) => ( */}
                                <React.Fragment key={1}>
                                    <Page
                                        loading={'Please wait!'}
                                        onLoadSuccess={() => {
                                          console.log('hi in her', 1, numPages)
                                          //   if (1 === numPages) {
                                          //     setBackdropState(false)
                                          //   }
                                        }}
                                        key={1}
                                        pageNumber={1}
                                        width={780}>
                                        <div className="pdf_page_break" />
                                    </Page>
                                </React.Fragment>
                                {/* ) */}
                                {/* )} */}
                            </Document>
                            // </div>
                        )}
                    </Grid>
                    {/* </Box> */}
                </Grid>
                {/* <Box
                            className="details_rightgrid-box"
                            id="previewDocument"
                            sx={{ maxHeight: 'calc(100vh - 17rem)' }}>
                            <PreviewDocument
                                documentId={documentId}
                                documentPreview={documentPreview}
                            />
                        </Box> */}
                {/* <PreviewDocument
                            documentId={documentId}
                            documentPreview={documentPreview}
                        /> */}
                {/* </Grid> */}
                {/* </Grid> */}
            </Box>
        </Modal>
  )
}

export default PreviewDocumentPdfModal
PreviewDocumentPdfModal.propTypes = {
  previewDocumentPdfModalState: PropTypes.object,
  handlePrevieDocumentPdfModalClose: PropTypes.func,
  handleFormSubmit: PropTypes.func
}
