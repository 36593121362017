import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EmailIcon from '../../../../../assets/images/emailSent.svg'
import palette from '../../../../../theme/palette'

export default function EmailLink () {
  const navigate = useNavigate()
  return (
        <div className="landingBody">
            <Box
                sx={{
                  height: '100vh',
                  width: '100wh',
                  background: `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`
                }}>
                <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 950,
                      height: 600,
                      background: palette.primary.light,
                      borderRadius: '15px'
                    }}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        paddingTop={12}>
                        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={EmailIcon} height="100px" width="100px" alt="emailSent" />
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="labelLarge">Email has been sent!</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            marginBottom={3}
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                                We have sent a password reset link to your email address
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="primary"
                                sx={{ width: '570px' }}
                                onClick={() => navigate('/')}>
                                Go back to login
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
  )
}
