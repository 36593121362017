/* eslint-disable */
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { ROLES, USER_ROLE } from '../../../constants/enum';
import { ALERT_MESSAGE, SUBSCRIPTIONCANCELMEESAGE } from '../../../constants/message';
import {
    useCancelSubscriptionMutation,
    useGetBillingSubInfoQuery
} from '../../../store/services/profleServices';
import Root from '../../../StyledComponents/DividerStyles/DividerStyles';
import { getSession } from '../../../util/helper';
import {
    BUSINESSCONFIGURATION,
    CORPORATECONFIGURATION
} from '../../../constants/configurationConstant';
import { ProfileContext } from '../../../contextapi/ProfileContext';
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader';
// import { RestrictionContext } from '../../../contextapi/RestrictionContext'
// import { useGetSubscriptionQuery } from '../../../store/services/profleServices'

const ViewSubscription = () => {
    const alertModal = {
        title: 'Do you really want to cancel subscription?',
        message: ALERT_MESSAGE.CANCELSUBSCRIPTION,
        cancelButtonText: 'No',
        submitButtonText: 'Yes'
    };
    const navigate = useNavigate();

    const [openAlertModal, setOpenAlertModal] = useState(false);

    const [alertModalState, setAlertModalState] = useState(alertModal);

    const [backdropState, setBackdropState] = useState(false);

    // cancel subscription mutation
    const [cancelSubscription] = useCancelSubscriptionMutation({});

    // Temporaray subscription purchased status from Context
    // const { setTempSubscriptionStatus } = useContext(RestrictionContext)

    const handleSubmitAlertModal = () => {
        setBackdropState(true);
        // logic for cancelling subscription for user
        cancelSubscription({})
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                toast.success(response?.message);
                setOpenAlertModal(false);
            })
            .catch((execption) => {
                setBackdropState(false);

                console.log('CANCEL SUBSCRIPTION:Backend Failed', execption);
            });
    };

    // Profile context api
    const { profile } = useContext(ProfileContext);

    const {
        data: profileData = {
            subscription: '',
            isShowRenew: false,
            isCanceled: false,
            subscriptionDetails: { isCanceled: true }
        }
    } = useGetBillingSubInfoQuery({});

    // Subscription configuration for corporate and business staff users
    const subscriptionConfiguration =
        profile?.role === ROLES?.CORPORATE
            ? CORPORATECONFIGURATION[`${profile?.subRole}`]
            : BUSINESSCONFIGURATION[`${profile?.subRole}`];

    const userInfo = JSON.parse(getSession('user'));

    const handleEdit = () => {
        if (userInfo?.role === USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()) {
            navigate('/myProfileBusiness/editSubscription', {
                state: {
                    HeaderTitle: 'Edit Subscription'
                }
            });
        } else {
            navigate('/myProfileCorporate/editSubscription', {
                state: {
                    HeaderTitle: 'Edit Subscription'
                }
            });
        }
    };

    const handleCancelSubscription = () => {
        if (profileData?.documentDetails?.consumed === 0) {
            setAlertModalState((prev) => ({
                ...prev,
                message: ALERT_MESSAGE.CANCELSUBSCRIPTIONREFUND
            }));
        } else {
            setAlertModalState((prev) => ({
                ...prev,
                message: SUBSCRIPTIONCANCELMEESAGE(profileData?.documentDetails?.consumed)
            }));
        }
        setOpenAlertModal(true);
    };

    return (
        <Box p={3}>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Current plan
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.planType}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Renewal date
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.renewalDate}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Plan price
                    </Typography>
                    <Typography variant="modalLabel">
                        Rs {profileData?.subscriptionDetails?.amount}/-
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Renewal cycle
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.tenureType}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Documents Count Consumed
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.documentDetails?.consumed}/
                        {profileData?.documentDetails?.available}
                    </Typography>
                </Grid>
            </Grid>
            {/* {subscriptionConfiguration?.profile?.subscription && (
                <Grid container mb={3}>
                    {!profileData?.subscriptionDetails?.isCanceled && (
                        <Button
                            variant="cancelButton"
                            sx={{ marginRight: '10px' }}
                            onClick={handleCancelSubscription}>
                            Cancel subscription
                        </Button>
                    )}
                    {(profileData?.isShowRenew || profileData?.subscriptionDetails?.isCanceled) && (
                        <Button variant="primary" onClick={() => navigate('/renewSubscription')}>
                            Renew subscription
                        </Button>
                    )}
                </Grid>
            )} */}
            <AlertModal
                openAlertModal={openAlertModal}
                handleAlertModalClose={() => setOpenAlertModal(false)}
                handleSubmitAlertModal={handleSubmitAlertModal}
                alertModalState={alertModalState}
            />
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container marginBottom={3}>
                <Grid item>
                    <Typography variant="darkValueLarge" sx={{ textDecoration: 'underline' }}>
                        Billing address
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        State
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.state?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        City
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.city?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pin code
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.pinCode}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Email address
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.email}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        {profile?.role === ROLES?.BUSINESS_ASSOCIATE ? 'Name' : 'Company name '}
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        GST
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.gst}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={4}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Company address
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.address}</Typography>
                </Grid>
            </Grid>
            {subscriptionConfiguration?.profile?.isBillingAddressEditable && (
                <Grid>
                    <Button variant="primary" onClick={handleEdit}>
                        Edit
                    </Button>
                </Grid>
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
    );
};

export default ViewSubscription;
