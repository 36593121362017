import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

const saveAsExcelFile = (bufferFile, fileName) => {
  const data = new Blob([bufferFile], {
    type: EXCEL_TYPE
  })
  saveAs(data, `${fileName}`)
}

export const exportExcel = (json, excelFileName) => {
  const workbook = XLSX.utils.book_new()
  const header = Object.keys(json[0])
  const wscols = []
  for (let i = 0; i < header.length; i++) {
    // columns length added
    wscols.push({ wch: header[i].length + 5 })
  }
  const worksheet = XLSX.utils.json_to_sheet(json)
  worksheet['!cols'] = wscols // increase column width

  // const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);

  console.log('Converting JSON data to sheet data: ', worksheet)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  // Generate an XLSX file buffer
  const xlsxBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  saveAsExcelFile(xlsxBuffer, excelFileName) // save the file

  // logs
  console.log('Excel Workbook:', workbook)
  console.log('Excel Buffer: ', xlsxBuffer)
}

export const readAsJson = (worksheet, fileName) => {
  console.log('worksheet', worksheet)
  const jsonData = XLSX.utils.sheet_to_json(worksheet)
  console.log('Json DataSAMPLEDOWNLOADFILESAMPLEDOWNLOADFILE: ', jsonData)
  return jsonData
}
