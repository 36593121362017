import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import verified from '../../../../../assets/images/tickgreen.svg'
import './SuccessModal.css'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  background: '#FFFFFF',
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '5px',
  p: 4
}

const SuccessModal = ({ openSuccessModal, handleSuccessModalClose }) => {
  const navigate = useNavigate()

  const handleSuccessModal = () => {
    handleSuccessModalClose()
    navigate('/myDocument', {
      state: {
        HeaderTitle: 'My Documents'
      }
    })
  }

  return (
            <Modal
                open={openSuccessModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} display="flex" flexDirection="column" alignItems="center">
                    <img
                        src={verified}
                        alt="relax"
                        height="60px"
                        width="60px"
                        style={{ marginBottom: '10px' }}
                    />
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '22px', marginBottom: '10px', fontWeight: 600 }}>
                        Sit Back and Relax!
                    </Typography>
                    <Grid display="flex" textAlign="center" mb={2}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '15px', lineHeight: '31px' }}>
                            We have successfully sent review invite through Email and SMS to other
                            party. We will notify you whenever other party submits their review.
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button variant="primary" onClick={handleSuccessModal}>
                            Ok
                        </Button>
                    </Grid>
                </Box>
            </Modal>
  )
}

export default SuccessModal

SuccessModal.propTypes = {
  openSuccessModal: PropTypes.bool,
  handleSuccessModalClose: PropTypes.func
}
