import './EmailVerified.css'
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import verifiedicon from '../../../../../assets/images/emailSent.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useVerifyEmailQuery } from '../../../../../store/services/userServices'
import palette from '../../../../../theme/palette'
function EmailVerified () {
  const { token } = useParams()
  const navigate = useNavigate()
  const { data: emailVerifyData = {}, isError } = useVerifyEmailQuery(token)

  useEffect(() => {
    if (isError) {
      navigate('/')
    }
  }, [isError])

  return (
        <div>
            <Box
                sx={{
                  height: '100vh',
                  width: '100wh',
                  background: `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`
                }}>
                <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 950,
                      height: 600,
                      background: palette.primary.light,
                      borderRadius: '15px'
                    }}>
                    {emailVerifyData?.verification === 'success' && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop={14}>
                            <Grid item lg={12} marginBottom={2}>
                                <img
                                    src={verifiedicon}
                                    height="100px"
                                    width="100px"
                                    alt="emailSent"
                                />
                            </Grid>
                            <Grid item lg={12} marginBottom={2}>
                                <Typography variant="labelLarge">Email verified</Typography>
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                                    Congratulations, your email is verified successfully
                                </Typography>
                            </Grid>
                            <Grid item lg={12}>
                                <Button
                                    variant="primary"
                                    sx={{ width: '570px' }}
                                    onClick={() => navigate('/')}>
                                    Go back
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {emailVerifyData?.verification === 'fail' && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop={14}>
                            <Grid item lg={12}>
                                <img
                                    src={verifiedicon}
                                    height="100px"
                                    width="100px"
                                    alt="emailSent"
                                />
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="labelLarge">
                                    Email verification failed
                                </Typography>
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="modalLabelBold">
                                    Verification email has been expired or invalid. Please resend
                                    new email from profile to verify your Email.
                                </Typography>
                            </Grid>
                            {/* <Grid item lg={12}>
                                <Button
                                    variant="primary"
                                    sx={{ width: '570px' }}
                                    onClick={handleResendEmailLink}>
                                    Resend link
                                </Button>
                            </Grid> */}
                        </Grid>
                    )}
                    {emailVerifyData?.verification === 'already' && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop={14}>
                            <Grid item lg={12}>
                                <img
                                    src={verifiedicon}
                                    height="100px"
                                    width="100px"
                                    alt="emailSent"
                                />
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="labelLarge">Email already verified</Typography>
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="modalLabelBold">
                                    Email is already verified. Please login
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </div>
  )
}

export default EmailVerified
