import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import uploadImg from '../../assets/images/uploadicon.svg'
import { useDropzone } from 'react-dropzone'
import './Dropzone.css'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'
import { maximumFileSize } from '../../constants/enum'

function Dropzone ({ handleFile, fileName, text }) {
  // const { myFiles, setMyFiles } = useState();
  const maxFileSize = maximumFileSize?.file100mb
  // const maxFiles = 1;
  const onDrop = (acceptedFiles) => {
    console.log('component:Dropzone,form,accepted file', fileName, acceptedFiles)
    handleFile(fileName, acceptedFiles)
  }

  const handleDropRejected = (rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      if (file.size > maxFileSize) {
        console.log('File size exceeds the maximum limit of 100MB')
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: handleDropRejected,
    maxSize: maxFileSize,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
      'document/pdf': ['.pdf'],
      'document/doc': ['.doc', '.docx', '.xlsx']
    },
    multiple: false
  })

  return (
        <>
            <Box>
                <Grid
                    {...getRootProps({ className: 'uploadDocuments' })}
                    sx={{
                      background: palette.secondary.main,
                      border: `1px dashed ${palette.text.light}`
                    }}>
                    <input {...getInputProps()} />
                    <img
                        src={uploadImg}
                        alt="Upload"
                        height="18px"
                        style={{ marginRight: '15px' }}
                    />
                    <Typography variant="darkValue" sx={{ textDecoration: 'underline' }}>
                        {text}
                    </Typography>
                </Grid>
            </Box>
        </>
  )
}

export default Dropzone

Dropzone.propTypes = {
  handleFile: PropTypes.func,
  fileName: PropTypes.string,
  text: PropTypes.string
}
