import React from 'react'
import { Box, Grid, Modal, Typography } from '@mui/material'
import cross from '../../../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../../../../theme/palette'
// import PreviewDocument from '../../PreviewDocument/PreviewDocument'
// import palette from '../../theme/palette'
import parse from 'html-react-parser'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 'calc(100vw-21rem)',
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2,
  overflow: 'scroll',
  width: '90%',
  height: '90%'
}

const PreviewDocumentModal = ({
  previewDocumentModalState,
  handlePrevieDocumentModalClose,
  documentId,
  documentPreview
}) => {
  return (
        <Modal
            open={previewDocumentModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handlePrevieDocumentModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2} container justifyContent={'center'}>
                    <Grid md={12} lg={12} display="flex" justifyContent={'center'}>
                        <Typography
                            variant="labelLarge"
                            component="div"
                            sx={{
                              fontSize: '25px',
                              marginBottom: '10px',
                              fontWeight: 600,
                              wordBreak: 'break-all'
                            }}>
                            Document Preview
                        </Typography>
                    </Grid>

                    <Grid mb={3}>
                        <Box
                            className="details_rightgrid-box"
                            id="previewDocument"
                            sx={{ maxHeight: 'calc(100vh - 17rem)' }}>
                            {parse(documentPreview) ? parse(documentPreview) : ''}
                            {/* <PreviewDocument
                                documentId={documentId}
                                documentPreview={documentPreview}
                            /> */}
                        </Box>
                        {/* <PreviewDocument
                            documentId={documentId}
                            documentPreview={documentPreview}
                        /> */}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default PreviewDocumentModal

PreviewDocumentModal.propTypes = {
  previewDocumentModalState: PropTypes.object,
  handlePrevieDocumentModalClose: PropTypes.func,
  documentId: PropTypes.string,
  documentPreview: PropTypes.object
}
