export const STATE = {
  ANDHRA_PRADESH: {
    ID: 1,
    LABEL: 'ANDHRA PRADESH'
  },
  ASSAM: {
    ID: 2,
    LABEL: 'ASSAM'
  },
  MAHARASHTRA: {
    ID: 3,
    LABEL: 'MAHARASHTRA'
  },
  BIHAR: {
    ID: 4,
    LABEL: 'BIHAR'
  },
  GUJARAT: {
    ID: 5,
    LABEL: 'GUJARAT'
  },
  SIKKIM: {
    ID: 6,
    LABEL: 'SIKKIM'
  },
  HIMACHAL_PRADESH: {
    ID: 7,
    LABEL: 'HIMACHAL PRADESH'
  },
  JAMMU_AND_KASHMIR: {
    ID: 8,
    LABEL: 'JAMMU AND KASHMIR'
  },
  KARNATAKA: {
    ID: 9,
    LABEL: 'KARNATAKA'
  },
  NAGALAND: {
    ID: 10,
    LABEL: 'NAGALAND'
  },
  MANIPUR: {
    ID: 11,
    LABEL: 'MANIPUR'
  },
  MIZORAM: {
    ID: 12,
    LABEL: 'MIZORAM'
  },
  MADHYA_PRADESH: {
    ID: 13,
    LABEL: 'MADHYA PRADESH'
  },
  MEGHALAYA: {
    ID: 14,
    LABEL: 'MEGHALAYA'
  },
  LAKSHADWEEP: {
    ID: 15,
    LABEL: 'LAKSHADWEEP'
  },
  ODISHA: {
    ID: 17,
    LABEL: 'ODISHA'
  },
  PUNJAB: {
    ID: 18,
    LABEL: 'PUNJAB'
  },
  RAJASTHAN: {
    ID: 19,
    LABEL: 'RAJASTHAN'
  },
  KERALA: {
    ID: 20,
    LABEL: 'KERALA'
  },
  TAMILNADU: {
    ID: 21,
    LABEL: 'TAMILNADU'
  },
  TRIPURA: {
    ID: 22,
    LABEL: 'TRIPURA'
  },
  UTTAR_PRADESH: {
    ID: 23,
    LABEL: 'UTTAR PRADESH'
  },
  HARYANA: {
    ID: 24,
    LABEL: 'HARYANA'
  },
  DELHI: {
    ID: 25,
    LABEL: 'DELHI'
  },
  GOA: {
    ID: 26,
    LABEL: 'GOA'
  },
  PUDUCHERRY: {
    ID: 27,
    LABEL: 'PUDUCHERRY'
  },
  UNION_TERRITORY_OF_DAMAN_AND_DIU: {
    ID: 29,
    LABEL: 'UNION TERRITORY OF DAMAN AND DIU'
  },
  DADRA_AND_NAGER_HAVELI: {
    ID: 30,
    LABEL: 'DADRA AND NAGER HAVELI'
  },
  CHANDIGARH: {
    ID: 31,
    LABEL: 'CHANDIGARH'
  },
  ANDAMAN_AND_NICOBAR: {
    ID: 32,
    LABEL: 'ANDAMAN AND NICOBAR'
  },
  UTTRAKHAND: {
    ID: 33,
    LABEL: 'UTTRAKHAND'
  },
  JHARKHAND: {
    ID: 34,
    LABEL: 'JHARKHAND'
  },
  CHATTISGARH: {
    ID: 35,
    LABEL: 'CHATTISGARH'
  },
  ARUNACHAL_PRADESH: {
    ID: 36,
    LABEL: 'ARUNACHAL PRADESH'
  },
  LADAKH: {
    ID: 46,
    LABEL: 'LADAKH'
  },
  WEST_BENGAL: {
    ID: 47,
    LABEL: 'WEST BENGAL'
  },
  TELANGANA: {
    ID: 48,
    LABEL: 'TELANGANA'
  }
}
