import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGetAllDocumentsQuery } from '../../../../store/services/documentServices'
import {
  useAddTicketMutation,
  useGetCategoryOfIssuesQuery
} from '../../../../store/services/helpServices'
import { toast } from 'react-toastify'
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles'
import CustomCard from '../../../../components/CustomCard/index'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import { PATTERN } from '../../../../constants/enum'

const AddTicket = () => {
  const navigate = useNavigate()

  // Ticket Details Form state
  const [ticketDetailForm, setTicketDetailForm] = useState({
    documentId: '',
    categoryofIssue: '',
    issueType: '',
    issueId: ''
  })

  // Submit button disable state
  const [disableSubmit, setdisableSubmit] = useState(true)

  // Get all documents according to the user
  const { data: { list = [] } = {} } = useGetAllDocumentsQuery(1)

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // Get all category of issues
  const { data: categoryOfIssuesArray = [] } = useGetCategoryOfIssuesQuery({})

  // // Get issue type by category of issue selected
  // const { data: issueTypeArray = [] } = useGetIssuesTypeByCategoryQuery(
  //   ticketDetailForm?.categoryofIssue,
  //   {
  //     skip: !ticketDetailForm.categoryofIssue
  //   }
  // )

  // // Get issues by issue type selected
  // const { data: issuesArray = [] } = useGetIssuesByIsssueTypeQuery(ticketDetailForm?.issueType, {
  //   skip: !ticketDetailForm.issueType
  // })

  const [addTicket] = useAddTicketMutation({})

  // Called when form change to handle disable enable of next button
  useEffect(() => {
    const nextState = formValid()
    setdisableSubmit(!nextState)
  }, [ticketDetailForm])

  /**
     * Handle ticket detail form change
     * @param {Object} event:event object
     */
  const handleTicketFormChange = (event) => {
    if (event.target.name === 'description') {
      setTicketDetailForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      }))
    } else {
      setTicketDetailForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
      }))
    }
  }

  /**
     * Handle Select category of issue form change
     * @param {Object} event:event object
     */
  const handleSelectCategory = (event) => {
    setTicketDetailForm((prev) => ({
      ...prev,
      issueType: '',
      issueId: '',
      [event.target.name]: event.target.value
    }))
  }

  // /**
  //    * Handle Select Issue type form change
  //    * @param {Object} event:event object
  //    */
  // const handleSelectIssueType = (event) => {
  //   setTicketDetailForm((prev) => ({
  //     ...prev,
  //     issueId: '',
  //     [event.target.name]: event.target.value
  //   }))
  // }

  const handleSubmitTicketForm = () => {
    const dataToSend = {
      issueCategory: ticketDetailForm?.categoryofIssue,
      description: ticketDetailForm?.description,
      issueTypeId: ticketDetailForm?.issueType,
      issueId: ticketDetailForm?.issueId,
      documentId: ticketDetailForm?.documentId
    }
    setBackdropState(true)
    addTicket({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        navigate('/help/tickets', {
          state: {
            HeaderTitle: 'Tickets'
          }
        })
      })
      .catch((execption) => {
        setBackdropState(false)
      })
  }

  // Check all required fields are filled or not
  const formValid = () => {
    return ticketDetailForm?.categoryofIssue && ticketDetailForm?.description
  }

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <CustomCard headerTitle={'Please select the ticket details'}>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">
                            Select document related to issue
                        </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Select
                            name="documentId"
                            value={ticketDetailForm?.documentId}
                            onChange={handleTicketFormChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            fullWidth
                            MenuProps={MenuProps}>
                            <MenuItem value="" disabled>
                                <Typography>List of documents that belong to this user</Typography>
                            </MenuItem>
                            {list.length > 0 &&
                                list.map((document) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={document?.id}
                                        value={document?.id}>
                                        {document?.name} - {document?.articleTemplate?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Select category of issue</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Select
                            name="categoryofIssue"
                            value={ticketDetailForm?.categoryofIssue}
                            onChange={handleSelectCategory}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            fullWidth>
                            <MenuItem value="" disabled>
                                <Typography>List of category issues</Typography>
                            </MenuItem>
                            {categoryOfIssuesArray.length > 0 &&
                                categoryOfIssuesArray.map((category) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={category?.id}
                                        value={category?.id}>
                                        {category?.categoryName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
                {/* <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Select issue type</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Select
                            name="issueType"
                            value={ticketDetailForm?.issueType}
                            onChange={handleSelectIssueType}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            fullWidth>
                            <MenuItem value="" disabled>
                                <Typography> Select issue type</Typography>
                            </MenuItem>
                            {issueTypeArray.length > 0 &&
                                issueTypeArray.map((issueType) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={issueType?.id}
                                        value={issueType?.id}>
                                        {issueType?.subCategoryName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Select issue</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Select
                            name="issueId"
                            value={ticketDetailForm?.issueId}
                            onChange={handleTicketFormChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            fullWidth>
                            <MenuItem value="" disabled>
                                <Typography>Select issue</Typography>
                            </MenuItem>
                            {issuesArray.length > 0 &&
                                issuesArray.map((issue) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={issue?.id}
                                        value={issue?.id}>
                                        {issue?.issueName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid> */}
                <Grid container alignItems="center" p={1} mb={2}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">
                            Add description{' '}
                            <span style={{ fontSize: '10px' }}>(2000 characters)</span>
                        </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            inputProps={{
                              type: 'text',
                              minlength: 10,
                              maxlength: 2000
                            }}
                            //   rows={1}
                            multiline
                            fullWidth
                            onChange={(e) => {
                              handleTicketFormChange(e)
                            }}
                            value={ticketDetailForm?.description}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Button
                        sx={{ marginRight: '10px' }}
                        variant="cancelButton"
                        onClick={() =>
                          navigate('/help/tickets', {
                            state: {
                              HeaderTitle: 'Tickets'
                            }
                          })
                        }>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitTicketForm}
                        variant="primary"
                        disabled={disableSubmit}>
                        Submit
                    </Button>
                </Grid>
            </CustomCard>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default AddTicket
