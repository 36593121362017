export const INDIVIDUALBASIGNATUREOPTION = [
  {
    label: 'Do aadhar based online signature',
    value: 'aadhar'
  },
  {
    label: 'Digital only',
    value: 'digitalOnly'
  }
]

export const CORPSIGNATUREOPTION = [
  {
    label: 'Digital only',
    value: 'digitalOnly'
  },
  {
    label: 'Do aadhar based online signature',
    value: 'aadhar'
  }
]

export const SIGNATUREMESSAGE = {
  AADHAR: 'Aadhar based online signature',
  DIGITALDSC: ' Digital Signature using DSC Pendrive'
}

export const DIGITALSIGNATUREPOSITION = {
  FIRSTPARTYPOSITION: {
    FIRSTPAGEX: 40,
    FIRSTPAGEY: 120,
    XPOS: 10,
    YPOS: 40
  },
  SECONDPARTYPOSITION: {
    FIRSTPAGEX: 430,
    FIRSTPAGEY: 120,
    XPOS: 470,
    YPOS: 40
  },
  GAPSIZE: {
    POS: 120
  }
}
