/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
// import CancelIcon from '@mui/icons-material/Cancel'
import '../ESignaturePage.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewDocumentPdf = ({
  isDocumentContainerClickable,
  handlePdfClick,
  signatureBoxsList,
  file
}) => {
  const [numPages, setNumPages] = useState(null)

  const [pdfPagesRef, setpdfPagesRef] = useState()

  // const pageRefs = useRef({})

  // const onItemClick = ({ pageNumber }) => {
  //   pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' })
  // }

  useEffect(() => {
    if (numPages) {
      console.log(numPages)
      // setpdfPagesRef(
      // numPages.map(() => React.createRef())
      // )
      // pdfPagesRef[2].scrollIntoView()
    }
  }, [numPages])

  // useEffect(() => {
  // if (pdfPagesRef) {
  //   pdfPagesRef[2].scrollIntoView()
  // }
  // console.log('pdfPagesRef', pdfPagesRef)
  // }, [pdfPagesRef])

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
        <>
            <Grid container id="pdfContainer">
                {/* {isDocumentContainerClickable && (
                    <Typography>
                        Please click anyWhere on the pdf to draw a box for the signature
                    </Typography> */}
                {/* )} */}
                {/* <Grid onClick={() => onItemClick(2)}>Button</Grid> */}
                <Grid item lg={12}>
                    {file && (
                        // <div id="pdf-container" className="details_rightgrid-box">
                        <Document
                            size="A4"
                            file={file} // Path to your PDF file
                            workerSrc={workerSrc}
                            onLoadSuccess={onDocumentLoadSuccess}
                            // onSourceError={(error) =>
                            //   alert('Error while retrieving document source! ' + error.message)
                            // }
                            // onSourceSuccess={() => alert('Document source retrieved!')}
                            // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                        >
                            {Array.from({ length: numPages }, (_, index) => index + 1).map(
                              (pageNumber) => (
                                    <React.Fragment
                                        key={pageNumber}
                                        // ref={(el) => {
                                        //   pageRefs.current[pageNumber] = el
                                        // }}
                                    >
                                        <Page
                                            key={pageNumber}
                                            pageNumber={pageNumber}
                                            width={594}
                                            onClick={(event) =>
                                              handlePdfClick(event, pageNumber, numPages)
                                            }
                                            // onLoadSuccess={() => {
                                            //   if (pageNumber === numPages) {
                                            //     // pageRefs.current[pageNumber].scrollIntoView({
                                            //     //   behavior: 'smooth'
                                            //     // })
                                            //   }
                                            // }}
                                            // id={`page${pageNumber}`}
                                        >
                                            <div className="pdf_page_break" />
                                            {signatureBoxsList
                                              .filter((box) => box.pageNumber === pageNumber)
                                              .map((box, index) => (
                                                    <>
                                                        <Grid
                                                            height="80px"
                                                            width="200px"
                                                            sx={{
                                                              border: '0.5px solid lightblue',
                                                              position: 'absolute',
                                                              background: 'lightblue',
                                                              left: box.xPos,
                                                              top: box.yPos - 80,
                                                              width: 200,
                                                              height: 80
                                                            }}>
                                                            <Box className="areaArrow">
                                                                {box?.name}
                                                            </Box>
                                                        </Grid>
                                                    </>
                                              ))}
                                        </Page>
                                    </React.Fragment>
                              )
                            )}
                        </Document>
                        // </div>
                    )}
                </Grid>
            </Grid>
        </>

  // <div>
  //     {isDocumentContainerClickable && (
  //         <Typography>
  //             Please click anyWhere on the pdf to draw a box for the signature
  //         </Typography>
  //     )}
  //     <Document
  //         size="A4"
  //         file="https://digidocblobstorage.blob.core.windows.net/digidoc-dev/output-3.pdf" // Path to your PDF file
  //         workerSrc={workerSrc}
  //         onLoadSuccess={onDocumentLoadSuccess}>
  //         {Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber) => (
  //             <React.Fragment key={pageNumber}>
  //                 <Page
  //                     key={pageNumber}
  //                     pageNumber={pageNumber}
  //                     width={595}
  //                     onClick={(event) => handlePdfClick(event, pageNumber)}>
  //                     <div className="page-break" />
  //                 </Page>
  //                 {signatureBoxsList
  //                   .filter((box) => box.pageNumber === pageNumber)
  //                   .map((box, index) => (
  //                         <>
  //                             <div
  //                                 className="drag-box"
  //                                 key={index}
  //                                 style={{
  //                                   position: 'absolute',
  //                                   border: '1px solid black',
  //                                   left: box.x,
  //                                   top: box.y,
  //                                   width: box.width,
  //                                   height: box.height
  //                                 }}></div>
  //                             <div
  //                                 style={{
  //                                   position: 'absolute',
  //                                   width: '10px',
  //                                   height: '10px',
  //                                   borderRadius: '50%',
  //                                   background: 'red',
  //                                   top: `${box.centerY - 5}px`,
  //                                   left: `${box.centerX - 5}px`
  //                                 }}></div>
  //                         </>
  //                   ))}
  //             </React.Fragment>
  //         ))}
  //     </Document>
  // </div>
  )
}

export default PreviewDocumentPdf

PreviewDocumentPdf.propTypes = {
  isDocumentContainerClickable: PropTypes?.bool,
  handlePdfClick: PropTypes?.func,
  signatureBoxsList: PropTypes?.array,
  file: PropTypes?.string
}
