import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../../../../theme/palette'
import { FileDownload } from '@mui/icons-material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const CorrectionRequired = ({
  selectedDocument,
  openCorrectionRequiredModal,
  handleCorrectionRequiredModalClose,
  handleSubmitCorrectionRequiredModalButton,
  correctionrequiredModalState
}) => {
  console.log('selectedDocument?.errorFiles?.link', selectedDocument)
  return (
        <Modal
            open={openCorrectionRequiredModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleCorrectionRequiredModalClose}>
                    <img src={cross} alt="cross" height="21px" width="21px" />
                </Grid>
                <Grid p={2}>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '25px', marginBottom: '10px', fontWeight: 600 }}>
                        {correctionrequiredModalState?.title}
                    </Typography>

                    <Grid mb={1} mt={2}>
                        <Typography variant="darkValueLarge">
                            {correctionrequiredModalState?.message}
                        </Typography>
                    </Grid>
                    <Grid mb={3}>
                        <Typography variant="darkValueLarge">
                            {correctionrequiredModalState?.walletBalance}
                        </Typography>
                    </Grid>

                    <Grid display="flex" gap={2}>
                        <Grid>
                            <a href={selectedDocument?.errorFiles?.link}>
                                <Button variant="cancelButton" endIcon={<FileDownload />}>
                                    {correctionrequiredModalState?.cancelButtonText}
                                </Button>
                            </a>
                        </Grid>
                        <Grid>
                            <Button
                                variant="primary"
                                onClick={handleSubmitCorrectionRequiredModalButton}>
                                {correctionrequiredModalState?.submitButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default CorrectionRequired

CorrectionRequired.propTypes = {
  selectedDocument: PropTypes.object,
  openCorrectionRequiredModal: PropTypes.bool,
  handleCorrectionRequiredModalClose: PropTypes.func,
  handleSubmitCorrectionRequiredModalButton: PropTypes.func,
  correctionrequiredModalState: PropTypes.object
}
