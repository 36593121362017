import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGetProfileQuery, useLazyResendEmailQuery } from '../../../store/services/userServices'
import React, { useState } from 'react'
import notVerifiedIcon from '../../../assets/images/notverifiedIcon.svg'
import { toast } from 'react-toastify'
import emailIcon from '../../../assets/actions/actionEmail.svg'
// import { ProfileContext } from '../../../contextapi/ProfileContext'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
// import { useGetProfileQuery } from '../../../store/services/profleServices'

const MyProfile = () => {
  const navigate = useNavigate()

  const { data: profileData = {} } = useGetProfileQuery({})

  // Profile context api
  //   const { profile: profileData } = useContext(ProfileContext)

  const [backdropState, setBackdropState] = useState(false)

  const [resendEmail] = useLazyResendEmailQuery()

  const handleEdit = () => {
    navigate('/myProfile/editProfile', {
      state: {
        HeaderTitle: 'Edit Profile'
      }
    })
    console.log('in handleEdit')
    // HeaderTitile('Edit Profile')
  }

  const handleResendEmail = () => {
    setBackdropState(true)
    resendEmail(profileData?.email)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
      })
      .catch((exception) => {
        setBackdropState(false)
        console.log('EXCEPTION:RESEND EMAIl', exception)
      })
  }

  return (
        <Box sx={{ padding: '3%' }}>
            <Grid container spacing={5} marginBottom={4}>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Name (as per Aadhar Card)
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.name}</Typography>
                </Grid>

                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Grid container alignItems="center">
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', marginRight: '10px' }}>
                            Email address
                        </Typography>
                        {!profileData?.isEmailVerified && (
                            <>
                                <img
                                    src={notVerifiedIcon}
                                    alt="notVerified"
                                    height="20px"
                                    title="Email not verified"
                                    style={{ marginRight: '10px', marginTop: '-10px' }}
                                />
                                <img
                                    src={emailIcon}
                                    alt="resendEmail"
                                    height="24px"
                                    title="Resend email"
                                    style={{ marginTop: '-10px', cursor: 'pointer' }}
                                    onClick={handleResendEmail}
                                />
                            </>
                        )}
                    </Grid>

                    <Typography variant="modalLabel">{profileData?.email}</Typography>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Grid container alignItems="center">
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Phone Number
                        </Typography>

                        {!profileData?.isPhoneVerified &&
                            (profileData?.phone !== null || profileData?.phone !== '') && (
                                <>
                                    <img
                                        src={notVerifiedIcon}
                                        alt="notVerified"
                                        height="20px"
                                        title="Phone Number not verified"
                                        style={{ marginRight: '10px', marginTop: '-10px' }}
                                    />
                                </>
                        )}
                    </Grid>
                    <Typography variant="modalLabel">
                        {profileData?.phone ? '+91-' : ''}
                        {profileData?.phone || '-'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={4}>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Address
                    </Typography>
                    <Typography variant="modalLabel" sx={{ wordBreak: 'break-all' }}>
                        {profileData?.address}
                    </Typography>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        State
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.state?.name}</Typography>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        City
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.city?.name}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={4}>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pin Code
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.pinCode}</Typography>
                </Grid>
            </Grid>
            <Grid marginTop={10}>
                <Button variant="primary" onClick={handleEdit} sx={{ width: '6rem' }}>
                    Edit info
                </Button>
            </Grid>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default MyProfile
