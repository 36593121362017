import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const uploadDocumentApi = createApi({
  reducerPath: 'uploadDocumentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({
    getDocumentFieldsById: builder.query({
      query: (documentId) => ({
        url: `docs/fieldsByDocument/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getuploadDocumentPreviewById: builder.query({
      query: (documentId) => ({
        url: `docs/document/preview/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    })
  })
})

export const { useGetDocumentFieldsByIdQuery, useGetuploadDocumentPreviewByIdQuery } =
    uploadDocumentApi
