import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Comment = ({ isReply, username, profileicon, comment, date, time, status }) => {
  return (
        <Box className="comment" sx={{ marginLeft: isReply ? '45px' : '0px' }}>
            <Grid display="flex" justifyContent="space-between">
                <Grid display="flex" gap="10px">
                    <img src={profileicon} alt="profile" height="20px" width="20px" />
                    <Typography variant="modalLabel" sx={{ fontWeight: 600 }}>
                        {username}
                    </Typography>
                </Grid>
                <Grid display="flex" gap="10px" ml={1}>
                    <Typography variant="modalLabelSmall">{date}</Typography>
                    <Typography variant="modalLabelSmall">{time}</Typography>
                    <Typography variant="modalLabelSmall" sx={{ color: 'red' }}>
                        {status === 'DRAFT' ? '(In Draft)' : ''}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="commentsection_rightgrid-comment">
                <Typography variant="darkValue" sx={{ fontStyle: 'italic' }}>
                    {comment}
                </Typography>
            </Grid>
        </Box>
  )
}

export default Comment

Comment.propTypes = {
  username: PropTypes.string,
  profileicon: PropTypes.string,
  comment: PropTypes.string,
  isReply: PropTypes.bool,
  date: PropTypes.string,
  time: PropTypes.string,
  status: PropTypes.string
}
