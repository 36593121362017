/* eslint-disable */
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useGetProfilePanDetailsQuery } from '../../../store/services/userServices';
import { PANVERIFICATIONSTATUS, SUB_ROLE } from '../../../constants/enum';
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from '../../../contextapi/ProfileContext';

const BusinessPanDetails = () => {
    const { data: panData = {} } = useGetProfilePanDetailsQuery({});
    const { profile } = useContext(ProfileContext);

    const [panDetailForm, setPanDetailForm] = useState({});
    const navigate = useNavigate();

    const getPanRequestStatus = (requestStatus) => {
        if (requestStatus === 'N/A') {
            return requestStatus;
        }

        for (const key in PANVERIFICATIONSTATUS) {
            if (PANVERIFICATIONSTATUS[key].id === requestStatus) {
                return PANVERIFICATIONSTATUS[key].title;
            }
        }
    };

    useEffect(() => {
        if (panData) {
            setPanDetailForm({
                name: panData.name || 'N/A',
                dob: panData.dob || 'N/A',
                panNumber: panData.panNumber || 'N/A',
                panRequestStatus: panData.panRequestStatus || 'N/A',
                fname: panData.fname || 'N/A',
                rejectReason: panData.rejectReason || 'N/A'
            });
        }
    }, [panData]);

    const handleEdit = () => {
        navigate('/myProfile/editPanDetails', {
            state: {
                HeaderTitle: 'Edit Pan Details'
            }
        });
        console.log('in handleEdit');
        // HeaderTitile('Edit Profile')
    };

    return (
        <Box sx={{ padding: '3%' }}>
            <Grid container spacing={5} marginBottom={4}>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pan Number
                    </Typography>
                    <Typography>{panDetailForm?.panNumber}</Typography>
                </Grid>

                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography
                        variant="darkValue"
                        component="div"
                        sx={{ marginBottom: '8px', marginRight: '10px' }}>
                        Name
                    </Typography>
                    <Typography>{panDetailForm?.name}</Typography>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Father's Name
                    </Typography>
                    <Typography>{panDetailForm?.fname}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={4}>
                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Date Of Birth
                    </Typography>
                    <Typography>{panDetailForm?.dob}</Typography>
                </Grid>

                <Grid lg={4} md={6} sm={12} xs={12} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pan Request Status
                    </Typography>
                    <Typography>{getPanRequestStatus(panDetailForm?.panRequestStatus)}</Typography>
                </Grid>

                {panDetailForm?.panRequestStatus === PANVERIFICATIONSTATUS.REJECTED.id ? (
                    <Grid lg={4} md={6} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Reason
                        </Typography>
                        <Typography>{panDetailForm?.rejectReason}</Typography>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>

            {/* <Grid marginTop={10}>
                {profile?.subRole !== SUB_ROLE.STAFF ? (
                    <Button
                        variant="primary"
                        sx={{ width: '6rem' }}
                        disabled={
                            panDetailForm?.panRequestStatus === PANVERIFICATIONSTATUS.NEW.id ||
                            panDetailForm?.panRequestStatus ===
                                PANVERIFICATIONSTATUS.REVISION_SUBMITTED.id ||
                            panDetailForm?.panRequestStatus == 'N/A'
                                ? true
                                : false
                        }
                        onClick={handleEdit}>
                        Edit info
                    </Button>
                ) : (
                    <></>
                )}
            </Grid> */}
        </Box>
    );
};

export default BusinessPanDetails;
