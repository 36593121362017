/* eslint-disable */
import React, { useContext, useState } from 'react';
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import add from '../../assets/images/addMoney.svg';
import push from '../../assets/images/pushMoney.svg';
import pushWhite from '../../assets/images/hand.svg';
import search from '../../assets/images/searchicon.svg';
import { PLACEHOLDER } from '../../constants/placeholder';
import DateRangeComponent from '../../components/DateRange/DateRange';
import calendericon from '../../assets/images/calendericon.svg';
import './WalletAndTransaction.css';
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles';
import WalletAndTransactionTable from './components/WalletAndTransactionTable/WalletAndTransactionTable';
import AddMoneyModal from './components/AddMoneyModal/AddMoneyModal';
import { getSession } from '../../util/helper';
import {
    BUSINESSFILTERROLE,
    CORPORATFILTERROLE,
    ENTRYTYPEFILTER,
    PAGINATIONINITIALS,
    PAYMENT_TYPE,
    ROLES,
    USER_ROLE
} from '../../constants/enum';
import { paymentInitialized } from '../../util/razorpayPayment';
import {
    useGetAllWalletTransactionsQuery,
    useUpdateWalletPaymentStatusMutation
} from '../../store/services/walletTransactionServices';
import WalletToBank from './components/WalletToBank/WalletToBank';
import { useGenerateOrderIdMutation } from '../../store/services/paymentServices';
import palette from '../../theme/palette';
import { exportExcel } from '../../util/excelService';
import { ProfileContext } from '../../contextapi/ProfileContext';
import {
    BUSINESSCONFIGURATION,
    CORPORATECONFIGURATION
} from '../../constants/configurationConstant';
import moment from 'moment';
import { RestrictionContext } from '../../contextapi/RestrictionContext';
import { toast } from 'react-toastify';
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
//   padding: 25,
//   height: '16vh',
//   textAlign: 'center',
//   border: '0.5px solid #BFBFBF',
//   boxShadow: 'none',
//   borderRadius: '5px'
// }))
import { useGetFilterLocationQuery } from '../../store/services/commonServices';
import { TOAST_MESSAGE } from '../../constants/message';

const WalletAndTransaction = () => {
    // Filter object
    const filterObj = {
        search: '',
        entryType: '',
        location: '',
        userRole: '',
        dateRange: [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
                skip: true
            }
        ]
    };

    const intialPaginationFilterState = {
        page: PAGINATIONINITIALS?.PAGE,
        rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
    };

    // Profile context api
    const { profile } = useContext(ProfileContext);

    // state for loader
    const [backdropState, setBackdropState] = useState(false);

    console.log('profile context', profile);

    // userInfo object from SessionStorage
    const userInfo = JSON.parse(getSession('user'));
    // Restriction context api
    const { handleRestriction } = useContext(RestrictionContext);

    const isAllowedDocumentError = handleRestriction();

    const walletConfiguration =
        profile?.role === ROLES?.CORPORATE
            ? CORPORATECONFIGURATION[`${profile?.subRole}`]
            : BUSINESSCONFIGURATION[`${profile?.subRole}`];

    // Load razorpay script
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    // Open addmoney modal state
    const [openAddMoneyModal, setOpenAddMoneyModal] = useState(false);

    // Selected tab state
    const [selectedTab, setSelectedTab] = useState('wallet');

    // Amount to add State
    const [amountToAdd, setAmountToAdd] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Filter object state
    const [filter, setFilter] = useState(filterObj);

    // Pagination Filter
    const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState);

    // Get all wallet transaction list
    const { data: transactionsData = {}, isLoading } = useGetAllWalletTransactionsQuery({
        page: paginationFilter?.page,
        size: paginationFilter?.rowsPerPage,
        search: filter?.search,
        entryType: filter?.entryType,
        location: filter?.location,
        role: filter?.userRole,
        fromDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.startDate).format('DD-MM-YYYY')
            : '',
        toDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.endDate).format('DD-MM-YYYY')
            : ''
    });

    // Get staff user Location filter query
    const { data: locationFilterList = [] } = useGetFilterLocationQuery(profile?.role, {
        skip: profile?.role !== ROLES?.CORPORATE
    });

    // Generate razorpay order id
    const [generateRazPaymentId] = useGenerateOrderIdMutation({});

    // update wallet after razorpay response mutation
    const [updateWallet] = useUpdateWalletPaymentStatusMutation({});

    /**
     * Handle add money in wallet modal submit
     * Load razorpay script, generate order id, update payment status
     */
    const handleSubmitAddMoneyModal = async () => {
        const razorpayScript = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!razorpayScript) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const dataToSend = {
            amount: amountToAdd,
            transactionType: PAYMENT_TYPE?.WALLET
        };
        setBackdropState(true);
        generateRazPaymentId({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                console.log('reponse', response);
                paymentInitialized(response)
                    .then((paymentStatusResponse) => {
                        console.log(paymentStatusResponse);
                        updatePayment(paymentStatusResponse);
                    })
                    .catch((paymentStatusexception) => {
                        updatePayment(paymentStatusexception);
                    });
                // console.log('payment Status', paymentStatus)
            })
            .catch((execption) => {
                setBackdropState(false);
                setAmountToAdd('');
                console.log('Order failed:Backend Failed', execption);
            });
        setOpenAddMoneyModal(false);
    };

    /**
     * Handle update payment status
     * @param {Object} response: Razorpay response
     */
    const updatePayment = (response) => {
        const dataToSend = {
            paymentGateway: response,
            transactionType: PAYMENT_TYPE.WALLET
        };
        setBackdropState(true);
        updateWallet({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                setAmountToAdd('');
                toast.success(TOAST_MESSAGE.MONEY_ADDED_TO_WALLET);
            })
            .catch((execption) => {
                setBackdropState(false);
                console.log('Order failed:Backend Failed', execption);
            });
    };

    /**
     * Handle filter change
     * @param {*} event : Event object
     */
    const handleFilterChange = (event) => {
        setFilter((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
        resetPage();
    };

    /**
     * Handle set date range in filter
     * @param {array} item: Returned on date selection date range
     */
    const dateRangeHandler = (item) => {
        const dateRangeValue = [
            {
                startDate: item[0]?.startDate,
                endDate: item[0]?.endDate,
                key: 'selection',
                skip: false
            }
        ];
        setFilter((prev) => ({
            ...prev,
            dateRange: dateRangeValue
        }));
        resetPage();
    };

    /**
     * Handle search textfield change
     * @param {object} event: Event object
     */
    const handleFilterSearchChange = (event) => {
        setFilter((prev) => ({
            ...prev,
            [event.target.name]: event.target.value.replace(/\s/gy, '')
        }));

        resetPage();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Create excel data for xlsx export
    const createExcelData = () => {
        const result = transactionsData?.transactions.map((item) => {
            return {
                'Transaction Id': item?.transId,
                Date: item?.date,
                'By Whom': item?.transactionBy,
                ...(profile?.role === ROLES?.CORPORATE && { Location: item?.location }),
                // Location: profile?.role === ROLES?.CORPORATE ? item?.location : undefined,
                'For What': item?.remarks,
                Amount: `Rs.${item?.amount.toFixed(2)}`,
                'Entry Type': item?.transactionType
            };
        });
        return result;
    };

    // Handle export excel
    const handleExportExcel = () => {
        if (transactionsData?.transactions?.length > 0) {
            console.log('in');
            const result = createExcelData();
            exportExcel(result, 'wallet Transaction');
        }
    };

    // Reset page to the initial page [initial Page=0]
    const resetPage = () => {
        setPaginationFilter((prev) => ({
            ...prev,
            page: PAGINATIONINITIALS?.PAGE
        }));
    };

    return (
        <>
            <Grid container spacing={2} sx={{ background: '#f5f5f5', padding: '1%' }} mb={3}>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Box
                        className="walletAndTransactionCards"
                        sx={{
                            flexDirection: 'column',
                            background: palette.primary.light,
                            border: `0.5px solid ${palette.text.light}`
                        }}>
                        <Typography
                            variant="labelSmall"
                            sx={{ fontSize: '25px', fontWeight: 600 }}
                            mb={1}>
                            Rs.
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                                transactionsData?.groupWallet?.walletBalance.toFixed(2) || 0
                            )}
                            /-
                            {/* Rs. 35,000/- */}
                        </Typography>
                        <Typography
                            variant="modalLabelBold"
                            sx={{ fontSize: '15px', fontWeight: 600, color: '#30A772' }}>
                            Wallet Balance
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Box
                        className="walletAndTransactionCards"
                        sx={{
                            flexDirection: 'column',
                            background: palette.primary.light,
                            border: `0.5px solid ${palette.text.light}`
                        }}>
                        <Typography
                            variant="labelSmall"
                            sx={{ fontSize: '25px', fontWeight: 600 }}
                            mb={1}>
                            Rs.
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                                transactionsData?.groupWallet?.totalExpenditure || 0
                            )}
                            /-
                        </Typography>
                        <Typography
                            variant="modalLabelBold"
                            sx={{ fontSize: '15px', fontWeight: 600, color: '#30A772' }}>
                            Total money spent till date
                        </Typography>
                    </Box>
                </Grid>
                {walletConfiguration?.walletTransacation?.addMoney && (
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <Box
                            className="walletAndTransactionCards"
                            sx={{
                                flexDirection: 'row',
                                background: palette.primary.light,
                                border: `0.5px solid ${palette.text.light}`
                            }}>
                            <img src={add} alt="addMoney" style={{ marginRight: '10px' }} />
                            <Link
                                className="text-link"
                                style={{ fontWeight: 600 }}
                                onClick={() => {
                                    if (isAllowedDocumentError) {
                                        toast.error(isAllowedDocumentError);
                                    } else {
                                        setOpenAddMoneyModal(true);
                                    }
                                }}>
                                Add money to wallet
                            </Link>
                        </Box>
                    </Grid>
                )}
                {/* {walletConfiguration?.walletTransacation?.pushMoneyBank && (
                    <Grid
                        item
                        lg={3}
                        md={6}
                        sm={12}
                        xs={12}
                        onClick={() => setSelectedTab('WalletToBank')}>
                        <Box
                            className="walletAndTransactionCards"
                            sx={{
                              flexDirection: 'row',
                              background:
                                    selectedTab === 'wallet'
                                      ? palette.primary.light
                                      : `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`,
                              border:
                                    selectedTab === 'wallet'
                                      ? `0.5px solid ${palette.text.light}`
                                      : palette.text.light
                            }}>
                            <img
                                src={selectedTab === 'wallet' ? push : pushWhite}
                                alt="pushMoney"
                                style={{
                                  marginRight: '8px'
                                }}
                                height="46px"
                            />
                            <Typography
                                sx={{
                                  fontWeight: 600,
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: selectedTab === 'wallet' ? '#06b5fa' : 'white'
                                }}>
                                Push money from wallet to bank
                            </Typography>
                        </Box>
                    </Grid>
                )} */}
            </Grid>
            {selectedTab === 'wallet' ? (
                <Box sx={{ padding: '1% 3%' }}>
                    <Grid
                        spacing={2}
                        container
                        mb={3}
                        sx={{
                            flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: 'column' }
                        }}
                        alignItems="center">
                        <Grid item lg={4} md={12} xs={12} sm={12} sx={{ display: 'flex' }}>
                            <TextField
                                value={filter?.search || ''}
                                name="search"
                                id="search"
                                placeholder={PLACEHOLDER?.SEARCHWALLETANDTRANSACTION}
                                InputLabelProps={{
                                    shrink: false
                                }}
                                InputProps={{
                                    style: {
                                        borderRadius: '5px'
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton aria-label="toggle password visibility">
                                                <img src={search} alt="search" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                onChange={handleFilterSearchChange}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={12}
                            xs={12}
                            sm={12}
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    lg: 'flex-end',
                                    md: 'flex-start',
                                    sm: 'flex-start',
                                    xs: 'flex-start'
                                }
                            }}>
                            <Typography
                                className="text-link"
                                sx={{ cursor: 'pointer' }}
                                onClick={handleExportExcel}>
                                Export XLS
                            </Typography>
                        </Grid>
                    </Grid>
                    {userInfo?.role ===
                        USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase() && (
                        <Grid
                            container
                            spacing={3}
                            marginBottom={2}
                            direction="row"
                            justifyContent={'left'}
                            alignItems="center">
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <Select
                                    value={filter?.entryType || ''}
                                    name="entryType"
                                    onChange={handleFilterChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '16px'
                                    }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="">All Entry type</MenuItem>
                                    {ENTRYTYPEFILTER?.length > 0 &&
                                        ENTRYTYPEFILTER?.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item?.id}
                                                value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <div className="date-range-input-container">
                                    <TextField
                                        aria-describedby={id}
                                        onClick={handleClick}
                                        type="text"
                                        value={
                                            !filter?.dateRange[0].skip
                                                ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                                : 'Date Range'
                                        }
                                        readOnly
                                        fullWidth
                                    />
                                    <img
                                        src={calendericon}
                                        alt="calender"
                                        className="calendar-icon"
                                    />
                                </div>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}>
                                    <DateRangeComponent
                                        dateRange={filter?.dateRange}
                                        setDateRange={dateRangeHandler}
                                        maxDate={new Date()}
                                    />
                                </Popover>
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <Select
                                    name="userRole"
                                    value={filter?.userRole || ''}
                                    onChange={handleFilterChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '16px'
                                    }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="">All users (Role)</MenuItem>
                                    {BUSINESSFILTERROLE?.length > 0 &&
                                        BUSINESSFILTERROLE?.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item?.id}
                                                value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </Grid>
                    )}
                    {userInfo?.role === USER_ROLE.CORPORATE.toUpperCase() && (
                        <Grid
                            container
                            spacing={3}
                            marginBottom={2}
                            direction="row"
                            justifyContent={'left'}
                            alignItems="center">
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <Select
                                    name="entryType"
                                    value={filter?.entryType || ''}
                                    onChange={handleFilterChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '16px'
                                    }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="">All Entry type</MenuItem>
                                    {ENTRYTYPEFILTER?.length > 0 &&
                                        ENTRYTYPEFILTER?.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item?.id}
                                                value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <div className="date-range-input-container">
                                    <TextField
                                        aria-describedby={id}
                                        onClick={handleClick}
                                        type="text"
                                        value={
                                            !filter?.dateRange[0].skip
                                                ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                                : 'Date Range'
                                        }
                                        readOnly
                                        fullWidth
                                    />
                                    <img
                                        src={calendericon}
                                        alt="calender"
                                        className="calendar-icon"
                                    />
                                </div>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}>
                                    <DateRangeComponent
                                        dateRange={filter?.dateRange}
                                        setDateRange={dateRangeHandler}
                                    />
                                </Popover>
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <Select
                                    name="location"
                                    value={filter?.location || ''}
                                    onChange={handleFilterChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '16px'
                                    }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="">All locations</MenuItem>
                                    {locationFilterList?.length > 0 &&
                                        locationFilterList?.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item?.id}
                                                value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} sm={12}>
                                <Select
                                    name="userRole"
                                    value={filter?.userRole || ''}
                                    onChange={handleFilterChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '16px'
                                    }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="">All users (Role)</MenuItem>
                                    {CORPORATFILTERROLE?.length > 0 &&
                                        CORPORATFILTERROLE?.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item?.id}
                                                value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </Grid>
                    )}
                    <WalletAndTransactionTable
                        transactionsArray={transactionsData?.transactions}
                        isLoading={isLoading}
                        paginationFilter={paginationFilter}
                        setPaginationFilter={setPaginationFilter}
                        totalCount={transactionsData?.totalCount ? transactionsData?.totalCount : 0}
                    />
                </Box>
            ) : (
                <WalletToBank
                    setSelectedTab={setSelectedTab}
                    walletBalance={transactionsData?.groupWallet?.walletBalance}
                />
            )}
            {openAddMoneyModal && (
                <AddMoneyModal
                    amountToAdd={amountToAdd}
                    setAmountToAdd={setAmountToAdd}
                    openAddMoneyModal={openAddMoneyModal}
                    handleAddMoneyModalClose={() => {
                        setOpenAddMoneyModal(false);
                        setAmountToAdd('');
                    }}
                    handleSubmitAddMoneyModal={handleSubmitAddMoneyModal}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </>
    );
};

export default WalletAndTransaction;
