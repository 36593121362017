import React from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material'
import rightarrow from '../../assets/images/rightarrow.svg'

// Accordian styles MUI
export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`
}))
// AccordionSummary styles MUI
export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<img src={rightarrow} alt="rightArrow" />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#F5F7F9',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))
// AccordionDetails styles MUI
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  background: 'white',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))
