import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../assets/images/cross.svg'
import tick from '../../assets/images/tickgreen.svg'
import PropTypes from 'prop-types'
import { UPLOADFILESACCEPTED } from '../../constants/enum'
import { toast } from 'react-toastify'
import CloseImg from '../../assets/images/closeImg.svg'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '400px', lg: '600px' },
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
  borderRadius: '5px',
  p: 1
}

function Files ({ file, handleRemoveFile }) {
  return (
        <li style={{ marginRight: '10px' }}>
            <Grid className="uploadFile">
                <Grid>
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, wordWrap: 'anywhere' }}>
                        {file?.name}
                    </Typography>
                </Grid>
                <img
                    src={CloseImg}
                    onClick={handleRemoveFile}
                    className="removeUploadedFile"
                    alt="Cancel"
                />
            </Grid>
        </li>
  )
}
Files.propTypes = {
  file: PropTypes.object,
  handleRemoveFile: PropTypes.func
}
const fileTerms = [
  'Only PDF, Doc, Docs formats are allowed.',
  'Maximum allowed size limit is 5mb.',
  'You can select only one document.',
  'Kindly make sure that your document is ready to be used. Since we do not allow any editing in uploaded documents.'
]

const UploadDocsModal = ({
  openUploadModal,
  handleUploadModalClose,
  fileUploaded,
  setFileUploaded,
  handleFormSubmit
}) => {
  /**
     * Handle file change on uploadModal file
     * @param {Object} event:event object
     */
  const handleFile = (event) => {
    const file = event.target.files[0]
    const fileType = file.type // image/jpeg
    console.log(fileType)
    const fileSize = file.size // 5MB
    const acceptedFiles = UPLOADFILESACCEPTED
    if (!acceptedFiles.includes(fileType)) {
      return toast.error('file not accepted')
      // return console.log('')
    } else if (fileSize > 5 * 1000000) {
      return toast.error('file too large')
    } else {
      setFileUploaded(event.target.files[0])
    }
  }

  const handleRemoveFile = () => {
    setFileUploaded('')
  }

  return (
        <Modal
            className="MuiBackdrop-root"
            open={openUploadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" flexDirection="row" justifyContent="flex-end">
                    <span style={{ cursor: 'pointer' }} onClick={handleUploadModalClose}>
                        <img src={cross} alt="" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2}>
                    <Typography
                        mb={2}
                        variant="darkValue"
                        component="div"
                        sx={{ marginBottom: '10px' }}>
                        Browse
                    </Typography>
                    <Grid display="flex" alignItems="center">
                        <Grid
                            p={2}
                            mr={3}
                            sx={{
                              backgroundColor: palette.primary.light,
                              border: `1px solid ${palette.text.light}`,
                              borderRadius: '5px'
                            }}>
                            <Typography variant="modalLabel">
                                Browse your system to upload custom document
                            </Typography>
                        </Grid>

                        <Grid>
                            {fileUploaded ? (
                                <Button
                                    variant="primary"
                                    component="label"
                                    onClick={handleFormSubmit}>
                                    Upload
                                </Button>
                            ) : (
                                <Button variant="primary" component="label">
                                    Browse
                                    <input
                                        hidden
                                        accept=".doc, .docx,.pdf"
                                        type="file"
                                        onChange={handleFile}
                                    />
                                </Button>
                            )}
                            {/* <Button variant="primary" component="label">
                                    Browse
                                    <input
                                        hidden
                                        accept=".doc, .docx,.pdf"
                                        type="file"
                                        onChange={handleFile}
                                    />
                                </Button> */}
                        </Grid>
                    </Grid>
                    <Box mb={2}>
                        {fileUploaded && (
                            <ul type="none" className="filesList">
                                <Files file={fileUploaded} handleRemoveFile={handleRemoveFile} />
                            </ul>
                        )}
                    </Box>
                    <div>
                        {fileTerms &&
                            fileTerms.map((item, key) => (
                                <Grid key={key} display="flex" mb={2}>
                                    <img src={tick} alt="tick" style={{ marginRight: '14px' }} />
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {item}
                                    </Typography>
                                </Grid>
                            ))}
                    </div>
                </Grid>
            </Box>
        </Modal>
  )
}

export default UploadDocsModal

UploadDocsModal.propTypes = {
  openUploadModal: PropTypes.bool,
  handleUploadModalClose: PropTypes.func,
  fileUploaded: PropTypes.any,
  setFileUploaded: PropTypes.func,
  handleFormSubmit: PropTypes.func
}
