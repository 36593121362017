import React, { useState } from 'react'
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import editicon from '../../../../assets/actions/actionEdit.svg'
import TableStatus from '../../../../components/TableStatus/TableStatus'
import {
  MANAGESTAFFUSERSHEADERBUSINESS,
  MANAGESTAFFUSERSHEADERCORPORATE
} from '../../../../constants/manageStaffUserConstants'
import { getSession } from '../../../../util/helper'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useUpdatestaffUserMutation } from '../../../../store/services/manageStaffUserServices'
import { toast } from 'react-toastify'
import AlertModal from '../../../../components/AlertModal/AlertModal'
import { ALERT_MESSAGE } from '../../../../constants/message'
import { STATUS } from '../../../../constants/enum'
import palette from '../../../../theme/palette'

const ManageStaffUsersTable = ({
  staffUserTableData,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  // Navigate
  const navigate = useNavigate()

  // pagination
  //   const [page, setPage] = useState(0)
  //   const [rowsPerPage, setRowsPerPage] = useState(10)

  // Alert Modal description
  const ModalState = {
    title: 'Alert',
    message: ALERT_MESSAGE.STATUSCHANGE,
    cancelButtonText: 'Cancel1',
    submitButtonText: 'Agree1',
    type: 'statusChange'
  }

  // Alert modal state
  const [openAlertModal, setOpenAlertModal] = useState(false)

  const [alertModalState, setAlertModalState] = useState(ModalState)

  // Save state for selected staff user when inactive or active is clicked
  const [selectedStaffUser, setSelectedStaffUser] = useState()

  const role = JSON.parse(getSession('user')).role

  const manageStaffUsersHeaders =
        role === 'BUSINESS' ? MANAGESTAFFUSERSHEADERBUSINESS : MANAGESTAFFUSERSHEADERCORPORATE

  // update staff user  data mutation
  const [updatestaffUserStatus] = useUpdatestaffUserMutation({})

  const handleChangePage = (event, newPage) => {
    // setPage(newPage)
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))

    // console.log(event.target.value, parseInt(event.target.value, 10))
    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  /**
     * Handle status change open alert modal and save selected user data
     * @param {Object} staffUserData: Selected staffuserdata
     */
  const handleStatusChange = (staffUserData) => {
    console.log(staffUserData)
    setAlertModalState({
      title: 'Alert',
      message: ALERT_MESSAGE.STATUSCHANGE,
      cancelButtonText: 'Cancel',
      submitButtonText: 'Agree',
      type: 'statusChange'
    })
    setOpenAlertModal(true)
    setSelectedStaffUser(staffUserData)
  }

  // Handle submit status change
  const handleSubmitAlertModal = () => {
    const dataToSend = {
      status: selectedStaffUser?.status === STATUS?.ACTIVE ? STATUS?.INACTIVE : STATUS?.ACTIVE
    }
    updatestaffUserStatus({ payload: dataToSend, userId: selectedStaffUser?.id })
      .unwrap()
      .then((response) => {
        setOpenAlertModal(false)
        console.log(response?.message, response, 'fulfil:STATUS CHAnge:success:staff user')
        toast.success(response.message)
      })
      .catch((execption) => {
        console.log('exception:SOcialLOgin:fail:LOGIN', execption)
        // toast.error(execption.message)
      })
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 20rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {manageStaffUsersHeaders.length > 0 &&
                                manageStaffUsersHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    {role === 'BUSINESS' && (
                        <TableBody>
                            {isLoading &&
                                [0, 1, 2, 3, 4, 5].map((item, index) => (
                                    <TableRow key={index}>
                                        {manageStaffUsersHeaders?.map((index) => (
                                            <StyledTableCell key={index?.id}>
                                                <Skeleton />
                                            </StyledTableCell>
                                        ))}
                                        {/* <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton />
                                        </StyledTableCell> */}
                                    </TableRow>
                                ))}
                            {
                                // (rowsPerPage > 0
                                //   ? staffUserTableData?.slice(
                                //     page * rowsPerPage,
                                //     page * rowsPerPage + rowsPerPage
                                //   )
                                //   : staffUserTableData
                                // )
                                staffUserTableData?.map((staffUserData, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell scope="row">
                                            {staffUserData?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.city?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.phone}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.email}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div
                                                onClick={() => {
                                                  handleStatusChange(staffUserData)
                                                }}
                                                style={{ cursor: 'pointer' }}>
                                                <TableStatus status={staffUserData?.status} />
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="actionIcons">
                                                <img
                                                    src={editicon}
                                                    alt="editicon"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                      navigate(
                                                            `/editUserBusiness/${staffUserData?.id}`,
                                                            {
                                                              state: {
                                                                HeaderTitle: 'Edit Staff User'
                                                              }
                                                            }
                                                      )
                                                    }}
                                                />
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    )}
                    {role === 'CORPORATE' && (
                        <TableBody>
                            {isLoading &&
                                [0, 1, 2, 3, 4].map((item, index) => (
                                    <TableRow key={index}>
                                        {manageStaffUsersHeaders?.map((item, hindex) => (
                                            <StyledTableCell key={hindex}>
                                                <Skeleton />
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            {
                                // (rowsPerPage > 0
                                //   ? staffUserTableData?.slice(
                                //     page * rowsPerPage,
                                //     page * rowsPerPage + rowsPerPage
                                //   )
                                //   :
                                // )
                                staffUserTableData?.map((staffUserData, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell scope="row">
                                            {staffUserData?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.role}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.manager?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.location}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.phone}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {staffUserData?.email}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {staffUserData?.lastLogin}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div
                                                onClick={() => {
                                                  handleStatusChange(staffUserData)
                                                }}
                                                style={{ cursor: 'pointer' }}>
                                                <TableStatus status={staffUserData?.status} />
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="actionIcons">
                                                <img
                                                    src={editicon}
                                                    alt="editicon"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                      navigate(
                                                            `/editUserCorporate/${staffUserData?.id}`,
                                                            {
                                                              state: {
                                                                HeaderTitle: 'Edit Staff User'
                                                              }
                                                            }
                                                      )
                                                    }}
                                                />
                                                {/* <img
                                                src={deleteicon}
                                                alt="deleteIcon"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  handleDeleteIcon(staffUserData?.id)
                                                }}
                                            /> */}
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    )}
                </Table>
                {staffUserTableData?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>
            {/* Pagination */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Alert confirmation modal */}
            {openAlertModal && (
                <AlertModal
                    openAlertModal={openAlertModal}
                    handleAlertModalClose={() => setOpenAlertModal(false)}
                    handleSubmitAlertModal={handleSubmitAlertModal}
                    alertModalState={alertModalState}
                />
            )}
        </Paper>
  )
}

export default ManageStaffUsersTable

ManageStaffUsersTable.propTypes = {
  staffUserTableData: PropTypes.array,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
