import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useGetAssociatedBrnachQuery } from '../../../store/services/profleServices'

const AssociatedBranch = () => {
  // Associated branch data Query
  const { data: associatedBranch = {} } = useGetAssociatedBrnachQuery({})
  console.log('associatedBranch', associatedBranch)

  return (
        <Box p={3}>
            <Grid container direction={'column'}>
                <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                    Name of branch
                </Typography>
                <Typography variant="modalLabel">{associatedBranch?.branchName}</Typography>
            </Grid>
            <Grid container direction={'column'}>
                <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                    Location of branch
                </Typography>
                <Typography variant="modalLabel">{associatedBranch?.address}</Typography>
            </Grid>
        </Box>
  )
}

export default AssociatedBranch
