import React from 'react'
import { Modal, Box, Grid, Typography, Button } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'
import cross from '../../assets/images/cross.svg'
import SendIcon from '@mui/icons-material/Send'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: palette.primary.light,
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '3px',
  p: 4
}

const SignatureModal = ({
  openSignModal,
  signatureModalDataState,
  handleSubmitSignModal,
  handleCancelSignModal
}) => {
  return (
        <div>
            <Modal
                open={openSignModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleCancelSignModal}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify-content="flex-start"
                        marginBottom={4}>
                        <Typography variant="darkValueLarge" sx={{ fontSize: '28px' }}>
                            Payment Successful.
                        </Typography>
                        <Typography variant="" sx={{ fontSize: '20px' }}>
                            Document Id is {signatureModalDataState?.urn}
                        </Typography>
                        <Typography variant="" sx={{ fontSize: '20px' }}>
                            E-Stamp Id is {signatureModalDataState?.estampId}
                        </Typography>
                        <Typography variant="" sx={{ fontSize: '20px' }}>
                            Please continue with the signature process.
                        </Typography>
                    </Grid>

                    <Grid container spacing={2} sx={{ gap: '10px' }}>
                        {/* <Grid item>
                            <Button variant="cancelButton" onClick={handleCancelSignModal}>
                                Cancel
                            </Button>
                        </Grid> */}
                        <Grid item>
                            <Button
                                variant="primary"
                                onClick={handleSubmitSignModal}
                                endIcon={<SendIcon />}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
  )
}

export default SignatureModal

SignatureModal.propTypes = {
  openSignModal: PropTypes.bool,
  signatureModalDataState: PropTypes.object,
  handleSubmitSignModal: PropTypes.func,
  handleCancelSignModal: PropTypes.func
}
