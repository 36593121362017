export const CORPORATECONFIGURATION = {
  OWNER: {
    manageStaffUsersFilter: {
      locationFilter: true,
      roleFilter: true
    },
    walletTransacation: {
      addMoney: true,
      pushMoneyBank: true
    },
    profile: {
      isEditable: true,
      isNationalDetailsHidden: false,
      isbankAccount: true,
      subscription: true,
      isBillingAddressEditable: true
    },
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  },
  ADMIN: {
    manageStaffUserFilter: {
      locationFilter: false,
      roleFilter: false
    },
    walletTransacation: {
      addMoney: false,
      pushMoneyBank: false
    },
    profile: {
      isEditable: true,
      isNationalDetailsHidden: true,
      isbankAccount: false,
      subscription: false,
      isBillingAddressEditable: false
    },
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  },
  STAFF: {
    manageStaffUserFilter: {
      locationFilter: false,
      roleFilter: false
    },
    walletTransacation: {
      addMoney: false,
      pushMoneyBank: false
    },
    profile: {
      isEditable: true,
      isNationalDetailsHidden: true,
      isbankAccount: false,
      subscription: false,
      isBillingAddressEditable: false
    },
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  }
}

export const BUSINESSCONFIGURATION = {
  OWNER: {
    walletTransacation: {
      addMoney: true,
      pushMoneyBank: true
    },
    profile: {
      isEditable: true,
      isbankAccount: true,
      subscription: true,
      isBillingAddressEditable: true
    },
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  },
  STAFF: {
    walletTransacation: {
      addMoney: false,
      pushMoneyBank: false
    },
    profile: {
      isEditable: true,
      isbankAccount: false,
      subscription: false,
      isBillingAddressEditable: false
    },
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  }
}

export const INDIVIDUALCONFIGURATION = {
  OWNER: {
    document: {
      stampDutyAccord: true,
      reviewConsentButton: false
    },
    customDocument: {
      stampdutyAccord: true
    }
  }
}
