import './Home.css'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { HOME_MESSAGE } from '../../constants/message'
import { Link, useNavigate } from 'react-router-dom'
import PanModal from './components/PanModal/PanModal'
import palette from '../../theme/palette'
import { RestrictionContext } from '../../contextapi/RestrictionContext'
import { PROFILESTATUS, ROLES, USERKYCSTATUS } from '../../constants/enum'
import NotesModal from '../../components/NotesModal/NotesModal'
import { ProfileContext } from '../../contextapi/ProfileContext'
import TermsCheckModal from './components/TermsCheckModal'
import { toast } from 'react-toastify'
import errormessages from '../../constants/errormessages'

export default function Home () {
  const { profile } = useContext(ProfileContext)

  // Restriction context api
  const { restrictionValues, tempSubscriptionStatus } = useContext(RestrictionContext)

  // Pan modal state
  const [openPanModal, setOpenPanModal] = useState(false)
  // Terms Modal
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const navigate = useNavigate()

  // Guide to kyc popup
  // const [kycGuideModal, setkycGuideModal] = useState({
  //   open: false,
  //   title: ALERT_MESSAGE?.KYCGUIDEMODALTITLE,
  //   message: MESSAGE_CONFIRMATION?.KYCCOMPLETEMESSAGE,
  //   cancelButtonText: 'Later',
  //   submitButtonText: 'Ok'
  // })

  // Information Before KYC with Digi Locker
  // const [digiLockerInfoModal, setdigiLockerInfoModal] = useState({
  //   open: false,
  //   title: 'Alert',
  //   message: MESSAGE_CONFIRMATION?.DIGILOCKERINITIATIONALERT,
  //   cancelButtonText: 'Cancel',
  //   submitButtonText: 'Proceed'
  // })

  // Rejection notes modal open close state
  const [rejectionNotesModal, setRejectionNotesModal] = useState({
    open: false,
    message: ''
  })

  useEffect(() => {
    const subscriptionStatus =
            tempSubscriptionStatus !== null
              ? tempSubscriptionStatus
              : restrictionValues?.subscriptionPurchased

    if (
      !subscriptionStatus &&
            !restrictionValues?.isSubscriptionCanceled &&
            restrictionValues?.profileVerified &&
            profile?.role === ROLES?.CORPORATE
    ) {
      console.warn('I am here to navigate subscription ====>', restrictionValues)
      // navigate subscription
      navigate('/subscription')
    }

    if (
      !subscriptionStatus &&
            !restrictionValues?.isSubscriptionCanceled &&
            restrictionValues?.isKycCompleted &&
            profile?.role === ROLES?.BUSINESS_ASSOCIATE
    ) {
      navigate('/subscription')
    }

    if (
      restrictionValues?.userPrivacyVersion === null &&
            restrictionValues?.userDisclaimerVersion === null &&
            restrictionValues?.userTermsVersion === null
    ) {
      setOpenTermsModal(true)
      setShowDisclaimer(true)
      setShowTerms(true)
      setShowPrivacy(true)
    } else {
      checkPrivacy()
      checkDisclaimer()
      checkTerms()
      if (showPrivacy === true || showDisclaimer === true || showTerms === true) {
        setOpenTermsModal(true)
      } else {
        setOpenTermsModal(false)
        // handleKycGuideModal()
      }
    }
  }, [restrictionValues])

  // const handleKycGuideModal = () => {
  //   if (
  //     !restrictionValues?.isKycCompleted &&
  //           restrictionValues?.userKycStatus === USERKYCSTATUS.NOT_SUBMITTED
  //   ) {
  //     setkycGuideModal((prev) => ({
  //       ...prev,
  //       open: true
  //     }))
  //   } else {
  //     setkycGuideModal((prev) => ({
  //       ...prev,
  //       open: false
  //     }))
  //   }
  // }

  const checkPrivacy = () => {
    if (restrictionValues?.userPrivacyVersion < restrictionValues?.currentPrivacyVersion) {
      setShowPrivacy(true)
    } else {
      setShowPrivacy(false)
    }
  }

  const checkDisclaimer = () => {
    if (
      restrictionValues?.userDisclaimerVersion < restrictionValues?.currentDisclaimerVersion
    ) {
      setShowDisclaimer(true)
    } else {
      setShowDisclaimer(false)
    }
  }

  const checkTerms = () => {
    if (
      restrictionValues?.userDisclaimerVersion < restrictionValues?.currentDisclaimerVersion
    ) {
      setShowTerms(true)
    } else {
      setShowTerms(false)
    }
  }

  // Restriction data
  // const {
  //   data: restrictionData = {
  //     documentAllowed: '',
  //     documentConsumed: '',
  //     emailVerified: true,
  //     errorMessage: '',
  //     isKycCompleted: true,
  //     isProfileCompleted: true,
  //     phoneVerified: true,
  //     profileRejected: true,
  //     profileStatus: '',
  //     profileVerified: true,
  //     subscriptionPurchased: true
  //   },
  //   isSuccess
  // } = useGetAllRestrictionChecksQuery({})

  // useEffect(() => {
  //   if (restrictionData && isSuccess) {
  //     setRestrictionValues(restrictionData)
  //     console.log('restrictionData', restrictionData)
  //   }
  // }, [restrictionData, isSuccess])

  // useEffect(() => {
  //   console.log('location in restriction', location.pathname)
  //   if (routes.includes(location.pathname)) {
  //     console.log('hi in')
  //   }
  // }, [location])

  const handleOpenRejectionModal = () => {
    setRejectionNotesModal({
      title: 'Rejection Notes:',
      open: true,
      message: restrictionValues?.errorMessage
    })
  }

  const handleTermsModalClose = () => {
    setOpenTermsModal(false)
    // handleKycGuideModal()
  }

  return (
        <>
            <Box>
                {/* <Button onClick={hande}>handle</Button> */}
                {profile?.role === ROLES?.CORPORATE &&
                    restrictionValues?.profileStatus === PROFILESTATUS?.PENDING?.id && (
                        <Box
                            p={1}
                            mb={5}
                            textAlign="center"
                            sx={{ backgroundColor: palette.yellowShades.main }}>
                            <Typography variant="modalLabel">{HOME_MESSAGE.CORPORATE}</Typography>
                        </Box>
                )}

                {profile?.role === ROLES?.CORPORATE &&
                    restrictionValues?.profileStatus === PROFILESTATUS?.REJECTED?.id && (
                        <Box p={1} mb={5} textAlign="center" sx={{ backgroundColor: '#ED2939' }}>
                            <Typography
                                variant="modalLabel"
                                sx={{ color: 'white', fontWeight: 800 }}>
                                Your account needs some improvements, before we can approve it.
                                <Button
                                    variant="link"
                                    sx={{
                                      background: '#ED2939',
                                      marginTop: '-2px',
                                      marginRight: '5px',
                                      marginLeft: '5px'
                                    }}
                                    onClick={handleOpenRejectionModal}>
                                    Click here
                                </Button>
                                to see the notes
                            </Typography>
                        </Box>
                )}

                {!restrictionValues?.isKycCompleted &&
                    restrictionValues?.userKycStatus === USERKYCSTATUS.NOT_SUBMITTED &&
                    profile?.role !== ROLES?.CORPORATE && (
                        <Box
                            p={1}
                            textAlign="center"
                            sx={{ backgroundColor: palette.yellowShades.main }}>
                            <Typography variant="modalLabel">
                                Please activate your profile by doing DigiLocker KYC. &nbsp;
                            </Typography>
                            <Link className="text-link" onClick={() => setOpenPanModal(true)}>
                                Click here
                            </Link>
                        </Box>
                )}
                {!restrictionValues?.isKycCompleted &&
                    (restrictionValues?.userKycStatus === USERKYCSTATUS.SUBMITTED ||
                        restrictionValues?.userKycStatus === USERKYCSTATUS.REVISION_SUBMITTED) &&
                    profile?.role !== ROLES?.CORPORATE && (
                        <Box
                            p={1}
                            textAlign="center"
                            sx={{ backgroundColor: palette.yellowShades.main }}>
                            <Typography variant="modalLabel">
                                Your pan request is under review. &nbsp;
                            </Typography>
                        </Box>
                )}
                {!restrictionValues?.isKycCompleted &&
                    restrictionValues?.userKycStatus === USERKYCSTATUS.REJECTED &&
                    profile?.role !== ROLES?.CORPORATE && (
                        <Box p={1} textAlign="center" sx={{ backgroundColor: palette.error.light }}>
                            <Typography variant="modalLabel" sx={{ color: 'white' }}>
                                This PAN No. is already used&nbsp;
                            </Typography>
                            <Link className="text-link" onClick={() => setOpenPanModal(true)}>
                                Retry here
                            </Link>
                        </Box>
                )}
                {!restrictionValues?.isKycCompleted &&
                    restrictionValues?.userKycStatus === USERKYCSTATUS.PAN_NOT_FOUND &&
                    profile?.role !== ROLES?.CORPORATE && (
                        <Box p={1} textAlign="center" sx={{ backgroundColor: palette.error.light }}>
                            <Typography variant="modalLabel" sx={{ color: 'white' }}>
                                We did not find your PAN in DigiLocker. Please connect your PAN with
                                DigiLocker&nbsp;
                            </Typography>
                            <Link className="text-link" onClick={() => setOpenPanModal(true)}>
                                Retry here
                            </Link>
                        </Box>
                )}
                <Grid
                    sx={{ background: palette.secondary.light }}
                    className="dashboard"
                    container
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    m={3}>
                    <Grid item xs={12} textAlign="center" zeroMinWidth>
                        <Typography variant="labelLarge" sx={{ wordWrap: 'break-word' }}>
                            Welcome to DIGIDOC Home
                        </Typography>
                    </Grid>
                </Grid>

                {openPanModal && (
                    <PanModal
                        openPanModal={openPanModal}
                        handlePanModalClose={() => {
                          toast.error(errormessages.PANNUMBERMANDATORY)
                          setOpenPanModal(false)
                        }}
                    />
                )}

                {rejectionNotesModal && (
                    <NotesModal
                        notesModalState={rejectionNotesModal}
                        handleNotesModalClose={() => {
                          setRejectionNotesModal({
                            open: false,
                            message: ''
                          })
                        }}
                    />
                )}

                {/* {kycGuideModal?.open && (
                    <CommonMessageButtonModal
                        handleOkButtonCommonModal={() => {
                          setkycGuideModal((prev) => ({
                            ...prev,
                            open: false
                          }))
                          setdigiLockerInfoModal((prev) => ({
                            ...prev,
                            open: true
                          }))
                        }}
                        handleCancelButton={() => {
                          setkycGuideModal((prev) => ({
                            ...prev,
                            open: false
                          }))
                        }}
                        CommonMessageModalState={kycGuideModal}
                    />
                )} */}

                {openTermsModal && (showDisclaimer || showTerms || showPrivacy) && (
                    <TermsCheckModal
                        openTermsModal={openTermsModal}
                        handleTermsModalClose={handleTermsModalClose}
                        showDisclaimer={showDisclaimer}
                        showTerms={showTerms}
                        showPrivacyPolicy={showPrivacy}
                    />
                )}

                {/* {digiLockerInfoModal?.open && (
                    <DigiLockerModal
                        handleCancelButton={() => {
                          setdigiLockerInfoModal((prev) => ({
                            ...prev,
                            open: false
                          }))
                        }}
                        handleOkButtonCommonModal={() => {
                          setdigiLockerInfoModal((prev) => ({
                            ...prev,
                            open: false
                          }))
                          setOpenPanModal(true)
                        }}
                        CommonMessageModalState={digiLockerInfoModal}
                    />
                )} */}
            </Box>
        </>
  )
}
