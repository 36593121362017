import React, { useContext } from 'react'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { Box } from '@mui/material'
import IndividualMyDocuments from '../MyDocumentIndvidual&Ba/IndividualMyDocument/IndividualMyDocuments'
import IndividualTransaction from '../MyDocumentsCorporate/IndividualTransaction'
import { USER_ROLE } from '../../../constants/enum'

const CommonIndividualScreen = () => {
  // Profile context api
  const { profile } = useContext(ProfileContext)

  return (
        <Box>
            {profile?.role === USER_ROLE.CORPORATE.toUpperCase() ? (
                <>
                    <IndividualTransaction />
                </>
            ) : (
                <>
                    <IndividualMyDocuments />
                </>
            )}
        </Box>
  )
}

export default CommonIndividualScreen
