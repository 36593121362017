import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../Header/Header'
import palette from '../../theme/palette'

const drawerWidth = 265

function ResponsiveDrawer (props) {
  const { window, childrenComponent } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const container = window !== undefined ? () => window().document.body : undefined

  return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                  ml: { sm: `${drawerWidth}px` }
                }}>
                <Toolbar
                    sx={{
                      borderBottom: `0.5px solid ${palette.text.light}`,
                      display: 'flex',
                      alignItems: 'flex-start'
                    }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 1, ml: 1, color: palette.primary.dark, display: { sm: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Header />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }} // Better open performance on mobile.
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}>
                    <Sidebar />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                    open>
                    <Sidebar />
                </Drawer>
            </Box>
            <Box p={3} sx={{ flexGrow: 1, width: { xs: `calc(100% - ${drawerWidth}px)` } }}>
                <Toolbar />
                {childrenComponent}
            </Box>
        </Box>
  )
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
  childrenComponent: PropTypes.object
}
export default ResponsiveDrawer
