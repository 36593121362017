import React, { useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, Typography } from '@mui/material'
import cross from '../../../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../../../../theme/palette'
import { MDRTERM } from '../../../../../constants/enum'
// import { Link } from 'react-router-dom'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const MakePaymentModal = ({
  openMakePaymentModal,
  handleMakePaymentModalClose,
  handleSubmitMakePaymentModalButton,
  MakePaymentModalState
}) => {
  const [termSelectedState, setTermSelecetedState] = useState({})

  // const termsArray = [
  //   {
  //     termType: TERMTYPES.TERMSANDCONDITIONS.title,
  //     termId: TERMTYPES.TERMSANDCONDITIONS.value
  //   },
  //   {
  //     termType: TERMTYPES.DISCLAIMER.title,
  //     termId: TERMTYPES.DISCLAIMER.value
  //   }
  // ]

  const handleSubmit = () => {
    handleSubmitMakePaymentModalButton()
  }
  return (
        <Modal
            open={openMakePaymentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <form
                    onSubmit={() => {
                      handleSubmit()
                    }}>
                    <Grid
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleMakePaymentModalClose}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </Grid>
                    <Grid p={2}>
                        <Typography
                            variant="labelLarge"
                            component="div"
                            sx={{ fontSize: '25px', marginBottom: '10px', fontWeight: 600 }}>
                            {MakePaymentModalState?.title}
                        </Typography>

                        <Grid mb={1} mt={2}>
                            <Typography variant="darkValueLarge">
                                {MakePaymentModalState?.message}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="darkValueLarge">
                                {MakePaymentModalState?.walletBalance}
                            </Typography>
                        </Grid>
                        <Grid mb={3} mt={2}>
                            <FormControlLabel
                                sx={{ mr: 0, marginBottom: 0, width: '100%' }}
                                key={MDRTERM.MDRCHARGES.title}
                                control={
                                    <Checkbox
                                        sx={{
                                          color: palette.text.main,
                                          '&.Mui-checked': {
                                            color: palette.success.dark
                                          }
                                        }}
                                        checked={termSelectedState[`${MDRTERM.MDRCHARGES.title}`]}
                                        onChange={(event) =>
                                          setTermSelecetedState((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.checked
                                          }))
                                        }
                                        required
                                        name={MDRTERM.MDRCHARGES.title}
                                        id={MDRTERM.MDRCHARGES.title}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="modalLabel"
                                        sx={{ color: palette.text.dark }}>
                                        I agree &nbsp;{MDRTERM.MDRCHARGES.value}
                                    </Typography>
                                }
                            />
                            {/* {termsArray.map((item) => (
                                <FormControlLabel
                                    sx={{ mr: 0, marginBottom: 0, width: '100%' }}
                                    key={item.termType}
                                    control={
                                        <Checkbox
                                            sx={{
                                              color: palette.text.main,
                                              '&.Mui-checked': {
                                                color: palette.success.dark
                                              }
                                            }}
                                            checked={termSelectedState[`${item.termId}`]}
                                            onChange={(event) =>
                                              setTermSelecetedState((prev) => ({
                                                ...prev,
                                                [event.target.name]: event.target.checked
                                              }))
                                            }
                                            required
                                            name={item.termId}
                                            id={item.termId}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="modalLabel"
                                            sx={{ color: palette.text.dark }}>
                                            I agree with &nbsp;
                                            <Link
                                                className="text-link"
                                                to={`/termsAndConditions/${item.termId}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {item.termType}.
                                            </Link>
                                        </Typography>
                                    }
                                />
                            ))} */}
                        </Grid>

                        <Grid display="flex" gap={2}>
                            <Grid>
                                <Button
                                    variant="cancelButton"
                                    onClick={handleMakePaymentModalClose}>
                                    {MakePaymentModalState?.cancelButtonText}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="primary" type="submit">
                                    {MakePaymentModalState?.submitButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
  )
}

export default MakePaymentModal

MakePaymentModal.propTypes = {
  openMakePaymentModal: PropTypes.bool,
  handleMakePaymentModalClose: PropTypes.func,
  handleSubmitMakePaymentModalButton: PropTypes.func,
  MakePaymentModalState: PropTypes.object
}
