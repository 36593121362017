import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
// import palette from "../../.theme/palette";
import PropTypes from 'prop-types'
import palette from '../../theme/palette'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const CommonMessageButtonModal = ({
  CommonMessageModalState,
  handleOkButtonCommonModal,
  handleCancelButton
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Prevent the default behavior and handle the key event
      event.preventDefault()
      handleCancelButton()
    }
  }

  return (
        <Modal
            open={CommonMessageModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyDown={handleKeyDown}>
            <Box sx={style}>
                {/* <form
                    onSubmit={(event) => {
                      console.log('hi im there')
                      event.preventDefault()
                      handleCancelButton()
                    }}> */}
                <Grid p={2}>
                    <Typography
                        variant="labelMedium"
                        component="div"
                        mb={3}
                        sx={{ fontSize: '22px', lineHeight: '31px' }}>
                        {CommonMessageModalState?.title}
                    </Typography>

                    <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '20px', lineHeight: '31px' }}>
                            {CommonMessageModalState?.message}
                        </Typography>
                    </Grid>
                    <input type="text" hidden></input>
                    <Grid display="flex" gap={2}>
                        <Grid>
                            <Button
                                variant="cancelButton"
                                type="submit"
                                onClick={handleCancelButton}>
                                {CommonMessageModalState.cancelButtonText}
                            </Button>
                        </Grid>
                        <Grid>
                            <Button variant="primary" onClick={handleOkButtonCommonModal}>
                                {CommonMessageModalState.submitButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </form> */}
            </Box>
        </Modal>
  )
}

export default CommonMessageButtonModal

CommonMessageButtonModal.propTypes = {
  handleOkButtonCommonModal: PropTypes.func,
  handleCancelButton: PropTypes.func,
  CommonMessageModalState: PropTypes.object
}
