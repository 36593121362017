/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import search from '../../assets/images/searchicon.svg'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField
} from '@mui/material'
import { PLACEHOLDER } from '../../constants/placeholder'
// import DocumentsTable from './components/DocumentsTable/DocumentsTable'
import DateRangeComponent from '../../components/DateRange/DateRange'
import calendericon from '../../assets/images/calendericon.svg'
import { useGetDocumentFilterStatusQuery } from '../../store/services/documentServices'
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles'
import palette from '../../theme/palette'
// import { getSession } from '../../util/helper'
// import MyDocumentCorporate from './MyDocumentsCorporate/MyDocumentCorporate'
import moment from 'moment'
import InvoiceTable from './components/InvoiceTable/InvoiceTable'
import {
  useGenerateClubbedInvoiceMutation,
  useGetAllInvoiceQuery
} from '../../store/services/userServices'
import { PAGINATIONINITIALS, ROLES } from '../../constants/enum'
import { toast } from 'react-toastify'
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader'
import { ProfileContext } from '../../contextapi/ProfileContext'
const Invoice = () => {
  // Filter initial object
  const filterObj = {
    status: 1,
    limit: 100,
    search: '',
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
  }
  const { profile } = useContext(ProfileContext)
  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  const [backdropState, setBackdropState] = useState(false)

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  // eslint-disable-next-line no-unused-vars
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ])

  // Filter state
  const [filter, setFilter] = useState(filterObj)

  // const [showCalander, setShowCalander] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Get all document data
  const { data: { list = [], totalCount = 0 } = {}, isLoading } = useGetAllInvoiceQuery({
    page: paginationFilter?.page,
    size: paginationFilter?.rowsPerPage
    // status: filter?.status,
    // search: filter?.search,
    // fromDate: !filter?.dateRange[0]?.skip
    //   ? moment(filter?.dateRange[0]?.startDate).format('YYYY-MM-DD')
    //   : '',
    // toDate: !filter?.dateRange[0]?.skip
    //   ? moment(filter?.dateRange[0]?.endDate).format('YYYY-MM-DD')
    //   : ''
  })

  // Generate Clubbed Invoice
  const [generateInvoice] = useGenerateClubbedInvoiceMutation({})

  // const toggleCalender = () => {
  //   setShowCalander((current) => !current)
  // }

  /**
     * Handle filter status change
     * @param {object} event: Event object
     */
  const handleStatusChange = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  /**
     * Handle filter date range change
     * @param {array} item: date range array
     */
  const dateRangeHandler = (item) => {
    const dateRangeValue = [
      {
        startDate: item[0]?.startDate,
        endDate: item[0]?.endDate,
        key: 'selection',
        skip: false
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
  }

  /**
     * Handle filter search change
     * @param {object} event: Event object
     */
  const handleSearchTextfield = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }

  /**
     * Handle Generate Invoice Button Click
     */
  const handleGenerateInvoiceClick = () => {
    setBackdropState(true)
    generateInvoice()
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
      })
      .catch((exception) => {
        setBackdropState(false)
        console.log(exception)
      })
  }

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <>
                {/* <Grid
                    container
                    spacing={3}
                    marginBottom={3}
                    direction="row"
                    justifyContent={'center'}
                    alignItems="center">
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Select
                            name="status"
                            value={filter?.status}
                            onChange={handleStatusChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                              fontSize: '16px'
                            }}
                            MenuProps={MenuProps}
                            fullWidth>
                            {documentStatusArray &&
                                documentStatusArray.map((item, index) => (
                                    <MenuItem key={index} value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div className="date-range-input-container">
                            <TextField
                                aria-describedby={id}
                                onClick={handleClick}
                                type="text"
                                value={
                                    !filter?.dateRange[0].skip
                                      ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                      : 'Date Range'
                                }
                                readOnly
                                fullWidth
                            />
                            <img src={calendericon} alt="calender" className="calendar-icon" />
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}>
                            <DateRangeComponent
                                maxDate={new Date()}
                                dateRange={filter?.dateRange}
                                setDateRange={dateRangeHandler}
                            />
                        </Popover>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <TextField
                            value={filter?.search}
                            name="search"
                            id="search"
                            placeholder={PLACEHOLDER.SEARCHDOCUMENT}
                            onChange={handleSearchTextfield}
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              style: {
                                borderRadius: '0px'
                              },
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility">
                                            <img src={search} alt="search" />
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                        />
                        <Button
                            variant="primary"
                            sx={{
                              borderRadius: '1px',
                              padding: '5px',
                              background: palette.text.main,
                              '&:hover': {
                                background: palette.text.main
                              }
                            }}>
                            Go
                        </Button>
                    </Grid>
                </Grid> */}
                {profile?.role !== ROLES.INDIVIDUAL ||
                    profile?.role !== ROLES.BUSINESS_ASSOCIATE ||
                    (profile?.role !== ROLES.CORPORATE && (
                        <Grid sx={{ marginBottom: 5 }}>
                            <Button
                                variant="primary"
                                sx={{ padding: 2 }}
                                onClick={() => {
                                  handleGenerateInvoiceClick()
                                }}>
                                Generate Invoice
                            </Button>
                        </Grid>
                    ))}
                <InvoiceTable
                    invoiceTableData={list}
                    isLoading={isLoading}
                    paginationFilter={paginationFilter}
                    setPaginationFilter={setPaginationFilter}
                    totalCount={totalCount}
                />
            </>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default Invoice
