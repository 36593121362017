import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BankAccountModal from '../../../components/BankAccountModal/BankAccountModal'
// import { useGetProfileQuery } from '../../../store/services/userServices'
import emailIcon from '../../../assets/actions/actionEmail.svg'
import notVerifiedIcon from '../../../assets/images/notverifiedIcon.svg'
import Root from '../../../StyledComponents/DividerStyles/DividerStyles'

import {
  useAddbankAccountDetailsMutation,
  useGetProfileQuery
} from '../../../store/services/profleServices'
import { toast } from 'react-toastify'
import { useLazyResendEmailQuery } from '../../../store/services/userServices'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import { BUSINESSCONFIGURATION } from '../../../constants/configurationConstant'

const MyProfileBusiness = () => {
  const navigate = useNavigate()

  // Bank Account modal labels
  const bankAccountModalState = {
    label: 'Add Bank Account',
    button: 'Add',
    type: 'add'
  }

  // Bank modal state
  const [openBankModal, setOpenBankModal] = useState(false)

  // Backdrop loader state
  const [backdropState, setbackdropState] = useState(false)

  // Bank account  form state
  const [bankAccountDetails, setBankAccountDetails] = useState({
    accountType: '',
    errors: {
      ifscCode: 'error',
      accountNumber: 'error',
      confirmAccountNumber: 'error'
    }
  })

  // Resend Email Query
  const [resendEmail] = useLazyResendEmailQuery()

  // Profile data Query
  const { data: profileData = {} } = useGetProfileQuery({})

  // Profile restrictions for business staff
  const profileRestrictionConfiguration = BUSINESSCONFIGURATION[`${profileData?.subRole}`]

  // Add bank account mutation
  const [AddbankAccountDetails] = useAddbankAccountDetailsMutation({})

  // Hanlde update profile button
  const handleEdit = () => {
    navigate('/myProfileBusiness/editProfile', {
      state: {
        HeaderTitle: 'Edit Profile'
      }
    })
  }

  // Handle submit bank account modal
  const handleSubmitBankModal = () => {
    console.log('bankAccountDetails', bankAccountDetails)
    const dataToSend = {
      bankName: bankAccountDetails?.bankName,
      accountNumber: bankAccountDetails?.accountNumber,
      accountHolder: bankAccountDetails?.accountHolderName,
      ifscCode: bankAccountDetails?.ifscCode,
      accountType: bankAccountDetails?.accountType
    }
    if (bankAccountDetails?.errors?.ifscCode === 'noerror') {
      AddbankAccountDetails({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          toast.success(response?.message)
          setOpenBankModal(false)
          setBankAccountDetails({
            accountType: '',
            errors: {
              ifscCode: 'error',
              accountNumber: 'error',
              confirmAccountNumber: 'error'
            }
          })
        })
        .catch((execption) => {
          console.log('exception:ACCOUNT ADD:FAIL', execption)
          // toast.error(execption.message)
        })
    } else {
      toast.error('Please fix all errors')
    }
    // api integration for saving bank account details
  }

  // Resend email for verification
  const handleResendEmail = () => {
    setbackdropState(true)
    resendEmail(profileData?.email)
      .unwrap()
      .then((response) => {
        setbackdropState(false)
        toast.success(response?.message)
      })
      .catch((exception) => {
        setbackdropState(false)
        console.log('EXCEPTION:RESEND EMAIl', exception)
      })
  }

  // handle bank account modal close
  const handleBankAccountModalClose = () => {
    setBankAccountDetails({
      accountType: '',
      errors: {
        ifscCode: 'error',
        accountNumber: 'error',
        confirmAccountNumber: 'error'
      }
    })
    setOpenBankModal(false)
  }

  return (
        <Box p={3}>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Name (as per Aadhar Card)
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Grid container alignItems="center">
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', marginRight: '10px' }}>
                            Email address
                        </Typography>
                        {!profileData?.isEmailVerified && (
                            <>
                                <img
                                    src={notVerifiedIcon}
                                    alt="notVerified"
                                    height="20px"
                                    title="Email not verified"
                                    style={{ marginRight: '10px', marginTop: '-10px' }}
                                />
                                <img
                                    src={emailIcon}
                                    alt="resendEmail"
                                    height="24px"
                                    title="Resend email"
                                    style={{ marginTop: '-10px', cursor: 'pointer' }}
                                    onClick={handleResendEmail}
                                />
                            </>
                        )}
                    </Grid>
                    <Typography variant="modalLabel">{profileData?.email}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Phone Number
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.phone ? '+91-' : ''}
                        {profileData?.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container spacing={5} mb={3}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Company address
                    </Typography>
                    <Typography variant="modalLabel" sx={{ wordBreak: 'break-all' }}>
                        {profileData?.address}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        State
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.state?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        City
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.city?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pin code
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.pinCode}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={5} mb={3}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Bank account
                    </Typography>
                    {profileData?.bankAccount ? (
                        <Typography variant="modalLabel">
                            {profileData?.bankAccount || 'NA'}
                        </Typography>
                    ) : (
                      profileRestrictionConfiguration?.profile?.isbankAccount && (
                            <Link className="text-link" onClick={() => setOpenBankModal(true)}>
                                Add account
                            </Link>
                      )
                    )}
                </Grid>
            </Grid>
            {profileRestrictionConfiguration?.profile?.isEditable && (
                <Grid>
                    <Button variant="primary" onClick={handleEdit}>
                        Edit info
                    </Button>
                </Grid>
            )}
            {openBankModal && (
                <BankAccountModal
                    bankAccountDetails={bankAccountDetails}
                    setBankAccountDetails={setBankAccountDetails}
                    bankAccountModalState={bankAccountModalState}
                    openBankModal={openBankModal}
                    handleBankModalClose={() => handleBankAccountModalClose()}
                    handleSubmitBankModal={handleSubmitBankModal}
                />
            )}

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default MyProfileBusiness
