import React, { useState } from 'react'
import { Button, Grid, Typography, Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
// import NormalTextField from '../../../../components/InputFields/NormalTextField'
// import DateTextField from '../../../../components/InputFields/DateTextField'
// import SelectDropdown from '../../../../components/InputFields/SelectDropdown'
// import SelectTextField from '../../../../components/InputFields/SelectTextField'
// import RadiowithTextfield from '../../../../components/InputFields/RadiowithTextfiled'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '../../../../StyledComponents/AccordianStyles/AccordionStyles'
import { useGetBulkDocumentIndividualDetailsByIdQuery } from '../../../../store/services/documentServices'
// import PreviewDocument from '../../../CreateDocument/UploadFlow/PreviewDocument/PreviewDocument'
import CommonTableReview from '../../../../components/CommonTableReview/CommonTableReview'
import PreviewIndividualBulkDocument from '../PreviewIndividualBulkDocument/PreviewIndividualBulkDocument'

const ViewBulkIndividualDocumentDetail = (props) => {
  // Navigate
  const navigate = useNavigate()

  // DOcument id by params
  const { documentId } = useParams()

  // state for accordian expand
  const [expanded, setExpanded] = useState(0)

  // Get document sections and fields
  const { data: documentDataResponse } = useGetBulkDocumentIndividualDetailsByIdQuery(
    documentId,
    {
      skip: !documentId
    }
  )

  // const [documentForm, setDocumentForm] = useState({
  //   form: {},
  //   errors: {}
  // })

  // Handle accordian expand state
  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : 0)
  }

  // // Handle accordian Arrow expand state
  // const handleExpandArrow = (currentSection) => {
  //   console.log('currentSection', currentSection, expanded)
  //   if (currentSection < expanded) {
  //     setExpanded(currentSection)
  //   }
  // }

  // // Expand Previous accordian
  // const handleExpandPrev = (currentSection) => {
  //   setExpanded(currentSection - 1)
  // }

  // /**
  //    * Handle next button  accordian state and check the validations and submit document draft
  //    * @param {number} index :current accordian open index
  //    */
  // const handleNextAccord = async (index) => {
  //   setExpanded(index + 1)
  //   const documentDraftData = []
  //   for (const [key, value] of Object.entries(documentForm?.form)) {
  //     documentDraftData.push({
  //       fieldId: Number(key),
  //       fieldValue: value
  //     })
  //   }
  // }

  /**
     * Handle form changes , if value is empty replace span with dash(_____)
     * @param {object} event :event object
     */
  // const handleDocumentFormChange = (event, fieldData) => {
  //   setDocumentForm((prev) => ({
  //     ...prev,
  //     form: {
  //       ...prev.form,
  //       [fieldData?.id]: event.target.value
  //     }
  //   }))
  // }

  // const handleDocumentFocusChange = () => {
  //   console.log('in focus')
  // }

  return (
        <div className="createdocument_container">
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button variant="cancelButton" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={6}>
                    {/* <Box sx={{ marginTop: '20px' }} mb="20px">
                        {documentDataResponse?.sections?.map((section, sectionIndex) => (
                            <Accordion
                                key={sectionIndex}
                                expanded={expanded === sectionIndex}
                                onChange={() => handleExpandArrow(sectionIndex)}
                                sx={{ marginBottom: '20px' }}>
                                <div className="toggle">
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header">
                                        <Typography
                                            variant={
                                                expanded === sectionIndex
                                                  ? 'darkValueLarge'
                                                  : 'darkValue'
                                            }>
                                            {section?.titleText}
                                        </Typography>
                                    </AccordionSummary>
                                </div>

                                <AccordionDetails>
                                    <Typography>
                                        <div style={{ padding: '30px' }}>
                                            {documentDataResponse?.sections[expanded]?.fields.map(
                                              (field, fieldindex) => (
                                                    <>
                                                        {field?.componentName === 'text' && (
                                                            <NormalTextField
                                                                key={field?.subFields[0]?.id}
                                                                textFieldData={field?.subFields[0]}
                                                                fieldLabel={field?.titleText}
                                                                onchange={handleDocumentFormChange}
                                                                onfocus={handleDocumentFocusChange}
                                                                documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'date' && (
                                                            <DateTextField
                                                                textFieldData={field?.subFields[0]}
                                                                key={field?.subFields[0]?.id}
                                                                fieldLabel={field?.titleText}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'select' && (
                                                            <SelectDropdown
                                                                key={field?.subFields[0]?.id}
                                                                selectData={field?.subFields[0]}
                                                                fieldLabel={field?.titleText}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'select-text' && (
                                                            <SelectTextField
                                                                key={field?.subFields[0]?.id}
                                                                fieldLabel={field?.titleText}
                                                                selectData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'dropdown'
                                                                )}
                                                                textFieldData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'text' ||
                                                                        field.type === 'number'
                                                                )}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'radio-text' && (
                                                            <RadiowithTextfield
                                                                key={field?.subFields[0]?.id}
                                                                radioData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type ===
                                                                        'radio_button'
                                                                )}
                                                                textFieldData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'text' ||
                                                                        field.type === 'number'
                                                                )}
                                                                fieldLabel={field?.titleText}
                                                                // documentForm={documentForm}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                            />
                                                        )}
                                                    </>
                                              )
                                            )}

                                            <div className="toggle-nextButton">
                                                {sectionIndex !== 0 && (
                                                    <Button
                                                        variant="primary"
                                                        onClick={() =>
                                                          handleExpandPrev(sectionIndex)
                                                        }>
                                                        Previous
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleNextAccord(sectionIndex)}>
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box> */}

                    {/* Consent table */}
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Accordion
                            expanded={expanded === documentDataResponse?.sections?.length}
                            onChange={handleExpandChange(documentDataResponse?.sections?.length)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography
                                        variant={
                                            expanded === documentDataResponse?.sections?.length
                                              ? 'darkValueLarge'
                                              : 'darkValue'
                                        }>
                                        Review Details
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    <CommonTableReview
                                        tableData={documentDataResponse?.recipientDetailList}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>

                <Grid lg={6} item>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Grid
                            className="details_rightgrid-box"
                            sx={{ maxHeight: 'calc(100vh - 14rem)' }}>
                            <PreviewIndividualBulkDocument documentId={documentId} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </div>
  )
}

export default ViewBulkIndividualDocumentDetail
