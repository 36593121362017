import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Button, Modal, Box, TextField } from '@mui/material'
import OtpIcon from '../../../../../assets/images/verifyOtp.svg'
import OtpInput from 'react18-input-otp'
import verifiedIcon from '../../../../../assets/images/verified.svg'
import { toast } from 'react-toastify'
import { EXCEPTTHISSYMBOLS } from '../../../../../constants/enum'
import {
  useLazyResendOtpQuery,
  useLazyVerifyOtpQuery,
  useUpdateNumberMutation
} from '../../../../../store/services/userServices'
import errormessages from '../../../../../constants/errormessages'
import palette from '../../../../../theme/palette'

// modal box style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 550,
  background: '#FFFFFF',
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '3px',
  p: 4
}

const OtpScreen = ({
  counter,
  setCounter,
  openOtpModal,
  handleOtpClose,
  phoneNumberSignup,
  code,
  setCode,
  handleverifiedContinue
}) => {
  // const navigate = useNavigate()
  const [enableResendOTP, setEnableResendOTP] = useState(false)
  const [phoneVerified, setphoneVerified] = useState(false)
  const [editNumber, setEditNumber] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberSignup)
  const [phoneNumberError, setPhoneNumberError] = useState('noerror')
  const secondsToDisplay = counter % 60
  const [verifyOtp] = useLazyVerifyOtpQuery()
  const [resendOtp] = useLazyResendOtpQuery()

  // const [resendOtp, { resendData }] = useResendOtpMutation({})
  // const [verifyOtp, { verifyData }] = useVerifyOtpMutation({})
  const [updateNumber] = useUpdateNumberMutation({})
  useEffect(() => {
    console.log('i her')
    setPhoneNumber(phoneNumberSignup)
  }, [phoneNumberSignup])

  const handleEditNumber = () => {
    setEditNumber(true)
    // handleOtpClose()
    setCode('')
  }

  // Handle Edit new number
  const handleSubmitEditNumber = () => {
    if (phoneNumberError === 'noerror') {
      updateNumber(phoneNumber)
        .unwrap()
        .then((response) => {
          setEditNumber(false)
          setCode('')
          toast.success(response.message)
        })
        .catch((execption) => {
          console.log('error')
        })
    }
  }

  // For resend otp
  const twoDigits = (num) => String(num).padStart(2, '0')

  useEffect(() => {
    if (counter === 0) setEnableResendOTP(true)
    else setEnableResendOTP(false)
    const timer =
            counter > 0 &&
            setInterval(() => {
              setCounter(counter - 1)
            }, 1000)
    return () => clearInterval(timer)
  }, [counter])

  const resendOTP = () => {
    // const dataToSend = {
    //   phoneNumber
    // }
    resendOtp(phoneNumber)
      .unwrap()
      .then((response) => {
        toast.success(response.message)
        setCode('')
        setCounter(30)
      })
      .catch((execption) => {
        console.log('error')
        // toast.error(execption.message)
      })
  }

  const exceptThisSymbols = EXCEPTTHISSYMBOLS

  // For otp verification
  const handleOtpChange = (code) => setCode(code)

  const handelVerify = () => {
    if (code.length === 6) {
      verifyOtp(code)
        .unwrap()
        .then((response) => {
          toast.success(response.message)
          setphoneVerified(true)
        })
        .catch((execption) => {
          console.log('execption:OTP VERIFICATION')
        })
    } else {
      toast.error('please fill 6 digit otp')
    }
  }

  /**
     * Handle phone number field validations
     * @param {object} event:Event object
     */
  const handleEditNewPhone = (event) => {
    setPhoneNumber(event.target.value)
    if (event.target.value === '') setPhoneNumberError(errormessages.PHONEREQUIRED)
    else if (event.target.value.toString().length < 10) {
      setPhoneNumberError(errormessages.PHONEMINLEN)
    } else setPhoneNumberError(errormessages.NOERROR)
  }

  return (
        <div>
            <Modal
                open={openOtpModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    {!phoneVerified && !editNumber && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} marginBottom={3}>
                                <img src={OtpIcon} alt="Otp" />
                            </Grid>
                            <Grid item lg={12} marginBottom={2}>
                                <Typography variant="labelLarge">
                                    Code sent, verify your number
                                </Typography>
                            </Grid>
                            <Grid item lg={12}>
                                <Typography variant="modalLabelBold" sx={{ alignItems: 'center' }}>
                                    We have sent a 6-digit verification code to your phone
                                </Typography>
                            </Grid>
                            <Grid item lg={12} marginBottom={3}>
                                <Typography variant="modalLabelBold">
                                    number ending with ** {phoneNumber?.toString().slice(8)}
                                </Typography>
                            </Grid>
                            <Grid item lg={12} marginBottom={4}>
                                <OtpInput
                                    value={code}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    separator={
                                        <span
                                            style={{
                                              width: '32px',
                                              marginLeft: '2px',
                                              fontWeight: 'bold'
                                            }}></span>
                                    }
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                      boxSizing: 'border-box',
                                      backgroundColor: palette.primary.light,
                                      border: `1px solid ${palette.secondary.dark}`,
                                      borderRadius: '0px',
                                      width: '50.17px',
                                      height: '45px',
                                      fontSize: '18px',
                                      color: palette.text.main,
                                      fontWeight: '600',
                                      caretColor: 'black'
                                    }}
                                    focusStyle={{
                                      width: '50.17px',
                                      height: '45px',
                                      border: `1px solid ${palette.secondary.dark}`,
                                      outline: 'none',
                                      fontWeight: '600'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} marginBottom={3}>
                                <Button
                                    variant="primary"
                                    sx={{
                                      width: '474px'
                                    }}
                                    onClick={handelVerify}>
                                    Verify number
                                </Button>
                            </Grid>
                            <Grid item xs={12} marginBottom={5}>
                                <Button
                                    variant="cancelButton"
                                    disabled={!enableResendOTP}
                                    onClick={resendOTP}
                                    sx={{
                                      width: '474px'
                                    }}>
                                    Resend code
                                    {counter !== 0 && (
                                        <div
                                            style={{
                                              position: 'relative',
                                              left: '5px',
                                              fontWeight: 500
                                            }}>
                                            {` in ${twoDigits(secondsToDisplay)}sec`}
                                        </div>
                                    )}
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="modalLabel">
                                    In case want to edit your number.
                                    <Button
                                        sx={{
                                          color: palette.editNumber.main,
                                          textDecoration: 'underline',
                                          textTransform: 'capitalize',
                                          fontSize: '16px'
                                        }}
                                        onClick={handleEditNumber}>
                                        Edit Number
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {editNumber && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} marginBottom={3}>
                                <img src={OtpIcon} alt="Otp" />
                            </Grid>
                            <Grid item lg={12} marginBottom={2}>
                                <Typography variant="labelLarge">Enter your number</Typography>
                            </Grid>

                            <form
                                onSubmit={(event) => {
                                  event.preventDefault()
                                  handleSubmitEditNumber()
                                }}>
                                <Grid item lg={12} marginBottom={4}>
                                    <TextField
                                        required
                                        error={
                                            !(
                                              phoneNumberError === 'error' ||
                                                phoneNumberError === 'noerror'
                                            )
                                        }
                                        helperText={
                                            phoneNumberError === 'error' ||
                                            phoneNumberError === 'noerror'
                                              ? ''
                                              : phoneNumberError
                                        }
                                        type="number"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={handleEditNewPhone}
                                        value={phoneNumber}
                                        style={{ width: '474px' }}
                                        onKeyDown={(e) =>
                                          exceptThisSymbols.includes(e.key) && e.preventDefault()
                                        }
                                        onInput={(e) => {
                                          e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10)
                                        }}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} marginBottom={3}>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        sx={{
                                          width: '474px'
                                        }}>
                                        Submit
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    )}

                    {phoneVerified && (
                        <Grid
                            sx={{
                              paddingTop: '60px'
                            }}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12} marginBottom={3}>
                                <img src={verifiedIcon} alt="verifiedIcon" />
                            </Grid>
                            <Grid item xs={12} marginBottom={3}>
                                <Typography variant="labelLarge">Phone number verified</Typography>
                            </Grid>
                            <Grid item xs={12} marginBottom={1}>
                                <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                                    Congratulations, your phone number
                                </Typography>
                            </Grid>
                            <Grid item xs={12} marginBottom={5}>
                                <Typography variant="modalLabel">
                                    is verified successfully
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="primary"
                                    sx={{
                                      width: '430px'
                                    }}
                                    onClick={handleverifiedContinue}>
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Modal>
        </div>
  )
}

export default OtpScreen

OtpScreen.propTypes = {
  counter: PropTypes.number,
  setCounter: PropTypes.func,
  code: PropTypes.string,
  setCode: PropTypes.func,
  openOtpModal: PropTypes.bool,
  handleOtpClose: PropTypes.func,
  phoneNumberSignup: PropTypes.number,
  handleverifiedContinue: PropTypes.func
}
