import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import WalletToBankTable from './WalletToBankTable'
import PushMoneyModal from '../PushMoneyModal/PushMoneyModal'
import {
  useGetTransferRequestToBankQuery,
  usePushMoneyToBankMutation
} from '../../../../store/services/walletTransactionServices'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import { PAGINATIONINITIALS } from '../../../../constants/enum'

const WalletToBank = ({ setSelectedTab, walletBalance }) => {
  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Open push money modal
  const [openPushMoneyModal, setOpenPushMoneyModal] = useState(false)

  const [walletBankForm, setWalletBankForm] = useState({})

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  const { data: { list = [], totalCount = 0 } = {}, isLoading } =
        useGetTransferRequestToBankQuery({
          page: paginationFilter?.page,
          size: paginationFilter?.rowsPerPage
        })

  const [pushMoneyToBank] = usePushMoneyToBankMutation()

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  const handleSubmitPushMoneyModal = () => {
    const dataToSend = {
      walletAmount: walletBankForm?.amountToAdd,
      password: walletBankForm?.password
    }
    setBackdropState(true)
    pushMoneyToBank({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        setWalletBankForm('')
        setOpenPushMoneyModal(false)
        toast.success(response.message)
      })
      .catch((execption) => {
        setBackdropState(false)
      })
    // logic for passsword api auth
  }

  const handlePushMoneyModalClose = () => {
    setWalletBankForm('')
    setOpenPushMoneyModal(false)
  }

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <Grid
                spacing={2}
                container
                mb={3}
                sx={{
                  flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: 'column' }
                }}
                alignItems="center">
                <Grid item lg={6} md={12} xs={12} sm={12}>
                    <Typography variant="darkValueLarge">
                        List of requests for transferring money from wallet to bank
                    </Typography>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: {
                        lg: 'flex-end',
                        md: 'flex-start',
                        sm: 'flex-start',
                        xs: 'flex-start'
                      }
                    }}>
                    <Button variant="cancelButton" onClick={() => setSelectedTab('wallet')}>
                        Back
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => setOpenPushMoneyModal(true)}
                        disabled={walletBalance <= 0}>
                        Create New Request
                    </Button>
                </Grid>
            </Grid>
            <WalletToBankTable
                transferRequestList={list}
                isLoading={isLoading}
                paginationFilter={paginationFilter}
                setPaginationFilter={setPaginationFilter}
                totalCount={totalCount}
            />

            {openPushMoneyModal && (
                <PushMoneyModal
                    walletBankForm={walletBankForm}
                    setWalletBankForm={setWalletBankForm}
                    openPushMoneyModal={openPushMoneyModal}
                    handlePushMoneyModalClose={handlePushMoneyModalClose}
                    handleSubmitPushMoneyModal={handleSubmitPushMoneyModal}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default WalletToBank

WalletToBank.propTypes = {
  setSelectedTab: PropTypes?.func,
  walletBalance: PropTypes?.number
}
