import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import cross from '../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 440,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const BulkSignatureModal = ({
  openBulkSignatureModal,
  handleBulkSignatureModalClose,
  handleSubmitDigitalSignModal
}) => {
  return (
        <Modal
            open={openBulkSignatureModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handleBulkSignatureModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2}>
                    <Typography variant="darkValueLarge" component="div" mb={3}>
                        Please insert your DSC token. If already inserted press sign
                    </Typography>
                    <Grid>
                        <Button variant="primary" onClick={handleSubmitDigitalSignModal}>
                            SIGN
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default BulkSignatureModal

BulkSignatureModal.propTypes = {
  openBulkSignatureModal: PropTypes.bool,
  handleBulkSignatureModalClose: PropTypes.func,
  handleSubmitDigitalSignModal: PropTypes.func
}
