/* eslint-disable */
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routing from '../src/routes/index';
import { StateProvider } from './contextapi/StateContext';
import { GlobalDebug } from './util/removeConsole';
import { IpProvider } from './contextapi/IpContext';

function App() {
    //  const navigate = useNavigate()
    /**
     * @REMOVE_CONSOLES
     * // remove the working of console logs
     * // remove any accidental use of console logs
     */
    useEffect(() => {
        // (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') &&
        GlobalDebug(true);
        //preventDuplicateTab()
    }, []);

    function preventDuplicateTab() {
        if (sessionStorage?.tabCreateTimestamp) {
            // Check if the tab has been duplicated (no window.name)
            if (!window.name) {
                window.name = 'tab';
                sessionStorage.tabCreateTimestamp = Date.now();
                sessionStorage.clear();
                window.location.href = '/session-expired';
            }
        } else {
            sessionStorage.tabCreateTimestamp = Date.now();
        }
    }

    return (
        <div className="App">
            <BrowserRouter>
                <StateProvider>
                    <IpProvider>
                        <Routing />
                    </IpProvider>
                </StateProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
