import { styled, TableCell, tableCellClasses } from '@mui/material'

//  custom cells and rows for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F5F7F9',
    color: '#003A51',
    fontSize: '14px',
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFFFFF',
    color: '#555555',
    fontSize: '14px',
    fontWeight: 400
  }
}))

export default StyledTableCell
