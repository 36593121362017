import React, { useContext, useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Popover
} from '@mui/material'
import flag from '../../../assets/images/india-flag.svg'
import { useGetCityQuery } from '../../../store/services/userServices'
import { useNavigate, useParams } from 'react-router-dom'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import {
  useGetStaffUserByIdQuery,
  useUpdatestaffUserMutation
} from '../../../store/services/manageStaffUserServices'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { PATTERN } from '../../../constants/enum'
import { toast } from 'react-toastify'
import CustomCard from '../../../components/CustomCard'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import info from '../../../assets/images/info.svg'
import { INFORMATION_MESSAGE } from '../../../constants/message'
import { PLACEHOLDER } from '../../../constants/placeholder'
import palette from '../../../theme/palette'

const EditStaffUserBusiness = () => {
  // Navigate
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // UserId from params
  const { userId } = useParams()

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // Profile data from context api
  const { profile } = useContext(ProfileContext)

  // Edit staff user form
  const [editStaffUserForm, setEditStaffUserForm] = useState({
    stateId: profile?.state?.id,
    cityId: ''
  })

  // Get all cities according to state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: profile?.state?.id },
    {
      skip: !profile?.state?.id
    }
  )

  // fetch staff user data by id
  const { data: staffUserData = {}, isSuccess } = useGetStaffUserByIdQuery(userId)

  // Update staff user mutation
  const [updateStaffUserData] = useUpdatestaffUserMutation({})

  // Set staff user data in edit form
  useEffect(() => {
    console.log('in useffect')
    if (staffUserData && isSuccess) {
      setEditStaffUserForm({
        name: staffUserData?.name || '',
        email: staffUserData?.email || '',
        phone: staffUserData?.phone || '',
        stateId: staffUserData?.stateId || profile?.state?.id,
        cityId: staffUserData?.city?.id || '',
        pinCode: staffUserData?.pinCode || ''
      })
    }
  }, [staffUserData, isSuccess])

  /**
     * Change values in Staff User edit form
     * @param {object} event:event textfield object
     */
  const handleEditStaffUserFormChange = (event) => {
    setEditStaffUserForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }

  // Handle edit staff user form submit
  const handleEditStaffUserFormSubmit = () => {
    setBackdropState(true)
    const dataToSend = {
      name: editStaffUserForm?.name,
      email: editStaffUserForm?.email,
      phone: editStaffUserForm?.phone,
      stateId: editStaffUserForm?.stateId,
      cityId: editStaffUserForm?.cityId,
      pinCode: editStaffUserForm?.pinCode
    }
    console.log('form submit', editStaffUserForm)
    updateStaffUserData({ payload: dataToSend, userId })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        navigate('/manageStaffUsers', {
          state: {
            HeaderTitle: 'Staff Users'
          }
        })
        console.log(response)
      })
      .catch((exception) => {
        setBackdropState(false)
        console.log(exception)
      })
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
        <Box p={3}>
            <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleEditStaffUserFormSubmit()
                }}>
                <CustomCard headerTitle="Add staff account">
                    <Grid container spacing={3} p={3}>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ display: 'flex' }}>
                                Name
                                <img
                                    style={{ marginLeft: 10 }}
                                    src={info}
                                    alt="info"
                                    onClick={handleClick}
                                />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                    }}>
                                    <Box
                                        sx={{
                                          background: palette.primary.light,
                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                        }}
                                        className="documentTablePopoverBox">
                                        <Typography
                                            variant="darkValue"
                                            sx={{
                                              letterSpacing: '0.03em',
                                              lineHeight: '30px'
                                            }}>
                                            {INFORMATION_MESSAGE?.NAME_MESSAGE}
                                        </Typography>
                                    </Box>
                                </Popover>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                required
                                placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                                value={editStaffUserForm?.name || ''}
                                name="name"
                                onChange={handleEditStaffUserFormChange}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                disabled
                                fullWidth
                                required
                                value={editStaffUserForm?.email || ''}
                                type="email"
                                name="email"
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                Phone
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={editStaffUserForm?.phone || ''}
                                fullWidth
                                required
                                type="text"
                                id="phoneNumber"
                                name="phone"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 10,
                                  minlength: 10
                                }}
                                InputProps={{
                                  startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={flag} alt="flag" />
                                            &nbsp;+91 |
                                        </InputAdornment>
                                  )
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleEditStaffUserFormChange(e)
                                  }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography component="div" variant="darkValue">
                                State
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                disabled
                                fullWidth
                                required
                                value={profile?.state?.name}
                                type="text"
                                name="stateId"
                            />
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ marginRight: '38px' }}>
                                City
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <Select
                                sx={{ fontSize: '16px' }}
                                value={editStaffUserForm?.cityId || ''}
                                disabled={!profile?.state?.id}
                                labelId="cityId"
                                id="cityId"
                                name="cityId"
                                onChange={(event) => {
                                  setEditStaffUserForm((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value
                                  }))
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                displayEmpty
                                MenuProps={MenuProps}
                                fullWidth
                                required>
                                <MenuItem value="" disabled>
                                    <Typography>Select City</Typography>
                                </MenuItem>
                                {cityArray &&
                                    cityArray.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            md={12}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="Center">
                            <Typography
                                component="div"
                                variant="darkValue"
                                sx={{ marginRight: '30px' }}>
                                Pin code
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={12} sm={12} xs={12}>
                            <TextField
                                value={editStaffUserForm?.pinCode || ''}
                                fullWidth
                                required
                                name="pinCode"
                                type="text"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 6,
                                  minlength: 6
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleEditStaffUserFormChange(e)
                                  }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomCard>
                <Box>
                    <Button
                        variant="cancelButton"
                        sx={{ marginRight: '20px' }}
                        onClick={() =>
                          navigate('/manageStaffUsers', {
                            state: {
                              HeaderTitle: 'Staff Users'
                            }
                          })
                        }>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Update
                    </Button>
                </Box>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditStaffUserBusiness
