import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useGetBulkDocumentIndividualFileByIdQuery } from '../../../../store/services/documentServices'
// import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewIndividualBulkDocument = ({ documentId }) => {
  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  // const navigate = useNavigate()
  const { data: documentPreview = {} } = useGetBulkDocumentIndividualFileByIdQuery(documentId, {
    skip: !documentId
  })

  return (
        <div>
            {documentPreview?.link && (
                <>
                    <Document
                        size="A4"
                        file={documentPreview?.link} // Path to your PDF file
                        workerSrc={workerSrc}
                        onLoadSuccess={onDocumentLoadSuccess}
                        // onSourceError={(error) =>
                        //   alert('Error while retrieving document source! ' + error.message)
                        // }
                        // onSourceSuccess={() => alert('Document source retrieved!')}
                        // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                    >
                        {Array.from({ length: numPages }, (_, index) => index + 1).map(
                          (pageNumber) => (
                                <React.Fragment key={pageNumber}>
                                    <Page key={pageNumber} pageNumber={pageNumber} width={594}>
                                        <div className="page-break" />
                                    </Page>
                                </React.Fragment>
                          )
                        )}
                    </Document>
                </>
            )}
        </div>
  )
}

export default React.memo(PreviewIndividualBulkDocument)
PreviewIndividualBulkDocument.propTypes = {
  documentId: PropTypes.string
}
