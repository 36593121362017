export const MANAGESTAFFUSERSHEADERBUSINESS = [
  'Name',
  'City',
  'Phone',
  'Email',
  'Status',
  'Action'
]

export const MANAGESTAFFUSERSHEADERCORPORATE = [
  'Name',
  'Role',
  'Reporting Manager',
  'Location',
  'Phone',
  'Email',
  'Last Login',
  'Status',
  'Action'
]

export const ADDUSERTYPE = {
  ADMIN: 'admin',
  STAFF: 'staff'
}

export const ADDUSEROPTIONSARRAYCOOWNER = [
  {
    id: 1,
    label: 'Admin',
    value: 'admin'
  },
  {
    id: 2,
    label: 'Staff',
    value: 'staff'
  }
]

export const ADDUSEROPTIONSARRAYCOADMIN = [
  {
    id: 2,
    label: 'Staff',
    value: 'staff'
  }
]
