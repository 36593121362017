import { Box, Button, Divider, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATTERN, ROLES, USER_ROLE } from '../../../constants/enum'
import { useGetCityQuery, useGetStateQuery } from '../../../store/services/userServices'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import Root from '../../../StyledComponents/DividerStyles/DividerStyles'
import { getSession } from '../../../util/helper'
import {
  useGetBillingSubInfoQuery,
  useUpdateBillingInfoMutation
} from '../../../store/services/profleServices'
import { toast } from 'react-toastify'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'

const EditSubscription = () => {
  const navigate = useNavigate()
  const [updateBillingForm, setUpdateBillingForm] = useState({})

  //   const exceptThisSymbols = EXCEPTTHISSYMBOLS

  const userInfo = JSON.parse(getSession('user'))

  // Profile context api
  const { profile } = useContext(ProfileContext)

  const { data: stateArray = [] } = useGetStateQuery({})

  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: updateBillingForm.stateId },
    {
      skip: !updateBillingForm.stateId
    }
  )

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  const [updateBillingInfo] = useUpdateBillingInfoMutation({})

  const { data: profileData = {}, isSuccess } = useGetBillingSubInfoQuery({})

  useEffect(() => {
    console.log('im here')
    if (profileData && isSuccess) {
      setUpdateBillingForm({
        stateId: profileData?.state?.id || '',
        cityId: profileData?.city?.id || '',
        pinCode: profileData?.pinCode || '',
        email: profileData?.email || '',
        companyName: profileData?.name || '',
        gst: profileData?.gst || '',
        address: profileData?.address || ''
      })
    }
  }, [profileData, isSuccess])

  const handleUpdateBillingStateChange = (event) => {
    setUpdateBillingForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      cityId: ''
    }))
  }
  const handleUpdateBillingFormChange = (event) => {
    console.log(event.target.name, event.target.value)
    setUpdateBillingForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }

  const handleUpdate = () => {
    if (userInfo?.role === USER_ROLE.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase()) {
      navigate('/myProfileBusiness/subscription', {
        state: {
          HeaderTitle: 'Subscription'
        }
      })
    } else {
      navigate('/myProfileCorporate/subscription', {
        state: {
          HeaderTitle: 'Subscription'
        }
      })
    }
  }

  const handleUpdateFormSubmit = () => {
    const dataToSend = {
      stateId: updateBillingForm?.stateId,
      cityId: updateBillingForm?.cityId,
      pinCode: Number(updateBillingForm?.pinCode),
      email: updateBillingForm?.email,
      name: updateBillingForm?.name,
      gst: updateBillingForm?.gst,
      address: updateBillingForm?.address,
      companyName: updateBillingForm?.companyName
    }
    setBackdropState(true)
    updateBillingInfo({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        handleUpdate()
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('EXCEPTION:PROFIL BILLING update', execption)
        // toast.error(execption?.message)
      })
  }

  return (
        <Box p={3}>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Current plan
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.planType}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Renewal date
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.renewalDate}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Plan price
                    </Typography>
                    <Typography variant="modalLabel">
                        Rs {profileData?.subscriptionDetails?.amount}/-
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Renewal cycle
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.subscriptionDetails?.tenureType}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Documents Count Consumed
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.documentDetails?.consumed}/
                        {profileData?.documentDetails?.available}
                    </Typography>
                </Grid>
            </Grid>
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="darkValueLarge" sx={{ textDecoration: 'underline' }}>
                        Billing address
                    </Typography>
                </Grid>
            </Grid>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleUpdateFormSubmit()
                }}>
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            State
                        </Typography>
                        <Select
                            name="stateId"
                            value={updateBillingForm?.stateId || ''}
                            onChange={handleUpdateBillingStateChange}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            MenuProps={MenuProps}
                            fullWidth
                            disabled>
                            <MenuItem value="" disabled>
                                <Typography>Select State</Typography>
                            </MenuItem>

                            {stateArray &&
                                stateArray.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            City
                        </Typography>
                        <Select
                            required
                            value={updateBillingForm?.cityId || ''}
                            name="cityId"
                            onChange={(event) => {
                              setUpdateBillingForm((prev) => ({
                                ...prev,
                                [event.target.name]: event.target.value
                              }))
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            MenuProps={MenuProps}
                            fullWidth
                            disabled>
                            <MenuItem value="" disabled>
                                <Typography>Select City</Typography>
                            </MenuItem>

                            {cityArray &&
                                cityArray.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Pin code
                        </Typography>
                        <TextField
                            value={updateBillingForm?.pinCode || ''}
                            required
                            type="text"
                            inputProps={{
                              type: 'text',
                              // inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxlength: 6,
                              minlength: 6
                            }}
                            id="pinCode"
                            name="pinCode"
                            fullWidth
                            disabled
                            onChange={(e) => {
                              if (
                                e.target.value === '' ||
                                    PATTERN?.NUMBERONLY.test(e?.target?.value)
                              ) {
                                handleUpdateBillingFormChange(e)
                              }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Email
                        </Typography>
                        <TextField
                            value={updateBillingForm?.email || ''}
                            type="email"
                            required
                            id="email"
                            name="email"
                            fullWidth
                            disabled
                            onChange={handleUpdateBillingFormChange}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            {profile?.role === ROLES?.BUSINESS_ASSOCIATE ? 'Name' : 'Company name '}
                        </Typography>
                        <TextField
                            disabled
                            value={updateBillingForm?.companyName || ''}
                            required
                            type="text"
                            id="companyName"
                            name="companyName"
                            fullWidth
                            onChange={handleUpdateBillingFormChange}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Gst
                        </Typography>
                        <Typography variant="modalLabel">
                            <TextField
                                required={profileData?.gst !== ''}
                                value={updateBillingForm?.gst || ''}
                                type="text"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  maxlength: 15
                                }}
                                id="gst"
                                name="gst"
                                fullWidth
                                onChange={(event) => {
                                  setUpdateBillingForm((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value
                                      .toUpperCase()
                                      .replace(/\s/gy, '')
                                  }))
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                    <Grid item lg={12}>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Company address
                        </Typography>
                        <TextField
                            value={updateBillingForm?.address || ''}
                            required
                            type="text"
                            id="address"
                            name="address"
                            multiline
                            rows={1}
                            fullWidth
                            disabled
                            onChange={handleUpdateBillingFormChange}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Button variant="primary" type="submit">
                        Update info
                    </Button>
                </Grid>
            </form>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditSubscription
