import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const walletTransactionApi = createApi({
  reducerPath: 'walletTransactionApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllWalletTransactions', 'getTransferRequestToBank'],
  endpoints: (builder) => ({
    getAllWalletTransactions: builder.query({
      query: (params) => ({
        url: 'payment/wallet/group/transact',
        method: 'GET',
        params
      }),
      providesTags: ['getAllWalletTransactions'],
      transformResponse: (response) => response?.data
    }),
    pushMoneyToBank: builder.mutation({
      query: ({ payload }) => ({
        url: 'wallet/transaction/addmoney',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getTransferRequestToBank', 'getAllWalletTransactions'],
      transformResponse: (response) => response
    }),
    getTransferRequestToBank: builder.query({
      query: (params) => ({
        url: 'wallet/transaction/payment',
        method: 'GET',
        params
      }),
      providesTags: ['getTransferRequestToBank'],
      transformResponse: (response) => response?.data
    }),
    updateWalletPaymentStatus: builder.mutation({
      query: ({ payload }) => ({
        url: 'transaction/save',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllWalletTransactions'],
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetAllWalletTransactionsQuery,
  useUpdateWalletMutation,
  usePushMoneyToBankMutation,
  useUpdateWalletPaymentStatusMutation,
  useGetTransferRequestToBankQuery
} = walletTransactionApi
