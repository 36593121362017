import { createTheme } from '@mui/material'
import palette from './palette'

const _theme = createTheme()
const typography = {
  fontFamily: 'Inter, sans-serif',
  label: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '2.9rem',
    color: palette.primary.main
  },
  labelLarge: {
    fontStyle: 'normal',
    fontWeight: '700',
    color: palette.text.main,
    // wordWrap: 'break-word',
    [_theme.breakpoints.up('xs')]: {
      fontSize: '20px'
    },
    [_theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    },
    [_theme.breakpoints.up('md')]: {
      fontSize: '24px'
    },
    [_theme.breakpoints.up('lg')]: {
      fontSize: '30px'
    }
  },
  labelSmall: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '30px',
    color: palette.text.main
  },
  modalLabelBold: {
    fontStyle: 'normal',
    fontWeight: '500',
    color: palette.text.main,
    [_theme.breakpoints.up('xs')]: {
      fontSize: '12px'
    },
    [_theme.breakpoints.up('sm')]: {
      fontSize: '14px'
    },
    [_theme.breakpoints.up('lg')]: {
      fontSize: '16px'
    }
  },
  modalLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    wordBreak: 'break-word',
    color: palette.text.main
  },
  modalLabelSmall: {
    fontStyle: 'normal',
    fontWeight: '400',
    wordBreak: 'break-word',
    color: palette.text.main,
    [_theme.breakpoints.up('xs')]: {
      fontSize: '12px'
    },
    [_theme.breakpoints.up('sm')]: {
      fontSize: '14px'
    },
    [_theme.breakpoints.up('lg')]: {
      fontSize: '14px'
    }
  },
  darkValue: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: palette.text.dark
  },
  darkValueLarge: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    color: palette.text.dark
  }
}
export default typography
