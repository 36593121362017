import React, { useRef } from 'react'
import { TextField, Typography } from '@mui/material'
// import calender from '../../assets/images/calendericon.svg'
import PropTypes from 'prop-types'

const DateTextField = ({
  textFieldData,
  fieldLabel,
  onchange,
  onfocus,
  documentForm,
  sectionIndex
}) => {
  const inputRef = useRef()
  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <TextField
                    error={documentForm?.errors[`${textFieldData?.id}`]}
                    helperText={documentForm?.errors[`${textFieldData?.id}`]}
                    value={
                        !documentForm?.form[`${textFieldData?.id}`] &&
                        documentForm?.form[`${textFieldData?.id}`] !== ''
                          ? textFieldData?.props?.prefilled
                          : documentForm?.form[`${textFieldData?.id}`]
                    }
                    placeholder={textFieldData?.placeholderName}
                    id={textFieldData?.id}
                    inputProps={{
                      min: textFieldData?.props?.min_date ? textFieldData?.props?.min_date : '',
                      max: textFieldData?.props?.max_date ? textFieldData?.props?.max_date : ''
                    }}
                    name={`span${textFieldData?.id}`}
                    type="date"
                    fullWidth
                    inputRef={inputRef}
                    InputProps={{
                      style: {
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px'
                      }
                    }}
                    onChange={(event) => onchange(event, textFieldData, sectionIndex)}
                    onFocus={(event) => onfocus(event, textFieldData)}
                />
                {/* <span
                    className="calendericon"
                    onClick={() => {
                      inputRef.current.showPicker()
                    }}>
                    <img src={calender} />
                </span> */}
            </div>
        </div>
  )
}

export default DateTextField

DateTextField.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  textFieldData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
