import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const commonApi = createApi({
  reducerPath: 'commonApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllRestrictionChecks', 'getAllNotifications'],
  endpoints: (builder) => ({
    getAccountTypes: builder.query({
      query: () => ({
        url: 'ba/accountTypes',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getAllRestrictionChecks: builder.query({
      query: () => ({
        url: 'dashboard/getDashboardData',
        method: 'GET'
      }),
      providesTags: ['getAllRestrictionChecks'],
      transformResponse: (response) => response?.data
    }),
    getFilterLocation: builder.query({
      query: () => ({
        url: 'corp/locations',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    checkPanKyc: builder.mutation({
      query: ({ payload }) => ({
        url: 'pan/verification',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllRestrictionChecks'],
      transformResponse: (response) => response
    }),
    verifyOtpPan: builder.mutation({
      query: ({ payload }) => ({
        url: 'user/pan/otp',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    VerifyPinCodeService: builder.query({
      query: (params) => ({
        url: 'bluedart/checkPinCodeService',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response
    }),
    getAllNotifications: builder.query({
      query: (params) => ({
        url: 'notification/getUserNotification?viaAuth=1',
        method: 'GET',
        params
      }),
      providesTags: ['getAllNotifications'],
      transformResponse: (response) => response?.data
    }),
    markNotificationRead: builder.query({
      query: (params) => ({
        url: 'notification/markRead?viaAuth=1',
        method: 'GET',
        params
      })
    }),

    addPan: builder.mutation({
      query: ({ payload }) => ({
        url: 'panVerify/addPanRequest',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    acceptTerms: builder.mutation({
      query: ({ payload }) => ({
        url: 'termsAndConditions/acceptTerms',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllRestrictionChecks'],
      transformResponse: (response) => response
    }),

    getCodeChallenge: builder.query({
      query: (params) => ({
        url: 'digiLocker/getCodeChallenge',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    sendDigiAuthCode: builder.mutation({
      query: ({ payload }) => ({
        url: 'digiLocker/sendAuthCode',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllRestrictionChecks'],
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetAccountTypesQuery,
  useGetFilterLocationQuery,
  useGetAllRestrictionChecksQuery,
  useLazyGetAllRestrictionChecksQuery,
  useCheckPanKycMutation,
  useVerifyOtpPanMutation,
  useVerifyPinCodeServiceQuery,
  useLazyVerifyPinCodeServiceQuery,
  useLazyGetAllNotificationsQuery,
  useLazyMarkNotificationReadQuery,
  useAddPanMutation,
  useAcceptTermsMutation,
  useGetCodeChallengeQuery,
  useSendDigiAuthCodeMutation
} = commonApi
