import React, { useState } from 'react'
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry.js'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import PropTypes from 'prop-types'
// import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import './PreviewDocument.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const PreviewDocument = ({ documentId, documentPreview }) => {
  const [numPages, setNumPages] = useState(null)

  // Function to render PDF pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  // const navigate = useNavigate()

  // let url = '';
  // const [url, seturl] = useState('')
  // const [docs, setdocs] = useState([])
  // useEffect(() => {
  //   console.log(isSuccess, isError)
  //   if (isSuccess) {
  //     // console.log('in success', headers)
  //     // const url = window.URL.createObjectURL(new Blob([documentPreview]))
  //     // const file = new Blob([documentPreview], { type: 'application/pdf' })
  //     // const fileURL = window.URL.createObjectURL(file)
  //     // document.querySelector('iframe').src = fileURL
  //     // seturl(fileURL)
  //     // previewPdf(new Blob([documentPreview]))
  //   }
  //   // if (!isSuccess) {
  //   //   console.log('in success hkjkl')
  //   //   toast.error('No Document found')
  //   //   navigate('/createDOcument', {
  //   //     state: {
  //   //       HeaderTitle: 'Create Document'
  //   //     }
  //   //   })
  //   // }

  //   // else if (!isSuccess) {
  //   //   toast.error('No Document found')
  //   //   navigate('/createDOcument', {
  //   //     state: {
  //   //       HeaderTitle: 'Create Document'
  //   //     }
  //   //   })
  //   // }
  //   // const link = document.createElement('a')
  //   // link.href = url
  //   // link.setAttribute('download', 'sample') // 3. Append to html page
  //   // document.body.appendChild(link) // 4. Force download
  //   // link.click()
  //   // setfile(window.URL.createObjectURL(documentPreview))
  //   // setdocs([{ uri: url}])
  // }, [isSuccess])

  // console.log(documentPreview?.link)

  // const docs = [
  //   {
  //     uri: 'https://rayxdx.s3.us-west-2.amazonaws.com/file_1684786890813.pdf'
  //   }
  // ]

  // const newfile = new File([new Blob([documentPreview])], 'test.pdf', {
  //   type: 'application/pdf'
  // })
  // const aa = window.URL.createObjectURL(newfile)
  // console.log('aaaa', newfile, url)
  // console.log(
  //   'rewr',
  //   documentPreview,
  //   'hgjkhkj',
  //   window.URL.createObjectURL(new Blob([documentPreview]))
  // )
  // function previewPdf (billName) {
  // const xhr = new XMLHttpRequest()
  // xhr.open('GET', 'home/download?pdfBillId=' + billName, true)
  // xhr.responseType = 'arraybuffer'
  // xhr.onload = function (e) {
  //   if (this.status === 200) {
  //     const file = new Blob([this.response], { type: 'application/pdf' })
  //     const fileURL = URL.createObjectURL(file)
  //     // document.querySelector('iframe').src = fileURL
  //     seturl(fileURL)
  //   }
  // }
  // xhr.send()
  // }

  // const headers = {
  //   'Access-Control-Allow-Origin': '*'
  // }

  return (
        <div>
            {documentPreview?.link && (
                <>
                    <Document
                        size="A4"
                        file={documentPreview?.link} // Path to your PDF file
                        workerSrc={workerSrc}
                        onLoadSuccess={onDocumentLoadSuccess}
                        // onSourceError={(error) =>
                        //   alert('Error while retrieving document source! ' + error.message)
                        // }
                        // onSourceSuccess={() => alert('Document source retrieved!')}
                        // onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                    >
                        {Array.from({ length: numPages }, (_, index) => index + 1).map(
                          (pageNumber) => (
                                <React.Fragment key={pageNumber}>
                                    <Page key={pageNumber} pageNumber={pageNumber} width={594}>
                                        <div className="pdf_page_break" />
                                    </Page>
                                </React.Fragment>
                          )
                        )}
                    </Document>
                    {/* <iframe src="http://docs.google.com/gview?url=https://digidocblobstorage.blob.core.windows.net/digidoc-dev/output-3.pdf&embedded=true"></iframe> */}
                    {/* <iframe src={documentPreview?.link} height={500} width={500}></iframe> */}

                    {/* <DocViewer
                        config={{
                          header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: true
                          },
                          pdfVerticalScrollByDefault: true // false as default
                        }}
                        style={{ width: 750, height: 620 }}
                        documents={docs}
                        prefetchMethod="GET"
                        requestHeaders={headers}
                        pluginRenderers={DocViewerRenderers}></DocViewer> */}
                </>
            )}
        </div>
  )
}

export default PreviewDocument
PreviewDocument.propTypes = {
  documentId: PropTypes.string,
  documentPreview: PropTypes.object
}
