import jwtDecode from 'jwt-decode'

/**
 * Decode Token
 * @param {string} token:token
 */
export const decodeToken = (token) => {
  try {
    const decode = jwtDecode(token)
    if (Date.now() < decode?.exp * 1000) return decode
    return ''
  } catch (error) {
    console.log('errr', error)
    return ''
  }
}
