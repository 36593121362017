import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import cross from '../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import palette from '../../../theme/palette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 630,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const ConfirmInvoiceGenerationModal = ({
  openAlertModal,
  handleAlertModalCancel,
  handleSubmitAlertModal,
  handleAlertModalClose,
  alertModalState
}) => {
  return (
        <Modal
            open={openAlertModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handleAlertModalCancel} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <Grid p={2}>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{
                          fontSize: '25px',
                          marginBottom: '10px',
                          fontWeight: 600,
                          textAlign: 'center'
                        }}>
                        {alertModalState.title}
                    </Typography>
                    <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmitAlertModal()
                        }}>
                        <FormControlLabel
                            sx={{ mb: '15px', mr: 0, width: '100%' }}
                            control={
                                <Checkbox
                                    onClick={(event) => {}}
                                    sx={{
                                      color: 'var(--g2)',
                                      '&.Mui-checked': {
                                        color: '#33AFB9'
                                      }
                                    }}
                                    required
                                    name="disclaimer"
                                    id="disclaimer"
                                />
                            }
                            label={
                                <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                    {alertModalState?.message}
                                </Typography>
                            }
                        />

                        <Grid display="flex" gap={2}>
                            <Grid>
                                <Button variant="cancelButton" onClick={handleAlertModalClose}>
                                    {alertModalState.cancelButtonText}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="primary" type="submit">
                                    {alertModalState.submitButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Box>
        </Modal>
  )
}

export default ConfirmInvoiceGenerationModal

ConfirmInvoiceGenerationModal.propTypes = {
  openAlertModal: PropTypes.bool,
  handleAlertModalCancel: PropTypes.func,
  handleSubmitAlertModal: PropTypes.func,
  handleAlertModalClose: PropTypes.func,
  alertModalState: PropTypes.object
}
