import React, { useState, useEffect } from 'react'
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetTicketByIdQuery,
  useUpdateTicketStatusMutation
} from '../../../../store/services/helpServices'
import { TICKETFILTERSTATUS } from '../../../../constants/faqConstants'
import { toast } from 'react-toastify'
import CustomCard from '../../../../components/CustomCard'
import Root from '../../../../StyledComponents/DividerStyles/DividerStyles'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'

const TicketSummary = () => {
  // Get ticket number from url params
  const { ticketId } = useParams()
  const decodeParam = decodeURIComponent(ticketId)
  console.log('Decoded Uri Component===>', decodeParam)
  const navigate = useNavigate()
  const encodedParam = encodeURIComponent(decodeParam)

  const [ticketSummary, setTicketSummary] = useState({
    status: 'Resolved'
  })

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  // Get  ticket data according to  ticket number
  const { data: ticketData, isSuccess } = useGetTicketByIdQuery(encodedParam, {
    skip: !encodedParam
  })

  useEffect(() => {
    setTicketSummary({
      ticketNumber: ticketData?.ticketNumber,
      status: ticketData?.status,
      document: ticketData?.document,
      issueCategory: ticketData?.issueCategory,
      issueType: ticketData?.issueType,
      issue: ticketData?.issue,
      description: ticketData?.description
    })
  }, [ticketData, isSuccess])

  // update status mutation
  const [updateStatus] = useUpdateTicketStatusMutation({})

  const handleStatusResolved = () => {
    setBackdropState(true)
    const dataToSend = {
      ticketId,
      status: TICKETFILTERSTATUS?.RESOLVED?.NAME
    }
    updateStatus({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)

        console.log('response', response)
        setTicketSummary((prev) => ({
          ...prev,
          status: response?.data?.status
        }))
        toast.success(response.message)
      })
      .catch((execption) => {
        setBackdropState(false)

        // toast.error(execption.message)
      })
  }

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <CustomCard
                headerTitle={'Ticket'}
                action={
                    <>
                        {ticketSummary?.status?.id !== TICKETFILTERSTATUS?.RESOLVED?.ID && (
                            <Button variant="link" onClick={handleStatusResolved}>
                                Mark as Resolved
                            </Button>
                        )}
                        <Button variant="link" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </>
                }>
                <Grid container alignItems="center" p={1} spacing={3} mb={3}>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                        <Typography variant="darkValue">Ticket number</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                        <TextField
                            value={ticketSummary?.ticketNumber || ''}
                            id="TicketNumber"
                            name="TicketNumber"
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                        <Typography variant="darkValue">Status of ticket</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                        <TextField
                            value={ticketSummary?.status?.value || ''}
                            id="TicketNumber"
                            name="TicketNumber"
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Root>
                    <Divider style={{ marginBottom: '30px' }} />
                </Root>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Document related to issue</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField value={ticketSummary?.document?.name || ''} fullWidth disabled />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Category of issue</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField value={ticketSummary?.issueCategory || ''} fullWidth disabled />
                    </Grid>
                </Grid>
                {/* <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Issue type</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField value={ticketSummary?.issueType || ''} fullWidth disabled />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Issue</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField value={ticketSummary?.issue || ''} fullWidth disabled />
                    </Grid>
                </Grid> */}
                <Grid container alignItems="center" p={1}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="darkValue">Description</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            value={ticketSummary?.description || ''}
                            multiline
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
            </CustomCard>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default TicketSummary
