import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './UnauthorizedAccess.css'
const UnauthorizedAccess = () => {
  const navigate = useNavigate()
  return (
        <Box className="page_511">
            <Grid>
                <Grid item lg={12} xs={12}>
                    <Grid mt={20} mb={3}>
                        <Typography sx={{ fontSize: '45px' }}>Unauthorized Data Access!</Typography>
                    </Grid>
                    <Grid>
                        <Typography component="h1" mb={2}>
                            Look like you&apos;re trying to access other user&apos;s data
                        </Typography>
                        {/* <Typography mb={2}>Please login again!</Typography> */}
                        <Button
                            variant="primary"
                            onClick={() => {
                              navigate('/')
                            }}
                            className="link_404">
                            Home
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
  )
}

export default UnauthorizedAccess
