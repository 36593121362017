/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Divider,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Button,
    FormControl,
    RadioGroup,
    Radio
} from '@mui/material';
import './PaymentAccord.css';
import {
    CLVALIDATIONS,
    COMMON,
    DOCTYPE,
    EXCEPTTHISSYMBOLS,
    PATTERN
} from '../../../../../constants/enum';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '../../CustomDocument/CustomDocument';
import {
    useAddPanForStampDutyMutation,
    useGetPaymentConfigDetailsQuery
} from '../../../../../store/services/documentServices';
import {
    PAYMENTDETAIL,
    STAMPDUTYCALCULATIONOPTIONS,
    STORAGETYPEOPTIONS
} from '../../../../../constants/documentConstans';
import Root from '../../../../../StyledComponents/DividerStyles/DividerStyles';
import {
    useGetCityQuery,
    useGetProfileQuery,
    useGetStateQuery
} from '../../../../../store/services/userServices';
import MenuProps from '../../../../../StyledComponents/SelectStyles/SelectStyles';
import { useLazyVerifyPinCodeServiceQuery } from '../../../../../store/services/commonServices';
import { toast } from 'react-toastify';
import { ALERT_MESSAGE, STAMPDUTYINFO } from '../../../../../constants/message';
import BackdropLoader from '../../../../../components/BackdropLoader/BackdropLoader';
import { validateStampDuty, validator } from '../../../../../util/validator';
import HowerTooltip from '../../../../../components/HowerTooltip/HowerTooltip';
import { roundFloor } from '../../../../../util/helper';

const PaymentAccord = ({
    Accordindex,
    handleExpandChange,
    expanded,
    documentId,
    paymentForm,
    setPaymentForm,
    handlePaymentInitialization
}) => {
    // Except symbol on number field
    const exceptThisSymbols = EXCEPTTHISSYMBOLS;

    // Storage type options
    const storageTypeList = [STORAGETYPEOPTIONS?.FREETIER, STORAGETYPEOPTIONS?.PAIDTIER];

    // Use profile address or not checkbox
    const [useProfileAddress, setUseProfileAddress] = useState(false);

    // PinCode status deliverable or not
    const [pinCodeStatus, setPinCodeStatus] = useState(false);

    // Backdrop loader state
    const [backdropState, setBackdropState] = useState(false);

    const PaymentDetails = PAYMENTDETAIL;

    // Payment details from the backend
    const [documentPaymentDetails, setDocumentPaymentDetails] = useState({
        total: 0
    });

    // Get payment details by stamp denomination query
    const { data: paymentConfig = {}, isSuccess } = useGetPaymentConfigDetailsQuery(documentId);

    useEffect(() => {
        if (paymentConfig && isSuccess) {
            const staticMiscTotal = paymentConfig?.miscChargesStatic?.reduce(
                (n, { value }) => n + value,
                0
            );
            setDocumentPaymentDetails({
                stampDuty: '',
                platformCharge: paymentConfig?.platformCharge || 0,
                dispatchCharge: paymentConfig?.dispatchCharge || 0,
                eSignCharge: paymentConfig?.esignCharge || 0,
                printingCharge: 0,
                totalGstCharge:
                    paymentConfig?.platformCharge +
                    paymentConfig?.esignCharge +
                    paymentConfig?.dispatchCharge,
                gst: paymentConfig?.gstCharge,
                miscChargePercent: paymentConfig.miscChargesPercentage,
                total:
                    paymentConfig?.platformCharge +
                    paymentConfig?.dispatchCharge +
                    paymentConfig?.esignCharge +
                    paymentConfig?.gstCharge +
                    staticMiscTotal
            });

            // if (
            //   paymentConfig?.dutyType?.id === STAMPDUTYCALCULATIONOPTIONS?.CONSIDERATIONPRICE?.id
            // ) {
            //   setPaymentForm((prev) => ({
            //     ...prev,
            //     stampDuty: paymentConfig?.minStampDuty,
            //     considerationAmount: '',
            //     errors: {
            //       ...prev.errors,
            //       stampDuty: 'noerror' // added noerror  because  the template is consideration amount
            //     }
            //   }))
            //   generatePaymentCharges(paymentConfig?.minStampDuty)
            // }
        }
    }, [isSuccess]);

    /**
     * Handle form change in payment summar form
     * @param {object} event:event object
     */
    const handlePaymentFormChange = (event) => {
        setPaymentForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value.replace(/\s/gy, '')
        }));
    };

    // Get profile data
    const { data: profileData = {}, isSuccess: isSuccessProfile } = useGetProfileQuery({});

    // Get all states
    const { data: stateArray = [] } = useGetStateQuery({});

    const [checkPinCode] = useLazyVerifyPinCodeServiceQuery({});

    // Validate and add pan card for Estapm duty on stamp duty greater than the provided limit(above 2 lakh for now)
    const [addvalidatePanCardEstamp] = useAddPanForStampDutyMutation({});

    // Get cities according to the state selected
    const { data: cityArray = [] } = useGetCityQuery(
        { stateId: paymentForm?.stateId },
        {
            skip: !paymentForm.stateId
        }
    );

    // Verify pincode status

    // const {
    //   data: panCardData = {
    //     data: true
    //   },
    //   // eslint-disable-next-line no-unused-vars
    //   isSuccess: pinCodeSuccess
    // } = useVerifyPinCodeServiceQuery(
    //   { pinCode: paymentForm.pinCode },
    //   {
    //     skip: paymentForm?.pinCode?.length !== 6
    //   }
    // )

    // useEffect(() => {
    //   if (panCardData) {
    //     console.log('panCard', panCardData)
    //   }
    // }, [pinCodeSuccess])

    const handleUseProfileAddress = (event) => {
        if (event.target.checked && isSuccessProfile) {
            console.log('huihuh');
            setPaymentForm((prev) => ({
                ...prev,
                name: profileData?.name,
                address: profileData?.address,
                stateId: profileData?.state?.id,
                cityId: profileData?.city?.id,
                pinCode: profileData?.pinCode,
                phone: profileData?.phone
            }));
            handleCheckPinCodeService(profileData?.pinCode);
        } else {
            setPaymentForm((prev) => ({
                ...prev,
                name: '',
                address: '',
                stateId: '',
                cityId: '',
                pinCode: '',
                phone: ''
            }));
        }
        setUseProfileAddress(event.target.checked);
    };

    useEffect(() => {
        console.log('docunment payment details===>', documentPaymentDetails);
    }, [documentPaymentDetails]);

    const findNearestStationaryChargeRange = (stampDuty) => {
        try {
            const chargeList = paymentConfig?.stationaryPrintCharges;
            if (stampDuty === 0) {
                return { charge: 0 };
            } else if (stampDuty >= chargeList[chargeList?.length - 1].maxAmount) {
                return chargeList[chargeList?.length - 1];
            } else if (stampDuty <= chargeList[0].maxAmount) {
                return chargeList[0];
            } else {
                for (let i = 1; i < chargeList?.length; i++) {
                    if (stampDuty <= chargeList[i].maxAmount) {
                        return chargeList[i];
                    }
                }
            }
        } catch (err) {
            console.log('err in promotion list', err);
        }
    };

    // Typing timeout used to clear timeout
    let typingTimeout = null;

    /**
     * Get all payment details by stamp denomination
     * @param {Object} event:Event object
     */
    const handlePayment = (event) => {
        // Debouncing affect used (as user stops typing  get payment details from backend)
        if (event.target.value === '' || PATTERN?.NUMBERONLY.test(event?.target?.value)) {
            const stampError = validateStampDuty(
                Number(event.target.value),
                Number(paymentConfig?.minStampDuty),
                Number(paymentConfig?.maxStampDuty)
            );
            setPaymentForm((prev) => ({
                ...prev,
                stampDuty: event.target.value,
                errors: {
                    ...prev?.errors,
                    stampDuty: stampError
                }
            }));
        }
        clearTimeout(typingTimeout);
        typingTimeout = setTimeout(async () => {
            // if (event.target.value === '' || PATTERN?.NUMBERONLY.test(event?.target?.value)) {
            console.log('im there', event.target.value);
            // }

            // Document type is delivery or hardcopy include dispatch charges

            // const stationaryPrintingCharge =
            //           paymentConfig?.stationaryPrintCharges.length > 0
            //             ? await findNearestStationaryChargeRange(Number(event.target.value))
            //             : { charge: 0 }
            // const stationaryChargeGst =
            //           (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100
            // console.log('stati', stationaryPrintingCharge, stationaryChargeGst)

            // setDocumentPaymentDetails((prev) => ({
            //   ...prev,
            //   [event.target.name]: event.target.value,
            //   gst: paymentConfig?.gstCharge + stationaryChargeGst,
            //   printingCharge: stationaryPrintingCharge?.charge,
            //   total:
            //               paymentConfig?.platformCharge +
            //               paymentConfig?.dispatchCharge +
            //               paymentConfig?.esignCharge +
            //               paymentConfig?.gstCharge +
            //               stationaryPrintingCharge?.charge +
            //               +stationaryChargeGst +
            //               Number(event.target.value)
            // }))

            generatePaymentCharges(Number(event.target.value), Number(paymentForm?.storageType));
        }, 500);
        console.log('paymentForm', paymentForm);
    };

    const handlePaymentIntiaiteStep2 = () => {
        // if (paymentForm?.errors?.stampDuty === 'noerror') {
        if (!pinCodeStatus && paymentConfig?.docType?.id === DOCTYPE?.PHYSICAL?.ID) {
            toast.error(ALERT_MESSAGE?.PINOCDENOTSERVICEABLE);
        } else {
            handlePaymentInitialization();
        }
        // }
    };

    /**
     * Handle form change in payment summary form
     * @param {object} event:event object
     */
    const handlePaymentFormPinCodeChange = (event) => {
        console.log(event.target.value);
        setPaymentForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value.replace(/\s/gy, '')
        }));
        if (event.target.value?.length === 6) {
            handleCheckPinCodeService(event.target.value);
        }
    };

    const handleCheckPinCodeService = (pinCode) => {
        setBackdropState(true);
        const dataToSend = {
            pinCode
        };
        checkPinCode(dataToSend)
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                console.log(response, 'response');
                setPinCodeStatus(response?.data);
                if (response?.data) {
                    toast.success(ALERT_MESSAGE?.PINCODESERVICEABLE);
                } else {
                    toast.error(ALERT_MESSAGE?.PINOCDENOTSERVICEABLE);
                }
            })
            .catch((exception) => {
                setBackdropState(false);
                setPinCodeStatus(false);
                console.log('EXCEPTION:DOCUMENT INITIALIZED', exception);
            });
    };

    const handlePancardChange = (event) => {
        const err = validator(event?.target?.name, event?.target?.value);

        setPaymentForm((prev) => ({
            ...prev,
            [event.target.name]: event?.target?.value?.toUpperCase(),
            errors: {
                ...prev.errors,
                [event.target.name]: err
            }
        }));
    };

    // Handle validate and add pancard with first party details for estamp certificate when stamp duty is greater than the limit(2 lakh limit for now)
    const handleValidatePanCard = () => {
        // if (
        //   paymentForm?.errors?.stampDuty === 'noerror' &&
        //         paymentForm?.errors?.panCard === 'noerror'
        // ) {
        setBackdropState(true);
        const dataToSend = {
            reviewConsentId: paymentConfig?.validateStampDutyResponse?.reviewConsentId,
            documentId,
            panCardNumber: paymentForm?.panCard
        };
        addvalidatePanCardEstamp({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                handlePaymentIntiaiteStep2();
            })
            .catch((exception) => {
                setBackdropState(false);
            });
        // }
    };

    // Handle initiate payment process
    // If stamp duty is greater than 2 lakh check for pan card and then procced to step 2
    // Stamp duty less than  2 lakh  proceed directly
    const handlePaymentInitiationProcess = () => {
        if (
            paymentForm?.errors?.stampDuty === 'noerror' &&
            Number(paymentForm?.stampDuty) >
                paymentConfig?.validateStampDutyResponse?.stampDutyAmount &&
            paymentForm?.errors?.panCard === 'noerror'
        ) {
            handleValidatePanCard();
        } else if (
            paymentForm?.errors?.stampDuty === 'noerror' &&
            Number(paymentForm?.stampDuty) <=
                paymentConfig?.validateStampDutyResponse?.stampDutyAmount
        ) {
            handlePaymentIntiaiteStep2();
        }
    };

    function roundToNearestWhole(value) {
        if (value - Math.floor(value) >= 0.5) {
            return Math.ceil(value); // Round up
        } else {
            return Math.floor(value); // Round down
        }
    }
    const handleConsiderationAmount = async (event) => {
        const calculatedStampDuty = Math.ceil(
            ((paymentConfig?.considerationPercent / 100) * Number(event.target.value)).toFixed(2)
        );

        // Set minStampDutyRequired  according to the condition
        // If calculated stampDuty less than  minStampDuty set minStampDuty, check same for maxStampDuty if it is greater than set maxStampDuty , otherwise set calculatedStampDuty
        const minStampDutyRequired =
            calculatedStampDuty < paymentConfig?.minStampDuty
                ? paymentConfig?.minStampDuty
                : paymentConfig?.maxStampDuty && calculatedStampDuty > paymentConfig?.maxStampDuty
                  ? paymentConfig?.maxStampDuty
                  : calculatedStampDuty;

        console.log(minStampDutyRequired, calculatedStampDuty);
        setPaymentForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
            stampDuty: minStampDutyRequired,
            errors: {
                ...prev.errors,
                stampDuty: 'noerror' // added noerror  because  the template is consideration amount
            }
        }));

        generatePaymentCharges(minStampDutyRequired, Number(paymentForm?.storageType));

        // const stationaryPrintingCharge =
        //         paymentConfig?.stationaryPrintCharges.length > 0
        //           ? await findNearestStationaryChargeRange(Number(stampDuty))
        //           : { charge: 0 }
        // const stationaryChargeGst = (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100
        // console.log('stati', stationaryPrintingCharge, stationaryChargeGst)

        // setDocumentPaymentDetails((prev) => ({
        //   ...prev,
        //   stampDuty,
        //   gst: paymentConfig?.gstCharge + stationaryChargeGst,
        //   printingCharge: stationaryPrintingCharge?.charge,
        //   total:
        //             paymentConfig?.platformCharge +
        //             paymentConfig?.dispatchCharge +
        //             paymentConfig?.esignCharge +
        //             paymentConfig?.gstCharge +
        //             stationaryPrintingCharge?.charge +
        //             +stationaryChargeGst +
        //             Number(stampDuty)
        // }))
    };

    /**
     * Handle change in storage type option and recalcuate the payment
     * @param {object} event: Event object
     */
    const handleStorageTypeChange = (event) => {
        setPaymentForm((prev) => ({
            ...prev,
            storageType: event.target.value
        }));

        generatePaymentCharges(Number(paymentForm?.stampDuty), Number(event.target.value));
    };

    /**
     * Handle generate all the payment charges by stamp duty
     * @param {Number} stampDuty: Stamp duty amount
     * @param {Number} storageType: Storage type selected
     */
    const generatePaymentCharges = async (
        stampDuty,
        storageType = Number(paymentForm?.storageType)
    ) => {
        console.log(
            'payment details for charges ===> ',
            stampDuty,
            storageType,
            documentPaymentDetails?.totalGstCharge
        );

        // Applicable stationary charges
        const stationaryPrintingCharge =
            paymentConfig?.stationaryPrintCharges?.length > 0
                ? await findNearestStationaryChargeRange(Number(stampDuty))
                : { charge: 0 };
        const stationaryChargeGst = (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100;

        // Applicable storage charges
        const storageCharge =
            storageType === STORAGETYPEOPTIONS?.PAIDTIER?.id ? paymentConfig?.storageCharge : 0;
        const storageChargeGst = paymentConfig?.isStorageGst
            ? (storageCharge * paymentConfig?.gst) / 100
            : 0;
        console.log('GST STORAGE CHARGE>>>>', storageChargeGst, storageChargeGst.toFixed(2));
        const miscChargeData = documentPaymentDetails?.miscChargePercent?.map((item) => {
            return {
                ...item,
                defaultValue: roundToNearestWhole((stampDuty * item.value) / 100)
            };
        });
        const miscChargeTotal = miscChargeData?.reduce(
            (n, { defaultValue }) => n + defaultValue,
            0
        );
        console.log('stationary Printing====>', stationaryPrintingCharge, stationaryChargeGst);
        console.log('totalGst charge====> ', documentPaymentDetails?.totalGstCharge);
        const staticMiscTotal = paymentConfig?.miscChargesStatic?.reduce(
            (n, { value }) => n + value,
            0
        );

        const totalGstCharge =
            paymentConfig?.platformCharge +
            paymentConfig?.esignCharge +
            paymentConfig?.dispatchCharge +
            storageCharge +
            stationaryPrintingCharge?.charge;
        //1.)  The below calculation is done to deal with difference in calculation of gstCharge , basically done for matching the backend calculation
        //2.) After the above we are asked to do the below calculation in case of CGST,SGST only, in case of  ß IGST it is to be done as it was done previously
        let gstFull = 0;
        if (!paymentConfig?.isIgst) {
            let gstSum = paymentConfig?.gstCharge + stationaryChargeGst + storageChargeGst;
            const gstHalf = gstSum / 2;
            const gstRounded = roundFloor(gstHalf);
            gstFull = gstRounded * 2;
        } else {
            gstFull = paymentConfig?.gstCharge + stationaryChargeGst + storageChargeGst;
        }

        setDocumentPaymentDetails((prev) => ({
            ...prev,
            stampDuty,
            gst: gstFull,
            storageCharge,
            printingCharge: stationaryPrintingCharge?.charge,
            totalGstCharge: totalGstCharge,
            miscChargePercent: miscChargeData,
            total:
                paymentConfig?.platformCharge +
                paymentConfig?.dispatchCharge +
                paymentConfig?.esignCharge +
                stationaryPrintingCharge?.charge +
                storageCharge +
                gstFull +
                Number(stampDuty) +
                miscChargeTotal +
                staticMiscTotal
        }));
    };

    return (
        <Accordion expanded={expanded === Accordindex} onChange={handleExpandChange(Accordindex)}>
            <div className="toggle">
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography variant={expanded === Accordindex ? 'darkValueLarge' : 'darkValue'}>
                        Make Payment
                    </Typography>
                </AccordionSummary>
            </div>
            <AccordionDetails>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        handlePaymentInitiationProcess();
                        // handlePaymentInitialization()
                        //   setOpenAlertModal(true)
                    }}>
                    <div style={{ padding: '30px' }}>
                        {/* Consideration Amount */}
                        {paymentConfig?.dutyType?.id ===
                            STAMPDUTYCALCULATIONOPTIONS?.CONSIDERATIONPRICE?.id && (
                            <div className="FieldBox">
                                <div className="FieldBoxLabel">
                                    <Typography variant="darkValue">
                                        Enter Consideration Amount
                                    </Typography>
                                </div>
                                <div className="FieldBoxInput">
                                    <TextField
                                        // value='Name of the uploaded document'
                                        inputProps={{
                                            type: 'text',
                                            maxlength: 20,
                                            minlength: 1
                                        }}
                                        value={paymentForm?.considerationAmount}
                                        required
                                        id="considerationAmount"
                                        name="considerationAmount"
                                        type="text"
                                        fullWidth
                                        // onKeyDown={(e) =>
                                        //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                                        // }
                                        // onInput={handlePayment}
                                        // onChange={handlePayment}
                                        onChange={(event) => {
                                            if (
                                                event.target.value === '' ||
                                                PATTERN?.NUMBERWITHZERO.test(event?.target?.value)
                                            ) {
                                                handleConsiderationAmount(event);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Stamp Denomination */}
                        <div className="FieldBox">
                            <div className="FieldBoxLabel">
                                <Typography variant="darkValue">
                                    Enter Stamp Duty
                                    <HowerTooltip
                                        message={STAMPDUTYINFO(paymentConfig?.minStampDuty)}
                                    />
                                </Typography>

                                {/* <HowerTooltip message={['thehr']} /> */}
                            </div>
                            <div className="FieldBoxInput">
                                <TextField
                                    disabled={
                                        paymentConfig?.dutyType?.id ===
                                        STAMPDUTYCALCULATIONOPTIONS?.CONSIDERATIONPRICE?.id
                                    }
                                    error={
                                        !(
                                            paymentForm?.errors?.stampDuty === 'error' ||
                                            paymentForm?.errors?.stampDuty === 'noerror'
                                        )
                                    }
                                    helperText={
                                        paymentForm?.errors?.stampDuty === 'error' ||
                                        paymentForm?.errors?.stampDuty === 'noerror'
                                            ? ''
                                            : paymentForm?.errors?.stampDuty
                                    }
                                    // value='Name of the uploaded document'
                                    inputProps={{
                                        type: 'text',
                                        maxlength: 20,
                                        minlength: 1
                                    }}
                                    value={paymentForm?.stampDuty}
                                    required
                                    id="stampDuty"
                                    name="stampDuty"
                                    type="text"
                                    fullWidth
                                    onKeyDown={(e) =>
                                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    }
                                    onInput={handlePayment}
                                    onChange={handlePayment}
                                />
                            </div>
                        </div>

                        {/* Pan Card */}
                        {Number(paymentForm?.stampDuty) >
                            paymentConfig?.validateStampDutyResponse?.stampDutyAmount &&
                            paymentForm?.errors?.stampDuty === 'noerror' && (
                                <div className="FieldBox">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">
                                            Enter PAN of stamp duty payer <br /> (
                                            {
                                                paymentConfig?.validateStampDutyResponse
                                                    ?.panCardRequiredPersonName
                                            }
                                            )
                                        </Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <TextField
                                            error={
                                                !(
                                                    paymentForm?.errors?.panCard === 'error' ||
                                                    paymentForm?.errors?.panCard === 'noerror'
                                                )
                                            }
                                            helperText={
                                                paymentForm?.errors?.panCard === 'error' ||
                                                paymentForm?.errors?.panCard === 'noerror'
                                                    ? ''
                                                    : paymentForm?.errors?.panCard
                                            }
                                            inputProps={{
                                                type: 'text',
                                                maxlength: 10,
                                                minlength: 10
                                            }}
                                            required
                                            type="text"
                                            id="panCard"
                                            name="panCard"
                                            value={paymentForm?.panCard || ''}
                                            onChange={handlePancardChange}
                                            placeholder="Enter PAN"
                                            fullWidth
                                            style={{ marginBottom: '20px' }}
                                        />
                                    </div>
                                </div>
                            )}

                        {/* Storage Type */}
                        <div className="FieldBox">
                            <div className="FieldBoxLabel">
                                <Typography variant="darkValue">Storage type</Typography>
                            </div>
                            <div className="FieldBoxInput">
                                {/* <Typography variant="darkValue">
                                    {paymentConfig?.docType?.name}
                                </Typography> */}
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="storageType"
                                        value={paymentForm?.storageType}
                                        onChange={handleStorageTypeChange}>
                                        {storageTypeList?.length > 0 &&
                                            storageTypeList.map((storageoption, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={storageoption?.id}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: 'var(--g2)',
                                                                '&.Mui-checked': {
                                                                    color: '#33AFB9'
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="darkValue">
                                                            {storageoption?.label}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        {/* Order Type */}
                        <div className="FieldBox">
                            <div className="FieldBoxLabel">
                                <Typography variant="darkValue">Document type</Typography>
                            </div>
                            <div className="FieldBoxInput">
                                <Typography variant="darkValue">
                                    {paymentConfig?.docType?.name}
                                </Typography>
                                {/* <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="orderType"
                                        value={paymentForm?.orderType}
                                        onChange={handleOrderTypeChange}>
                                        {orderTypesArray.length > 0 &&
                                            orderTypesArray.map((orderType, Accordindex) => (
                                                <FormControlLabel
                                                    key={Accordindex}
                                                    value={orderType?.id}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                              color: 'var(--g2)',
                                                              '&.Mui-checked': {
                                                                color: '#33AFB9'
                                                              }
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="darkValue">
                                                            {orderType?.name}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                    </RadioGroup>
                                </FormControl> */}
                            </div>
                        </div>

                        {/* Shipping address Form */}
                        {paymentConfig?.docType?.id === DOCTYPE?.PHYSICAL?.ID && (
                            <div className="details_box">
                                <div className="shippingAddress-head">
                                    <div className="details_box-content">
                                        <div className="details_box-content_head">
                                            <Typography variant="darkValueLarge">
                                                Shipping address
                                            </Typography>
                                        </div>
                                        <div className="details_box-content_link">
                                            <FormControlLabel
                                                sx={{ mt: '-10px', mr: '0px' }}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: 'var(--g2)',
                                                            '&.Mui-checked': {
                                                                color: '#33AFB9'
                                                            }
                                                        }}
                                                        checked={useProfileAddress}
                                                        name="useProfileAddress"
                                                        id="useProfileAddress"
                                                        paymentConfig
                                                        onChange={handleUseProfileAddress}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--g7)',
                                                            fontWeight: 600,
                                                            fontSize: '15px'
                                                        }}>
                                                        Use my profile address
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: '30px' }}>
                                    <div className="FieldBox">
                                        <div className="shippingAddress_container-title">
                                            <Typography variant="darkValue">Name</Typography>
                                        </div>
                                        <div className="shippingAddress_container-field">
                                            <TextField
                                                value={paymentForm?.name || ''}
                                                required
                                                id="name"
                                                name="name"
                                                type="text"
                                                inputProps={{
                                                    type: 'text',
                                                    maxlength:
                                                        CLVALIDATIONS?.DELIVERYNAME?.MAXLENGTH,
                                                    minlength:
                                                        CLVALIDATIONS?.DELIVERYNAME?.MINLENGTH
                                                }}
                                                fullWidth
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value === '' ||
                                                        CLVALIDATIONS?.DELIVERYNAME?.PATTERN.test(
                                                            event?.target?.value
                                                        )
                                                    ) {
                                                        handlePaymentFormChange(event);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="FieldBox">
                                        <div className="shippingAddress_container-title">
                                            <Typography variant="darkValue">
                                                Address Line 1
                                            </Typography>
                                        </div>
                                        <div className="shippingAddress_container-field">
                                            <TextField
                                                value={paymentForm?.address || ''}
                                                required
                                                inputProps={{
                                                    type: 'text',
                                                    maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                                    minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                                }}
                                                id="address"
                                                name="address"
                                                type="text"
                                                fullWidth
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value === '' ||
                                                        CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                            event?.target?.value
                                                        )
                                                    ) {
                                                        handlePaymentFormChange(event);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="FieldBox">
                                        <div className="shippingAddress_container-title">
                                            <Typography variant="darkValue">
                                                Address Line 2
                                            </Typography>
                                        </div>
                                        <div className="shippingAddress_container-field">
                                            <TextField
                                                value={paymentForm?.addressLineTwo || ''}
                                                required
                                                inputProps={{
                                                    type: 'text',
                                                    maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                                    minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                                }}
                                                id="address"
                                                name="addressLineTwo"
                                                type="text"
                                                fullWidth
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value === '' ||
                                                        CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                            event?.target?.value
                                                        )
                                                    ) {
                                                        handlePaymentFormChange(event);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="FieldBox">
                                        <div className="shippingAddress_grid-title">
                                            <Typography variant="darkValue">State</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <Select
                                                name="stateId"
                                                value={paymentForm?.stateId || ''}
                                                onChange={(event) => {
                                                    setPaymentForm((prev) => ({
                                                        ...prev,
                                                        [event.target.name]: event.target.value,
                                                        cityId: ''
                                                    }));
                                                }}
                                                required
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ fontSize: '16px' }}
                                                MenuProps={MenuProps}
                                                fullWidth>
                                                <MenuItem value="" disabled>
                                                    <Typography>Select State</Typography>
                                                </MenuItem>

                                                {stateArray &&
                                                    stateArray.map((item) => (
                                                        <MenuItem
                                                            sx={{ fontSize: '16px' }}
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                        <div
                                            className="shippingAddress_grid-title"
                                            style={{ justifyContent: 'center' }}>
                                            <Typography variant="darkValue">City</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <Select
                                                name="cityId"
                                                value={paymentForm?.cityId || ''}
                                                onChange={(event) => {
                                                    setPaymentForm((prev) => ({
                                                        ...prev,
                                                        [event.target.name]: event.target.value
                                                    }));
                                                }}
                                                required
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ fontSize: '16px' }}
                                                MenuProps={MenuProps}
                                                fullWidth>
                                                <MenuItem value="" disabled>
                                                    <Typography>Select City</Typography>
                                                </MenuItem>
                                                {cityArray &&
                                                    cityArray.map((item) => (
                                                        <MenuItem
                                                            sx={{ fontSize: '16px' }}
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="FieldBox">
                                        <div className="shippingAddress_grid-title">
                                            <Typography variant="darkValue">Pincode</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <TextField
                                                value={paymentForm?.pinCode || ''}
                                                required
                                                id="pinCode"
                                                name="pinCode"
                                                type="text"
                                                inputProps={{
                                                    type: 'text',
                                                    // inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                    maxlength: 6,
                                                    minlength: 6
                                                }}
                                                // onKeyDown={(e) =>
                                                //   exceptThisSymbols.includes(e.key) &&
                                                //     e.preventDefault()
                                                // }
                                                fullWidth
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                                    ) {
                                                        handlePaymentFormPinCodeChange(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="shippingAddress_grid-title"
                                            style={{ justifyContent: 'center' }}>
                                            <Typography variant="darkValue">Phone</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <TextField
                                                value={paymentForm?.phone || ''}
                                                required
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                inputProps={{
                                                    type: 'text',
                                                    // inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                    maxlength: 10,
                                                    minlength: 10
                                                }}
                                                // onKeyDown={(e) =>
                                                //   exceptThisSymbols.includes(e.key) &&
                                                //     e.preventDefault()
                                                // }
                                                // onInput={(e) => {
                                                //   e.target.value = Math.max(
                                                //     0,
                                                //     parseInt(e.target.value)
                                                //   )
                                                //     .toString()
                                                //     .slice(0, 10)
                                                // }}
                                                fullWidth
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                                    ) {
                                                        handlePaymentFormChange(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Payment summary  */}
                        <div
                            style={{
                                border: '0.5px solid #bfbfbf',
                                borderRadius: '5px',
                                padding: '1px'
                            }}>
                            <div className="customDocument_paymentSummarySegregation">
                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            Stamp duty amount
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {documentPaymentDetails?.stampDuty
                                                ? documentPaymentDetails?.stampDuty
                                                : 0}
                                            /-
                                        </Typography>
                                    </div>
                                </div>
                                {documentPaymentDetails?.miscChargePercent?.map((item) => (
                                    <div
                                        className="customDocument_paymentSummaryContainer"
                                        key={item.id}>
                                        <div className="customDocument_paymentSummaryContainer-title">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {item.valueName} {` (${item.value}%)`}
                                            </Typography>
                                        </div>
                                        <div className="customDocument_paymentSummaryContainer-field">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {COMMON?.RUPEESYMBOL}
                                                {item.defaultValue}
                                                /-
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                                {paymentConfig?.miscChargesStatic?.map((item) => (
                                    <div
                                        className="customDocument_paymentSummaryContainer"
                                        key={item.id}>
                                        <div className="customDocument_paymentSummaryContainer-title">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {item.valueName}
                                            </Typography>
                                        </div>
                                        <div className="customDocument_paymentSummaryContainer-field">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {COMMON?.RUPEESYMBOL}
                                                {item.value}
                                                /-
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="customDocument_paymentSummaryGst">
                                {PaymentDetails &&
                                    PaymentDetails.map((item, value) => (
                                        <div
                                            className="customDocument_paymentSummaryContainer"
                                            key={value}>
                                            <div className="customDocument_paymentSummaryContainer-title">
                                                <Typography
                                                    variant="darkValue"
                                                    sx={{ fontSize: '15px' }}>
                                                    {item.title}
                                                </Typography>
                                            </div>
                                            <div className="customDocument_paymentSummaryContainer-field">
                                                <Typography
                                                    variant="darkValue"
                                                    sx={{ fontSize: '15px' }}>
                                                    {COMMON?.RUPEESYMBOL}
                                                    {documentPaymentDetails[`${item?.amountTitle}`]
                                                        ? documentPaymentDetails[
                                                              `${item?.amountTitle}`
                                                          ]
                                                        : 0}
                                                    /-
                                                </Typography>
                                            </div>
                                        </div>
                                    ))}
                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            Storage charges
                                            {`(${
                                                Number(paymentForm?.storageType) ===
                                                STORAGETYPEOPTIONS?.FREETIER?.id
                                                    ? STORAGETYPEOPTIONS?.FREETIER?.label
                                                    : STORAGETYPEOPTIONS?.PAIDTIER?.label
                                            })`}
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {documentPaymentDetails?.storageCharge
                                                ? documentPaymentDetails?.storageCharge
                                                : 0}
                                            /-
                                        </Typography>
                                    </div>
                                </div>
                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            eSign charges
                                            {`(${paymentConfig?.esignRequired} Signatures Required)`}
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {paymentConfig?.esignCharge
                                                ? paymentConfig?.esignCharge
                                                : 0}
                                            /-
                                        </Typography>
                                    </div>
                                </div>
                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            Total charges
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {documentPaymentDetails?.totalGstCharge
                                                ? documentPaymentDetails?.totalGstCharge.toFixed(2)
                                                : 0}
                                            /-
                                        </Typography>
                                    </div>
                                </div>

                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            GST on charges {`(${paymentConfig?.gst}%)`}
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {documentPaymentDetails?.gst
                                                ? documentPaymentDetails?.gst?.toFixed(2)
                                                : 0}
                                            /-
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="customDocument_paymentSummarySegregation">
                                <div className="customDocument_paymentSummaryContainer">
                                    <div className="customDocument_paymentSummaryContainer-title">
                                        <Typography variant="labelSmall" sx={{ fontSize: '15px' }}>
                                            Total Payable
                                        </Typography>
                                    </div>
                                    <div className="customDocument_paymentSummaryContainer-field">
                                        <Typography variant="labelSmall" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}{' '}
                                            {documentPaymentDetails?.total.toFixed(2)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Agree and procced */}
                        <>
                            <div style={{ marginTop: '20px' }}>
                                <FormControlLabel
                                    sx={{ mb: '15px', mr: 0 }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {
                                                setPaymentForm((prev) => ({
                                                    ...prev,
                                                    [event.target.name]: event.target.checked
                                                }));
                                            }}
                                            sx={{
                                                color: 'var(--g2)',
                                                '&.Mui-checked': {
                                                    color: '#33AFB9'
                                                }
                                            }}
                                            required
                                            name="paymentTerms"
                                            id="paymentTerms"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree,&nbsp; MDR charges will be added by payment
                                            gateway on top of amount shown.
                                        </Typography>
                                    }
                                />
                                {/* <FormControlLabel
                                    sx={{ mb: '15px', mr: 0, width: '100%' }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {
                                              setPaymentForm((prev) => ({
                                                ...prev,
                                                [event.target.name]: event.target.checked
                                              }))
                                            }}
                                            sx={{
                                              color: 'var(--g2)',
                                              '&.Mui-checked': {
                                                color: '#33AFB9'
                                              }
                                            }}
                                            required
                                            name="paymentTerms"
                                            id="paymentTerms"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree with &nbsp;
                                            <Link
                                                to={`/termsAndConditions/${TERMTYPES.TERMSANDCONDITIONS.value}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-link"
                                                color="var(--g7)">
                                                Terms and Conditions.
                                            </Link>
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ mb: '15px', mr: 0, width: '100%' }}
                                    control={
                                        <Checkbox
                                            onClick={(event) => {
                                              setPaymentForm((prev) => ({
                                                ...prev,
                                                [event.target.name]: event.target.checked
                                              }))
                                            }}
                                            sx={{
                                              color: 'var(--g2)',
                                              '&.Mui-checked': {
                                                color: '#33AFB9'
                                              }
                                            }}
                                            required
                                            name="disclaimer"
                                            id="disclaimer"
                                        />
                                    }
                                    label={
                                        <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                            I agree with&nbsp;
                                            <Link
                                                to={`/termsAndConditions/${TERMTYPES.DISCLAIMER.value}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-link"
                                                color="var(--g7)">
                                                Disclaimer.
                                            </Link>
                                        </Typography>
                                    }
                                /> */}
                            </div>
                            <Button variant="primary" type="submit">
                                Proceed to payment
                            </Button>
                        </>
                    </div>
                </form>
            </AccordionDetails>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Accordion>
    );
};

export default PaymentAccord;

PaymentAccord.propTypes = {
    expanded: PropTypes.number,
    handleExpandChange: PropTypes.func,
    paymentDetails: PropTypes.array,
    Accordindex: PropTypes.number,
    documentId: PropTypes.string,
    paymentForm: PropTypes.object,
    setPaymentForm: PropTypes.func,
    handlePaymentInitialization: PropTypes.func
};
