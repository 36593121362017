import React from 'react'
import { IconButton, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import PropTypes from 'prop-types'
import { StyledTooltip } from '../../StyledComponents/TootipStyles/StyledTooltip'
const HowerTooltip = ({
  message = []
}) => {
  return (
        <StyledTooltip
            placement="right"
            PopperProps={{
              disablePortal: true
            }}
            title={
                <>
                    {message &&
                        message?.map((item, indexImpNotes) => (
                            <div className="popover_box-details1" key={indexImpNotes}>
                                <Typography
                                    variant="modalLabel"
                                    sx={{
                                      color: '#555555',
                                      letterSpacing: '0.03em',
                                      lineHeight: '20px'
                                    }}>
                                    {item}
                                </Typography>
                            </div>
                        ))}
                </>
            }
            arrow>
            <IconButton sx={{ color: '#1caeb2' }}>
                <InfoIcon />
            </IconButton>
        </StyledTooltip>
  )
}

export default HowerTooltip
HowerTooltip.propTypes = {
  message: PropTypes.array,
  openTooltip: PropTypes.bool,
  setOpenTooltip: PropTypes.func,
  handleTooltipOpen: PropTypes.func,
  handleTooltipClose: PropTypes.func
}
