import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BulkTransactionDocumentDetailsTable from './components/BulkTransactionDocumentDetailsTable'
import { useGetAllDocumentsByBulkIdQuery } from '../../../store/services/documentServices'

const BulkTransactionDocumentDetails = () => {
  const { bulkId } = useParams()

  const navigate = useNavigate()

  // Get  Document data according to Bulk id
  const { data: bulkDocumentDetailsList = [], isLoading } = useGetAllDocumentsByBulkIdQuery(
    bulkId,
    {
      skip: !bulkId
    }
  )

  // const bulkDocumentDetailsList = [
  //     {
  //         bulkFileId: 54,
  //         totalDocuments: 120,
  //         articleCategory: {
  //             name: 'TS'
  //         },
  //         uploadedBy: {
  //             name: 'uygd'
  //         },
  //         totalAmount: 1212,
  //         status: {
  //             name: 'dsd',
  //             id: 4
  //         },
  //         completedDate: '12-10-2023'
  //     }
  // ];

  return (
        <Box sx={{ p: 4 }}>
            <Grid container justifyContent="space-between" mb={5}>
                <Grid item lg={4}>
                    <Typography variant="darkValueLarge">
                        List of details under the uploaded bulk file
                    </Typography>
                </Grid>
                <Grid item lg={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="link" onClick={() => navigate(-1)}>
                        BACK
                    </Button>
                </Grid>
            </Grid>
            <BulkTransactionDocumentDetailsTable
                bulkDocumentDetailsList={bulkDocumentDetailsList}
                isLoading={isLoading}
            />
        </Box>
  )
}

export default BulkTransactionDocumentDetails
