import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material'
import PropTypes from 'prop-types'
import { PATTERN } from '../../constants/enum'
import palette from '../../theme/palette'

const RadiowithTextfield = ({
  radioData,
  textFieldData,
  fieldLabel,
  documentForm,
  onchange,
  onfocus,
  sectionIndex
}) => {
  const radioValue =
        !documentForm?.form[`${radioData?.id}`] && documentForm?.form[`${radioData?.id}`] !== ''
          ? radioData?.props?.prefilled
          : documentForm?.form[`${radioData?.id}`]
  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <FormControl fullWidth>
                    <RadioGroup
                        row
                        id={radioData?.id}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name={`span${radioData?.id}`}
                        value={
                            !documentForm?.form[`${radioData?.id}`] &&
                            documentForm?.form[`${radioData?.id}`] !== ''
                              ? radioData?.props?.prefilled
                              : documentForm?.form[`${radioData?.id}`]
                        }
                        onChange={(event) => onchange(event, radioData)}>
                        {JSON.parse(radioData?.props?.options.replace(/'/g, '"')).length > 0 &&
                            JSON.parse(radioData?.props?.options.replace(/'/g, '"')).map(
                              (item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={item}
                                        control={
                                            <Radio
                                                sx={{
                                                  color: palette.text.main,
                                                  '&.Mui-checked': {
                                                    color: palette.success.dark
                                                  }
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="labelSmall"
                                                sx={{
                                                  color: palette.text.dark,
                                                  fontSize: '14px'
                                                }}>
                                                {item}
                                            </Typography>
                                        }
                                    />
                              )
                            )}
                    </RadioGroup>
                </FormControl>
                <TextField
                    key={textFieldData?.id}
                    error={documentForm?.errors[`${textFieldData?.id}`]}
                    helperText={documentForm?.errors[`${textFieldData?.id}`]}
                    placeholder={textFieldData?.placeholderName}
                    value={
                        !documentForm?.form[`${textFieldData?.id}`] &&
                        documentForm?.form[`${textFieldData?.id}`] !== ''
                          ? textFieldData?.props?.prefilled
                          : documentForm?.form[`${textFieldData?.id}`]
                    }
                    required
                    id={textFieldData?.id}
                    name={`span${textFieldData?.id}`}
                    type={textFieldData?.type}
                    onChange={(event) => {
                      if (textFieldData?.type === 'currency') {
                        if (
                          event.target.value === '' ||
                                PATTERN?.NUMBERONLY.test((event?.target?.value).replaceAll(',', ''))
                        ) {
                          onchange(event, textFieldData, sectionIndex)
                        }
                      } else {
                        onchange(event, textFieldData, sectionIndex)
                      }
                    }}
                    onFocus={(event) => onfocus(event, textFieldData)}
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                {textFieldData?.props?.prefix || ''}
                            </InputAdornment>
                      )
                    }}
                    fullWidth
                    disabled={radioValue === 'No'}
                />
            </div>
        </div>
  )
}

export default RadiowithTextfield

RadiowithTextfield.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  documentForm: PropTypes.object,
  radioData: PropTypes.object,
  textFieldData: PropTypes.object,
  sectionIndex: PropTypes.number
}
