import React, { useState } from 'react'
import ReviewAndConsentTable from './components/ReviewAndConsentTable'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField
} from '@mui/material'
import calendericon from '../../../assets/images/calendericon.svg'
import search from '../../../assets/images/searchicon.svg'
import DateRangeComponent from '../../../components/DateRange/DateRange'
import { PLACEHOLDER } from '../../../constants/placeholder'
import { PAGINATIONINITIALS } from '../../../constants/enum'
import {
  useGetAllReviewConsentDocumentsQuery,
  useGetDocumentReviewFilterStatusQuery
} from '../../../store/services/documentServices'
import moment from 'moment'

const ReviewAndConsent = () => {
  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Filter initial object
  const filterObj = {
    status: 1,
    limit: 100,
    search: '',
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
  }

  // Filter state
  const [filter, setFilter] = useState(filterObj)
  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Get all document data
  const { data: { list = [], totalCount = 0 } = {}, isLoading } =
        useGetAllReviewConsentDocumentsQuery({
          page: paginationFilter?.page,
          size: paginationFilter?.rowsPerPage,
          status: filter?.status,
          search: filter?.search,
          fromDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.startDate).format('YYYY-MM-DD')
            : '',
          toDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.endDate).format('YYYY-MM-DD')
            : ''
        })

  /**
     * Handle filter status change
     * @param {object} event: Event object
     */
  const handleStatusChange = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  /**
     * Handle filter date range change
     * @param {array} item: date range array
     */
  const dateRangeHandler = (item) => {
    const dateRangeValue = [
      {
        startDate: item[0]?.startDate,
        endDate: item[0]?.endDate,
        key: 'selection',
        skip: false
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
    resetPage()
  }

  /**
     * Handle filter search change
     * @param {object} event: Event object
     */
  const handleSearchTextfield = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    resetPage()
  }

  // Reset page to the initial page [initial Page=0]
  const resetPage = () => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: PAGINATIONINITIALS?.PAGE
    }))
  }
  const { data: documentStatusArray = [] } = useGetDocumentReviewFilterStatusQuery({})

  return (
        <>
            <Grid
                container
                spacing={3}
                marginBottom={3}
                direction="row"
                justifyContent={'center'}
                alignItems="center">
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Select
                        name="status"
                        value={filter?.status}
                        onChange={handleStatusChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          fontSize: '16px'
                        }}
                        MenuProps={MenuProps}
                        fullWidth>
                        {documentStatusArray &&
                            documentStatusArray.map((item, index) => (
                                <MenuItem key={index} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <div className="date-range-input-container">
                        <TextField
                            aria-describedby={id}
                            onClick={handleClick}
                            type="text"
                            value={
                                !filter?.dateRange[0].skip
                                  ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                  : 'Date Range'
                            }
                            readOnly
                            fullWidth
                        />
                        <img src={calendericon} alt="calender" className="calendar-icon" />
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}>
                        <DateRangeComponent
                            maxDate={new Date()}
                            dateRange={filter?.dateRange}
                            setDateRange={dateRangeHandler}
                        />
                    </Popover>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                    <TextField
                        value={filter?.search}
                        name="search"
                        id="search"
                        placeholder={PLACEHOLDER.SEARCHDOCUMENT}
                        onChange={handleSearchTextfield}
                        InputLabelProps={{
                          shrink: false
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '5px'
                          },
                          startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton aria-label="toggle password visibility">
                                        <img src={search} alt="search" />
                                    </IconButton>
                                </InputAdornment>
                          )
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <ReviewAndConsentTable
                documentTableData={list}
                isLoading={isLoading}
                paginationFilter={paginationFilter}
                setPaginationFilter={setPaginationFilter}
                totalCount={totalCount}
            />
        </>
  )
}

export default ReviewAndConsent
