import React from 'react'
import parse from 'html-react-parser'
import { useParams } from 'react-router-dom'
import { useGetDocumentPreviewByIdQuery } from '../../../../store/services/documentServices'

const PreviewScreenHtml = () => {
  const { documentId } = useParams()

  // Get html content by document
  const { data: documentPreview = { content: '<div>Loading</div>' } } =
        useGetDocumentPreviewByIdQuery(documentId, {
          skip: !documentId
        })

  return <div>{parse(documentPreview?.content) ? parse(documentPreview?.content) : ''}</div>
}

export default PreviewScreenHtml
