import React from 'react'
import { Tooltip, styled } from '@mui/material'

// export const StyledTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))`
//     & .MuiTooltip-tooltip {
//         opacity: 1;
//         transform: none;
//         transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//             transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//         border: 1px solid rgba(0, 0, 0, 0.15);
//         background-color: #f5f7f9;
//         box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
//         color: #7d7d7e;
//     }

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        PopperProps={{
          modifiers: [
            {
              name: 'zIndex',
              enabled: true,
              phase: 'afterWrite',
              fn: ({ state }) => {
                state.styles.popper.zIndex = 9999 // Set z-index for the Popper element
              }
            }
          ]
        }}
    />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    opacity: '1',
    transform: 'none',
    transition:
            'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform: 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    backgroundColor: '#f5f7f9 !important',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
    color: '#7d7d7e',
    padding: '10px',
    maxWidth: 500
  }
}))
