import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography,
  MenuItem,
  Box,
  FormControl,
  RadioGroup,
  Radio
} from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './PaymentSummary.css'
import {
  useGetStateQuery,
  useGetCityQuery,
  useGetProfileQuery
} from '../../../../store/services/userServices'
import {
  CLVALIDATIONS,
  COMMON,
  DOCTYPE,
  ORDER_TYPE,
  PATTERN,
  PAYMENT_TYPE,
  ROLES
} from '../../../../constants/enum'
import AlertModal from '../../../../components/AlertModal/AlertModal'
import { ALERT_MESSAGE } from '../../../../constants/message'
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles'
import {
  PAYMENTDETAIL,
  STAMPDUTYCALCULATIONOPTIONS,
  STORAGETYPEOPTIONS
} from '../../../../constants/documentConstans'
import {
  useUpdateDocumentPaymentStausMutation,
  useGenerateDocumentPaymentIdMutation,
  useGetPaymentConfigDetailsQuery,
  useInitateDocumentPaymentCorpBaMutation,
  useUploadDocumentHtmlTopdfMutation,
  useAddPanForStampDutyMutation
} from '../../../../store/services/documentServices'
import { paymentInitialized } from '../../../../util/razorpayPayment'
import CustomCard from '../../../../components/CustomCard'
import palette from '../../../../theme/palette'
import { ProfileContext } from '../../../../contextapi/ProfileContext'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import SignatureModal from '../../../../components/SignatureModal/SignatureModal'
import { useGetuploadDocumentPreviewByIdQuery } from '../../../../store/services/uploadDocumentServices'
// import { generateDocument } from '../../../../util/generateClDocument'
import { toast } from 'react-toastify'
import { useLazyVerifyPinCodeServiceQuery } from '../../../../store/services/commonServices'
import CommonMessageModal from '../../../../components/commonMessageModal/CommonMessageModal'
import { validateStampDuty, validator } from '../../../../util/validator'
import { generateStampPaper } from '../../../../util/stampPaperHelper'
import { roundFloor } from '../../../../util/helper'
const PaymentSummary = () => {
  // Load razorpay script
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  // Profile context api
  const { profile } = useContext(ProfileContext)

  // Storage type options
  const storageTypeList = [STORAGETYPEOPTIONS?.FREETIER, STORAGETYPEOPTIONS?.PAIDTIER]

  const navigate = useNavigate()

  const { documentId } = useParams()

  // Open alert modal state on submission
  const [openAlertModal, setOpenAlertModal] = useState(false)

  // Payment summary form
  const [paymentSummaryForm, setPaymentSummaryForm] = useState({
    orderType:
            profile?.role === ROLES?.INDIVIDUAL ? ORDER_TYPE?.DELIVERY : ORDER_TYPE?.HARDCOPY,
    considerationPrice: '',
    storageType: 1,
    stampDuty: '',
    errors: {
      stampDuty: 'error',
      panCard: 'noerror'
    }
  })

  // Use profile address or not checkbox
  const [useProfileAddress, setUseProfileAddress] = useState(false)

  // Accept payment terms and conditions or not checkbox
  const [paymentTerms, setPaymentTerms] = useState({})

  // PinCode status deliverable or not
  const [pinCodeStatus, setPinCodeStatus] = useState(false)

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  // SIgnature modal state
  const [signatureModalState, setSignatureModalState] = useState(false)

  // Handle error notes modal state
  const [notesModalState, setNotesModalState] = useState({
    open: false,
    title: ALERT_MESSAGE?.CLERRORTITLE,
    message: '',
    buttonText: 'Continue'
  })

  // Payment details from the backend
  const [documentPaymentDetails, setDocumentPaymentDetails] = useState({
    total: 0
  })

  // Signature modal data state
  const [signatureModalDataState, setSignatureModalDataState] = useState({})

  // Get all states
  const { data: stateArray = [] } = useGetStateQuery({})

  // Get cities according to the state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: paymentSummaryForm.stateId },
    {
      skip: !paymentSummaryForm.stateId
    }
  )

  // Get profile data
  const { data: profileData = {}, isSuccess } = useGetProfileQuery({})

  // Get payment details by stamp denomination query
  const { data: paymentConfig = {}, isSuccess: paymentConfigSuccess } =
        useGetPaymentConfigDetailsQuery(documentId)

  // Get document preview
  const { data: documentPreview = {} } = useGetuploadDocumentPreviewByIdQuery(documentId, {
    skip: !documentId
  })

  // Verify pincode
  // const {
  //   data: panCardData = {
  //     data: false
  //   },
  //   // eslint-disable-next-line no-unused-vars
  //   isSuccess: pinCodeSuccess
  // } = useVerifyPinCodeServiceQuery(
  //   { pinCode: paymentSummaryForm.pinCode },
  //   {
  //     skip: paymentSummaryForm?.pinCode?.length !== 6
  //   }
  // )

  const [checkPinCode] = useLazyVerifyPinCodeServiceQuery({})

  // useEffect(() => {
  //   console.log('im here in the success')
  // }, [pinCodeSuccess])

  // Generate razorpay orderId
  const [generateDocumentRazPaymentID] = useGenerateDocumentPaymentIdMutation({})

  // Upload document pdf on successfull payment
  const [uploadDocumentPdf] = useUploadDocumentHtmlTopdfMutation()

  // Validate and add pan card for Estapm duty on stamp duty greater than the provided limit(above 2 lakh for now)
  const [addvalidatePanCardEstamp] = useAddPanForStampDutyMutation({})

  // Update document payment status
  const [updateDocumentPaymentStatus] = useUpdateDocumentPaymentStausMutation({})

  // Update document payment status  for BA or Corporation
  const [intializeDocumentPaymentCorpBa] = useInitateDocumentPaymentCorpBaMutation({})

  useEffect(() => {
    let staticMiscTotal = 0
    if (paymentConfig?.miscChargesStatic && paymentConfig?.miscChargesStatic.lenght !== 0) {
      staticMiscTotal = paymentConfig?.miscChargesStatic.reduce(
        (n, { value }) => n + value,
        0
      )
    }

    setDocumentPaymentDetails({
      stampDuty: '',
      platformCharge: paymentConfig?.platformCharge || 0,
      dispatchCharge: paymentConfig?.dispatchCharge || 0,
      eSignCharge: paymentConfig?.esignCharge || 0,
      printingCharge: 0,
      totalGstCharge:
                paymentConfig?.platformCharge +
                paymentConfig?.dispatchCharge +
                paymentConfig?.esignCharge +
                documentPaymentDetails?.printingCharge,
      gst: paymentConfig?.gstCharge,
      miscChargePercent: paymentConfig.miscChargesPercentage,
      total:
                paymentConfig?.platformCharge +
                paymentConfig?.dispatchCharge +
                paymentConfig?.esignCharge +
                paymentConfig?.gstCharge +
                staticMiscTotal
    })
  }, [paymentConfigSuccess])

  // Alert modal state
  const alertModalState = {
    title: 'Caution',
    message: ALERT_MESSAGE.CREATEDOCUMENT,
    cancelButtonText: 'No i want to recheck',
    submitButtonText: 'I agree'
  }

  // Payment details
  const paymentDetails = PAYMENTDETAIL

  const handleUseProfileAddress = (event) => {
    if (event.target.checked && isSuccess) {
      setPaymentSummaryForm((prev) => ({
        ...prev,
        name: profileData?.name,
        address: profileData?.address,
        addressLineTwo: '',
        stateId: profileData?.state?.id,
        cityId: profileData?.city?.id,
        pinCode: profileData?.pinCode,
        phone: profileData?.phone
      }))
      handleCheckPinCodeService(profileData?.pinCode)
    } else {
      setPaymentSummaryForm((prev) => ({
        ...prev,
        name: '',
        address: '',
        stateId: '',
        cityId: '',
        pinCode: '',
        phone: ''
      }))
    }
    setUseProfileAddress(event.target.checked)
  }

  /**
     * Handle form change in payment summary form
     * @param {object} event:event object
     */
  const handlePaymentFormChange = (event) => {
    console.log(event.target.value)
    setPaymentSummaryForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }

  /**
     * Handle change in storage type option and recalcuate the payment
     * @param {object} event: Event object
     */
  const handleStorageTypeChange = (event) => {
    setPaymentSummaryForm((prev) => ({
      ...prev,
      storageType: event.target.value
    }))
    console.log(event.target.value)

    generatePaymentCharges(Number(paymentSummaryForm?.stampDuty), Number(event.target.value))
  }

  /**
     * Handle form change in payment summary form
     * @param {object} event:event object
     */
  const handlePaymentFormPinCodeChange = (event) => {
    console.log(event.target.value)
    setPaymentSummaryForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    if (event.target.value?.length === 6) {
      handleCheckPinCodeService(event.target.value)
    }
  }

  const handleCheckPinCodeService = (pinCode) => {
    setBackdropState(true)
    const dataToSend = {
      pinCode
    }
    checkPinCode(dataToSend)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        console.log(response, 'response')
        setPinCodeStatus(response?.data)
        if (response?.data) {
          toast.success(ALERT_MESSAGE?.PINCODESERVICEABLE)
        } else {
          toast.error(ALERT_MESSAGE?.PINOCDENOTSERVICEABLE)
        }
      })
      .catch((exception) => {
        setBackdropState(false)
        setPinCodeStatus(false)
        console.log('EXCEPTION:DOCUMENT INITIALIZED', exception)
      })
  }

  /**
     * Handle give minimum stamp duty charges required
     * @param {number} stampDuty: stamp duty
     * @returns {object} The stationary printing charge in stamp duty range
     */
  const findNearestStationaryChargeRange = (stampDuty) => {
    try {
      const chargeList = paymentConfig?.stationaryPrintCharges
      if (stampDuty === 0) {
        return { charge: 0 }
      } else if (stampDuty >= chargeList[chargeList.length - 1].maxAmount) {
        return chargeList[chargeList.length - 1]
      } else if (stampDuty <= chargeList[0].maxAmount) {
        return chargeList[0]
      } else {
        for (let i = 1; i < chargeList.length; i++) {
          if (stampDuty <= chargeList[i].maxAmount) {
            return chargeList[i]
          }
        }
      }
    } catch (err) {
      console.log('err in promotion list', err)
    }
  }

  // Typing timeout used to clear timeout
  let typingTimeout = null

  /**
     * Get all payment details by stamp denomination
     * @param {Object} event:Event object
     */
  const handleStampDuty = (event) => {
    console.log(event.target.value)
    // Debouncing affect used (as user stops typing  get payment details from backend)
    clearTimeout(typingTimeout)
    const stampError = validateStampDuty(
      Number(event.target.value),
      Number(paymentConfig?.minStampDuty),
      Number(paymentConfig?.maxStampDuty)
    )
    setPaymentSummaryForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      errors: {
        ...prev.errors,
        stampDuty: stampError
      }
    }))
    typingTimeout = setTimeout(async () => {
      generatePaymentCharges(
        Number(event.target.value),
        Number(paymentSummaryForm?.storageType)
      )

      // const stationaryPrintingCharge =
      //           paymentConfig?.stationaryPrintCharges?.length > 0
      //             ? await findNearestStationaryChargeRange(Number(event.target.value))
      //             : { charge: 0 }

      // const stationaryChargeGst =
      //           (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100

      // console.log('stati', stationaryPrintingCharge, stationaryChargeGst)

      // setDocumentPaymentDetails((prev) => ({
      //   ...prev,
      //   [event.target.name]: event.target.value,
      //   gst: paymentConfig?.gstCharge + stationaryChargeGst,
      //   printingCharge: stationaryPrintingCharge?.charge,
      //   total:
      //               paymentConfig?.platformCharge +
      //               paymentConfig?.dispatchCharge +
      //               paymentConfig?.esignCharge +
      //               paymentConfig?.gstCharge +
      //               stationaryPrintingCharge?.charge +
      //               +stationaryChargeGst +
      //               Number(event.target.value)
      // }))
    }, 500)
    console.log('paymetnt summary', paymentSummaryForm)
  }

  const handlePaymentIntiaiteStep2 = () => {
    if (paymentSummaryForm?.errors?.stampDuty === 'noerror') {
      if (!pinCodeStatus && paymentConfig?.docType?.id === DOCTYPE?.PHYSICAL?.ID) {
        toast.error(ALERT_MESSAGE?.PINOCDENOTSERVICEABLE)
      } else {
        setOpenAlertModal(true)
      }
    }
  }

  /**
     * Start Payment intialization process, razorpay window open
     * Payment success or failure update payment status on backend
     */
  const handleSubmitAlertModal = async () => {
    setOpenAlertModal(false)
    setBackdropState(true)
    const razorpayScript = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!razorpayScript) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const dataToSend = {
      stampDuty: Number(paymentSummaryForm?.stampDuty),
      docType: paymentConfig?.docType?.id,
      considerationAmount: paymentSummaryForm?.considerationAmount,
      storageType: paymentSummaryForm?.storageType,
      documentId,
      address: {
        name: paymentSummaryForm?.name,
        address: paymentSummaryForm?.address,
        addressLineTwo: paymentSummaryForm?.addressLineTwo,
        stateId: paymentSummaryForm?.stateId,
        cityId: paymentSummaryForm?.cityId,
        pinCode: paymentSummaryForm?.pinCode,
        phoneNumber: paymentSummaryForm?.phone
      },
      transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
    }
    generateDocumentRazPaymentID({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        console.log('reponse', response)
        setBackdropState(false)
        paymentInitialized(response)
          .then((paymentStatusResponse) => {
            console.log(paymentStatusResponse)
            updatePaymentIndividual(paymentStatusResponse)
          })
          .catch((paymentStatusexception) => {
            updatePaymentIndividual(paymentStatusexception)
          })
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('Order failed:Backend Failed', execption)
      })
  }

  /**
     * Update payment status
     * @param {Object} razorpayPaymentResponse:Razorpay Payment success or failure object
     */
  const updatePaymentIndividual = (razorpayPaymentResponse) => {
    setBackdropState(true)
    const dataToSend = {
      stampDuty: Number(paymentSummaryForm?.stampDuty),
      paymentGateway: razorpayPaymentResponse,
      considerationAmount: paymentSummaryForm?.considerationAmount,
      storageType: paymentSummaryForm?.storageType,
      address: {
        name: paymentSummaryForm?.name,
        address: paymentSummaryForm?.address,
        addressLineTwo: paymentSummaryForm?.addressLineTwo,
        stateId: paymentSummaryForm?.stateId,
        cityId: paymentSummaryForm?.cityId,
        pinCode: paymentSummaryForm?.pinCode,
        phoneNumber: paymentSummaryForm?.phone
      },
      documentId,
      transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
    }
    updateDocumentPaymentStatus({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setOpenAlertModal(false)
        setBackdropState(false)
        if (response?.error) {
          // toast.error(response?.errorMessage)
          setNotesModalState({
            open: true,
            title: ALERT_MESSAGE?.PINCODETITLE,
            message: response?.errorMessage,
            buttonText: 'Continue'
          })
        } else {
          const extraFieldResponse = {
            stateHeading: response?.stampPaperStateHeading,
            belowHeadingTitle:
                            response?.stampPaperStateSecondHeading +
                            response?.estampDataResponse.certificateNo
          }
          const stateId = response?.stateId
          handleSendContentPdf(stateId, response?.estampDataResponse, extraFieldResponse)
        }
        // handleSendContentPdf({
        //   certificateIssuedDate: '22-11-2023',
        //   firstParty: 'test',
        //   stampDutyAmount: '100',
        //   certificateNo: '1234567894',
        //   accountReference: '123456789',
        //   uniqueDocReference: '123456789',
        //   purchaseBy: 'test',
        //   descriptionOfDocument: 'test',
        //   propertyDescription: 'test',
        //   considerationPrice: '100',
        //   secondParty: 'tester',
        //   stampDutyPaidBy: 'test'
        // })

        // navigate('/thankYou', {
        //   state: {
        //     HeaderTitle: 'Create Document',
        //     documentId
        //   }
        // })
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT PAYMENT STATUS:Backend Failed', execption)
      })
  }

  const handlePaymentStatusCorpBa = () => {
    setOpenAlertModal(false)
    setBackdropState(true)
    const dataToSend = {
      documentId,
      stampDuty: Number(paymentSummaryForm?.stampDuty),
      docType: paymentConfig?.docType?.id,
      considerationAmount: paymentSummaryForm?.considerationAmount,
      storageType: paymentSummaryForm?.storageType,
      address: {
        name: paymentSummaryForm?.name,
        address: paymentSummaryForm?.address,
        addressLineTwo: paymentSummaryForm?.addressLineTwo,
        stateId: paymentSummaryForm?.stateId,
        cityId: paymentSummaryForm?.cityId,
        pinCode: paymentSummaryForm?.pinCode,
        phoneNumber: paymentSummaryForm?.phone
      },
      transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
    }
    intializeDocumentPaymentCorpBa({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        if (response?.error) {
          // toast.error(response?.errorMessage)
          setNotesModalState({
            open: true,
            title: ALERT_MESSAGE?.PINCODETITLE,
            message: response?.errorMessage,
            buttonText: 'Continue'
          })
        } else {
          const extraFieldResponse = {
            stateHeading: response?.stampPaperStateHeading,
            belowHeadingTitle:
                            response?.stampPaperStateSecondHeading +
                            response?.estampDataResponse.certificateNo
          }
          const stateId = response?.stateId
          handleSendContentPdf(stateId, response?.estampDataResponse, extraFieldResponse)
        }
        // handleSendContentPdf(response?.estampDataResponse)

        // navigate('/thankYou', {
        //   state: {
        //     HeaderTitle: 'Create Document',
        //     documentId
        //   }
        // })
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  const handleSendContentPdf = async (stateId, clResponse, extraFieldResponse) => {
    setBackdropState(true)

    // Add Cl Details
    const newPdfBlob = await generateStampPaper(
      stateId,
      documentPreview?.link,
      'custom',
      clResponse,
      extraFieldResponse
    )

    // const response = await fetch(documentPreview?.link) // Replace with the actual PDF link

    // const pdfArrayBuffer = await response.arrayBuffer()
    // const existingPdfBytes = pdfArrayBuffer

    // Load a PDFDocument from the existing PDF bytes
    // const pdfDoc = await PDFDocument.load(existingPdfBytes)
    // const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    // console.log('im here', pdfDoc, pdfArrayBuffer)
    // // Embed the Helvetica font
    // await pdfDoc.embedFont(StandardFonts.Helvetica)
    // pdfDoc.insertPage(0)

    // // Get the first page of the document
    // const pages = pdfDoc.getPages()

    // console.log('im here', pages)

    // for (let i = 0; i < pages.length; i++) {
    //   const firstPage = pages[i]
    //   const { width, height } = firstPage.getSize()

    //   firstPage.drawText('Header Text', {
    //     x: width / 2,
    //     y: height - 20,
    //     size: 10,
    //     font: timesRomanFont
    //   })

    //   // Add footer
    //   firstPage.drawText('Footer Text', {
    //     x: width / 2,
    //     y: 10,
    //     size: 10,
    //     font: timesRomanFont
    //   })
    // }

    // const pdfDOc23 = await pdfDoc.save()
    // const pdfBlob = await new Blob([pdfDOc23], { type: 'application/pdf' })

    // Create a download link and trigger the download
    // const link = document.createElement('a')
    // link.href = URL.createObjectURL(pdfBlob)
    // link.download = 'generated.pdf'
    // link.click()

    const dataToSend = {
      file: newPdfBlob
    }

    uploadDocumentPdf({ payload: dataToSend, documentId })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        setSignatureModalDataState({
          estampId: clResponse?.certificateNo,
          urn: clResponse?.urn
        })
        setSignatureModalState(true)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
    // setSignatureModalState(true)
  }

  const handlePancardChange = (event) => {
    const err = validator(event?.target?.name, event?.target?.value)

    setPaymentSummaryForm((prev) => ({
      ...prev,
      [event.target.name]: event?.target?.value?.toUpperCase(),
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))
  }

  // Handle validate and add pancard with first party details for estamp certificate when stamp duty is greater than the limit(2 lakh limit for now)
  const handleValidatePanCard = () => {
    // if (
    //   paymentForm?.errors?.stampDuty === 'noerror' &&
    //         paymentForm?.errors?.panCard === 'noerror'
    // ) {
    setBackdropState(true)
    const dataToSend = {
      reviewConsentId: paymentConfig?.validateStampDutyResponse?.reviewConsentId,
      documentId,
      panCardNumber: paymentSummaryForm?.panCard
    }
    addvalidatePanCardEstamp({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        handlePaymentIntiaiteStep2()
      })
      .catch((exception) => {
        setBackdropState(false)
      })
    // }
  }

  // Handle initiate payment process
  // If stamp duty is greater than 2 lakh check for pan card and then procced to step 2
  // Stamp duty less than  2 lakh  proceed directly
  const handlePaymentInitiationProcess = () => {
    if (
      paymentSummaryForm?.errors?.stampDuty === 'noerror' &&
            Number(paymentSummaryForm?.stampDuty) >
                paymentConfig?.validateStampDutyResponse?.stampDutyAmount &&
            paymentSummaryForm?.errors?.panCard === 'noerror'
    ) {
      handleValidatePanCard()
    } else if (
      paymentSummaryForm?.errors?.stampDuty === 'noerror' &&
            Number(paymentSummaryForm?.stampDuty) <=
                paymentConfig?.validateStampDutyResponse?.stampDutyAmount
    ) {
      handlePaymentIntiaiteStep2()
    }
  }

  function roundToNearestWhole (value) {
    if (value - Math.floor(value) >= 0.5) {
      return Math.ceil(value) // Round up
    } else {
      return Math.floor(value) // Round down
    }
  }

  const handleConsiderationAmount = async (event) => {
    const calculatedStampDuty = Math.ceil(
      ((paymentConfig?.considerationPercent / 100) * Number(event.target.value)).toFixed(2)
    )

    // Set minStampDutyRequired  according to the condition
    // If calculated stampDuty less than  minStampDuty set minStampDuty, check same for maxStampDuty if it is greater than set maxStampDuty , otherwise set calculatedStampDuty
    const minStampDutyRequired =
            calculatedStampDuty < paymentConfig?.minStampDuty
              ? paymentConfig?.minStampDuty
              : paymentConfig?.maxStampDuty && calculatedStampDuty > paymentConfig?.maxStampDuty
                ? paymentConfig?.maxStampDuty
                : calculatedStampDuty

    setPaymentSummaryForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      stampDuty: minStampDutyRequired,
      errors: {
        ...prev.errors,
        stampDuty: 'noerror' // added noerror  because  the template is consideration amount
      }
    }))

    generatePaymentCharges(minStampDutyRequired, Number(paymentSummaryForm?.storageType))

    // const stationaryPrintingCharge =
    //         paymentConfig?.stationaryPrintCharges.length > 0
    //           ? await findNearestStationaryChargeRange(Number(stampDuty))
    //           : { charge: 0 }
    // const stationaryChargeGst = (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100
    // console.log('stati', stationaryPrintingCharge, stationaryChargeGst)

    // setDocumentPaymentDetails((prev) => ({
    //   ...prev,
    //   stampDuty,
    //   gst: paymentConfig?.gstCharge + stationaryChargeGst,
    //   printingCharge: stationaryPrintingCharge?.charge,
    //   total:
    //             paymentConfig?.platformCharge +
    //             paymentConfig?.dispatchCharge +
    //             paymentConfig?.esignCharge +
    //             paymentConfig?.gstCharge +
    //             stationaryPrintingCharge?.charge +
    //             +stationaryChargeGst +
    //             Number(stampDuty)
    // }))
  }

  /**
     * Handle generate all the payment charges by stamp duty
     * @param {Number} stampDuty: Stamp duty amount
     * @param {Number} storageType: Storage type selected
     */
  const generatePaymentCharges = async (
    stampDuty,
    storageType = Number(paymentSummaryForm?.storageType)
  ) => {
    console.log('stati', stampDuty, storageType)

    const stationaryPrintingCharge =
            paymentConfig?.stationaryPrintCharges.length > 0
              ? await findNearestStationaryChargeRange(Number(stampDuty))
              : { charge: 0 }
    const stationaryChargeGst = (stationaryPrintingCharge?.charge * paymentConfig?.gst) / 100

    // Applicable storage charges
    const storageCharge =
            storageType === STORAGETYPEOPTIONS?.PAIDTIER?.id ? paymentConfig?.storageCharge : 0
    const storageChargeGst = paymentConfig?.isStorageGst
      ? (storageCharge * paymentConfig?.gst) / 100
      : 0

    const miscChargeData = documentPaymentDetails.miscChargePercent.map((item) => {
      return {
        ...item,
        defaultValue: roundToNearestWhole((stampDuty * item.value) / 100)
      }
    })
    const miscChargeTotal = miscChargeData.reduce((n, { defaultValue }) => n + defaultValue, 0)
    const staticMiscTotal = paymentConfig?.miscChargesStatic.reduce(
      (n, { value }) => n + value,
      0
    )
    const totalGstCharge =
            paymentConfig?.platformCharge +
            paymentConfig?.esignCharge +
            paymentConfig?.dispatchCharge +
            storageCharge +
            stationaryPrintingCharge?.charge

    // 1.)  The below calculation is done to deal with difference in calculation of gstCharge , basically done for matching the backend calculation
    // 2.) After the above we are asked to do the below calculation in case of IGST only, in case of SGST and CGST it is to be done as it was done previously
    let gstFull = 0
    if (!paymentConfig?.isIgst) {
      const gstSum = paymentConfig?.gstCharge + stationaryChargeGst + storageChargeGst
      const gstHalf = gstSum / 2
      const gstRounded = roundFloor(gstHalf)
      gstFull = gstRounded * 2
    } else {
      gstFull = paymentConfig?.gstCharge + stationaryChargeGst + storageChargeGst
    }
    setDocumentPaymentDetails((prev) => ({
      ...prev,
      stampDuty,
      gst: gstFull,
      storageCharge,
      totalGstCharge,
      printingCharge: stationaryPrintingCharge?.charge,
      miscChargePercent: miscChargeData,
      total:
                paymentConfig?.platformCharge +
                paymentConfig?.dispatchCharge +
                paymentConfig?.esignCharge +
                stationaryPrintingCharge?.charge +
                storageCharge +
                gstFull +
                Number(stampDuty) +
                miscChargeTotal +
                staticMiscTotal
    }))
  }

  return (
        <Box p={1}>
            <Grid container spacing={3}>
                <Grid item lg={6} sm={12} md={12} xs={12}>
                    <form
                        onSubmit={(event) => {
                          event.preventDefault()
                          handlePaymentInitiationProcess()
                        }}>
                        <CustomCard
                            headerTitle="Document details"
                            action={
                                <Button variant="link" onClick={() => navigate('/myDocument')}>
                                    Back
                                </Button>
                            }>
                            <Grid p={3}>
                                {/* Consideration Amount */}
                                {paymentConfig?.dutyType?.id ===
                                    STAMPDUTYCALCULATIONOPTIONS?.CONSIDERATIONPRICE?.id && (
                                    <Grid mb={2} display="flex">
                                        <Grid lg={6} sm={12} md={12} xs={12}>
                                            <Typography
                                                sx={{ width: '110px' }}
                                                variant="darkValue"
                                                component="div">
                                                Enter Consideration Amount
                                            </Typography>
                                        </Grid>

                                        <Grid ml={2} lg={12} sm={12} md={12} xs={12}>
                                            <TextField
                                                inputProps={{
                                                  type: 'text',
                                                  maxlength: 20,
                                                  minlength: 1
                                                }}
                                                value={paymentSummaryForm?.considerationAmount}
                                                required
                                                id="considerationAmount"
                                                name="considerationAmount"
                                                type="text"
                                                fullWidth
                                                // onKeyDown={(e) =>
                                                //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                                                // }
                                                // onInput={handlePayment}
                                                // onChange={handlePayment}
                                                onChange={(event) => {
                                                  if (
                                                    event.target.value === '' ||
                                                        PATTERN?.NUMBERWITHZERO.test(
                                                          event?.target?.value
                                                        )
                                                  ) {
                                                    handleConsiderationAmount(event)
                                                  }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {/* Stamp Duty */}
                                <Grid mb={2} display="flex">
                                    <Grid lg={6} sm={12} md={12} xs={12}>
                                        <Typography
                                            sx={{ width: '110px' }}
                                            variant="darkValue"
                                            component="div">
                                            Enter stamp duty
                                        </Typography>
                                    </Grid>

                                    <Grid ml={2} lg={12} sm={12} md={12} xs={12}>
                                        <TextField
                                            error={
                                                !(
                                                  paymentSummaryForm?.errors?.stampDuty ===
                                                        'error' ||
                                                    paymentSummaryForm?.errors?.stampDuty ===
                                                        'noerror'
                                                )
                                            }
                                            helperText={
                                                paymentSummaryForm?.errors?.stampDuty === 'error' ||
                                                paymentSummaryForm?.errors?.stampDuty === 'noerror'
                                                  ? ''
                                                  : paymentSummaryForm?.errors?.stampDuty
                                            }
                                            disabled={
                                                !paymentConfigSuccess ||
                                                paymentConfig?.dutyType?.id ===
                                                    STAMPDUTYCALCULATIONOPTIONS?.CONSIDERATIONPRICE
                                                      ?.id
                                            }
                                            value={paymentSummaryForm?.stampDuty || ''}
                                            id=" stampDuty"
                                            name="stampDuty"
                                            type="text"
                                            inputProps={{
                                              type: 'text',
                                              maxlength: 20,
                                              minlength: 1
                                            }}
                                            fullWidth
                                            onInput={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                    PATTERN?.NUMBERONLY.test(e?.target?.value)
                                              ) {
                                                handleStampDuty(e)
                                              }
                                            }}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                    PATTERN?.NUMBERONLY.test(e?.target?.value)
                                              ) {
                                                handleStampDuty(e)
                                              }
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>

                                {/* Pan Card */}
                                {Number(paymentSummaryForm?.stampDuty) >
                                    paymentConfig?.validateStampDutyResponse?.stampDutyAmount && (
                                    <Grid mb={2} display="flex">
                                        <Grid lg={6} sm={12} md={12} xs={12}>
                                            <Typography variant="darkValue" sx={{ width: '110px' }}>
                                                Enter PAN of stamp duty payer <br /> (
                                                {
                                                    paymentConfig?.validateStampDutyResponse
                                                      ?.panCardRequiredPersonName
                                                }
                                                )
                                            </Typography>
                                        </Grid>
                                        <Grid ml={2} lg={12} sm={12} md={12} xs={12}>
                                            <TextField
                                                error={
                                                    !(
                                                      paymentSummaryForm?.errors?.panCard ===
                                                            'error' ||
                                                        paymentSummaryForm?.errors?.panCard ===
                                                            'noerror'
                                                    )
                                                }
                                                helperText={
                                                    paymentSummaryForm?.errors?.panCard ===
                                                        'error' ||
                                                    paymentSummaryForm?.errors?.panCard ===
                                                        'noerror'
                                                      ? ''
                                                      : paymentSummaryForm?.errors?.panCard
                                                }
                                                inputProps={{
                                                  type: 'text',
                                                  maxlength: 10,
                                                  minlength: 10
                                                }}
                                                required
                                                type="text"
                                                id="panCard"
                                                name="panCard"
                                                value={paymentSummaryForm?.panCard || ''}
                                                onChange={handlePancardChange}
                                                placeholder="Enter PAN"
                                                fullWidth
                                                style={{ marginBottom: '20px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {/* Storage Type */}
                                <Grid mb={2} display="flex">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Storage type</Typography>
                                    </div>
                                    {/* <div className="FieldBoxInput">
                                        <Typography variant="darkValue">
                                            {paymentConfig?.docType?.name}
                                        </Typography>
                                    </div> */}

                                    {/* <Grid ml={2} lg={12} sm={12} md={12} xs={12}> */}
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="storageType"
                                            value={paymentSummaryForm?.storageType}
                                            onChange={handleStorageTypeChange}>
                                            {storageTypeList.length > 0 &&
                                                storageTypeList.map((storageOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={storageOption?.id}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                  color: palette.text.main,
                                                                  '&.Mui-checked': {
                                                                    color: palette.success.dark
                                                                  }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant="darkValue">
                                                                {storageOption?.label}
                                                            </Typography>
                                                        }
                                                    />
                                                ))}
                                        </RadioGroup>
                                    </FormControl>
                                    {/* </Grid> */}
                                </Grid>

                                {/* Order Type */}
                                <Grid mb={2} display="flex">
                                    <div className="FieldBoxLabel">
                                        <Typography variant="darkValue">Document type</Typography>
                                    </div>
                                    <div className="FieldBoxInput">
                                        <Typography variant="darkValue">
                                            {paymentConfig?.docType?.name}
                                        </Typography>
                                    </div>
                                    {/* <Typography variant="darkValue" component="div">
                                        Document type
                                    </Typography>
                                    <Typography variant="darkValue">
                                        {paymentConfig?.docType?.name}
                                    </Typography> */}
                                    {/* <Grid ml={2} lg={12} sm={12} md={12} xs={12}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="orderType"
                                                value={paymentSummaryForm?.orderType}
                                                onChange={handleOrderTypeChange}>
                                                {orderTypesArray.length > 0 &&
                                                    orderTypesArray.map((orderType, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            value={orderType?.id}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                      color: palette.text.main,
                                                                      '&.Mui-checked': {
                                                                        color: palette.success
                                                                          .dark
                                                                      }
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <Typography variant="darkValue">
                                                                    {orderType?.name}
                                                                </Typography>
                                                            }
                                                        />
                                                    ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </CustomCard>
                        {/* Shipping address  */}
                        {paymentConfig?.docType?.id === DOCTYPE?.PHYSICAL?.ID && (
                            <CustomCard
                                headerTitle="Document details"
                                action={
                                    <FormControlLabel
                                        sx={{ mt: '-10px', mr: '0px' }}
                                        control={
                                            <Checkbox
                                                sx={{
                                                  color: palette.text.main,
                                                  '&.Mui-checked': {
                                                    color: palette.success.dark
                                                  }
                                                }}
                                                checked={useProfileAddress}
                                                name="useProfileAddress"
                                                id="useProfileAddress"
                                                onChange={handleUseProfileAddress}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                  color: palette.success.light,
                                                  fontWeight: 600,
                                                  fontSize: '15px'
                                                }}>
                                                Use my profile address
                                            </Typography>
                                        }
                                    />
                                }>
                                <Grid p={3}>
                                    <Grid mb={2} display="flex">
                                        <Typography
                                            sx={{ width: '110px' }}
                                            variant="darkValue"
                                            component="div">
                                            Name
                                        </Typography>
                                        <Grid item ml={2} lg={12} sm={12} md={12} xs={12}>
                                            <TextField
                                                value={paymentSummaryForm?.name || ''}
                                                required
                                                id="name"
                                                inputProps={{
                                                  type: 'text',
                                                  maxlength:
                                                        CLVALIDATIONS?.DELIVERYNAME?.MAXLENGTH,
                                                  minlength:
                                                        CLVALIDATIONS?.DELIVERYNAME?.MINLENGTH
                                                }}
                                                name="name"
                                                type="text"
                                                fullWidth
                                                onChange={(event) => {
                                                  if (
                                                    event.target.value === '' ||
                                                        CLVALIDATIONS?.DELIVERYNAME?.PATTERN.test(
                                                          event?.target?.value
                                                        )
                                                  ) {
                                                    handlePaymentFormChange(event)
                                                  }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid mb={2} display="flex">
                                        <Typography
                                            sx={{ width: '110px' }}
                                            variant="darkValue"
                                            component="div">
                                            Address Line 1
                                        </Typography>
                                        <Grid item ml={2} lg={12} sm={12} md={12} xs={12}>
                                            <TextField
                                                value={paymentSummaryForm?.address || ''}
                                                required
                                                inputProps={{
                                                  type: 'text',
                                                  maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                                  minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                                }}
                                                id="address"
                                                name="address"
                                                type="text"
                                                fullWidth
                                                onChange={(event) => {
                                                  if (
                                                    event.target.value === '' ||
                                                        CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                          event?.target?.value
                                                        )
                                                  ) {
                                                    handlePaymentFormChange(event)
                                                  }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid mb={2} display="flex">
                                        <Typography
                                            sx={{ width: '110px' }}
                                            variant="darkValue"
                                            component="div">
                                            Address Line 2
                                        </Typography>
                                        <Grid item ml={2} lg={12} sm={12} md={12} xs={12}>
                                            <TextField
                                                value={paymentSummaryForm?.addressLineTwo || ''}
                                                required
                                                inputProps={{
                                                  type: 'text',
                                                  maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                                  minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                                }}
                                                id="address"
                                                name="addressLineTwo"
                                                type="text"
                                                fullWidth
                                                onChange={(event) => {
                                                  if (
                                                    event.target.value === '' ||
                                                        CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                          event?.target?.value
                                                        )
                                                  ) {
                                                    handlePaymentFormChange(event)
                                                  }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid mb={2} display="flex">
                                        <div className="shippingAddress_grid-title">
                                            <Typography variant="darkValue">State</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <Select
                                                name="stateId"
                                                value={paymentSummaryForm?.stateId || ''}
                                                onChange={(event) => {
                                                  setPaymentSummaryForm((prev) => ({
                                                    ...prev,
                                                    [event.target.name]: event.target.value,
                                                    cityId: ''
                                                  }))
                                                }}
                                                required
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ fontSize: '16px' }}
                                                MenuProps={MenuProps}
                                                fullWidth>
                                                <MenuItem value="" disabled>
                                                    <Typography>Select State</Typography>
                                                </MenuItem>

                                                {stateArray &&
                                                    stateArray.map((item) => (
                                                        <MenuItem
                                                            sx={{ fontSize: '16px' }}
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                        <div
                                            className="shippingAddress_grid-title"
                                            style={{ justifyContent: 'center' }}>
                                            <Typography variant="darkValue">City</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <Select
                                                name="cityId"
                                                value={paymentSummaryForm?.cityId || ''}
                                                onChange={(event) => {
                                                  setPaymentSummaryForm((prev) => ({
                                                    ...prev,
                                                    [event.target.name]: event.target.value
                                                  }))
                                                }}
                                                required
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ fontSize: '16px' }}
                                                MenuProps={MenuProps}
                                                fullWidth>
                                                <MenuItem value="" disabled>
                                                    <Typography>Select City</Typography>
                                                </MenuItem>
                                                {cityArray &&
                                                    cityArray.map((item) => (
                                                        <MenuItem
                                                            sx={{ fontSize: '16px' }}
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid mb={2} display="flex">
                                        <div className="shippingAddress_grid-title">
                                            <Typography variant="darkValue">Pincode</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <TextField
                                                value={paymentSummaryForm?.pinCode || ''}
                                                required
                                                id="pinCode"
                                                name="pinCode"
                                                type="text"
                                                inputProps={{
                                                  type: 'text',
                                                  // inputMode: 'numeric',
                                                  pattern: '[0-9]*',
                                                  maxlength: 6,
                                                  minlength: 6
                                                }}
                                                // onKeyDown={(e) =>
                                                //   exceptThisSymbols.includes(e.key) &&
                                                //     e.preventDefault()
                                                // }
                                                fullWidth
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value === '' ||
                                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                                  ) {
                                                    handlePaymentFormPinCodeChange(e)
                                                  }
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="shippingAddress_grid-title"
                                            style={{ justifyContent: 'center' }}>
                                            <Typography variant="darkValue">Phone</Typography>
                                        </div>
                                        <div className="shippingAddress_grid-field">
                                            <TextField
                                                value={paymentSummaryForm?.phone || ''}
                                                required
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                inputProps={{
                                                  type: 'text',
                                                  // inputMode: 'numeric',
                                                  pattern: '[0-9]*',
                                                  maxlength: 10,
                                                  minlength: 10
                                                }}
                                                // onKeyDown={(e) =>
                                                //   exceptThisSymbols.includes(e.key) &&
                                                //     e.preventDefault()
                                                // }
                                                // onInput={(e) => {
                                                //   e.target.value = Math.max(
                                                //     0,
                                                //     parseInt(e.target.value)
                                                //   )
                                                //     .toString()
                                                //     .slice(0, 10)
                                                // }}
                                                fullWidth
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value === '' ||
                                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                                  ) {
                                                    handlePaymentFormChange(e)
                                                  }
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </CustomCard>
                        )}
                        <Grid container direction="column">
                            <FormControlLabel
                                sx={{ mb: '15px', mr: 0 }}
                                control={
                                    <Checkbox
                                        required
                                        sx={{
                                          color: palette.text.main,
                                          '&.Mui-checked': {
                                            color: palette.success.dark
                                          }
                                        }}
                                        checked={paymentTerms?.paymentTerms}
                                        name="paymentTerms"
                                        id="paymentTerms"
                                        onChange={(event) =>
                                          setPaymentTerms((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.checked
                                          }))
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        variant="modalLabel"
                                        sx={{ color: palette.text.dark }}>
                                        I agree, &nbsp; MDR charges will be added by payment gateway
                                        on top of amount shown.
                                    </Typography>
                                }
                            />
                            {/* <FormControlLabel
                                sx={{ mb: '15px', mr: 0 }}
                                control={
                                    <Checkbox
                                        required
                                        sx={{
                                          color: palette.text.main,
                                          '&.Mui-checked': {
                                            color: palette.success.dark
                                          }
                                        }}
                                        checked={
                                            paymentTerms[`${TERMTYPES.TERMSANDCONDITIONS.value}`]
                                        }
                                        name={TERMTYPES.TERMSANDCONDITIONS.value}
                                        id={TERMTYPES.TERMSANDCONDITIONS.value}
                                        onChange={(event) =>
                                          setPaymentTerms((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.checked
                                          }))
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        variant="modalLabel"
                                        sx={{ color: palette.text.dark }}>
                                        I agree with &nbsp;
                                        <Link
                                            to={`/termsAndConditions/${TERMTYPES.TERMSANDCONDITIONS.value}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-link"
                                            color="var(--g7)">
                                            Terms and Conditions.
                                        </Link>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                sx={{ mb: '15px', mr: 0 }}
                                control={
                                    <Checkbox
                                        required
                                        sx={{
                                          color: palette.text.main,
                                          '&.Mui-checked': {
                                            color: palette.success.dark
                                          }
                                        }}
                                        checked={paymentTerms[`${TERMTYPES.DISCLAIMER.value}`]}
                                        name={TERMTYPES.DISCLAIMER.value}
                                        id={TERMTYPES.DISCLAIMER.value}
                                        onChange={(event) =>
                                          setPaymentTerms((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.checked
                                          }))
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        variant="modalLabel"
                                        sx={{ color: palette.text.dark }}>
                                        I agree with&nbsp;
                                        <Link
                                            to={`/termsAndConditions/${TERMTYPES.DISCLAIMER.value}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-link"
                                            color="var(--g7)">
                                            Disclaimer.
                                        </Link>
                                    </Typography>
                                }
                            /> */}

                            <Button variant="primary" type="submit" sx={{ width: '30%' }}>
                                Proceed to payment
                            </Button>
                        </Grid>
                    </form>
                </Grid>

                <Grid lg={6} sm={12} md={12} xs={12} item>
                    <CustomCard headerTitle={'Payment Summary'}>
                        <Grid sx={{ backgroundColor: '#f1f4f7' }}>
                            <Grid className="paymentSummary_container">
                                <div className="paymentSummary_container-title">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        Stamp duty amount
                                    </Typography>
                                </div>
                                <div className="paymentSummary_container-field">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {COMMON?.RUPEESYMBOL}
                                        {documentPaymentDetails?.stampDuty
                                          ? documentPaymentDetails?.stampDuty
                                          : 0}
                                        /-
                                    </Typography>
                                </div>
                            </Grid>
                            {documentPaymentDetails?.miscChargePercent?.map((item) => (
                                <Grid key={item.id} className="paymentSummary_container">
                                    <div className="paymentSummary_container-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {item.valueName} {` (${item.value}%)`}
                                        </Typography>
                                    </div>
                                    <div className="paymentSummary_container-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {item.defaultValue}
                                            /-
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                            {paymentConfig?.miscChargesStatic?.map((item) => (
                                <Grid
                                    key={item.id}
                                    sx={{ borderBottom: `0.5px solid ${palette.text.light}` }}
                                    className="paymentSummary_container">
                                    <div className="paymentSummary_container-title">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {item.valueName}
                                        </Typography>
                                    </div>
                                    <div className="paymentSummary_container-field">
                                        <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                            {COMMON?.RUPEESYMBOL}
                                            {item.value}
                                            /-
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid sx={{ backgroundColor: '#ffffff' }}>
                            {paymentDetails &&
                                paymentDetails.map((item, value) => (
                                    <Grid className="paymentSummary_container" key={value}>
                                        <div className="paymentSummary_container-title">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {item.title}
                                            </Typography>
                                        </div>
                                        <div className="paymentSummary_container-field">
                                            <Typography
                                                variant="darkValue"
                                                sx={{ fontSize: '15px' }}>
                                                {COMMON?.RUPEESYMBOL}
                                                {documentPaymentDetails[`${item?.amountTitle}`]
                                                  ? documentPaymentDetails[`${item?.amountTitle}`]
                                                  : 0}
                                                /-
                                            </Typography>
                                        </div>
                                    </Grid>
                                ))}

                            <Grid className="paymentSummary_container">
                                <div className="paymentSummary_container-title">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        Stoarge charges
                                        {`(${
                                            Number(paymentSummaryForm?.storageType) ===
                                            STORAGETYPEOPTIONS?.FREETIER?.id
                                                ? STORAGETYPEOPTIONS?.FREETIER?.label
                                                : STORAGETYPEOPTIONS?.PAIDTIER?.label
                                        })`}
                                    </Typography>
                                </div>
                                <div className="paymentSummary_container-field">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {COMMON?.RUPEESYMBOL}
                                        {documentPaymentDetails?.storageCharge
                                          ? documentPaymentDetails?.storageCharge.toFixed(2)
                                          : 0}
                                        /-
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid className="paymentSummary_container">
                                <div className="paymentSummary_container-title">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        eSign charges
                                        {`(${paymentConfig?.esignRequired} Signatures Required)`}
                                    </Typography>
                                </div>
                                <div className="paymentSummary_container-field">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {COMMON?.RUPEESYMBOL}
                                        {paymentConfig?.esignCharge
                                          ? paymentConfig?.esignCharge.toFixed(2)
                                          : 0}
                                        /-
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid className="paymentSummary_container">
                                <div className="paymentSummary_container-title">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        Total charges
                                    </Typography>
                                </div>
                                <div className="paymentSummary_container-field">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {COMMON?.RUPEESYMBOL}
                                        {documentPaymentDetails?.totalGstCharge
                                          ? documentPaymentDetails?.totalGstCharge.toFixed(2)
                                          : 0}
                                        /-
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid className="paymentSummary_container">
                                <div className="paymentSummary_container-title">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        Gst {`(${paymentConfig?.gst}%)`}
                                    </Typography>
                                </div>
                                <div className="paymentSummary_container-field">
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {COMMON?.RUPEESYMBOL}
                                        {documentPaymentDetails?.gst
                                          ? documentPaymentDetails?.gst.toFixed(2)
                                          : 0}
                                        /-
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{ background: palette.text.main }}
                            className="paymentSummary_total">
                            <div className="paymentSummary_container-title">
                                <Typography variant="label" sx={{ fontSize: '15px' }}>
                                    Total
                                </Typography>
                            </div>
                            <div className="paymentSummary_container-field">
                                <Typography variant="label" sx={{ fontSize: '15px' }}>
                                    {COMMON?.RUPEESYMBOL} {documentPaymentDetails?.total.toFixed(2)}{' '}
                                    /-
                                </Typography>
                            </div>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            <AlertModal
                openAlertModal={openAlertModal}
                handleAlertModalClose={() => setOpenAlertModal(false)}
                handleSubmitAlertModal={
                    profile?.role === ROLES?.INDIVIDUAL
                      ? handleSubmitAlertModal
                      : handlePaymentStatusCorpBa
                }
                alertModalState={alertModalState}
            />
            {backdropState && <BackdropLoader backdropState={backdropState} />}
            <SignatureModal
                openSignModal={signatureModalState}
                signatureModalDataState={signatureModalDataState}
                handleSubmitSignModal={() => {
                  navigate(`/previewEsignPage/${documentId}`, {
                    state: {
                      HeaderTitle: 'Signature Selection'
                    }
                  })
                }}
                handleCancelSignModal={() => {
                  setSignatureModalState(false)
                  navigate('/myDocument', {
                    state: {
                      HeaderTitle: 'My Document'
                    }
                  })
                }}
            />
            {notesModalState?.open && (
                <CommonMessageModal
                    CommonModalState={notesModalState}
                    handleOkButtonCommonModal={() => {
                      navigate('/myDocument', {
                        state: {
                          HeaderTitle: 'My Documents'
                        }
                      })
                    }}
                />
            )}
        </Box>
  )
}

export default PaymentSummary
