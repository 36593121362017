import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useContext, useState } from 'react'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import { formatDate } from '../../../../util/helper'
import palette from '../../../../theme/palette'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import PropTypes from 'prop-types'
import downloadicon from '../../../../assets/actions/downloadIcon.svg'
import actionDownloadInvoice from '../../../../assets/actions/actionDownloadInvoice.svg'
import { ProfileContext } from '../../../../contextapi/ProfileContext'
import { EVENT_LOG_TYPES, ROLES } from '../../../../constants/enum'
import { useLogInvoiceDownloadEventMutation } from '../../../../store/services/userServices'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'

const InvoiceTable = ({
  invoiceTableData,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  const { profile } = useContext(ProfileContext)

  // Table headers for each user
  const invoiceTableHeaders = [
    'Transaction No.',
    'Document Id',
    'Title',
    'Amount',
    'Date',
    'Action'
  ]

  const [backdropState, setBackdropState] = useState(false)
  if (profile?.role !== ROLES.INDIVIDUAL) {
    invoiceTableHeaders.unshift('Invoice No.')
  }
  // pagination
  //   const [page, setPage] = useState(0)
  //   const [rowsPerPage, setRowsPerPage] = useState(10)
  const [logDownloadInvoiceEvent] = useLogInvoiceDownloadEventMutation({})

  const handleChangePage = (event, newPage) => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))

    // setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))

    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  const handleDownloadClick = (link, row) => {
    const dataToSend = {
      auditEventType: EVENT_LOG_TYPES.INVOICEDOWNLOAD.value,
      invoiceId: row?.invoiceFile?.id
    }
    setBackdropState(true)
    logDownloadInvoiceEvent(dataToSend)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        // toast.success(response?.message)
      })
      .catch((exception) => {
        setBackdropState(false)
        console.log(exception)
      })
  }
  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {invoiceTableHeaders.length > 0 &&
                                invoiceTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {invoiceTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (rowsPerPage > 0
                            //   ? invoiceTableData?.slice(
                            //     page * rowsPerPage,
                            //     page * rowsPerPage + rowsPerPage
                            //   )
                            //   : invoiceTableData
                            // )
                            invoiceTableData?.map((invoiceData, index) => (
                                <TableRow key={index}>
                                    {profile?.role !== ROLES.INDIVIDUAL && (
                                        <StyledTableCell align="left">
                                            {invoiceData?.id}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                        {invoiceData?.transId}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {invoiceData?.documentId}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {invoiceData?.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {invoiceData?.amount}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {formatDate(invoiceData?.createdOn)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="actionIcons">
                                            <>
                                                <a
                                                    style={{ paddingTop: '5px' }}
                                                    href={invoiceData?.receiptFile?.link}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img
                                                        src={downloadicon}
                                                        alt="download icon"
                                                        title="Download Payment Receipt"
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </a>
                                                {profile?.role !== ROLES.INDIVIDUAL &&
                                                    invoiceData?.isInvoiceGenerated && (
                                                        <>
                                                            <a
                                                                style={{ paddingTop: '5px' }}
                                                                onClick={(e) => {
                                                                  e.preventDefault()
                                                                  handleDownloadClick(
                                                                    invoiceData?.invoiceFile
                                                                      ?.link,
                                                                    invoiceData
                                                                  )
                                                                }}
                                                                href={
                                                                    invoiceData?.invoiceFile?.link
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer">
                                                                <img
                                                                    src={actionDownloadInvoice}
                                                                    alt="download icon"
                                                                    title="Download Invoice"
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </a>
                                                        </>
                                                )}
                                            </>
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {invoiceTableData?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* pagination calling here */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Paper>
  )
}

export default InvoiceTable
InvoiceTable.propTypes = {
  invoiceTableData: PropTypes.array,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
