import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { documentApi } from './services/documentServices'
import { helpApi } from './services/helpServices'
import { paymentApi } from './services/paymentServices'
import { reviewConsentApi } from './services/reviewConsentService'
import { subscriptionApi } from './services/subscription'
import { userApi } from './services/userServices'
import { manageStaffUserApi } from './services/manageStaffUserServices'
import { uploadDocumentApi } from './services/uploadDocumentServices'
import { profileAPi } from './services/profleServices'
import { commonApi } from './services/commonServices'
import { walletTransactionApi } from './services/walletTransactionServices'
import { signatureApi } from './services/signatureService'

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [profileAPi.reducerPath]: profileAPi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [walletTransactionApi.reducerPath]: walletTransactionApi.reducer,
    [uploadDocumentApi.reducerPath]: uploadDocumentApi.reducer,
    [manageStaffUserApi.reducerPath]: manageStaffUserApi.reducer,
    [reviewConsentApi.reducerPath]: reviewConsentApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [helpApi.reducerPath]: helpApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [signatureApi.reducerPath]: signatureApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      userApi.middleware,
      profileAPi.middleware,
      subscriptionApi.middleware,
      walletTransactionApi.middleware,
      documentApi.middleware,
      uploadDocumentApi.middleware,
      manageStaffUserApi.middleware,
      reviewConsentApi.middleware,
      paymentApi.middleware,
      helpApi.middleware,
      commonApi.middleware,
      signatureApi.middleware
    )
})

// Export default store;
setupListeners(store.dispatch)
