import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({
    generateOrderId: builder.mutation({
      query: ({ payload }) => ({
        url: 'transaction/order',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    updatePaymentStatus: builder.mutation({
      query: ({ payload }) => ({
        url: 'transaction/save',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    })
  })
})

export const { useGenerateOrderIdMutation, useUpdatePaymentStatusMutation } = paymentApi
