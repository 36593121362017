import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import palette from '../../../../theme/palette'
import viewicon from '../../../../assets/actions/actionView.svg'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const BulkTransactionDocumentDetailsTable = ({ bulkDocumentDetailsList, isLoading }) => {
  const navigate = useNavigate()

  // pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const bulkTransactionDocumentTableHeaders = [
    'Document ID(URN)',
    'Article Type',
    'Template type',
    'Document Type',
    'Location',
    'Created By',
    'Role',
    'Associated Branch',
    'Status',
    'Actions'
  ]

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {bulkTransactionDocumentTableHeaders?.length > 0 &&
                                bulkTransactionDocumentTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {bulkTransactionDocumentTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {(rowsPerPage > 0
                          ? bulkDocumentDetailsList?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          : bulkDocumentDetailsList
                        )?.map((document, index) => (
                            <TableRow key={index}>
                                <StyledTableCell scope="row">
                                    {document?.documentId}({document?.urnNumber})
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {document?.articleCategory?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {document?.articleTemplate?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {document?.docDelType || 'NA'}
                                </StyledTableCell>
                                <StyledTableCell align="left">{document?.location}</StyledTableCell>
                                <StyledTableCell align="left">
                                    {document?.createdBy?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">{document?.role}</StyledTableCell>
                                <StyledTableCell scope="row">
                                    {document?.associatedBranch}
                                </StyledTableCell>
                                <StyledTableCell scope="row">
                                    {document?.status?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <div className="actionIcons">
                                        <img
                                            src={viewicon}
                                            alt="viewicon"
                                            title="View document"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              navigate(
                                                    `/myDocument/bulkTransaction/bulkIndividualDocumentDetails/${document?.documentId}`
                                              )
                                            }
                                        />
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {bulkDocumentDetailsList?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* pagination calling here */}
            <CustomTablePagination
                count={bulkDocumentDetailsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
  )
}

export default BulkTransactionDocumentDetailsTable

BulkTransactionDocumentDetailsTable.propTypes = {
  bulkDocumentDetailsList: PropTypes.array,
  isLoading: PropTypes.bool
}
