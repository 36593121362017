import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material'
import palette from '../../theme/palette'

export const ReviewStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: palette.text.main,
    color: palette.primary.light,
    fontSize: '14x',
    fontWeight: 400
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: palette.primary.light,
    color: palette.text.dark,
    fontSize: '14px',
    fontWeight: 400
  }
}))

export const ReviewStyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))
