import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const helpApi = createApi({
  reducerPath: 'helpApi',
  refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 0,
  // refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllTickets', 'getTicketById'],
  endpoints: (builder) => ({
    getFaqTopics: builder.query({
      query: () => ({
        url: 'faq/topics',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getFaqQuesById: builder.query({
      query: (topicId) => ({
        url: `faq/${topicId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getAllTickets: builder.query({
      query: (params) => ({
        url: 'support/ticket/getUserTickets',
        method: 'GET',
        params
      }),
      providesTags: ['getAllTickets'],
      transformResponse: (response) => response?.data
    }),
    addTicket: builder.mutation({
      query: ({ payload }) => ({
        url: 'support/ticket/create',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllTickets'],
      transformResponse: (response) => response
    }),
    getTicketById: builder.query({
      query: (ticketNumber) => ({
        url: `support/ticket/get?ticketId=${ticketNumber}`,
        method: 'GET'
      }),
      providesTags: ['getTicketById'],
      transformResponse: (response) => response?.data
    }),
    deleteTicket: builder.mutation({
      query: ({ payload }) => ({
        url: 'docs/templ/make-doc',
        method: 'DELETE',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    getCategoryOfIssues: builder.query({
      query: () => ({
        url: 'support/ticket/categories',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getIssuesTypeByCategory: builder.query({
      query: (categoryId) => ({
        url: `support/ticket/types?categoryId=${categoryId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),

    getIssuesByIsssueType: builder.query({
      query: (issueTypeId) => ({
        url: `support/ticket/issues?subCategoryId=${issueTypeId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getAllTicketFilterStatus: builder.query({
      query: () => ({
        url: 'support/ticket/status',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    updateTicketStatus: builder.mutation({
      query: ({ payload }) => ({
        url: 'support/ticket/update',
        method: 'PUT',
        payload
      }),
      invalidatesTags: ['getTicketById', 'getAllTickets'],
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetFaqTopicsQuery,
  useGetFaqQuesByIdQuery,
  useGetAllTicketsQuery,
  useGetCategoryOfIssuesQuery,
  useAddTicketMutation,
  useDeleteTicketMutation,
  useGetTicketByIdQuery,
  useGetIssuesTypeByCategoryQuery,
  useGetIssuesByIsssueTypeQuery,
  useGetAllTicketFilterStatusQuery,
  useUpdateTicketStatusMutation
} = helpApi
