import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const profileAPi = createApi({
  reducerPath: 'profileAPi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  // refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getProfile', 'getBillingSubInfo'],
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: 'user/profile',
        method: 'GET'
      }),
      providesTags: ['getProfile'],
      transformResponse: (response) => response?.data
    }),
    updateProfile: builder.mutation({
      query: ({ payload }) => ({
        url: 'user/update',
        method: 'PUT',
        payload
      }),
      invalidatesTags: ['getProfile'],
      transformResponse: (response) => response?.data
    }),
    AddbankAccountDetails: builder.mutation({
      query: ({ payload }) => ({
        url: 'bank-details',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getProfile'],
      transformResponse: (response) => response
    }),
    editbankAccountDetails: builder.mutation({
      query: ({ payload }) => ({
        url: 'bank-details',
        method: 'PUT',
        payload
      }),
      invalidatesTags: ['getProfile'],
      transformResponse: (response) => response
    }),
    getBillingSubInfo: builder.query({
      query: () => ({
        url: 'plans/billing',
        method: 'GET'
      }),
      providesTags: ['getBillingSubInfo'],
      transformResponse: (response) => response?.data
    }),
    updateBillingInfo: builder.mutation({
      query: ({ payload }) => ({
        url: 'plans/billing/update',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    renewSubscription: builder.mutation({
      query: ({ payload }) => ({
        url: 'subscription/renew',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: 'subscription/cancel',
        method: 'PUT'
      }),
      invalidatesTags: ['getBillingSubInfo'],
      transformResponse: (response) => response
    }),
    getAssociatedBrnach: builder.query({
      query: () => ({
        url: 'branches/getAssociatedBranch',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    })
  })
})

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
  useGetBillingSubInfoQuery,
  useAddbankAccountDetailsMutation,
  useEditbankAccountDetailsMutation,
  useUpdateBillingInfoMutation,
  useRenewSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetAssociatedBrnachQuery
} = profileAPi
